import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { memorialSide } from '../../serverApi/server';
import { useNavigate } from 'react-router-dom';
import Skeleton from '../../pages/skeleton/Skeleton';

const MemorialSlide = () => {
  const navigate = useNavigate();
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  const [Slides, setSlides] = useState([]);

  const fetchMemorialImages = async () => {
    const product = 'memorial_funeral'
    try {
      const getMemorialImagesResponse = await memorialSide(product);
      setSlides(getMemorialImagesResponse.data.memorial_products_serializer.slice(0, 14));
    } catch (error) {
      console.error('errorMemorialImage:' ,error)
    }
  }

  useEffect(  () => {
    fetchMemorialImages();
  }, [])

  const [loadingTime, setLoadingTime] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTime(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [])

  return (
    <div>
      {loadingTime ? (
        <>
          <div className='hidden lg:grid grid-cols-6 gap-[10px]'>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
          </div>

          <div className='hidden md:grid lg:hidden grid-cols-4 gap-[10px]'>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
          </div>

          <div className='hidden sm:grid md:hidden grid-cols-3 gap-[10px]'>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
          </div>

          <div className='grid sm:hidden grid-cols-2 gap-[10px]'>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
            <div className='border'>
              <div className='w-auto h-40 md:h-52 mb-6'>
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className='flex flex-col w-full items-center'>
                <Skeleton width='100px' height='20px' marginBottom='20px'/>
                <Skeleton width='150px' height='20px' marginBottom='8px'/>
              </div>
            </div>
          </div>
        </>
        ) : (
          <Swiper
            spaceBetween={10}
            centeredSlides={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 6,
              },
            }}
          >
            {Slides.filter(slide => slide.first_menu && slide.first_menu.category_name !== 'Our Allied Companies').map((slide) => (
              
              <SwiperSlide key={slide.first_menu.id}>
                <div onClick={() => navigate(`/shopdetails/${slide.first_menu.id}`)} className="relative flex flex-col items-center justify-center border cursor-pointer">
                  <div
                    className='relative h-52 overflow-hidden bg-transparent'
                    onClick={handleImageClick}
                  >
                    <img
                      className={`transition-transform h-full w-full duration-500 ease-in-out hover:scale-125 ${isZoomed ? 'scale-125' : ''}`}
                      src={slide.first_menu.image_file} alt={slide.first_menu.menu_name}
                    />
                  </div>
                  <div className='w-full border-t'>
                    <h3 className='text-center px-2 py-2'>{slide.first_menu.category_name}</h3>
                  </div>
                  <div className='border-t w-full'> 
                    <div className="flex py-3 px-1 sm:px-2 justify-center items-center hover:text-orange-400 text-gray-700 gap-1 bg-gray-100">
                      <FaEye />
                      <button>
                        View Detail
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
      )}
    </div>
  );
};

export default MemorialSlide;