import React from 'react';
import { decryptToken, getCookie } from '../components/Helper/Helpers'


const PrivateRoute = ({ component: Component, ...rest }) => {
    const decryptedRefreshToken = decryptToken(getCookie('_prtsap'));
    const decryptedAccessToken = decryptToken(getCookie('_patsap'));
    return decryptedRefreshToken && decryptedAccessToken ? <Component {...rest} /> : window.location.pathname = '/';
}

export default PrivateRoute;
