import React, { useRef, useState } from "react";
import tickImg from "../assets/paymentSuccess.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadFormImagesDetails } from "../serverApi/server";
import { MdError } from "react-icons/md";

const PDFUploadForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, orderId, paymentId, headerMenu } = location.state || {};

  console.log('\n userId', userId)
  console.log('\n orderId', orderId)
  console.log('\n paymentId', paymentId)
  console.log('\n headerMenu', headerMenu)

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    dateOfBirth: "",
    dateOfDeath: "",
    funeralDetails: '',
    obituaryPoems: '',
    addiInformation: '',
    mainCover: [],
    backCover: [],
    orderOfService: [],
    multiplePhotos: [],
  });
  
  const refs = {
    name: useRef(),
    dateOfBirth: useRef(null),
    dateOfDeath: useRef(null),
    funeralDetails: useRef(),
    obituaryPoems: useRef(),
    addiInformation: useRef(),
    mainCover: useRef(),
    backCover: useRef(),
    orderOfService: useRef(),
    multiplePhotos: useRef(),
  };
  
  const [errors, setErrors] = useState({ 
    name: false,
    dateOfBirth: false,
    dateOfDeath: false,
    funeralDetails: false,
    obituaryPoems: false,
    addiInformation: false,
    mainCover: false,
    backCover: false,
    orderOfService: false,
    multiplePhotos: false,
  });
  
  const [preview, setPreview] = useState({
    mainCover: null,
    backCover: null,
    orderOfService: null,
    multiplePhotos: null,
  });
  
  const [multipleFileCount, setMultipleFileCount] = useState({
    orderOfService: '',
    multiplePhotos: '',
  });
  
  const handleFileChange = (e) => {
    const name  = e.target.name;
    const file  = e.target.files[0];
    if (name === 'multiplePhotos' || name === 'orderOfService') {
      const files = Array.from(e.target.files);
      if (files.length > 1) {
        setMultipleFileCount((prev) => ({
          ...prev,
          [name]: files.length,
      }));
      };
      if (files.length < 2) {
        setMultipleFileCount((prev) => ({
          ...prev,
          [name]: '',
      }));
      };
      if (files.length > 0) {
        setFormData({
          ...formData,
          [name]: files,
        });

        const firstFile = files[0];
        const reader = new FileReader();

        if (firstFile.type.startsWith('image/')) {
          reader.onload = () => {
            setPreview((prev) => ({
              ...prev,
              [name]: reader.result,
            }));
          };
          reader.readAsDataURL(firstFile);
        } else if (firstFile.type === 'application/pdf') {
          const url = URL.createObjectURL(firstFile);
          setPreview((prev) => ({
            ...prev,
            [name]: { type: 'pdf', url },
          }));
        };
      };
      return;
    };
    if (file) {
      setFormData({
        ...formData,
        [name]: file
      });
      const reader = new FileReader();
  
      if (file.type.startsWith('image/')) {
        reader.onload = () => {
          setPreview((prev) => ({
            ...prev,
            [name]: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      } else if (file.type === 'application/pdf') {
        const url = URL.createObjectURL(file);
        setPreview((prev) => ({
          ...prev,
          [name]: { type: 'pdf', url },
        }));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: false
    }));
    if (name === 'dateOfBirth' || name === 'dateOfDeath') {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value.replace(/\s{2,}/g, " ")
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newError = Object.keys(formData).reduce((errors, key) => {
      if (!errors) {
        errors = {};
      }
      if (key !== 'mainCover' && key !== 'backCover' && key !== 'orderOfService' && key !== 'addiInformation') {
        errors[key] = !formData[key] || 
          (Array.isArray(formData[key]) ? formData[key].length === 0 : !formData[key].toString().trim());
      }
      return errors;
    }, {});
    
    setErrors(newError);
    const firstErrorKey = Object.keys(newError).find((key) => newError[key]);
  
    if (firstErrorKey) {
      refs[firstErrorKey]?.current?.focus();
      setIsLoading(false);
      return;
    }
    const subFormData = new FormData();
    subFormData.append("form_details", JSON.stringify({
      name: formData.name,
      dateOfBirth: formData.dateOfBirth,
      dateOfDeath: formData.dateOfDeath,
      funeralDetails: formData.funeralDetails,
      obituaryPoems: formData.obituaryPoems,
      addiInformation: formData.addiInformation,
      userId: userId,
      orderId: orderId,
      paymentId: paymentId
    }));
    
    if (formData.mainCover) subFormData.append("mainCover", formData.mainCover);
    if (formData.backCover) subFormData.append("backCover", formData.backCover);
    
    formData.orderOfService.forEach((file, index) => {
      subFormData.append(`orderOfService[${index}]`, file);
    });
    
    formData.multiplePhotos.forEach((file, index) => {
      subFormData.append(`multiplePhotos[${index}]`, file);
    });

    var uploadType = 'memorial_funeral'
    subFormData.append("uploadType", uploadType);
    if (!userId && !orderId && !paymentId && !headerMenu) {
      setIsLoading(false);
      return;
    }
    const uploadFormImagesDetailsAPIResponse = await uploadFormImagesDetails(subFormData);
    setIsLoading(false);
    navigate("/");

  };

  const [marketingEssentialsForm, setMarketingEssentialsForm] = useState({
    name: '',
    addiInformation: '',
    multiplePhotos: [],
  });
  const marketingEssentialsRefs = {
    name: useRef(),
    addiInformation: useRef(),
    multiplePhotos: useRef(),
  };
  const [marketingEssentialserrors, setMarketingEssentialsErrors] = useState({ 
    name: false,
    addiInformation: false,
    multiplePhotos: false,
  });
  const [marketingEssentialsPreview, setmarketingEssentialsPreview] = useState({
    multiplePhotos: null,
  });
  const [marketingEssentialsMultipleFileCount, setMarketingEssentialsMultipleFileCount] = useState({
    multiplePhotos: '',
  });
  const handleMarketingEssentialsChange = (e) => {
    const { name, value } = e.target;
    setMarketingEssentialsErrors((prev) => ({
      ...prev,
      [name]: false
    }));
    setMarketingEssentialsForm((prev) => ({
      ...prev,
      [name]: value.replace(/\s{2,}/g, " ")
    }));
  };

  const handleMarketingEssentialsFileChange = (e) => {
    const name  = e.target.name;
    const file  = e.target.files[0];

    if (name === 'multiplePhotos' || name === 'orderOfService') {
      const files = Array.from(e.target.files);
      if (files.length > 1) {
        setMarketingEssentialsMultipleFileCount((prev) => ({
          ...prev,
          [name]: files.length,
      }));
      };
      if (files.length < 2) {
        setMarketingEssentialsMultipleFileCount((prev) => ({
          ...prev,
          [name]: '',
      }));
      };
      if (files.length > 0) {
        setMarketingEssentialsForm({
          ...marketingEssentialsForm,
          [name]: files,
        });

        const firstFile = files[0];
        const reader = new FileReader();

        if (firstFile.type.startsWith('image/')) {
          reader.onload = () => {
            setmarketingEssentialsPreview((prev) => ({
              ...prev,
              [name]: reader.result,
            }));
          };
          reader.readAsDataURL(firstFile);
        } else if (firstFile.type === 'application/pdf') {
          const url = URL.createObjectURL(firstFile);
          setmarketingEssentialsPreview((prev) => ({
            ...prev,
            [name]: { type: 'pdf', url },
          }));
        };
      };
      return;
    };
    if (file) {
      setMarketingEssentialsForm({
        ...marketingEssentialsForm,
        [name]: file
      });
      const reader = new FileReader();
  
      if (file.type.startsWith('image/')) {
        reader.onload = () => {
          setmarketingEssentialsPreview((prev) => ({
            ...prev,
            [name]: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      } else if (file.type === 'application/pdf') {
        const url = URL.createObjectURL(file);
        setmarketingEssentialsPreview((prev) => ({
          ...prev,
          [name]: { type: 'pdf', url },
        }));
      }
    }
  };

  const handleMarketingEssentialsSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newError = Object.keys(marketingEssentialsForm).reduce((errors, key) => {
      if (!errors) {
        errors = {};
      }
      if (key !== 'mainCover' && key !== 'backCover' && key !== 'orderOfService' && key !== 'addiInformation') {
        errors[key] = !marketingEssentialsForm[key] || 
          (Array.isArray(marketingEssentialsForm[key]) ? marketingEssentialsForm[key].length === 0 : !marketingEssentialsForm[key].toString().trim());
      }
      return errors;
    }, {});
    
    setMarketingEssentialsErrors(newError);
    const firstErrorKey = Object.keys(newError).find((key) => newError[key]);
  
    if (firstErrorKey) {
      refs[firstErrorKey]?.current?.focus();
      setIsLoading(false);
      return;
    }
    const marketingEssentialsSubFormData = new FormData();
    marketingEssentialsSubFormData.append("form_details", JSON.stringify({
      name: marketingEssentialsForm.name,
      addiInformation: marketingEssentialsForm.addiInformation,
      userId: userId,
      orderId: orderId,
      paymentId: paymentId
    }));
    
    marketingEssentialsForm.multiplePhotos.forEach((file, index) => {
      marketingEssentialsSubFormData.append(`multiplePhotos[${index}]`, file);
    });

    var uploadType = 'marketing_essentials'
    marketingEssentialsSubFormData.append("uploadType", uploadType);
    if (!userId && !orderId && !paymentId && !headerMenu) {
      setIsLoading(false);
      return;
    }
    const uploadFormImagesDetailsAPIResponse = await uploadFormImagesDetails(marketingEssentialsSubFormData);

    setIsLoading(false);
    navigate("/");
  }


  return (
    <div className="bg-[#F9F9F9] flex items-center py-6 md:py-8 justify-center">
      <div className="w-11/12 min-[500px]:w-3/4 sm:w-2/3 md:w-2/3 min-[840px]:w-2/3 xl:w-1/2 px-4 bg-white shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)] rounded-lg animate-slide-down">
        <div className="flex flex-col items-center gap-2 sm:gap-4 pt-4 pb-2 sm:py-4 border-b border-neutral-500">
          <div className="w-14 sm:w-16 md:w-20">
            <img src={tickImg} alt="tick-image" className="w-full" />
          </div>
          <h1 className="text-[#4CAF50] text-center text-lg sm:text-xl">
            Transaction Complete! Thank you for your purchase.
          </h1>
        </div>
        <div className="flex flex-col sm:items-center gap-3 md:gap-4 pt-2 pb-5 md:py-4 px-2 md:px-5">
          <h1 className="text-neutral-700 text-lg md:text-xl font-medium text-center">
            {headerMenu === 'memorial_funeral' ? 'Please upload funeral details and images for your product' : 'Please upload images for your product'}
          </h1>
          
          {headerMenu === 'memorial_funeral' ? (
            <form
            className="w-full rounded-md border flex flex-col items-center p-2 sm:p-4 bg-[#F9F9F9] "
          >
            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-4">
              <label
                htmlFor="name"
                className='text-[15px] pl-2 md:text-[17px] sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700'
              >
                Deceased Person Name*
              </label>
              <div className="relative mt-2">
                <input
                  type="text"
                  name="name"
                  ref={refs.name}
                  id="name"
                  value={formData.name}
                  placeholder="Enter full name"
                  onChange={handleChange}
                  className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[14px] border ${errors.name ? 'border-[#B3261E] hover:border-[#B3261E] outline-[#B3261E] focus:outline-opacity-100' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none'} outline-opacity-0 rounded outline-2 transform transition-opacity duration-500`}
                />
                {errors.name && <label
                  htmlFor="name"
                  className='absolute right-4 text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-4 md:translate-y-[18px]'
                >
                  <MdError />
                </label>}
              </div>
            </div>

            <div className="flex max-[565px]:flex-col md:justify-between gap-2 md:gap-5 w-full max-w-[360px] sm:max-w-[420px] mt-3">
              <div className="w-full">
                <label
                  className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
                >
                  Date of Birth*
                </label>
                <div className="relative mt-2">
                  <input
                    type="date"
                    name="dateOfBirth"
                    ref={refs.dateOfBirth}
                    id="dateOfBirth"
                    value={formData.dateOfBirth}
                    onClick={(e) => e.target.showPicker()}
                    onChange={handleChange}
                    className={`w-full cursor-pointer p-2 min-[500px]:p-3 md:px-4 md:py-[14px] border ${errors.dateOfBirth ? 'border-[#B3261E] hover:border-[#B3261E] outline-[#B3261E] focus:outline-opacity-100' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none'} outline-opacity-0 rounded outline-2 transform transition-opacity duration-500`}
                  />
                  {errors.dateOfBirth && <label
                    htmlFor="dateOfBirth"
                    className='absolute right-1 top-1 text-red-500 transform transition-all duration-200'
                  >
                    <MdError />
                  </label>}
                </div>
              </div>
              <div className="w-full">
                <label
                  className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
                >
                  Date of Death*
                </label>
                <div className="relative mt-2">
                  <input
                    type="date"
                    name="dateOfDeath"
                    ref={refs.dateOfDeath}
                    id="dateOfDeath"
                    value={formData.dateOfDeath}
                    onClick={(e) => e.target.showPicker()}
                    onChange={handleChange}
                    className={`w-full cursor-pointer p-2 min-[500px]:p-3 md:px-4 md:py-[14px] border ${errors.dateOfDeath ? 'border-[#B3261E] hover:border-[#B3261E] outline-[#B3261E] focus:outline-opacity-100' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none'} outline-opacity-0 rounded outline-2 transform transition-opacity duration-500`}
                  />
                  {errors.dateOfDeath && <label
                    htmlFor="dateOfDeath"
                    className='absolute right-1 top-1 text-red-500 transform transition-all duration-200'
                  >
                    <MdError />
                  </label>}
                </div>
              </div>
            </div>

            <div className="flex max-[565px]:flex-col md:justify-between gap-2 md:gap-5 w-full max-w-[360px] sm:max-w-[420px] mt-3">
              <div className="w-full max-w-[360px] sm:max-w-[420px] mt-2">
                <label
                  htmlFor="mainCover"
                  className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
                >
                  Main Cover Photo
                </label>
                <div className={`w-5/6 min-[565px]:w-full relative rounded lg:rounded-md overflow-hidden duration-500 mt-2 bg-white min-h-32 max-h-40 min-[565px]:max-h-56 overflow-y-auto ${preview.mainCover ? '' : 'p-2 min-[500px]:p-3 md:px-4 md:py-[14px]'} ${errors.mainCover ? 'border-[#B3261E] hover:border-[#B3261E]/60 outline-[#B3261E] focus:outline-opacity-100 border' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none border-2'} outline-opacity-0 outline-2 transform transition-opacity`}>
                {preview.mainCover ? (
                  preview.mainCover.type === 'pdf' ? (
                    <div className="w-full">
                      <iframe
                        src={preview.mainCover.url}
                        title="pdf-preview"
                        className="w-full max-h-56"
                      ></iframe>
                    </div>
                  ) : (
                    <div className="w-full">
                      <img
                        src={preview.mainCover}
                        alt="image-preview"
                        className="w-full"
                      />
                    </div>
                  ))
                  : (
                    <>
                      <label htmlFor="mainCover" className="absolute text-base text-neutral-700 inset-0 flex items-center justify-center bg-white cursor-pointer">
                        Click to upload
                      </label>
                      <input
                        type="file"
                        name="mainCover"
                        ref={refs.mainCover}
                        id="mainCover"
                        onChange={handleFileChange}
                        className='sr-only'
                      />
                      {errors.mainCover && <label
                        htmlFor="mainCover"
                        className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                      >
                        <MdError />
                      </label>}
                    </> )}
                </div>
                {preview.mainCover && (
                  <div className="w-5/6 min-[565px]:w-full relative p-2 pb-4 border-b bg-white hover:bg-neutral-800/10 duration-200 border-l border-r rounded-b-md rounded-l-md rounded-r-md">
                    <label htmlFor="mainCover" className="absolute text-[15px] sm:text-base text-neutral-700 font-light inset-0 flex items-center justify-center cursor-pointer">
                      Upload again
                    </label>
                    <input
                      type="file"
                      name="mainCover"
                      ref={refs.mainCover}
                      id="mainCover"
                      onChange={handleFileChange}
                      className='sr-only'
                    />
                  </div>
                )}
              </div>

              <div className="w-full max-w-[360px] sm:max-w-[420px] mt-2">
                <label
                  htmlFor="backCover"
                  className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
                >
                  Back Cover Photo
                </label>
                <div className={`w-5/6 min-[565px]:w-full relative rounded lg:rounded-md overflow-hidden duration-500 mt-2 bg-white min-h-32 max-h-40 min-[565px]:max-h-56 overflow-y-auto ${preview.backCover ? '' : 'p-2 min-[500px]:p-3 md:px-4 md:py-[14px]'} ${errors.backCover ? 'border-[#B3261E] hover:border-[#B3261E]/60 outline-[#B3261E] focus:outline-opacity-100 border' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none border-2'} outline-opacity-0 outline-2 transform transition-opacity`}>
                {preview.backCover ? (
                  preview.backCover.type === 'pdf' ? (
                    <div className="w-full">
                      <iframe
                        src={preview.backCover.url}
                        title="PDF Preview"
                        className="w-full max-h-56"
                      ></iframe>
                    </div>
                  ) : (
                    <div className="w-full">
                      <img
                        src={preview.backCover}
                        alt="File Preview"
                        className="w-full"
                      />
                    </div>
                  ))
                  : (
                    <>
                      <label htmlFor="backCover" className="absolute text-base text-neutral-700 inset-0 flex items-center justify-center bg-white cursor-pointer">
                        Click to upload
                      </label>
                      <input
                        type="file"
                        name="backCover"
                        ref={refs.backCover}
                        id="backCover"
                        onChange={handleFileChange}
                        className='sr-only'
                      />
                      {errors.backCover && <label
                        htmlFor="backCover"
                        className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                      >
                        <MdError />
                      </label>}
                    </> )}
                </div>
                {preview.backCover && (
                  <div className="w-5/6 min-[565px]:w-full relative p-2 pb-4 border-b bg-white hover:bg-neutral-800/10 duration-200 border-l border-r rounded-b-md rounded-l-md rounded-r-md">
                    <label htmlFor="backCover" className="absolute text-[15px] sm:text-base text-neutral-700 font-light inset-0 flex items-center justify-center cursor-pointer">
                      Upload again
                    </label>
                    <input
                      type="file"
                      name="backCover"
                      ref={refs.backCover}
                      id="backCover"
                      onChange={handleFileChange}
                      className='sr-only'
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-4">
              <label
                htmlFor="orderOfService"
                className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
              >
                Order of service
              </label>
              <div className={`w-5/6 min-[565px]:w-full relative rounded lg:rounded-md overflow-hidden duration-500 mt-2 bg-white min-h-32 max-h-40 min-[565px]:max-h-56 overflow-y-auto ${preview.orderOfService ? '' : 'p-2 min-[500px]:p-3 md:px-4 md:py-[14px]'} ${errors.orderOfService ? 'border-[#B3261E] hover:border-[#B3261E]/60 outline-[#B3261E] focus:outline-opacity-100 border' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none border-2'} outline-opacity-0 outline-2 transform transition-opacity`}>
              {preview.orderOfService ? (
                preview.orderOfService.type === 'pdf' ? (
                  <div className="w-full">
                    <iframe
                      src={preview.orderOfService.url}
                      title="pdf-preview"
                      className="w-full max-h-56"
                    ></iframe>
                  </div>
                ) : (
                  <div className="w-full">
                    <img
                      src={preview.orderOfService}
                      alt="image-preview"
                      className="w-full"
                    />
                  </div>
                ))
                : (
                  <>
                    <label htmlFor="orderOfService" className="absolute text-base text-neutral-700 inset-0 flex items-center justify-center bg-white cursor-pointer">
                      Click to upload
                    </label>
                    <input
                      type="file"
                      name="orderOfService"
                      ref={refs.orderOfService}
                      multiple
                      id="orderOfService"
                      onChange={handleFileChange}
                      className='sr-only'
                    />
                    {errors.orderOfService && <label
                      htmlFor="orderOfService"
                      className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                    >
                      <MdError />
                    </label>}
                  </> )}
                  {multipleFileCount.orderOfService !== '' && (
                    <span className="fixed top-4 right-7 text-base sm:text-lg md:text-xl lg:text-xl text-neutral-800 bg-white/50 px-4 py-3 rounded-full">+{multipleFileCount.orderOfService}</span>
                  )}
              </div>
              {preview.orderOfService && (
                <div className="w-5/6 min-[565px]:w-full relative p-2 pb-4 border-b bg-white hover:bg-neutral-800/10 duration-200 border-l border-r rounded-b-md rounded-l-md rounded-r-md">
                  <label htmlFor="orderOfService" className="absolute text-[15px] sm:text-base text-neutral-700 font-light inset-0 flex items-center justify-center cursor-pointer">
                    Upload again
                  </label>
                  <input
                    type="file"
                    name="orderOfService"
                    multiple
                    ref={refs.orderOfService}
                    id="orderOfService"
                    onChange={handleFileChange}
                    className='sr-only'
                  />
                </div>
              )}
            </div>

            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-3">
              <label
                htmlFor="funeralDetails"
                className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
              >
                Funeral Details*
              </label>
              <div className="relative mt-2">
                <textarea
                  type="textarea"
                  name="funeralDetails"
                  ref={refs.funeralDetails}
                  id="funeralDetails"
                  value={formData.funeralDetails}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.style.height = 'auto';
                    const scrollHeight = e.target.scrollHeight;
                    const maxHeight = 160;
                    const minHeight = 80;
                    e.target.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
                  }}
                  className={`w-full min-h-20 max-h-40 h-auto overflow-y-auto p-2 min-[500px]:p-3 md:px-4 md:py-[14px] border ${errors.funeralDetails ? 'border-[#B3261E] hover:border-[#B3261E]  outline-[#B3261E] focus:outline-opacity-100' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none'} outline-opacity-0 rounded outline-2 transform transition-opacity duration-500`}
                />
                {errors.funeralDetails && <label
                  htmlFor="funeralDetails"
                  className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                >
                  <MdError />
                </label>}
              </div>
            </div>

            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-2">
              <label
                htmlFor="obituaryPoems"
                className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
              >
                Obituary Backpage Poems*
              </label>
              <div className="relative mt-2">
                <textarea
                  type="textarea"
                  name="obituaryPoems"
                  ref={refs.obituaryPoems}
                  id="obituaryPoems"
                  value={formData.obituaryPoems}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.style.height = 'auto';
                    const scrollHeight = e.target.scrollHeight;
                    const maxHeight = 160;
                    const minHeight = 80;
                    e.target.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
                  }}
                  className={`w-full min-h-20 max-h-40 overflow-y-auto p-2 min-[500px]:p-3 md:px-4 md:py-[14px] border ${errors.obituaryPoems ? 'border-[#B3261E] hover:border-[#B3261E] outline-[#B3261E] focus:outline-opacity-100' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none'} outline-opacity-0 rounded outline-2 transform transition-opacity duration-500`}
                />
                
                {errors.obituaryPoems && <label
                  htmlFor="obituaryPoems"
                  className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                >
                  <MdError />
                </label>}
              </div>
            </div>

            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-2">
              <label
                htmlFor="addiInformation"
                className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
              >
                Additional Information
              </label>
              <div className="relative mt-2">
                <textarea
                  type="textarea"
                  name="addiInformation"
                  ref={refs.addiInformation}
                  id="addiInformation"
                  value={formData.addiInformation}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.style.height = 'auto';
                    const scrollHeight = e.target.scrollHeight;
                    const maxHeight = 160;
                    const minHeight = 80;
                    e.target.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
                  }}
                  className={`w-full min-h-20 max-h-40 overflow-y-auto p-2 min-[500px]:p-3 md:px-4 md:py-[14px] border ${errors.addiInformation ? 'border-[#B3261E] hover:border-[#B3261E] outline-[#B3261E] focus:outline-opacity-100' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none'} outline-opacity-0 rounded outline-2 transform transition-opacity duration-500`}
                />
                {errors.addiInformation && <label
                  htmlFor="addiInformation"
                  className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                >
                  <MdError />
                </label>}
              </div>
            </div>

            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-4">
              <label
                htmlFor="multiplePhotos"
                className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
              >
                Upload Collage or multiple photos*
              </label>
              <div className={`w-5/6 min-[565px]:w-full relative rounded lg:rounded-md overflow-hidden duration-500 mt-2 bg-white min-h-32 max-h-40 min-[565px]:max-h-56 overflow-y-auto ${preview.multiplePhotos ? '' : 'p-2 min-[500px]:p-3 md:px-4 md:py-[14px]'} ${errors.multiplePhotos ? 'border-[#B3261E] hover:border-[#B3261E]/60 outline-[#B3261E] focus:outline-opacity-100 border' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none border-2'} outline-opacity-0 outline-2 transform transition-opacity`}>
              {preview.multiplePhotos ? (
                preview.multiplePhotos.type === 'pdf' ? (
                  <div className="w-full">
                    <iframe
                      src={preview.multiplePhotos.url}
                      title="pdf-preview"
                      className="w-full max-h-56"
                    ></iframe>
                  </div>
                ) : (
                  <div className="w-full">
                    <img
                      src={preview.multiplePhotos}
                      alt="image-preview"
                      className="w-full"
                    />
                  </div>
                ))
                : (
                  <>
                    <label htmlFor="multiplePhotos" className="absolute text-base text-neutral-700 inset-0 flex items-center justify-center bg-white cursor-pointer">
                      Click to upload
                    </label>
                    <input
                      type="file"
                      name="multiplePhotos"
                      ref={refs.multiplePhotos}
                      multiple
                      id="multiplePhotos"
                      onChange={handleFileChange}
                      className='sr-only'
                    />
                    {errors.multiplePhotos && <label
                      htmlFor="multiplePhotos"
                      className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                    >
                      <MdError />
                    </label>}
                  </> )}
                  {multipleFileCount.multiplePhotos !== '' && (
                    <span className="fixed top-4 right-7 text-base sm:text-lg md:text-xl lg:text-xl text-neutral-800 bg-white/50 px-4 py-3 rounded-full">+{multipleFileCount.multiplePhotos}</span>
                  )}
              </div>
              {preview.multiplePhotos && (
                <div className="w-5/6 min-[565px]:w-full relative p-2 pb-4 border-b bg-white hover:bg-neutral-800/10 duration-200 border-l border-r rounded-b-md rounded-l-md rounded-r-md">
                  <label htmlFor="multiplePhotos" className="absolute text-[15px] sm:text-base text-neutral-700 font-light inset-0 flex items-center justify-center cursor-pointer">
                    Upload again
                  </label>
                  <input
                    type="file"
                    name="multiplePhotos"
                    multiple
                    ref={refs.multiplePhotos}
                    id="multiplePhotos"
                    onChange={handleFileChange}
                    className='sr-only'
                  />
                </div>
              )}
            </div>

            <div className="flex items-center justify-center mt-6 mb-4">
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className="bg-[#43A047] hover:bg-[#43A047]/90 duration-200 px-6 py-2 rounded-full text-white inline-block"
              >
                {isLoading ? (
                <div className="flex justify-center w-full px-[14px]">
                  <div className="w-3 h-3 sm:w-4 sm:h-4 md:w-[22px] md:h-[22px] border-2 border-transparent border-t-2 border-t-white rounded-full animate-spin"></div>
                </div>
              ) : 'Submit' }
              </button>
            </div>

          </form>
          ) : (<form
            className="w-full rounded-md border flex flex-col items-center p-2 sm:p-4 bg-[#F9F9F9] "
          >
            
            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-4">
              <label
                htmlFor="name"
                className='text-[15px] pl-2 md:text-[17px] sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700'
              >
                Person Name*
              </label>
              <div className="relative mt-2">
                <input
                  type="text"
                  name="name"
                  ref={marketingEssentialsRefs.name}
                  id="name"
                  value={marketingEssentialsForm.name}
                  placeholder="Enter full name"
                  onChange={handleMarketingEssentialsChange}
                  className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[14px] border ${marketingEssentialserrors.name ? 'border-[#B3261E] hover:border-[#B3261E] outline-[#B3261E] focus:outline-opacity-100' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none'} outline-opacity-0 rounded outline-2 transform transition-opacity duration-500`}
                />
                {marketingEssentialserrors.name && <label
                  htmlFor="name"
                  className='absolute right-4 text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-4 md:translate-y-[18px]'
                >
                  <MdError />
                </label>}
              </div>
            </div>

            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-2">
              <label
                htmlFor="addiInformation"
                className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
              >
                Additional Information
              </label>
              <div className="relative mt-2">
                <textarea
                  type="textarea"
                  name="addiInformation"
                  ref={marketingEssentialsRefs.addiInformation}
                  id="addiInformation"
                  value={marketingEssentialsForm.addiInformation}
                  onChange={handleMarketingEssentialsChange}
                  onInput={(e) => {
                    e.target.style.height = 'auto';
                    const scrollHeight = e.target.scrollHeight;
                    const maxHeight = 160;
                    const minHeight = 80;
                    e.target.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
                  }}
                  className={`w-full min-h-20 max-h-40 overflow-y-auto p-2 min-[500px]:p-3 md:px-4 md:py-[14px] border ${marketingEssentialserrors.addiInformation ? 'border-[#B3261E] hover:border-[#B3261E] outline-[#B3261E] focus:outline-opacity-100' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none'} outline-opacity-0 rounded outline-2 transform transition-opacity duration-500`}
                />
                {marketingEssentialserrors.addiInformation && <label
                  htmlFor="addiInformation"
                  className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                >
                  <MdError />
                </label>}
              </div>
            </div>

            <div className="w-full max-w-[360px] sm:max-w-[420px] mt-4">
              <label
                htmlFor="multiplePhotos"
                className='text-[15px] md:text-[17px] pl-2 sm:text-base font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100'
              >
                Upload multiple photos*
              </label>
              <div className={`w-5/6 min-[565px]:w-full relative rounded lg:rounded-md overflow-hidden duration-500 mt-2 bg-white min-h-32 max-h-40 min-[565px]:max-h-56 overflow-y-auto ${preview.multiplePhotos ? '' : 'p-2 min-[500px]:p-3 md:px-4 md:py-[14px]'} ${errors.multiplePhotos ? 'border-[#B3261E] hover:border-[#B3261E]/60 outline-[#B3261E] focus:outline-opacity-100 border' : 'focus:border-[#4CAF50] hover:border-[#4CAF50] focus:shadow-[0px_0px_7px_1px_rgba(0,0,0,0.1)]  focus:outline-none border-2'} outline-opacity-0 outline-2 transform transition-opacity`}>
              {marketingEssentialsPreview.multiplePhotos ? (
                marketingEssentialsPreview.multiplePhotos.type === 'pdf' ? (
                  <div className="w-full">
                    <iframe
                      src={marketingEssentialsPreview.multiplePhotos.url}
                      title="pdf-preview"
                      className="w-full max-h-56"
                    ></iframe>
                  </div>
                ) : (
                  <div className="w-full">
                    <img
                      src={marketingEssentialsPreview.multiplePhotos}
                      alt="image-preview"
                      className="w-full"
                    />
                  </div>
                ))
                : (
                  <>
                    <label htmlFor="multiplePhotos" className="absolute text-base text-neutral-700 inset-0 flex items-center justify-center bg-white cursor-pointer">
                      Click to upload
                    </label>
                    <input
                      type="file"
                      name="multiplePhotos"
                      ref={marketingEssentialsRefs.multiplePhotos}
                      multiple
                      id="multiplePhotos"
                      onChange={handleMarketingEssentialsFileChange}
                      className='sr-only'
                    />
                    {marketingEssentialserrors.multiplePhotos && <label
                      htmlFor="multiplePhotos"
                      className='absolute right-2 top-2 text-xl text-red-500 transform transition-all duration-200'
                    >
                      <MdError />
                    </label>}
                  </> )}
                  {marketingEssentialsMultipleFileCount.multiplePhotos !== '' && (
                    <span className="fixed top-4 right-7 text-base sm:text-lg md:text-xl lg:text-xl text-neutral-800 bg-white/50 px-4 py-3 rounded-full">+{marketingEssentialsMultipleFileCount.multiplePhotos}</span>
                  )}
              </div>
              {marketingEssentialsPreview.multiplePhotos && (
                <div className="w-5/6 min-[565px]:w-full relative p-2 pb-4 border-b bg-white hover:bg-neutral-800/10 duration-200 border-l border-r rounded-b-md rounded-l-md rounded-r-md">
                  <label htmlFor="multiplePhotos" className="absolute text-[15px] sm:text-base text-neutral-700 font-light inset-0 flex items-center justify-center cursor-pointer">
                    Upload again
                  </label>
                  <input
                    type="file"
                    name="multiplePhotos"
                    multiple
                    ref={marketingEssentialsRefs.multiplePhotos}
                    id="multiplePhotos"
                    onChange={handleMarketingEssentialsFileChange}
                    className='sr-only'
                  />
                </div>
              )}
            </div>

            <div className="flex items-center justify-center mt-6 mb-4">
              <button
                onClick={handleMarketingEssentialsSubmit}
                disabled={isLoading}
                className="bg-[#43A047] hover:bg-[#43A047]/90 duration-200 px-6 py-2 rounded-full text-white inline-block"
              >
                {isLoading ? (
                <div className="flex justify-center w-full px-[14px]">
                  <div className="w-3 h-3 sm:w-4 sm:h-4 md:w-[22px] md:h-[22px] border-2 border-transparent border-t-2 border-t-white rounded-full animate-spin"></div>
                </div>
              ) : 'Submit' }
              </button>
            </div>

          </form>) }
          
          






        </div>
      </div>

    </div>
  );
};

export default PDFUploadForm;
