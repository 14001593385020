import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome, SlArrowRight } from "react-icons/sl";
import { useLocation } from "react-router-dom";
import { getCategorySubMenusAPI, getfieldName, updatefieldDescription, deleteDescription } from "../../serverApi/server";
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";

const FieldDescription = () => {
  const location = useLocation();
  const headerMenus = location.state || [];
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(
    headerMenus.find((menu) => menu.additional_name === "memorial_funeral")
      ?.id || ""
  );
  const [categoryList, setCategoryList] = useState([]);
  const [subMenuList, setSubMenuList] = useState("");
  const [fieldType, setFieldType] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [descriptionTable, setDescriptionTable] = useState({});

  const toggleEdit = () => setEditOpen(!editOpen);

  const fetchCategorySubMenus = async (categoryId) => {
    try {
      const response = await getCategorySubMenusAPI(categoryId);
      const categories = response.data.categories_serializer;
      setCategoryList(categories);
      setSubMenuList(
        categories.find((menu) => menu.additional_name === "bookmarks")?.id || ""
      );
    } catch (error) {
      console.error("Error fetching sub-menus:", error);
    }
  };

  const fetchFields = async () => {
    try {
      const response = await getfieldName(subMenuList);
      setFieldType(response.data.form_field_serializer || []);
    } catch (error) {
      console.error("Error fetching fields:", error);
    }
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 6000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);


  useEffect(() => {
    if (selectedCategory) {
      fetchCategorySubMenus(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (subMenuList) {
      fetchFields();
    }
  }, [subMenuList]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSubMenuListChange = (e) => {
    setSubMenuList(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      const data = await updatefieldDescription(descriptionTable.id, descriptionTable.description);
      fetchFields();
      toggleEdit();
      setSuccessMessage("Description Added successfully!");
    } catch (error) {
      alert("An error occurred while updating the description.");
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDescription(id);
      fetchFields();
      setSuccessMessage("Description deleted successfully!");
    } catch (error) {
      alert("An error occurred while deleting the description.");
      console.error(error);
    }
  };

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full custom-scroll">
        <SideBar />
      </div>

      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <section className="bg-[#F5F7FD] px-7 py-6">
          <div className="flex items-center justify-between">
            <div className="text-blue-950 flex items-center gap-6">
              <h1 className="pr-6 text-2xl font-medium border-r">
                Admin Users
              </h1>
              <div className="flex text-sm items-center gap-3">
                <button>
                  <SlHome />
                </button>
                <SlArrowRight />
                <button>Field Description</button>
              </div>
            </div>
          </div>

          {editOpen && (
            <div
              className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
              aria-hidden="true"
            >
              <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto animate-slide-down">
                <div className="flex justify-between items-center p-4 border-b">
                  <h5 className="text-lg font-medium">
                    Edit Description
                  </h5>
                  <button
                    type="button"
                    className="text-gray-600 text-3xl hover:text-gray-900"
                    onClick={toggleEdit}
                  >
                    &times;
                  </button>
                </div>
                <div className="p-4">
                  <form>
                    <div className="mb-4">
                      <label
                        htmlFor="subCategory"
                        className="block text-sm text-gray-700"
                      >
                        Edit the field Description
                      </label>
                      <textarea
                        name="textArea"
                        value={descriptionTable.description || ""}
                        onChange={(e) =>
                          setDescriptionTable((prevState) => ({
                            ...prevState,
                            description: e.target.value,
                          }))
                        }
                        placeholder="Enter text here"
                        className="h-[150px] mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm resize-none"
                      />
                    </div>
                  </form>
                </div>
                <div className="flex justify-end items-center p-4 border-t">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                    onClick={toggleEdit}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>

                </div>
              </div>
            </div>
          )}

          <section className="px-3 md:px-4 bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]">
            <div className="flex items-center gap-2">
              <div className="flex text-sm items-center gap-1 font-light">
                <p className="text-neutral-700">Filter by Category:</p>
                <select
                  className="focus:outline-none focus:border-blue-300 border rounded px-1 py-1"
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  {headerMenus.map((menu) => (
                    <option key={menu.id} value={menu.id}>
                      {menu.menu}
                    </option>
                  ))}
                </select>
                <select
                  className="focus:outline-none focus:border-blue-300 border rounded px-1 py-1"
                  onChange={handleSubMenuListChange}
                  value={subMenuList}
                >
                  {categoryList.map((menu) => (
                    <option key={menu.id} value={menu.id}>
                      {menu.category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {successMessage && (
              <div className="mt-4 bg-green-100 text-green-800 p-4 flex items-center justify-between rounded">
                <div className="flex items-center gap-2">
                  <AiOutlineCheckCircle className="text-2xl" />
                  <span>{successMessage}</span>
                </div>
              </div>
            )}

            <div className="overflow-x-auto">
              <table
                className="min-w-full bg-white mt-8"
                style={{ borderCollapse: "separate", borderSpacing: "2px" }}
              >
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400 flex items-center justify-center space-x-2">
                      FIELD NAME
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400 ">
                      DESCRIPTION
                    </th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400 flex items-center justify-center space-x-2">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fieldType.length > 0 ? (
                    fieldType.map((field) => (
                      <tr key={field.id}>
                        <td className="px-5 py-4 border border-[#48ABF7]">
                          {field.field_name}
                        </td>
                        <td className="px-5 py-4 border border-[#48ABF7]">
                          {field.description || "No description available"}
                        </td>
                        <td className="px-5 py-4 border border-[#48ABF7]">
                          <div className="flex justify-center items-center space-x-4">
                            <button
                              className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center"
                              onClick={() => {
                                toggleEdit();
                                setDescriptionTable({
                                  ...field,
                                });
                              }}
                            >
                              <FaEdit className="mr-1" /> Edit
                            </button>

                            <button
                              className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center"
                              onClick={() => handleDelete(field.id)}
                            >
                              <MdDeleteOutline className="mr-1" /> Delete
                            </button>
                          </div>
                        </td>

                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-4">
                        No fields available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default FieldDescription;
