import React from "react";
import contact from "../assets/Carousel/St Albans Banner 03.png";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

const Privacy = () => {
  return (
    <div className="bg-[#F9F9F9]">
      <div className="px-5 sm:px-10 flex flex-col items-center justify-center">
        <div className="relative w-full h-full mb-5 sm:mb-10">
          <img src={contact} alt="contact banner" className="w-full h-full" />
          <div className="absolute top-0 w-full h-full bg-black/40 flex items-center justify-center">
            <h1 className="text-2xl sm:text-4xl font-medium text-white drop-shadow-md">
              Privacy Policy
            </h1>
          </div>
        </div>

        <div className="flex gap-1 sm:gap-3 items-center justify-center">
          <p className="w-8 sm:w-12 h-[2px] bg-black"></p>
          <h1 className="text-2xl text-center sm:text-3xl md:text-3xl lg:text-4xl font-semibold">
            Privacy Policy
          </h1>
          <p className="w-8 sm:w-12 h-[2px] bg-black"></p>
        </div>

        <div className="sm:px-5 md:px-[8%] pt-8 pb-6 sm:pb-12">
          <div className="bg-white p-2 sm:p-3 md:p-4 rounded sm:rounded-md md:rounded-lg shadow-[0_0_30px_-7px_rgba(0,0,0,0.1)]"> 

            <div>
              <h2 className="font-semibold text-xl md:text-2xl lg:text-[26px]">Privacy Policy for St. Albans Digital Printing Inc.</h2>
              <p className="pt-2 pb-2 text-neutral-500 text-[17px]">
                <span className="font-semibold">St. Albans Digital Printing Inc</span>. is committed to protecting your
                personal information and ensuring its confidentiality. Any contact
                details we collect will be used solely for processing your orders
                and communication related to those orders.
              </p>
              <p className="pb-4 text-neutral-500 text-[17px]">We will send you email
                notifications regarding your order, such as order confirmation,
                status updates, and tracking information once your order ships.
                Additionally, you may receive special offers unless you opt out.
                You can easily unsubscribe at any time.
              </p>
            </div>

            <div>
              <h2 className="font-semibold text-xl md:text-2xl lg:text-[26px]">Your Privacy Matters to Us</h2>
              <p className="pt-2 pb-2 text-neutral-500 text-[17px]">
                We will <span className="font-semibold">NEVER</span> sell, rent, or share your personal information with any third party for solicitation purposes. We highly value the trust you place in us and take every measure to protect your privacy.
              </p>
              <p className="pb-3 text-neutral-500 text-[17px]">For your security, payments are processed through secure servers, and all sensitive information is encrypted using advanced encryption technology. We invest in the best protection available to ensure your data is safe..
              </p>
              <p className="pb-4 text-neutral-500 text-[17px]">Our systems are regularly tested for vulnerabilities to ensure that your information remains secure. We take your privacy seriously and strive to maintain your trust.
              </p>
            </div>

            <div>
              <h2 className="font-semibold text-xl md:text-2xl lg:text-[26px]">Rights Under the General Data Protection Regulation (GDPR)</h2>
              <p className="pt-2 pb-2 text-neutral-500 text-[17px]">
                If you are located in the European Union, you have specific rights under the GDPR:
              </p>
              <ul className="list-disc px-8 pt-1 pb-3">
                <li className="text-neutral-500 text-[17px]"><span className="font-semibold">Right to be Informed</span>: Know how your data is used. Our Privacy Policy explains this.</li>
                <li className="text-neutral-500 text-[17px]"><span className="font-semibold">Right of Access:</span> Access your data via "Account Settings" on our website.</li>
                <li className="text-neutral-500 text-[17px]"><span className="font-semibold">Right to Rectification:</span> Update inaccurate data in "Account Settings."</li>
                <li className="text-neutral-500 text-[17px]"><span className="font-semibold">Right to Erasure:</span> Request data deletion by emailing <a className="text-orange-500/80" href="mailto:support@stalbansprinting.com">support@stalbansprinting.com</a>.</li>
                <li className="text-neutral-500 text-[17px]"><span className="font-semibold">Right to Restrict Processing:</span> Contact us to limit data processing.</li>
                <li className="text-neutral-500 text-[17px]"><span className="font-semibold">Right to Data Portability:</span> Request a copy of your data in a structured format.</li>
                <li className="text-neutral-500 text-[17px]"><span className="font-semibold">Right to Object:</span> Object to data processing by emailing <a className="text-orange-500/80" href="mailto:support@stalbansprinting.com">support@stalbansprinting.com</a>.</li>
              </ul>
              <p className="pb-3 text-neutral-500 text-[17px]">If you are unable to exercise these rights through the methods above, you may reach us by phone at 1-718-528-5100, and we will document your request for follow-up.
              </p>
              <p className="pb-4 text-neutral-500 text-[17px]">By continuing to use the services provided by St. Albans Digital Printing Inc., you consent to the collection and processing of your data as described in this Privacy Policy.
              </p>
            </div>

            <div>
              <h2 className="font-semibold text-xl md:text-2xl lg:text-[26px]">Use of Reviews and Testimonials</h2>
              <p className="pt-2 pb-2 text-neutral-500 text-[17px]">
                By submitting reviews or testimonials on our website, you agree that St. Albans Digital Printing Inc. may use them for promotional purposes, including on our website, social media pages, and other platforms. We may edit reviews for length, spelling, or grammar, and remove any sensitive information before publishing. If you wish to have your review or testimonial removed, please contact us at support@stalbansprinting.com.
              </p>
            </div>
            <div>
              <h2 className="font-semibold text-xl md:text-2xl lg:text-[26px]">Data Sharing</h2>
              <p className="pt-2 pb-2 text-neutral-500 text-[17px]">
                St. Albans Digital Printing Inc. only shares your data with trusted third parties such as our email service providers (Constant Contact and MailChimp), payment processor (Elavon.com), and courier services (USPS, FEDEX) for the purpose of processing and delivering orders. We do not share your information for any other purpose.
              </p>
            </div>
            <div>
              <h2 className="font-semibold text-xl md:text-2xl lg:text-[26px]">Data Retention</h2>
              <p className="pt-2 pb-2 text-neutral-500 text-[17px]">
                We retain contact information, shipping details, and payment information as needed for order fulfillment. Artwork submitted for printing is kept for reprints or reorders for at least 90 days, after which it will be deleted from our systems.
              </p>
            </div>
            <div>
              <h2 className="font-semibold text-xl md:text-2xl lg:text-[26px]">Use of Analytics</h2>
              <p className="pt-2 pb-2 text-neutral-500 text-[17px]">
                We use Personyze to better understand our customers' needs and optimize our services. Personyze helps us analyze user behavior on our website (such as time spent on pages, clicks, and preferences). It collects data including anonymized IP addresses, device information, geographic location (country), and language preferences. The information is used to improve your experience and is not shared with third parties.
              </p>
            </div>
            <div>
              <h2 className="font-semibold text-xl md:text-2xl lg:text-[26px]">Contact Us</h2>
              <p className="pt-2 pb-2 text-neutral-500 text-[17px]">
                If you have questions about our Privacy Policy, you can reach us at:
              </p>
              <div className="flex flex-col py-[10px] bg-[#F3F3F3] px-2 border-l-4 border-l-orange-500/80 gap-4">
                  <p className="text-orange-500/80"><span className="font-semibold text-neutral-700">Email:</span> support@stalbansprinting.com</p>
                  <p className="text-neutral-500"><span className="font-semibold text-neutral-700">Phone:</span> 718-528-5100</p>
                  <p className="text-neutral-500"><span className="font-semibold text-neutral-700">Address:</span> St. Albans Digital Printing Inc., 199-12 Linden Blvd., St. Albans, NY 11412</p>
              </div>
              <p className="pt-2 pb-4 md:pb-8 text-neutral-500 text-[17px]">
                Thank you for choosing St. Albans Digital Printing Inc. We appreciate your trust and aim to keep your information secure.
              </p>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default Privacy;
