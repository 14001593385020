import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Home from '../pages/Home'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Login from '../pages/Login'
import Cart from '../pages/Cart'
import ShopDetails from '../pages/ShopDetails'
import DigitOffset from '../pages/Services/DigitOffset'
import DocumentScan from '../pages/Services/DocumentScan'
import Photography from '../pages/Services/Photography'
import PhotoRestoration from '../pages/Services/PhotoRestoration'
import ChurchDirectory from '../pages/Services/ChurchDirectory'
import Signs from '../pages/Services/Signs'
import WebDesigning from '../pages/Services/WebDesigning'
import Flyers from '../pages/Services/Flyers'
import EventJournal from '../pages/Services/EventJournal'
import FAQ from '../pages/FAQ'
import MemorialFuneral from '../pages/MemorialFuneral'
import Register from '../pages/Register'
import AdminLogin from '../pages/Admin/AdminLogin'
import Dashboard from '../pages/Admin/Dashboard'
import CategoryPricing from '../pages/Admin/CategoryPricing'
import FieldPricing from '../pages/Admin/FieldPricing'
import PaymentPage from '../pages/PaymentPage'
import PDFUploadForm from '../pages/PDFUploadForm'
import AdminData from '../pages/Admin/AdminData'
import UsersData from '../pages/Admin/UsersData'
import Transaction from '../pages/Admin/Transaction'
import Subscribers from '../pages/Admin/Subscribers'
import Orders from '../pages/Orders'
import CurrentOrders from '../pages/Admin/CurrentOrders'
import { getHeadersAndCategory } from '../serverApi/server'
import DispatchedOrders from '../pages/Admin/DispatchedOrders'
import ProductDescription from '../pages/Admin/ProductDescription'
import Main from '../pages/Main'
import HeaderDescription from '../pages/Admin/HeaderDescription'
import SvgPngUpload from '../pages/Admin/SvgPngUpload'
import CategoryImageUpload from '../pages/Admin/CategoryImageUpload'
import CategoryImageDeleteUpdate from '../pages/Admin/CategoryImageDeleteUpdate'
import Privacy from '../pages/Privacy'
import ErrorResponse from '../pages/ErrorResponse'
import SendClientMail from '../pages/Admin/SendClientMail'
import Unsubscribe from '../unsubscribe/Unsubscribe'
import BookletsDesign from '../pages/BookletsDesign'
import CategoryKeywords from '../pages/Admin/CategoryKeywords'
import PrivateRoute from '../routes/PrivateRoute'
import AdminPrivateRoute from '../routes/AdminPrivateRoute'
import CartPlusLogin from '../pages/CartPlusLogin'
import FieldDescription from '../pages/Admin/FieldDescription'
import AnniversaryImages from '../pages/AnniversaryImages'

function RoutesConfig () {
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchHeader = async () => {
    try {
      const getHeaderCategory = await getHeadersAndCategory();
      setHeaders(getHeaderCategory.all_header_menus_details);
    } catch (error) {
      console.error("Error Fetching Header:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchHeader();
  }, [])
  const headerRoute = headers?.flatMap((header) => header.categories || []) || [];
  
  return (
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/MemorialFuneral' element={<MemorialFuneral />} />
      <Route path='/shopdetails/:id?' element={<ShopDetails/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/contact_us' element={<Contact/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/register' element={<Register/>} />
      <Route path='/cart' element={<Cart/>} />
      <Route path='/product-cart' element={<CartPlusLogin/>} />
      <Route path='/orders' element={<Orders/>} />
      <Route path='/digital_&_offset_printing' element={<DigitOffset/>} />
      <Route path='/document_scanning' element={<DocumentScan/>} />
      <Route path='/photography' element={<Photography/>} />
      <Route path='/photo_restoration' element={<PhotoRestoration/>} />
      <Route path='/church_directory' element={<ChurchDirectory/>} />
      <Route path='/signs' element={<Signs/>} />
      <Route path='/website_designing' element={<WebDesigning/>} />
      <Route path='/event_journal' element={<EventJournal/>} />
      <Route path='/faq' element={<FAQ/>} />
      <Route path='/design' element={<Main/>} />
      <Route path='/payment-page' element={<PrivateRoute component={PaymentPage} />} /> 
      <Route path='/pdf-upload-form' element={<PrivateRoute component={PDFUploadForm} />} />
      <Route path='/template' element={<Main/>} />
      <Route path='/privacy-policy' element={<Privacy/>} />
      <Route path='/error-response' element={<ErrorResponse/>} />
      <Route path='/unsubscribe' element={<Unsubscribe/>} />
      <Route path='/booklets-design/:id?' element={<BookletsDesign/>} />
      

      {!loading && headerRoute.map((item) => {
        if (item.additional_name === 'celebrations') {
          return (
            <Route
              key={item.id}
              path={item.additional_name}
              element={<MemorialFuneral title={item.category} additionalName={item.additional_name} />}
            />
          );
        } else if (item.additional_name === 'about_us') {
          return (
            <Route
              key={item.id}
              path={item.additional_name}
              element={<About />}
            />
          );
        } else if (item.additional_name === 'contact_us') {
          return (
            <Route
              key={item.id}
              path={item.additional_name}
              element={<Contact />}
            />
          );
        } else if (item.additional_name === '1st_anniversary' || item.additional_name === '3rd_anniversary') {
          return (
            <Route
              key={item.id}
              path={item.additional_name}
              element={<AnniversaryImages title={item.category} additionalName={item.additional_name} />}
            />
          );
        } else {
          return (
            <Route
              key={item.id}
              path={item.additional_name}
              element={<MemorialFuneral title={item.category} additionalName={item.additional_name} />}
            />
          );
        }
      })}

      {/* Admin Page Routing */}
      <Route path='/admin' element={<AdminLogin />} />
      <Route path='/admin/dashboard' element={<AdminPrivateRoute component={Dashboard} />} />
      <Route path='/admin/sub-category-pricing' element={<AdminPrivateRoute component={CategoryPricing} />} />
      <Route path='/admin/field-pricing' element={<AdminPrivateRoute component={FieldPricing} />} />
      <Route path='/admin/admin_data' element={<AdminPrivateRoute component={AdminData} />} />
      <Route path='/admin/users_data' element={<AdminPrivateRoute component={UsersData} />} />
      <Route path='/admin/transaction' element={<AdminPrivateRoute component={Transaction} />} />
      <Route path='/admin/subscribers' element={<AdminPrivateRoute component={Subscribers} />} />
      <Route path='/admin/current_orders' element={<AdminPrivateRoute component={CurrentOrders} />} />
      <Route path='/admin/dispatched_orders' element={<AdminPrivateRoute component={DispatchedOrders} />} />
      <Route path='/admin/productdescription' element={<AdminPrivateRoute component={ProductDescription} />} />
      <Route path='/admin/headerdescription' element={<AdminPrivateRoute component={HeaderDescription} />} />
      <Route path='/admin/upload_designassets' element={<AdminPrivateRoute component={SvgPngUpload} />} />
      <Route path='/admin/category_image_upload' element={<AdminPrivateRoute component={CategoryImageUpload} />} />
      <Route path='/admin/category_images_delete_update' element={<AdminPrivateRoute component={CategoryImageDeleteUpdate} />} />
      <Route path='/admin/send_client_mail' element={<AdminPrivateRoute component={SendClientMail} />} />
      <Route path='/admin/category_keywords' element={<AdminPrivateRoute component={CategoryKeywords}  />} />
      <Route path='/admin/field_description' element={<AdminPrivateRoute component={FieldDescription} />} />

      {!loading && <Route path="*" element={<Navigate to="/error-response" replace />} />}
      
    </Routes>
  )
}

export default RoutesConfig
