import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from 'react-router-dom';
import { FaEdit } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { createNewCategory, deleteCategory, getCategorySubMenusAPI, updateCategorySubmenuPrice, updateCategoryMenu, editFieldName } from '../../serverApi/server'

const CategoryPricing = () => {
  const location = useLocation();
  const headerMenus = location.state || [];
  const [prices, setPrices] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const [categoryList, setCategoryList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(
    headerMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
  );
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const fetchCategorySubMenus = async () => {
    try {
      const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedCategory);
      setCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer)
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      fetchCategorySubMenus();
    }
  }, [selectedCategory]);

  useEffect(() => {
    const initialPrices = {};
    categoryList.forEach(category => {
      initialPrices[category.id] = category.amount || '';
    });
    setPrices(initialPrices);
  }, [categoryList]);


  const handlePriceChange = (e, categoryId) => {
    setPrices(prevPrices => ({
      ...prevPrices,
      [categoryId]: e.target.value,
    }));
  };

  const updateCategoryPrice = async (categoryId, category_sub_menu) => {
    setLoadingButton(categoryId);
    const price = prices[categoryId];
    try {
      const updateCategorySubmenuPriceResponse = await updateCategorySubmenuPrice(categoryId, price, category_sub_menu);
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    } finally {
      setTimeout(() => {
        setLoadingButton(null);
      }, 2000);
    }
  }

  const toggleForm = () => setFormOpen(!formOpen);
  const [formOpen, setFormOpen] = useState(false);
  const [headerName, setHeaderName] = useState(
    headerMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
  );
  const [newCategory, setNewCategory] = useState("");
  const [categoryFields, setCategoryFields] = useState([]);

  const saveCategory = async () => {
    const updatedCategoryArray = [
      newCategory,
      ...categoryFields.map((field) => field.value).filter(Boolean),
    ].filter(Boolean);
    try {
      const createCategoryResponse = await createNewCategory(headerName, updatedCategoryArray);

      if (createCategoryResponse.data.client_status_code === 200) {
        fetchCategorySubMenus();
        setNewCategory("");
        setCategoryFields([]);
      }
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    }
    toggleForm();
  };

  const handleDelete = async (delete_category_id) => {
    try {
      const deleteResponse = await deleteCategory(delete_category_id)
      if (deleteResponse.data.client_status_code === 200) {
        fetchCategorySubMenus();
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  useEffect(() => {
    if (formOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [formOpen]);

  const addNewField = () => {
    setCategoryFields([
      ...categoryFields,
      { id: categoryFields.length + 1, value: "" },
    ]);
  };

  const getTheHeaderSelectedOption = async (headerMenuId, categoryId) => {
    await updateCategoryMenu(headerMenuId, categoryId)
    await fetchCategorySubMenus();
  }

  const [categoryEditInput, setCategoryEditInput] = useState(null);
  const [categoryName, setCategoryName] = useState(null);

  const toggleCategoryEdit = (categoryId) => {
    setCategoryEditInput((prevId) => (prevId === categoryId ? null : categoryId))
  }

  const updateCategoryName = (e, categoryIdValue) => {
    const { value } = e.target;
    setCategoryList((prevState) =>
      prevState.map((item) =>
        item.id === categoryIdValue
          ? { ...item, category: value }
          : item
      )
    );
    setCategoryName(value)
  }

  const apiUpdateCategoryName = async (apiUpdateCategoryId) => {
    const categoryType = 'category'
    if (!apiUpdateCategoryId || !categoryName) {
      toggleCategoryEdit(apiUpdateCategoryId);
      return;
    }
    const response = await editFieldName( categoryType, apiUpdateCategoryId, categoryName);
    toggleCategoryEdit(apiUpdateCategoryId);
  }

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full custom-scroll">
        <SideBar />
      </div>
      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <div className="bg-[#F5F7FD] px-7 py-6">
          <div className="flex items-center justify-between">
            <div className="text-blue-950 flex items-center gap-6">
              <h1 className="pr-6 text-2xl font-medium border-r">
                Admin Users
              </h1>
              <div className="flex text-sm items-center gap-3">
                <button>
                  <SlHome />
                </button>
                <SlArrowRight />
                <button>
                  Pricing
                </button>
                <SlArrowRight />
                <button>
                  Category Pricing
                </button>
              </div>
            </div>

            <button
              className="bg-blue-500 hover:bg-blue-400 duration-200 px-4 py-2 rounded text-white mr-4 md:mr-8"
              onClick={toggleForm}
            >
              Add New Category
            </button>
          </div>
          {formOpen && (
            <div
              className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
              aria-hidden="true"
            >
              <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-4 border-b">
                  <h5 className="text-lg font-medium">Add New Category</h5>
                  <button
                    type="button"
                    className="text-gray-600 text-3xl hover:text-gray-900"
                    onClick={toggleForm}
                  >
                    &times;
                  </button>
                </div>
                <div className="p-4">

                  <form>
                    <div className="mb-4">
                      <label
                        htmlFor="headerName"
                        className="block text-sm text-gray-700"
                      >
                        Header Name
                      </label>
                      <select
                        name="headerName"
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        value={headerName}
                        onChange={(e) => setHeaderName(e.target.value)}
                      >
                        {headerMenus.map((menu) => (
                          <option key={menu.id} value={menu.id}>
                            {menu.menu}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="newCategory"
                        className="block text-sm text-gray-700"
                      >
                        New Category
                      </label>
                      <input
                        type="text"
                        name="newCategory"
                        placeholder="Category 1"
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                      />
                    </div>
                    <div className="mb-4">
                      {categoryFields.map((field) => (
                        <div className="flex items-center gap-1">
                          <input
                            key={field.id}
                            type="text"
                            placeholder={`Category ${field.id + 1}`}
                            className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                            value={field.value}
                            onChange={(e) =>
                              setCategoryFields((prevFields) =>
                                prevFields.map((f) =>
                                  f.id === field.id ? { ...f, value: e.target.value } : f
                                )
                              )
                            }
                          />
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-400 duration-200 text-xl"
                            onClick={() =>
                              setCategoryFields((prev) =>
                                prev.filter((f) => f.id !== field.id)
                              )
                            }
                          >
                            <FaMinusCircle />
                          </button>
                        </div>
                      ))}
                    </div>
                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                      onClick={addNewField}
                    >
                      Add New Category
                    </button>
                  </form>
                </div>
                <div className="flex justify-end items-center p-4 border-t">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                    onClick={toggleForm}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                    onClick={saveCategory}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className='bg-white mt-10 px-3 py-4 rounded-lg'>
            <div className='flex items-center gap-3 text-sm'>
              <p className='text-neutral-500'>Filter by Role:</p>
              <div className='flex items-center gap-2'>
                <select className='focus:outline-none focus:border-blue-300 border rounded px-1 py-1'
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  {headerMenus.map((menu) => (
                    <option key={menu.id} value={menu.id}>
                      {menu.menu}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="overflow-x-auto table-auto">
              <table className="min-w-full bg-white mt-8" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 text-start whitespace-nowrap py-2 border border-[#48ABF7] text-white font-normal bg-blue-400" style={{ width: '1%' }}>SUB-CATEGORY ID</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">SUB-CATEGORY NAME</th>
                    <th className="px-2 hidden sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">PRICE</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">Header Menu</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryList.map((category) => (
                    <tr key={category.id}>
                      <td className="px-2 py-2 font-normal border border-[#48ABF7] whitespace-nowrap">{category.id}</td>
                      <td className="px-4 py-2 font-normal border border-[#48ABF7]">
                        
                        
                        {categoryEditInput === category.id ? (
                          <div className="flex items-center gap-2">
                            <input
                              type="text"
                              name="field"
                              value={category.category}
                              onChange={(e) => updateCategoryName(e, category.id)}
                              placeholder="rename field"
                              className="px-2 py-1 w-48 rounded border shadow focus:outline-none"
                            />
                            <button
                              onClick={() => apiUpdateCategoryName(category.id)}
                              className="bg-blue-500 hover:bg-blue-400 text-white px-2 rounded-full active:bg-blue-200 duration-200"
                            >
                              Update
                            </button>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <span>{category.category}</span>
                            <button
                              onClick={() => toggleCategoryEdit(category.id)}
                              className="text-blue-500 hover:text-blue-600 hover:bg-black/15 p-2 rounded-full duration-200"
                            >
                              <FaEdit />
                            </button>
                          </div>
                        )}

                      </td>
                      <td className="px-4 hidden py-2 border border-[#48ABF7]">
                        <table className="w-full">
                          <tbody>
                            <tr>
                              <td className="px-2 py-2">
                                <div style={{ display: 'flex' }}>
                                  <span style={{ marginTop: '6px', fontSize: '20px', marginRight: '5px' }}>$</span>
                                  <input
                                    type="text"
                                    className="form-input w-full border rounded-md p-2"
                                    placeholder="Enter price"
                                    value={prices[category.id] || ''}
                                    onChange={(e) => handlePriceChange(e, category.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="px-4 py-2 font-normal border border-[#48ABF7]">
                        <select
                          name="headerName"
                          className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                          value={selectedCategory}
                          onChange={(e) => getTheHeaderSelectedOption(e.target.value, category.id)}
                        >
                          {headerMenus.map((menu) => (
                            <option key={menu.id} value={menu.id}>
                              {menu.menu}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="px-4 py-2 border border-[#48ABF7]">
                        <button
                          className={`bg-blue-500 hidden text-white py-1 px-3 mb-2 rounded-full items-center update-btn ${loadingButton === category.id ? 'loading' : ''
                            }`}
                          title="Update"
                          onClick={() => updateCategoryPrice(category.id, 'category_sub_menu')}
                          disabled={loadingButton === category.id} // Disable the button if it's loading
                        >
                          <FaEdit className="text-sm mr-1" />
                          {loadingButton === category.id ? 'Updating...' : 'Update'}
                        </button>
                        <button
                          className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center"
                          onClick={() => handleDelete(category.id)}
                        >
                          <MdDeleteOutline className="mr-[1px]" />
                          Delete
                        </button>
                      </td>
                      {/* <td className="px-4 py-2 border border-[#48ABF7]">
                        <button className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center update-btn" title="Update"
                          onClick={() => updateCategoryPrice(category.id, 'category_sub_menu')}
                          style={{ pointerEvents: loading ? 'none' : 'auto' }}>
                            {loading ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Show a spinner when loading
                            ) : (
                              <FaEdit />
                            )}
                            {loading ? 'Updating...' : 'Update'}
                        </button>
                      </td> */}

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPricing;