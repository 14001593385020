import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CiSearch } from 'react-icons/ci';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaCartArrowDown, FaEye } from 'react-icons/fa'
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { GetCategoryImagesAPI } from '../serverApi/server'
import { CONNECTION_REFUSED } from '../components/Helper/Helpers'

const MemorialFuneral = ({ title, additionalName }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopupRefused, setShowPopupRefused] = useState(false);
  const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
  const [description, setDescription] = useState(null);

  const pageSize = 500;
  const [totalPageAPI, setTotalPageAPI] = useState(null);
  const [currentPageAPI, setCurrentPageAPI] = useState(null);
  const [previousPageAPI, setPreviousPageAPI] = useState(null);
  const [nextPageAPI, setNextPageAPI] = useState(null);
  
  const GetOurProductsCategoryImages = async (additionalName, page, pageSize) => {

    try {
      const GetCategoryImagesApiResponse = await GetCategoryImagesAPI(additionalName, page, pageSize);
      if (GetCategoryImagesApiResponse.status === 200 && GetCategoryImagesApiResponse.data && GetCategoryImagesApiResponse.data.category_serializer.length !== 0) {
        setProducts(GetCategoryImagesApiResponse.data.category_serializer);
        setDescription(GetCategoryImagesApiResponse.data);
        setCurrentPageAPI(GetCategoryImagesApiResponse.data.current_page);
        setTotalPageAPI(GetCategoryImagesApiResponse.data.total_pages);
        setPreviousPageAPI(GetCategoryImagesApiResponse.data.previous_page)
        setNextPageAPI(GetCategoryImagesApiResponse.data.next_page)
      } else {
        setPopupMessage(GetCategoryImagesApiResponse.response.data.message);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      }
    } catch (error) {
      setPopupRefusedMessage(CONNECTION_REFUSED);
      setShowPopupRefused(true);
      setTimeout(() => setShowPopupRefused(false), 2000);
    }
  };
  const firstPage = 1;
  useEffect(() => {
    GetOurProductsCategoryImages(additionalName, firstPage, pageSize)
  }, [additionalName]);
  
  const [currentPage, setCurrentPage] = useState(currentPageAPI);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    GetOurProductsCategoryImages(additionalName, page, pageSize);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  const formPageDetails = (product) => {
    if (additionalName === 'booklets' || additionalName === 'paperback_books' || additionalName === 'hardcover_books' || additionalName === 'magazines' || additionalName === 'catalogs' || additionalName === 'share_&_sell') {
      navigate(`/booklets-design/${product.id}`)
    } else {
      navigate(`/shopdetails/${product.id}`)
    }
  }

  return (
    <div>

      <div className='px-4 md:px-14 py-5 md:py-6  gap-8'>

        <div class="mb-10 sm:mb-10 mt-4 sm:mt-6">
          <div class="flex flex-col items-center">
              <h1 class="font-semibold uppercase mb-3 text-2xl md:text-3xl">{description && description.category_name}</h1>
              <div class="inline-flex w-full md:11/12 lg:w-3/4"
                  dangerouslySetInnerHTML={{
                    __html: description && description.category_description,
                  }}
              >
              </div>
          </div>
        </div>

        <div className='mt-5 lg:mt-0 w-full'>

          <div className='flex items-center justify-between w-full gap-8'>
            <form action="">
              <div className="flex items-center border rounded">
                <input
                  type="text"
                  className="w-full p-2 focus:outline-none"
                  placeholder="Search by name"
                />
                <span className="p-2 border-l w-12 text-orange-600">
                  <CiSearch className='h-full w-full' />
                </span>
              </div>
            </form>

            <div className="relative" ref={dropdownRef}>
              <button
                className="border flex items-center justify-between w-24 lg:w-36 p-2 rounded"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
              >
                Sort by <IoMdArrowDropdown className={`inline duration-200 ${dropdownOpen && 'rotate-180'}`} />
              </button>

              {dropdownOpen && (
                <div className="absolute z-50 right-0 mt-2 w-48 bg-white border rounded shadow-lg">
                  <a className="block px-4 py-2 hover:bg-gray-200" href="#" onClick={() => setDropdownOpen(!dropdownOpen)}>Latest</a>
                  <a className="block px-4 py-2 hover:bg-gray-200" href="#" onClick={() => setDropdownOpen(!dropdownOpen)}>Popularity</a>
                  <a className="block px-4 py-2 hover:bg-gray-200" href="#" onClick={() => setDropdownOpen(!dropdownOpen)}>Best Rating</a>
                </div>
              )}
            </div>
          </div>


          <div className='mt-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 sm:gap-4 md:gap-6'>
            {products && products.map((product) => (
              <div onClick={() => formPageDetails(product)} key={product.id} >
                <div className=''>
                  <div
                    className='relative w-full h-full overflow-hidden flex justify-center bg-transparent border-t border-r border-l cursor-pointer'
                    onClick={() => handleImageClick}
                  >
                    <img
                      className={`w-full h-full transition-transform duration-500 ease-in-out hover:scale-125 ${isZoomed ? 'scale-125' : ''}`}
                      src={product.image_file} alt={product.image_name} />
                  </div>
                  <div className="flex py-[6px] sm:py-2 px-2 sm:px-3 text-sm justify-around md:justify-between items-center text-gray-700 gap-1 bg-gray-100 border">
                    <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                      <FaEye className='text-orange-700' />
                      <button className='text-xs'>
                        <span className='hidden sm:inline'>View</span> Detail
                      </button>
                    </div>
                    <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                      <FaCartArrowDown className='text-orange-700' />
                      <button className='text-xs'>
                        Add <span className='hidden sm:inline'>To Cart</span>
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            ))}

          </div>

          <div style={{ display: 'none' }} className="flex justify-center items-center space-x-2 mt-6 md:mt-8">
            <button
              className={`px-2 py-3 border ${
                currentPageAPI > 1 ? "text-black" : "text-gray-400 cursor-not-allowed"
              }`}
              disabled={currentPageAPI <= 1}
              onClick={() => handlePageChange(currentPageAPI - 1)}
            >
              <MdKeyboardDoubleArrowLeft />
            </button>

            <div className="flex">
              {Array.from({ length: totalPageAPI }, (_, index) => index + 1)
                .filter((page) => {
                  if (totalPageAPI <= 5) {
                    return true;
                  } else if (currentPageAPI < 3) {
                    return page <= 3;
                  } else if (currentPageAPI >= totalPageAPI - 2) {
                    return page > totalPageAPI - 3;
                  } else {
                    return Math.abs(currentPageAPI - page) <= 2;
                  }
                })
                .map((page) => (
                  <button
                    key={page}
                    className={`px-3 py-2 ${
                      page === 1 ? "border" : "border-t border-r border-b border-l-0"
                    } ${
                      page === currentPageAPI ? "bg-orange-300 text-white" : "text-black"
                    }`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                ))}
            </div>

            <button
              className={`px-2 py-3 border ${
                currentPageAPI < totalPageAPI
                  ? "text-black"
                  : "text-gray-400 cursor-not-allowed"
              }`}
              disabled={currentPageAPI >= totalPageAPI}
              onClick={() => handlePageChange(currentPageAPI + 1)}
            >
              <MdKeyboardDoubleArrowRight />
            </button>
          </div>


        </div>
      </div>

    </div>
  )
}

export default MemorialFuneral