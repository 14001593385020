import React, { useState, useEffect } from "react";
import visa from "../assets/visa.png";
import american from "../assets/american-express.png";
import bank from "../assets/bank-transfer.png";
import discover from "../assets/discover.png";
import paymentFail from "../assets/paymentFail.jpg"
import masterCard from '../assets/masterCard.png'
import { useLocation, useNavigate } from "react-router-dom";
import { getConvergeSessionToken, transactionResponse, generateRefreshToken } from '../serverApi/server'
import $ from 'jquery';
import CryptoJS from 'crypto-js';
import { encryptToken, setCookie } from '../components/Helper/Helpers'


const Billing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userId, totalPrice, invoiceNumber, couponId, turnAround } = location.state || {};
  const [isSubmitting, setIsSubmitting] = useState(false);

  var parsedCartIdList = localStorage.getItem('cartIdList') ? JSON.parse(localStorage.getItem('cartIdList')) : [];

  const [formData, setFormData] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    email: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.cardNumber || formData.cardNumber.length !== 16) {
      setLoading(false);
	  setIsSubmitting(false);
      newErrors.cardNumber = "Card number must be 16 digits.";
    }
    if (!formData.expiryDate || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expiryDate)) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.expiryDate = "Expiration date must be in MM/YY format.";
    }    
    if (!formData.cvv || formData.cvv.length !== 3) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.cvv = "CVV must be 3 digits.";
    }
    if (!formData.firstName) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.firstName = "First name is required.";
    }
    if (!formData.lastName) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.lastName = "Last name is required.";
    }
    if (!formData.address1) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.address1 = "Address1 is required.";
    }
    if (!formData.city) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.city = "City is required.";
    }
    if (!formData.state) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.state = "State/Province is required.";
    }
    if (!formData.postalCode) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.postalCode = "Postal code is required.";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.email = "Valid email is required.";
    }
    if (!formData.phoneNumber || formData.phoneNumber.length < 10) {
      setLoading(false);
      setIsSubmitting(false);
      newErrors.phoneNumber = "Phone number must be at least 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
 
  const getConvergeSessionTokenFunction = async (totalPrice) => {
    try {
      const getConvergeSessionTokenResponse = await getConvergeSessionToken(totalPrice);
      if (getConvergeSessionTokenResponse.status === 401) {
        const generateRefreshTokenAPIResponse = await generateRefreshToken()
        const encryptedAccessToken = generateRefreshTokenAPIResponse.data.access_token
        setCookie('_patsap', encryptedAccessToken, 100);
        return await getConvergeSessionTokenFunction(totalPrice)
      } else if (getConvergeSessionTokenResponse.data.client_status_code === 200) {
        return getConvergeSessionTokenResponse.data.session_token
      } else {
        return null
      }
    } catch (error) {
      console.error('Error fetching session token:', error);
    }
  };

  const transactionResponseFunction = async (payment_response, user_data, couponId, shippingAddress, turnAround) => {
    try {
      const transactionAPIResponse = await transactionResponse(payment_response, user_data, couponId, shippingAddress, turnAround);
      if (transactionAPIResponse.data.client_status_code === 200) {
        return transactionAPIResponse.data
      } else {
        return null
      }
    } catch (error) {
      console.error('Error fetching session token:', error);
    }
  };

  const [isPaymentFailed, setIsPaymentFailed] = useState(false);

  const user_data = {
    'user_id': userId,
    'last_name': formData.lastName,
    'first_name': formData.firstName,
    'email': formData.email,
    'phone': formData.phoneNumber,
    'state': formData.state,
    'city': formData.city,
    'address': formData.address1 + ' ' + formData.address2,
    'zip': formData.postalCode,
    'cart_id_list': parsedCartIdList
  }

  const shippingAddress = {
    'shipping_first_name': formData.lastName,
    'shipping_last_name': formData.firstName,
    'shipping_mail': formData.email,
    'shipping_phone_number': formData.phoneNumber,
    'shipping_state_province': formData.state,
    'shipping_city': formData.city,
    'shipping_address_one': formData.address1,
    'shipping_address_two': formData.address2,
    'shipping_postal_code': formData.postalCode,
  }

  const callback = {
    onError: async  (error) => {
      console.log('errorerrorerrorerrorerrorerrorerror', error)
      setLoading(false);
      setIsPaymentFailed(true)
      setIsSubmitting(false);
    },
    onDeclined: (response) => {
      console.log('responseresponseresponseresponseresponseresponseresponse', response)
      setLoading(false);
      setIsPaymentFailed(true)
      setIsSubmitting(false);
    },
    onApproval: async (response) => {
      const transactionDetailsResponse = await transactionResponseFunction(response, user_data, couponId, shippingAddress, turnAround);
      setLoading(false);
      localStorage.removeItem('cartIdList');
      setIsSubmitting(false);
      navigate('/pdf-upload-form', { state: {
        userId: transactionDetailsResponse.user_id,
        orderId: transactionDetailsResponse.order_id,
        paymentId: transactionDetailsResponse.payment_id,
        headerMenu: transactionDetailsResponse.header_menu
      }})
    },
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    setLoading(true);
    e.preventDefault();
    if (validate()) { } else { return; }
    if (!userId && !totalPrice && !invoiceNumber) {
      setLoading(false);
      setIsSubmitting(false);
      return;
    }
    const convergeSessionToken = await getConvergeSessionTokenFunction(totalPrice)
    console.log('convergeSessionToken', convergeSessionToken)
    const paymentData = {
      ssl_txn_auth_token: convergeSessionToken,
      ssl_card_number: formData.cardNumber,
      ssl_exp_date: formData.expiryDate.replace("/", ""),
      ssl_get_token: 'y',
      ssl_add_token: 'y',
      ssl_invoice_number: invoiceNumber,
      ssl_first_name: formData.firstName,
      ssl_last_name: formData.lastName,
      ssl_cvv2cvc2: formData.cvv,
      ssl_avs_address: formData.address1,
      ssl_address2: formData.address2,
      ssl_city: formData.city,
      ssl_state: formData.state,
      ssl_avs_zip: formData.postalCode,
    };

    if (userId && totalPrice && invoiceNumber && convergeSessionToken) {
      console.log('\n 11111111111111111111111')
      window.ConvergeEmbeddedPayment.pay(paymentData, callback);
    } else {
      console.log('\n 22222222222222222222222222222')
      setLoading(false);
      setIsSubmitting(false);
      setIsPaymentFailed(true)
    }
    
  };

  return (
    <div className="bg-[#F9F9F9]">
      <form onSubmit={handleSubmit}>
        <div className="px-5 py-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Order Section */}
          <div className="flex flex-col gap-6">
            <div className="border border-neutral-300 rounded-md overflow-hidden bg-white">
              <h1 className="px-2 py-1 w-full bg-[#DB9D57] text-lg font-semibold text-white">
                Order Section
              </h1>
              <div className="pt-3 pb-8 px-5 flex flex-col gap-1">
                <p className="font-semibold text-base sm:text-[17px]">
                  Amount: <span className="font-light text-base">$ {totalPrice}</span>
                </p>
                <p className="font-semibold text-base sm:text-[17px]">
                  Invoice Number: <span className="font-light text-base">{invoiceNumber}</span>
                </p>
              </div>
            </div>

            {/* Payment Section */}
            <div className="border border-neutral-300 rounded-md overflow-hidden bg-white">
              <h1 className="px-2 py-1 w-full bg-[#DB9D57] text-lg font-semibold text-white">
                Payment
              </h1>
              <div className="pt-3 pb-8 px-5 flex flex-col gap-1">
                <p className="font-semibold text-base sm:text-[17px]">Payment Card</p>
                <div className="flex">
                  <img src={visa} alt="visa" className="w-10 mr-[10px]" />
                  <img src={bank} alt="bank-transfer" className="w-10 mr-[10px]" />
                  <img src={discover} alt="discover" className="w-10 mr-[10px]" />
                  <img src={masterCard} alt="masterCard" className="w-10 mr-[10px]" />
                </div>
                {/* Card Details */}
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                    Card Number*
                  </label>
                  <input
                    type="text"
                    name="cardNumber"
                    placeholder="Enter your card number"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    className="px-2 py-[6px] border border-neutral-300 rounded"
                  />
                  {errors.cardNumber && <span className="text-red-500 text-sm">{errors.cardNumber}</span>}
                </div>
                {/* Other fields (Expiry, CVV) */}
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="expiryDate" className="font-semibold text-base sm:text-[17px]">
                    Expiration Date (MM/YY)*
                  </label>
                  <input
                    type="text"
                    name="expiryDate"
                    placeholder="MM/YY"
                    value={formData.expiryDate}
                    onChange={handleChange}
                    className="px-2 py-[6px] border border-neutral-300 rounded"
                  />
                  {errors.expiryDate && <span className="text-red-500 text-sm">{errors.expiryDate}</span>}
                </div>
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="cvv" className="font-semibold text-base sm:text-[17px]">
                    CVV*
                  </label>
                  <input
                    type="text"
                    name="cvv"
                    placeholder="Enter your CVV"
                    value={formData.cvv}
                    onChange={handleChange}
                    className="px-2 py-[6px] border border-neutral-300 rounded"
                  />
                  {errors.cvv && <span className="text-red-500 text-sm">{errors.cvv}</span>}
                </div>
              </div>
            </div>
          </div>

          {/* Billing Address Section */}
          <div className="border border-neutral-300 rounded-md overflow-hidden bg-white">
            <h1 className="px-2 py-1 w-full bg-[#DB9D57] text-lg font-semibold text-white">
              Billing Address
            </h1>

            <div className="pt-3 pb-8 px-5 flex flex-col gap-1">

              <div className="grid grid-cols-2 gap-7">
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                    First Name*
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="px-2 py-[6px] border border-neutral-300 rounded"
                  />
                  {errors.firstName && <span className="text-red-500 text-sm">{errors.firstName}</span>}
                </div>
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="px-2 py-[6px] border border-neutral-300 rounded"
                  />
                  {errors.lastName && <span className="text-red-500 text-sm">{errors.lastName}</span>}
                </div>
              </div>
              <div className="flex flex-col gap-[2px]">
                <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                  Address1*
                </label>
                <input
                  type="text"
                  name="address1"
                  placeholder="Street Address"
                  value={formData.address1}
                  onChange={handleChange}
                  className="px-2 py-[6px] border border-neutral-300 rounded"
                />
                {errors.lastName && <span className="text-red-500 text-sm">{errors.lastName}</span>}
              </div>
              <div className="flex flex-col gap-[2px]">
                <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                  Address2 (Optional)
                </label>
                <input
                  type="text"
                  name="address2"
                  placeholder="Apartment, suite, etc"
                  className="px-2 py-[6px] border border-neutral-300 rounded"
                />
              </div>
              <div className="grid grid-cols-2 gap-7">
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                    City*
                  </label>
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                    className="px-2 py-[6px] border border-neutral-300 rounded"
                  />
                  {errors.city && <span className="text-red-500 text-sm">{errors.city}</span>}
                </div>
                <div className="flex flex-col gap-[2px]">
                  <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                    State/Province*
                  </label>
                  <input
                    type="text"
                    name="state"
                    placeholder="State/Province"
                    value={formData.state}
                    onChange={handleChange}
                    className="px-2 py-[6px] border border-neutral-300 rounded"
                  />
                  {errors.state && <span className="text-red-500 text-sm">{errors.state}</span>}
                </div>
              </div>
              <div className="flex flex-col gap-[2px]">
                <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                  Postal Code*
                </label>
                <input
                  type="number"
                  name="postalCode"
                  placeholder="Postal Code"
                  value={formData.postalCode}
                  onChange={handleChange}
                  className="px-2 py-[6px] border border-neutral-300 rounded"
                />
                {errors.postalCode && <span className="text-red-500 text-sm">{errors.postalCode}</span>}
              </div>
              <div className="flex flex-col gap-[2px]">
                <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="px-2 py-[6px] border border-neutral-300 rounded"
                />
                {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
              </div>
              <div className="flex flex-col gap-[2px]">
                <label htmlFor="cardNumber" className="font-semibold text-base sm:text-[17px]">
                  Phone Number*
                </label>
                <input
                  type="number"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="px-2 py-[6px] border border-neutral-300 rounded"
                />
                {errors.phoneNumber && <span className="text-red-500 text-sm">{errors.phoneNumber}</span>}
              </div>
            </div>

          </div>
        </div>

        
        <div className="w-full flex items-center justify-center pb-6">
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-orange-400 hover:bg-orange-500 duration-200 text-white px-4 py-1 rounded-md"
          >
            {isSubmitting ? 'Processing...' : 'Confirm Payment'}
          </button>
        </div>


      </form>
      <div>
        {loading && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-90 flex flex-col justify-center items-center z-50"
          >
            <div className="w-12 h-12 border-4 border-gray-300 border-t-4 border-t-orange-500 rounded-full animate-spin"></div>
            <p className="mt-4 text-lg text-gray-600">Loading, please wait...</p>
          </div>
        )}
      </div>

      <div>
        {isPaymentFailed &&
          <div
            className="fixed top-0 left-0 w-full h-full bg-[#7F7F7F] flex flex-col justify-center items-center z-[150]"
          >
            <div
              className="w-3/4 sm:w-3/5 md:w-1/2 lg:w-2/5 xl:w-1/3 bg-white rounded-lg animate-slide-down"
            >
              <div className="border-b">
                <div className="flex flex-col border-b-4 border-b-red-600 items-center gap-1 sm:gap-2 mx-4 my-8 lg:my-12 py-6 sm:py-8 md:py-12 rounded-md shadow-[0px_0px_6px_2px_rgba(0,0,0,0.1)]">
                  <div className="w-24 sm:w-36">
                    <img src={paymentFail} alt="paymentFail" className="w-full" />
                  </div>
                  <h1 className="text-center text-xl sm:text-2xl md:text-3xl font-medium">Transaction <br />Declined</h1>
                  <p className="text-base sm:text-lg text-neutral-600 font-medium">Try again later</p>
                </div>
              </div>
              <div className="flex gap-2 justify-end px-4 py-4">
                <button
                  className="bg-neutral-500 hover:bg-neutral-600 duration-200 px-3 py-[6px] rounded text-white"
                  onClick={() => setIsPaymentFailed(false)}
                >
                  Close
                </button>
                <button
                  className="bg-neutral-500 hover:bg-neutral-600 duration-200 px-3 py-[6px] rounded text-white"
                  onClick={() => setIsPaymentFailed(false)}
                >
                  Try again
                </button>
              </div>
            </div>
          </div>
        }
      </div>

    </div>
  );
};

export default Billing;
