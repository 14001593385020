import React, { useEffect, useState } from 'react';
import logo from '../../assets/Stalbanslogo.png';
import newLogo from '../../assets/newStalbansLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import SocialMedia from './SocialMedia';
import { CiSearch } from 'react-icons/ci';
import { FaCartArrowDown, FaTruckArrowRight } from 'react-icons/fa6';
import Skeleton from '../../pages/skeleton/Skeleton';
import { getHeadersAndCategory } from '../../serverApi/server';

const Navbar = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [categories, setCategories] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loadingTime, setLoadingTime] = useState(true);

  const orderRoute = () => navigate('./login');
  const cartRoute = () => navigate('/product-cart');

  useEffect(() => {
    const timer = setTimeout(() => setLoadingTime(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const fetchCategories = async (query) => {
    try {
      const data = await getHeadersAndCategory();
      const allCategories = data.all_header_menus_details;
      const filteredCategories = allCategories
        .map((menu) => ({
          ...menu,
          categories: menu.categories.filter(
            (category) =>
              category.category.toLowerCase().startsWith(query.toLowerCase()) ||
              (category.keywords &&
                category.keywords.toLowerCase().includes(query.toLowerCase()))
          ),
        }))
        .filter((menu) => menu.categories.length > 0);

      setCategories(filteredCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      fetchCategories(query);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleCategorySelect = (category) => {
    if (category.additional_name) {
      navigate(category.additional_name);
    } else {
      alert(`No route specified for: ${category.category}`);
    }
    setSearchQuery('');
    setShowDropdown(false);
  };

  return (
    <nav className="border-b-[1px]">
      <SocialMedia />
      <div className="flex items-center justify-between pt-5 pb-4 font-medium px-5 sm:px-[2vw] md:px-[3vw] lg:px-[5vw]">
        <Link to="/" className="hidden custom-991:flex items-center">
          {loadingTime ? (
            <div className="w-16">
              <Skeleton width="100%" height="35px" />
            </div>
          ) : (
            <img
              src={newLogo}
              alt="brand-newLogo"
              className="w-16 cursor-pointer"
              style={{ width: '8rem' }}
            />
          )}
        </Link>

        <div className="relative w-2/5 sm:w-2/5 md:w-1/2 custom-991:w-2/6">
          <form
            className="block bg-gray-50 text-center border border-gray-400 items-center justify-center relative rounded-full"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="relative flex">
              <input
                className="w-full sm:w-11/12 px-4 py-2 text-sm font-light bg-gray-50 outline-none rounded-full"
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleInputChange}
                onFocus={() => setShowDropdown(searchQuery.length > 2)}
              />
              <button
                className="absolute h-full right-1 top-1/2 -translate-y-1/2 px-2 md:px-4 sm:block"
                type="button"
              >
                <CiSearch className="w-6 h-6" />
              </button>
            </div>
          </form>
          {showDropdown && (
            <div className="absolute z-10 bg-white border border-gray-300 rounded shadow-lg mt-2 w-full">
              {categories.length > 0 ? (
                categories.map((menu) => (
                  <div key={menu.header_menu_id} className="p-2">
                    <p className="font-semibold">{menu.header_menu}</p>
                    <ul>
                      {menu.categories.map((category) => (
                        <li
                          key={category.id}
                          className="p-1 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleCategorySelect(category)}
                        >
                          {category.category}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <div className="p-2 text-gray-500">No results found</div>
              )}
            </div>
          )}
        </div>

        <div className={`flex items-center ${loadingTime ? 'gap-3 md:gap-5' : 'gap-1'}`}>
          {loadingTime ? (
            <div className="w-6 sm:w-12 h-6 md:h-9 flex items-center">
              <Skeleton width="100%" height="100%" />
            </div>
          ) : (
            <button onClick={cartRoute} className="flex items-center sm:mr-2 group">
              <div className="flex items-center gap-1 sm:gap-2 sm:border-[0.1px] border-gray-200 border-spacing-1 px-1 sm:px-3 py-1 rounded-lg hover:bg-orange-500 duration-200">
                <FaCartArrowDown className="group-hover:text-neutral-100 duration-200 text-orange-500 text-xl sm:text-2xl" />
              </div>
              <span className="sm:hidden font-light px-1">|</span>
            </button>
          )}
          {loadingTime ? (
            <div className="w-6 sm:w-12 h-6 md:h-9 flex items-center">
              <Skeleton width="100%" height="100%" />
            </div>
          ) : (
            <button onClick={orderRoute} className="flex items-center group">
              <div className="flex items-center gap-1 sm:gap-2 sm:border-[0.1px] border-gray-200 border-spacing-1 px-1 sm:px-3 py-1 rounded-lg hover:bg-orange-500 duration-200">
                <FaTruckArrowRight className="group-hover:text-neutral-100 duration-200 text-orange-500 text-xl sm:text-2xl" />
              </div>
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;