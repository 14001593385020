import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import { getClientMailDetails, addNewClientMailDetails, sendClientMail, deleteClientDetails, updateClientDetails } from '../../serverApi/server'
import { RiMailSendFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";


const SendClientMail = () => {
    const location = useLocation();
    const toggleForm = () => {
        setMailExistsStatus(false);
        setFormOpen(!formOpen)
    }
    const [formOpen, setFormOpen] = useState(false);
    const [clientMailDetails, setClientMailDetails] = useState()
    const toggleMailForm = () => setMailSendOpenForm(!mailSendOpenForm);
    const [mailSendOpenForm, setMailSendOpenForm] = useState(false);
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [loadingMailId, setLoadingMailId] = useState(null);
    const [expiryDate, setExpiryDate] = useState('');
    const editToggleForm = () => setEditFormOpen(!editFormOpen);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [editFirstname, setEditFirstname] = useState('');
    const [editLastname, setEditLastname] = useState('');
    const [editMail, setEditMail] = useState('');
    const [editId, setEditId] = useState('');
    const [mailExistsStatus, setMailExistsStatus] = useState(false);
    const [mailExistsMessage, setMailExistsMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const getClientMailDetailsAPIResponse = async () => {
        try {
            const getClientMailDetailsResponse = await getClientMailDetails();
            setClientMailDetails(getClientMailDetailsResponse.data.client_mail_details_serializer)
        } catch (error) {
            console.error(error);
        }
    }

    const addNewClientMailDetailsAPIResponse = async (email, firstname, lastname) => {
        try {
            const addNewClientMailDetailsResponse = await addNewClientMailDetails(email, firstname, lastname);
            return addNewClientMailDetailsResponse
        } catch (error) {
            console.error(error);
        }
    }

    const sendClientMailAPIResponse = async (email, expiryDate) => {
        try {
            const sendClientMailResponse = await sendClientMail(email, expiryDate);
            return sendClientMailResponse
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getClientMailDetailsAPIResponse();
    }, [])

    const handleSendMail = async (clientMailId) => {
        setLoadingMailId(clientMailId);
        await sendClientMailAPIResponse(clientMailId)
        getClientMailDetailsAPIResponse();
        setLoadingMailId(null);
    }

    const saveCategory = async (email, firstname, lastname) => {
        setLoading(true)
        const addNewClientMailAPIResponseDetails = await addNewClientMailDetailsAPIResponse(email, firstname, lastname)
        if (addNewClientMailAPIResponseDetails.data && addNewClientMailAPIResponseDetails.data.client_status_code === 400 && addNewClientMailAPIResponseDetails.data.id === 1) {
            setMailExistsStatus(true)
            setMailExistsMessage(addNewClientMailAPIResponseDetails.data.message)
            setTimeout(()=>{
                setLoading(false)
            }, 2000)
            
        } else {
            setMailExistsStatus(false)
            getClientMailDetailsAPIResponse();
            toggleForm();
            setTimeout(()=>{
                setLoading(false)
            }, 2000)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault(); 
        saveCategory(email, firstname, lastname);
    };

    const sendBulkmail = async (e) => {
        toggleMailForm();
        e.preventDefault(); 
        for (const clientdetails of clientMailDetails) {
            setLoadingMailId(clientdetails.id);
            const sendClientMailResponse = await sendClientMailAPIResponse(clientdetails.id, expiryDate);
            setLoadingMailId(null);
            if (sendClientMailResponse.data.client_status_code === 200) {
                setClientMailDetails((prevDetails) =>
                    prevDetails.map((detail) =>
                        detail.id === clientdetails.id ? { ...detail, status: true } : detail
                    )
                );
            }
        }
    }

    const editClientMailDetails = (clientId) => {
        var editClientDetail = clientMailDetails.find(clientDetail => clientDetail.id === clientId)
        setEditFirstname(editClientDetail.firstname)
        setEditLastname(editClientDetail.lastname)
        setEditMail(editClientDetail.mail)
        setEditId(editClientDetail.id)
        setEditFormOpen(true)
    }

    const saveEditClientDetails = async (e) => {
        e.preventDefault(); 
        setLoading(true)
        const updateClientDetailsResponse = await updateClientDetails(editId, editFirstname, editLastname, editMail)
        getClientMailDetailsAPIResponse();
        setEditFormOpen(false)
        setTimeout(()=>{
            setLoading(false)
        }, 2000)
    }

    const deleteClientMailDetails = async (clientId) => {
        setLoading(true)
        await deleteClientDetails(clientId)
        getClientMailDetailsAPIResponse();
        setTimeout(()=>{
            setLoading(false)
        }, 2000)
    }

    return (
        <div className="flex w-full h-screen">
            <div className="fixed h-full custom-scroll">
                <SideBar />
            </div>
            <div className="ml-64 flex-1 overflow-y-auto">
                <Header />
                <div className="bg-[#F5F7FD] px-7 py-6">
                    <div className="flex items-center justify-between">
                        <div className="text-blue-950 flex items-center gap-6">
                            <h1 className="pr-6 text-2xl font-medium border-r">
                                Admin User
                            </h1>
                            <div className="flex text-sm items-center gap-3">
                                <button>
                                    <SlHome />
                                </button>
                                <SlArrowRight />
                                <button>Send Client Mail</button>
                            </div>
                        </div>
                        <div>
                            <button
                                className="bg-blue-500 hover:bg-blue-400 duration-200 px-4 py-2 rounded text-white mr-4 md:mr-8"
                                // onClick={sendBulkmail}
                                onClick={toggleMailForm}
                            >
                                Sent Bulk Mail
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-400 duration-200 px-4 py-2 rounded text-white mr-4 md:mr-8"
                                onClick={toggleForm}
                            >
                                Add Mail
                            </button>
                        </div>
                    </div>

                    {formOpen && (
                        <div
                            className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
                        >
                            <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto">
                                <div className="flex justify-between items-center p-4 border-b">
                                    <h5 className="text-lg font-medium">
                                        Add New Client
                                    </h5>
                                    <button
                                        type="button"
                                        className="text-gray-600 text-3xl hover:text-gray-900"
                                        onClick={toggleForm}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="p-4">
                                    <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                            <label
                                                htmlFor="category_zip_file"
                                                className="block text-sm text-gray-700"
                                            >
                                                Enter The FirstName:
                                            </label>
                                            <input
                                                type='text'
                                                name="category_zip_file"
                                                required
                                                onChange={(e) => { setFirstname(e.target.value); }}
                                                placeholder="Enter The Firstname"
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="category_zip_file"
                                                className="block text-sm text-gray-700"
                                            >
                                                Enter The LastName:
                                            </label>
                                            <input
                                                type='text'
                                                name="category_zip_file"
                                                required
                                                onChange={(e) => { setLastname(e.target.value); }}
                                                placeholder="Enter The LastName"
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="category_zip_file"
                                                className="block text-sm text-gray-700"
                                            >
                                                Enter The Mail:
                                            </label>
                                            <input
                                                type='email'
                                                name="category_zip_file"
                                                required
                                                onChange={handleInputChange}
                                                placeholder="Enter The Mail"
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            />
                                            {mailExistsStatus && <span className="text-red-500 text-sm">{mailExistsMessage}</span>}
                                        </div>
                                        <div className="flex justify-end items-center p-4 border-t">
                                            <button
                                                type="button"
                                                className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                                                onClick={toggleForm}
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="submit"
                                                className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {editFormOpen && (
                        <div
                            className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
                        >
                            <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto">
                                <div className="flex justify-between items-center p-4 border-b">
                                    <h5 className="text-lg font-medium">
                                        Edit Client Details
                                    </h5>
                                    <button
                                        type="button"
                                        className="text-gray-600 text-3xl hover:text-gray-900"
                                        onClick={editToggleForm}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="p-4">
                                    <form onSubmit={saveEditClientDetails}>
                                    <div className="mb-4">
                                            <label
                                                htmlFor="category_zip_file"
                                                className="block text-sm text-gray-700"
                                            >
                                                Edit The FirstName:
                                            </label>
                                            <input
                                                type='text'
                                                name="category_zip_file"
                                                required
                                                value={editFirstname}
                                                onChange={(e) => {setEditFirstname(e.target.value);}}
                                                placeholder="Enter The Firstname"
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="category_zip_file"
                                                className="block text-sm text-gray-700"
                                            >
                                                Edit The LastName:
                                            </label>
                                            <input
                                                type='text'
                                                name="category_zip_file"
                                                required
                                                value={editLastname}
                                                onChange={(e) => { setEditLastname(e.target.value); }}
                                                placeholder="Enter The LastName"
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="category_zip_file"
                                                className="block text-sm text-gray-700"
                                            >
                                                Edit The Mail:
                                            </label>
                                            <input
                                                type='email'
                                                name="category_zip_file"
                                                required
                                                value={editMail}
                                                onChange={(e) => { setEditMail(e.target.value); }}
                                                placeholder="Enter The Mail"
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            />
                                        </div>
                                        <div className="flex justify-end items-center p-4 border-t">
                                            <button
                                                type="button"
                                                className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                                                onClick={editToggleForm}
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="submit"
                                                className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {mailSendOpenForm && (
                        <div
                            className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
                        >
                            <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto">
                                <div className="flex justify-between items-center p-4 border-b">
                                    <h5 className="text-lg font-medium">
                                        Send Bulk Mail
                                    </h5>
                                    <button
                                        type="button"
                                        className="text-gray-600 text-3xl hover:text-gray-900"
                                        onClick={toggleMailForm}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="p-4">
                                    <form onSubmit={sendBulkmail}>
                                        
                                        <div className="mb-4">
                                            <label
                                                htmlFor="category_zip_file"
                                                className="block text-sm text-gray-700"
                                            >
                                                Select The Coupon Expiry Date:
                                            </label>
                                            <input
                                                type="date"
                                                name="expiry_date"
                                                required
                                                onChange={(e) => setExpiryDate(e.target.value)}
                                                placeholder="Select expiry date"
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            />
                                        </div>
                                        
                                        <div className="flex justify-end items-center p-4 border-t">
                                            <button
                                                type="submit"
                                                className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                                            >
                                                Submit
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="bg-white mt-10 px-3 py-4 rounded-lg">
                        <div className="overflow-x-auto table-auto">
                            <table className="min-w-full bg-white mt-8" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                                <thead>
                                    <tr>
                                        <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">FIRSTNAME</th>
                                        <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">LASTNAME</th>
                                        <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">MAIL ID</th>
                                        <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">STATUS</th>
                                        <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientMailDetails && clientMailDetails.length > 0 ? (
                                        clientMailDetails.map((clientMail) => (
                                            <tr key={clientMail.id}>
                                            <td className="px-4 py-2 font-normal border border-[#48ABF7]">{clientMail.firstname}</td>
                                            <td className="px-4 py-2 font-normal border border-[#48ABF7]">{clientMail.lastname}</td>
                                            <td className="px-4 py-2 font-normal border border-[#48ABF7]">{clientMail.mail}</td>
                                            
                                            {/* <td className="px-4 py-2 font-normal border border-[#48ABF7]">
                                                <p
                                                    className={`px-4 py-2 font-normal ${
                                                    clientMail.status === true ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                                                    }`}
                                                >
                                                    {clientMail.status === true ? 'Sent' : 'Not Sent'}
                                                </p>
                                            </td> */}

                                            <td className="px-4 py-2 font-normal border border-[#48ABF7]">
                                                {loadingMailId === clientMail.id ? (
                                                    <div className="spinner"></div> // Spinner displayed while loading
                                                ) : (
                                                    <p
                                                        className={`px-4 py-2 font-normal ${
                                                            clientMail.status === true
                                                                ? 'bg-green-100 text-green-700'
                                                                : 'bg-red-100 text-red-700'
                                                        }`}
                                                    >
                                                        {clientMail.status === true ? 'Sent' : 'Not Sent'}
                                                    </p>
                                                )}
                                            </td>

                                            <td className="px-4 py-2 border border-[#48ABF7]" style={{display: 'flex', height: '60px', justifyContent: 'center'}}>
                                                <button 
                                                    className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center"
                                                    style={{marginRight: '10px'}}
                                                    onClick={() => editClientMailDetails(clientMail.id)}
                                                >
                                                <FaEdit className="mr-[1px]"/>
                                                UPDATE
                                                </button>

                                                <button 
                                                    className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center" 
                                                    onClick={() => deleteClientMailDetails(clientMail.id)}
                                                >
                                                <MdDeleteOutline className="mr-[1px]"/>
                                                DELETE
                                                </button>

                                            </td>
                                            </tr>
                                        ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="px-4 py-2 text-center font-normal border border-[#48ABF7]">
                                                    Mail details are not available
                                                </td>
                                            </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        {loading && (
                            <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-90 flex flex-col justify-center items-center z-50">
                                <div className="w-12 h-12 border-4 border-gray-300 border-t-4 border-t-orange-500 rounded-full animate-spin"></div>
                                <p className="mt-4 text-sm sm:text-base md:text-lg text-gray-600">
                                    Loading, please wait...
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendClientMail;
