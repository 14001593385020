import axios from 'axios';
import { decryptToken, getCookie } from '../components/Helper/Helpers'


const serverUrl = 'https://www.stalbansprinting.com'


export const GetCategoryImagesAPI = async (category_menu, page, page_size) => {
    const url = `${serverUrl}/api/category/get_category_list`;
  	
    const payload = { category_menu, page, page_size }    
    try {
        const GetCategoryImagesApiResponse = await axios.post(url, payload);
        return GetCategoryImagesApiResponse;
    } catch (error) {
        return error;
    }
};

export const getCategoryMenuData = async (category_menu_option_id) => {
    const url = `${serverUrl}/api/category/get_category_menu_fields`;
    const payload = { category_menu_option_id };
    
    try {
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error('Error fetching category menu data:', error);
        throw error;
    }
};

export const loginUserAPI = async (email, password, login_type) => {
    const url = `${serverUrl}/api/login_panel/login_user/`;
    const payload = { email, password, login_type };

    try {
        const loginResponse = await axios.post(url, payload);
        return loginResponse;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

export const generateRefreshToken = async () => {
    const refresh_token = decryptToken(getCookie('_prtsap'));
    console.log('\n refresh_tokenrefresh_tokenrefresh_token: ', refresh_token)
    const url = `${serverUrl}/api/login_panel/login_user/?refresh_token=${refresh_token}`;
    try {
        const generateRefreshTokenAPIResponse = await axios.get(url);
        console.log('\n generateRefreshTokenAPIResponse', generateRefreshTokenAPIResponse)
        return generateRefreshTokenAPIResponse;
    } catch (error) {
        return error;
    }
}

export const registerUserAPI = async (formData) => {
    const url = `${serverUrl}/api/login_panel/register_user`;
    const payload = {
        full_name: formData.regName,
        email: formData.regEmail,
        password: formData.regConfirmPassword,
    };

    try {
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error('Registration error:', error);
        throw error;
    }
};

export const getHeaderMenusAPI =  async () => { 
    const url = `${serverUrl}/api/admin_panel/get_header_menus`
    console.log('url--------', url)
    const response = await axios.get(url);
  	console.log('response-------', response)
    return response.data;
}

export const getHeadersAndCategory = async () => {
    const url = `${serverUrl}/api/category/get_header_category_details`
    const response = await axios.get(url);
    return response.data;
}

export const getCategorySubMenusAPI =  async (header_menu_id) => {
    const url = `${serverUrl}/api/admin_panel/get_header_menus`
    const payload = { header_menu_id };
    try {
        const categorySubMenusAPIResponse = await axios.post(url, payload);
        return categorySubMenusAPIResponse;
    } catch (error) {
        return error;
    }
}

export const createNewCategory = async (header_menu_id, category_menu_list) => {
    const url = `${serverUrl}/api/admin_panel/create_new_category`
    const payload = { header_menu_id, category_menu_list };
    try {
        const createNewCategoryAPIResponse = await axios.post(url, payload);
        return createNewCategoryAPIResponse
    } catch (error) {
        console.error('CreateNewCategory error:', error);
        throw error;
    }
}

export const deleteCategory = async (delete_category_id) => {
    const url = `${serverUrl}/api/admin_panel/create_new_category`
    const payload = { delete_category_id };
    try {       
        const deleteCategoryResponse = await axios.delete(url, { data: payload });
        return deleteCategoryResponse
    } catch (error) {
        console.error('deleteCategory error:', error);
        throw error;
    }
}

export const createFieldandOptions = async (sub_category_id, field_name, field_id, new_field_options_list) => {
    const url = `${serverUrl}/api/admin_panel/create_delete_new_field`
    const payload = { sub_category_id, field_name, field_id, new_field_options_list };
    try {       
        const createFieldandOptionsResponse = await axios.post( url, payload );
        return createFieldandOptionsResponse
    } catch (error) {
        console.error('createFieldandOptions error:', error);
        throw error;
    }
}

export const deleteOption = async (delete_id, delete_type) => {
    const url = `${serverUrl}/api/admin_panel/delete_field_option`
    const payload = { delete_id, delete_type };
    try {
        const deleteOptionResponse = await axios.delete(url, { data: payload });
        return deleteOptionResponse
    } catch (error) {
        console.error('deleteOption error:', error);
        throw error;
    }
}

export const deleteField = async (field_id) => {
    const url = `${serverUrl}/api/admin_panel/create_delete_new_field`
    const payload = { field_id };
    try {       
        const deleteFieldResponse = await axios.delete(url, { data: payload });
        return deleteFieldResponse
    } catch (error) {
        console.error('deleteField error:', error);
        throw error;
    }
}

export const getFields = async (field_id) => {
    const url = `${serverUrl}/api/admin_panel/get_category_fields`
    const payload = { field_id };
    try {       
        const getFieldsResponse = await axios.get( url, payload );
        return getFieldsResponse
    } catch (error) {
        console.error('getFieldsResponse error:', error);
        throw error;
    }
}

export const updateCategorySubmenuPrice = async (category_submenu_id, category_submenu_price, category_option, category_update_type, selected_category, paper_type_selected_option, update_name, size_selected_option, all_price_field_options, current_category_id, field_ids) => {
    const url = `${serverUrl}/api/admin_panel/update_category_submenu_price`
    const payload = { category_submenu_id, category_submenu_price, category_option, category_update_type, selected_category, paper_type_selected_option, update_name, size_selected_option, all_price_field_options, current_category_id, field_ids };
    try {
        const updateCategorySubmenuPriceAPIResponse = await axios.put(url, payload);
        return updateCategorySubmenuPriceAPIResponse;
    } catch (error) {
        return error;
    }
}


export const getSubmenuFields = async (submenu_field_id) => {
    const url = `${serverUrl}/api/admin_panel/get_submenu_fields`
    const payload = { submenu_field_id };
    try {
        const updateCategorySubmenuPriceAPIResponse = await axios.post(url, payload);
        return updateCategorySubmenuPriceAPIResponse;
    } catch (error) {
        return error;
    }
}

export const memorialSide = async (product) => {
    const url = `${serverUrl}/api/category/popular_memorial_products_list`
    const payload = { product };
    try {
        const getMemorialSlideResponse = await axios.post(url, payload );
        return getMemorialSlideResponse;
    } catch (error) {
        return error;
    }
}

export const userDataDetails = async (user_role) => {
    const url = `${serverUrl}/api/admin_panel/get_user_admin_details`
    const payload = { user_role };
    try {
        const getUserDataResponse = await axios.post(url, payload );
        return getUserDataResponse;
    } catch (error) {
        return error;
    }
} 

export const adminDataDetails = async (user_role) => {
    const url = `${serverUrl}/api/admin_panel/get_user_admin_details`
    const payload = { user_role };
    try {
        const getUserDataResponse = await axios.post(url, payload );
        return getUserDataResponse;
    } catch (error) {
        return error;
    }
} 

export const createSubscribersAPI =  async (subscriber_name, subscriber_mail) => {
    const url = `${serverUrl}/api/category/add_new_subscribe_details`
    const payload = { subscriber_name, subscriber_mail };
    try {
        const createSubscribersResponse = await axios.post(url, payload);
        return createSubscribersResponse;
    } catch (error) {
        return error;
    }
}

export const getSubscribersDetailsAPI = async () => {
    const url = `${serverUrl}/api/admin_panel/get_all_subscribe_details`
    try {
        const getSubscribersDetailsResponse = await axios.get(url);
        return getSubscribersDetailsResponse;
    } catch (error) {
        return error;
    }
}

export const getPaymentDetailsAPI = async (filter_order_date, filter_month_year) => {
    const url = `${serverUrl}/api/admin_panel/payment_details/`
    const payload = { filter_order_date, filter_month_year };
    try {
        const fetchPaymentDetailsResponse = await axios.post(url, payload);
        return fetchPaymentDetailsResponse;
    } catch (error) {
        return error;
    }
}

export const getCurrentOrderDetailsAPI = async (user_type, user_id, filter_order_date, filter_month_year) => {
    const url = `${serverUrl}/api/admin_panel/current_orders_details/`
    const payload = { user_type, user_id, filter_order_date, filter_month_year };
    try {
        const fetchCurrentOrderDetailsResponse = await axios.post(url, payload);
        return fetchCurrentOrderDetailsResponse;
    } catch (error) {
        return error;
    }
}

export const deleteOrderPaymentAPI = async (delete_id, delete_type) => {
    const url = `${serverUrl}/api/admin_panel/delete_orders_details`
    const payload = { delete_id, delete_type};
    try {
        const fetchCurrentOrderDetailsResponse = await axios.delete(url, { data: payload });
        return fetchCurrentOrderDetailsResponse;
    } catch (error) {
        return error;
    }
}

export const downloadUploadFormImagesAPI = async (order_id) => {
    const url = `${serverUrl}/api/admin_panel/download_upload_form_images`;
    const payload = { order_id };
  
    try {
      const response = await axios.post(url, payload, { responseType: 'blob' });
      return response;
    } catch (error) {
      console.error('Error in downloadUploadFormImagesAPI:', error);
      throw error;
    }
}

export const addCartDetails = async (category_menu_option_id, selected_options, permission_option_id, field_size, additional_name, price) => {
    const url = `${serverUrl}/api/category/add_to_cart`
    const payload = { category_menu_option_id, selected_options, permission_option_id, field_size, additional_name, price };
    console.log('payload -------------->:', payload)
    try {
        const addCartDetailsAPIResponse = await axios.post(url, payload);
        return addCartDetailsAPIResponse;
    } catch (error) {
        return error;
    }
}

export const getCartDetails = async (cart_id_list) => {
    const url = `${serverUrl}/api/category/cart_details`
    const payload = { cart_id_list };
    try {
        const getCartDetailsAPIResponse = await axios.post(url, payload);
        return getCartDetailsAPIResponse;
    } catch (error) {
        return error;
    }
}

export const deleteCartDetail = async (cart_id) => {
    const url = `${serverUrl}/api/category/delete_cart`
    const payload = { cart_id };
    try {
        const getdeleteCartDetailAPIResponse = await axios.delete(url, { data: payload });
        return getdeleteCartDetailAPIResponse;
    } catch (error) {
        return error;
    }
}

export const getInvoiceNumber =  async () => { 
    const url = `${serverUrl}/api/accounts/generate_invoice_number`
    const response = await axios.get(url);
    return response
}

export const getConvergeSessionToken = async (amount) => {
    const url = `${serverUrl}/api/accounts/get_converge_session_token/`
    const decryptedAccessToken = decryptToken(getCookie('_patsap'));
    const payload = { amount };
    try {
        const getConvergeSessionTokenAPIResponse = await axios.post(url, payload);
        return getConvergeSessionTokenAPIResponse;
    } catch (error) {
        return error;
    }
}

export const transactionResponse = async (payment_response, user_data, coupon_id, shipping_address, turn_around) => {
    const url = `${serverUrl}/api/accounts/transaction_response`
    const payload = { payment_response, user_data, coupon_id, shipping_address, turn_around};
    try {
        const transactionAPIResponse = await axios.post(url, payload);
        return transactionAPIResponse;
    } catch (error) {
        return error;
    }
}

export const uploadFormImagesDetails = async (formData) => {
    const url = `${serverUrl}/api/accounts/upload_images_details/`
    try {
        const uploadFormImagesDetailsAPIResponse = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }, });
        return uploadFormImagesDetailsAPIResponse;
    } catch (error) {
        return error;
    }
}

export const updateDescription = async (description_type, category_id, description) => {
    const url = `${serverUrl}/api/admin_panel/create_update_category_description`
    const payload = {description_type, category_id, description}    
    try {
        const uploadDescriptionAPIResponse = await axios.post(url, payload);
        return uploadDescriptionAPIResponse;
    } catch (error) {
        return error;
    }
}

export const paperTypeSelectedOption = async (all_price_field_options) => {
    const url = `${serverUrl}/api/admin_panel/paper_type_selected_option`
    const payload = { all_price_field_options }   
    try {
        const paperTypeSelectedOptionAPIResponse = await axios.post(url, payload);
        return paperTypeSelectedOptionAPIResponse;
    } catch (error) {
        return error;
    }
}

export const uploadImageZipFile = async (formData) => {
    const url = `${serverUrl}/api/category/upload_category_images`
    try {
        const uploadImageZipFileAPIResponse = await axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return uploadImageZipFileAPIResponse;
    } catch (error) {
        return error;
    }
}

export const getCategoryImagesFolders = async () => {
    const url = `${serverUrl}/api/admin_panel/get_category_image_folders`
    try {
        const getCategoryImagesFoldersAPIResponse = await axios.get(url);
        return getCategoryImagesFoldersAPIResponse;
    } catch (error) {
        return error;
    }
}

export const deleteCategoryImageFolder = async (delete_category_id) => {
    const url = `${serverUrl}/api/admin_panel/delete_category_images`
    const payload = { delete_category_id };
    try {       
        const deleteCategoryImageFolderResponse = await axios.delete(url, { data: payload });
        return deleteCategoryImageFolderResponse
    } catch (error) {
        console.error('deleteCategory error:', error);
        throw error;
    }
}

export const editFieldName = async (edit_type, edit_id, edit_name) => {
    const url = `${serverUrl}/api/admin_panel/edit_field_option_name`
    const payload = { edit_type, edit_id, edit_name }
    try {
        const editFieldNameAPIResponse = await axios.put(url, payload);
        return editFieldNameAPIResponse;
    } catch (error) {
        return error;
    }
}

export const updateCategoryMenu = async (header_menu_id, category_id) => {
    const url = `${serverUrl}/api/admin_panel/update_category_menu`
    const payload = { header_menu_id, category_id }
    try {
        const editFieldNameAPIResponse = await axios.put(url, payload);
        return editFieldNameAPIResponse;
    } catch (error) {
        return error;
    }
}

export const deleteCategoryImages = async (category_image_id) => {
    const url = `${serverUrl}/api/admin_panel/update_delete_category_images`
    const payload = { category_image_id }
    try {
        const editFieldNameAPIResponse = await axios.delete(url, { data: payload });
        return editFieldNameAPIResponse;
    } catch (error) {
        return error;
    }
}

export const updateCategoryImages = async (category_image_id, category_id) => {
    const url = `${serverUrl}/api/admin_panel/update_delete_category_images`
    const payload = { category_image_id, category_id }
    try {
        const updateCategoryImageAPIResponse = await axios.put(url, payload);
        return updateCategoryImageAPIResponse;
    } catch (error) {
        return error;
    }
}

export const saveContactUsDetails = async (name, mail, phone_number, subject, message) => {
    const url = `${serverUrl}/api/category/save_contact_us_details`
    const payload = { name, mail, phone_number, subject, message }
    try {
        const saveContactUsDetailsAPIResponse = await axios.post(url, payload);
        return saveContactUsDetailsAPIResponse;
    } catch (error) {
        return error;
    }
}

export const getClientMailDetails = async () => {
    const url = `${serverUrl}/api/admin_panel/get_client_mail_details`
    try {
        const getClientMailDetailsAPIResponse = await axios.get(url);
        return getClientMailDetailsAPIResponse;
    } catch (error) {
        return error;
    }
}

export const addNewClientMailDetails = async (new_client_mail, new_client_firstname, new_client_lastname) => {
    const url = `${serverUrl}/api/admin_panel/get_client_mail_details`
    const payload = { new_client_mail, new_client_firstname, new_client_lastname }
    try {
        const addNewClientMailDetailsAPIResponse = await axios.put(url, payload);
        return addNewClientMailDetailsAPIResponse;
    } catch (error) {
        return error;
    }
}

export const sendClientMail = async (client_id, expiry_date) => {
    const url = `${serverUrl}/api/admin_panel/send_client_mail`
    const payload = { client_id, expiry_date }
    try {
        const sendClientMailAPIResponse = await axios.post(url, payload);
        return sendClientMailAPIResponse;
    } catch (error) {
        return error;
    }
}

export const unSubscribeClientMail = async (unsubscribe_client_id) => {
    const url = `${serverUrl}/api/admin_panel/unsubscribe_client_mail`
    const payload = { unsubscribe_client_id }
    try {
        const unSubscribeClientMailAPIResponse = await axios.post(url, payload);
        return unSubscribeClientMailAPIResponse;
    } catch (error) {
        return error;
    }
}

export const updateFieldOrder = async (updatedFields) => {
    const url = `${serverUrl}/api/category/update_field_order`;
    const payload = {
      updatedFields: updatedFields.map((field, index) => ({
        id: field.id,
        order: index,
        updated_time: new Date().toISOString(),
      })),
    };
    try {
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating field order:', error);
      throw error;
    }
};

export const updateOptionsOrder = async (fieldId, reorderedOptions) => {
    const url = `${serverUrl}/api/category/update_options_order`;
    const payload = {
      fieldId: fieldId,
      updatedOptions: reorderedOptions.map((option, index) => ({
        id: option.id,
        order: index,
        updated_time: new Date().toISOString(),
      })),
    };
  
    try {
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating options order:', error);
      throw error;
    }
};

export const checkCouponValidation = async (coupon_code) => {
    const url = `${serverUrl}/api/category/check_coupon_validation`
    const payload = { coupon_code }
    try {
        const checkCouponValidationAPIResponse = await axios.post(url, payload);
        return checkCouponValidationAPIResponse;
    } catch (error) {
        return error;
    }
}

export const updateClientDetails = async (edit_client_id, edit_client_firstname, edit_client_lastname, edit_client_mail) => {
    const url = `${serverUrl}/api/admin_panel/update_delete_client_details`
    const payload = { edit_client_id, edit_client_firstname, edit_client_lastname, edit_client_mail }
    try {
        const updateClientDetailsAPIResponse = await axios.put(url, payload);
        return updateClientDetailsAPIResponse;
    } catch (error) {
        return error;
    }
}

export const deleteClientDetails = async (edit_client_id) => {
    const url = `${serverUrl}/api/admin_panel/update_delete_client_details`
    const payload = { edit_client_id };
    try {       
        const deleteClientDetailsAPIResponse = await axios.delete(url, { data: payload });
        return deleteClientDetailsAPIResponse
    } catch (error) {
        throw error;
    }
}

export const fetchCategoriesAPI = async () => {
    const url = `${serverUrl}/api/category/get_header_category_details`;
    try {
        const response = await axios.get(url);
        return response.data.all_header_menus_details;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};

export const uploadKeywordsAPI = async (payload) => {
    const url = `${serverUrl}/api/category/upload_keywords`;
    try {
        const response = await axios.post(url, payload);
        return response.data.message;
    } catch (error) {
        console.error('Error uploading keywords:', error);
        throw error;
    }
};

export const deleteCategoryAPI = async (categoryId) => {
    const url = `${serverUrl}/api/category/delete_category/${categoryId}/`;
    try {
        const response = await axios.delete(url);

        if (response.data.client_status_code === 200) {
            return 'Category deleted successfully!';
        } else {
            throw new Error('Failed to delete category');
        }
    } catch (error) {
        console.error("Error deleting category:", error);
        throw error;
    }
};

export const customerFirstOrderDiscountDetails = async (user_mail, full_name) => {
    const url = `${serverUrl}/api/category/customer_first_order_discount_details`
    const payload = { user_mail, full_name }
    try {
        const customerFirstOrderDiscountDetailsAPIResponse = await axios.post(url, payload);
        return customerFirstOrderDiscountDetailsAPIResponse;
    } catch (error) {
        return error;
    }
}

export const exportServerDatabase = async () => {
    const url = `${serverUrl}/api/admin_panel/export_database_in_sql_format`
    try {
        const exportServerDatabaseAPIResponse = await axios.get(url, {
            responseType: 'blob', 
        });

        return exportServerDatabaseAPIResponse;
    } catch (error) {
        return error;
    }
}

export const exportOrderDetails = async (filter_order_date, filter_month_year) => {
    const url = `${serverUrl}/api/admin_panel/export_currect_order_details/`
    try {
        const exportServerDatabaseAPIResponse = await axios.post(url, {
            filter_order_date,
            filter_month_year
        }, { responseType: 'blob' });

        return exportServerDatabaseAPIResponse;
    } catch (error) {
        return error;
    }
}

export const validateAccessToken = async (user_id, access_token, refresh_token) => {
    const url = `${serverUrl}/api/login_panel/validate_access_token`
    const payload = { user_id, access_token, refresh_token }
    console.log('\n payload-------------->:', payload)
    try {
        const validateAccessTokenAPIResponse = await axios.post(url, payload);

        return validateAccessTokenAPIResponse;
    } catch (error) {
        return error;
    }
}

export const checkEmailExists = async (email) => {
    const url = `${serverUrl}/api/login_panel/check_email`;
    const payload = { email };
  
    try {
      const response = await axios.post(url, payload, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error checking email:', error);
      throw error;
    }
};

export const resetPassword = async (email, newPassword) => {
    const url = `${serverUrl}/api/login_panel/forgot_password`;
    const payload = { email, newPassword };
  
    try {
      const response = await axios.post(url, payload, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
};

export const getfieldName = async (category_id) => {
    const url = `${serverUrl}/api/admin_panel/get_category_based_fields`;
    try {
        const getFieldsResponse = await axios.get(url, {
            params: { category_id },
        });
        return getFieldsResponse;
    } catch (error) {
        console.error('getFieldsResponse error:', error);
        throw error;
    }
};

export const updatefieldDescription = async (id, description) => {
    const url = `${serverUrl}/api/admin_panel/${id}/update_description/`;
    const payload = { description };

    try {
        const response = await axios.put(url, payload, {
            headers: { 'Content-Type': 'application/json' },
        });

        return response.data;
    } catch (error) {
        console.error('Error updating description:', error);
        throw error;
    }
};

export const deleteDescription = async (id) => {
    const url = `${serverUrl}/api/admin_panel/${id}/delete_description/`;

    try {
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error('Error deleting description:', error);
        throw error;
    }
};

export const orderStatusList = async () => {
    const url = `${serverUrl}/api/admin_panel/order_status_list`
    try {
        const orderStatusListResponse = await axios.get(url);
        return orderStatusListResponse;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
}

export const updateCurrentOrderStatus = async (order_id, order_status) => {
    const url = `${serverUrl}/api/admin_panel/update_current_order_status`
    const payload = { order_id, order_status }
    try {
        const updateCurrentOrderStatusAPIResponse = await axios.post(url, payload);
        return updateCurrentOrderStatusAPIResponse;
    } catch (error) {
        return error;
    }
}



export const updateDiscountPriceAPI = async (option_id, discount_percentage) => {
    const url = `${serverUrl}/api/admin_panel/update_discount_price`
    const payload = { option_id, discount_percentage };
    try {
        const updateDiscountPriceAPIResponse = await axios.post(url, payload);
        return updateDiscountPriceAPIResponse;
    } catch (error) {
        return error;
    }
}


// Customize Design one 

export const getSvgPng = async (asset_type) => {
    const url = `${serverUrl}/api/category/get_images`
    const payload = {asset_type}
    try {
        const getSvgPngAPIResponse = await axios.post(url, payload);
        console.log('getSvgPngAPIResponse - server ->', getSvgPngAPIResponse)
        return getSvgPngAPIResponse;
    } catch (error) {
        return error;
    }
}

export const verifyCaptcha = async (token) => {
    const url = `${serverUrl}/api/login_panel/verify_recaptcha`;
    const payload = { token };

    try {
        const response = await axios.post(url, payload);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getStateList = async () => {
    const url = `${serverUrl}/api/category/get_state_list/`
    try {
        const getStateListResponse = await axios.get(url);
        return getStateListResponse;
    } catch (error) {
        console.error('Error fetching state-list:', error);
        throw error;
    }
}








// Customize Design two

export const GetImages = async (asset_type, user_id) => {
    let url = `https://yearbook.3pstudio.us/api/user_panel/get_images`;
    const payload = { asset_type, user_id };
    try {
        const uploadImageApiResponse = await axios.post(url, payload);
        return uploadImageApiResponse;
    } catch (error) {
        return error;
    }
};

export const saveEditedHTML = async (payload) => {
    let url = `https://yearbook.3pstudio.us/api/accounts/save_edited_html/`;
    try {
        const storeEditedHTML = await axios.post(url, payload);
        return storeEditedHTML;
    } catch (error) {
        return error;
    }
};

export const UploadImageAPI = async (formDataFiles) => {
    let url = `https://yearbook.3pstudio.us/api/user_panel/upload_image`;
    const header = { header: { 'Content-Type': 'multipart/form-data' } };

    try {
        const uploadImageApiResponse = await axios.post(url, formDataFiles, header);
        return uploadImageApiResponse;
    } catch (error) {
        return error;
    }
};

export const getLatestEditedPdf = async () => {
    try {
        const response = await axios.get(`https://yearbook.3pstudio.us/api/accounts/latest-edited-pdf/`, {
            params: { user_id: localStorage.getItem('userId'), email: localStorage.getItem('email') },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching latest edited PDFs:', error);
        return { error: error.response.data.error || 'An error occurred' };
    }
};

export const uploadSvgPng = async (upload_files, user_id, asset_type, filename) => {
    const url = `${serverUrl}/api/category/upload_image`
    const payload = {upload_files, user_id, asset_type, filename}
    try {
        const uploadSvgPngAPIResponse = await axios.post(url, payload);
        return uploadSvgPngAPIResponse;
    } catch (error) {
        return error;
    }
}

export const fetchUsers = async () => {
    try {
        const response = await axios.get(`https://yearbook.3pstudio.us/api/accounts/list_users/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const createTemporaryYearbookAPI = async (userId, thumbnailBlob, pdfBlob) => {
    const url = `https://yearbook.3pstudio.us/api/accounts/temporaryyearbooks/`;
    const formData = new FormData();
    formData.append('user', userId);
    formData.append('yearbook_front_page', thumbnailBlob, 'front_page.png');
    formData.append('yearbook', pdfBlob, 'yearbook.pdf');

    try {
        const response = await axios.post(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to create yearbook entry:', error.response?.data || error.message);
        throw error;
    }
};

export const generateSessionLink = async (userId, email, pdfId) => {
    const url = `https://yearbook.3pstudio.us/api/accounts/generate_session_link/`;
    const payload = {
        user_id: userId,
        email: email,
        session_data: { pdfId },
    };

    try {
        const response = await axios.post(url, payload, {
            headers: { 'Content-Type': 'application/json' },
        });

        if (response.status !== 200) {
            throw new Error('Failed to generate session link');
        }

        return response.data.link;
    } catch (error) {
        console.error('Error generating session link:', error);
        throw error;
    }
};

export const updatePermissionsAPI = async (sessionLink, permissions) => {
    try {
        const response = await axios.post(`https://yearbook.3pstudio.us/api/accounts/update_permissions/`, {
            session_link: sessionLink,
            permissions,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating permissions:', error);
        throw error;
    }
};

export const sendEmailAPI = async (email, sessionLink, userName) => {
    try {
        const response = await axios.post(`https://yearbook.3pstudio.us/api/accounts/send-email/`, {
            email,
            session_link: sessionLink,
            user_name: userName,
        });
        return response.data;
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
    }
};

export const getSessionData = async (sessionLink, userId) => {
    const url = `https://yearbook.3pstudio.us/api/accounts/get_session_data/`;

    try {
        // Include user_id in the params
        const response = await axios.get(url, {
            params: {
                session_link: sessionLink,
                user_id: userId,
            },
        });

        return response.data; // Return session data
    } catch (error) {
        if (error.response) {
            console.error('Server error:', error.response.data);
            throw new Error(error.response.data.error || 'Failed to fetch session data');
        } else if (error.request) {
            console.error('No response from server:', error.request);
            throw new Error('No response received from server');
        } else {
            console.error('Error setting up request:', error.message);
            throw new Error('Error setting up request');
        }
    }
};