import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from 'react-router-dom';
import { getCategorySubMenusAPI, GetCategoryImagesAPI, deleteCategoryImages, updateCategoryImages } from '../../serverApi/server'
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";


const FieldPricing = () => {
    const location = useLocation();
    const headerMenus = location.state || [];
    const [categoryList, setCategoryList] = useState([])

    const [editCategoryId, setEditCategoryId] = useState('')
    const [editImageId, setEditImageId] = useState('')

    const [selectedCategory, setSelectedCategory] = useState(
        headerMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
    );

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const [subMenuList, setSubMenuList] = useState('')

    const handleSubMenuListChange = (e) => {
        setSubMenuList(e.target.value);
    }

    const fetchCategorySubMenus = async () => {
        try {
            const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedCategory);
            setCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer)
            const categories = getCategorySubMenusAPIResponse.data.categories_serializer;
            const defaultMenu = categories.length > 0 ? categories[0].additional_name : '';

            setSubMenuList(
                categories.find((menu) => menu.additional_name === 'bookmarks')?.additional_name || defaultMenu
            );
        } catch (error) {
            console.error('Error fetching sub-menus:', error);
        }
    };

    useEffect(() => {
        fetchCategorySubMenus()
    }, [selectedCategory])


    const pageSize = 1000;
    const [totalPageAPI, setTotalPageAPI] = useState(null);
    const [currentPageAPI, setCurrentPageAPI] = useState(null);
    const [previousPageAPI, setPreviousPageAPI] = useState(null);
    const [nextPageAPI, setNextPageAPI] = useState(null);
    const [products, setProducts] = useState('');

    const GetOurProductsCategoryImages = async (additionalName, page, pageSize) => {
        const GetCategoryImagesApiResponse = await GetCategoryImagesAPI(additionalName, page, pageSize);
        if (GetCategoryImagesApiResponse.status === 200 && GetCategoryImagesApiResponse.data && GetCategoryImagesApiResponse.data.category_serializer.length !== 0) {
            console.log('GetCategoryImagesApiResponse', GetCategoryImagesApiResponse)
            setProducts(GetCategoryImagesApiResponse.data.category_serializer);
            setCurrentPageAPI(GetCategoryImagesApiResponse.data.current_page);
            setTotalPageAPI(GetCategoryImagesApiResponse.data.total_pages);
            setPreviousPageAPI(GetCategoryImagesApiResponse.data.previous_page)
            setNextPageAPI(GetCategoryImagesApiResponse.data.next_page)
        } else {
            setProducts('')
        }
    };

    const firstPage = 1;
    const [currentPage, setCurrentPage] = useState(currentPageAPI);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        GetOurProductsCategoryImages(subMenuList, page, pageSize);
    };


    const fetchCategoryImagesList = async (subMenuList) => {
        await GetOurProductsCategoryImages(subMenuList, firstPage, pageSize)
    };

    useEffect(() => {
        console.log('subMenuListsubMenuListsubMenuList', subMenuList)
        fetchCategoryImagesList(subMenuList)
    }, [subMenuList])

    const [isZoomed, setIsZoomed] = useState(false);

    const handleImageClick = () => {
        setIsZoomed(!isZoomed);
    };


    const formheaderMenus = location.state || [];
    const [formCategoryList, setFormCategoryList] = useState([]);
    const [formSubMenuList, setFormSubMenuList] = useState('')
    const [selectedFormHeader, setSelectedFormHeader] = useState(
        formheaderMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
    );
    const handleFormHeaderChange = (e) => {
        setSelectedFormHeader(e.target.value);
    };
    const handleFormSubMenuListChange = (e) => {
        setFormSubMenuList(e.target.value);
    };
    useEffect(() => {
        const fetchCategorySubMenus = async () => {
            try {
                const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedFormHeader);
                setFormCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer)
                setFormSubMenuList(getCategorySubMenusAPIResponse.data.categories_serializer.find((menu) => menu.additional_name === 'bookmarks')?.id || '')
            } catch (error) {
                console.error('Error fetching FORM-sub-menus:', error);
            }
        };
        if (selectedFormHeader) {
            fetchCategorySubMenus();
        }
    }, [selectedFormHeader]);



    const toggleForm = () => setFormOpen(!formOpen);
    const [formOpen, setFormOpen] = useState(false);
    const handleEdit = (categoryId, imageId) => {
        console.log('\n categoryId', categoryId)
        console.log('\n imageId', imageId)
        setEditCategoryId(categoryId)
        setEditImageId(imageId)
        toggleForm()
    }

    const handleDelete = async (categoryId) => {
        console.log('\n categoryId', categoryId)
        await deleteCategoryImages(categoryId)
        fetchCategoryImagesList(subMenuList)
    }

    const editCategoryImage = async () => {
        console.log('1111111111111222222222222333333333333333444444444444455555555555555666666666666677777777777778888888888')
        console.log('formSubMenuList', formSubMenuList)
        console.log('editImageId', editImageId)
        await updateCategoryImages(editImageId, formSubMenuList)
        fetchCategoryImagesList(subMenuList)
        toggleForm();
    }


    return (
        <div className="flex w-full h-screen">
            <div className="fixed h-full custom-scroll">
                <SideBar />
            </div>
            <div className="ml-64 flex-1 overflow-y-auto">
                <Header />
                <div className="bg-[#F5F7FD] px-7 py-6">
                    <div className='flex items-center justify-between'>
                        <div className="text-blue-950 flex items-center gap-6">
                            <h1 className="pr-6 text-2xl font-medium border-r">
                                Admin Users
                            </h1>
                            <div className="flex text-sm items-center gap-3">
                                <button>
                                    <SlHome />
                                </button>
                                <SlArrowRight />
                                <button>
                                    Category Images Delete Update
                                </button>
                            </div>
                        </div>
                    </div>

                    {formOpen && (
                        <div
                            className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
                            aria-hidden="true"
                        >
                            <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto">
                                <div className="flex justify-between items-center p-4 border-b">
                                    <h5 className="text-lg font-medium">Edit Category Image</h5>
                                    <button
                                        type="button"
                                        className="text-gray-600 text-3xl hover:text-gray-900"
                                        onClick={toggleForm}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="p-4">

                                    <div className="mb-4">
                                        <label
                                            htmlFor="categoryType"
                                            className="block text-sm text-gray-700"
                                        >
                                            Header Name
                                        </label>
                                        <select
                                            className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            onChange={handleFormHeaderChange}
                                            value={selectedFormHeader}
                                        >
                                            {formheaderMenus.map((menu) => (
                                                <option key={menu.id} value={menu.id}>
                                                    {menu.menu}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="subCategory"
                                            className="block text-sm text-gray-700"
                                        >
                                            Category
                                        </label>
                                        <select
                                            id="subCategory"
                                            name="subCategory"
                                            required
                                            className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            onChange={handleFormSubMenuListChange}
                                            value={formSubMenuList}
                                        >
                                            <option value="" disabled>
                                                Choose an option
                                            </option>
                                            {formCategoryList && formCategoryList.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.category}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex justify-end items-center pt-4 border-t">
                                        <button
                                            type="button"
                                            className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                                            onClick={toggleForm}
                                        >
                                            Close
                                        </button>
                                        <button
                                            onClick={editCategoryImage}
                                            className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    )}

                    <div className='bg-white mt-10 px-3 py-4 rounded-lg'>
                        <div className='flex items-center gap-3 text-sm'>
                            <p className='text-neutral-500'>Filter by Role:</p>
                            <div className='flex items-center gap-2'>
                                <select className='focus:outline-none focus:border-blue-300 border rounded px-1 py-1'
                                    onChange={handleCategoryChange}
                                    value={selectedCategory}
                                >
                                    {headerMenus.map((menu) => (
                                        <option key={menu.id} value={menu.id}>
                                            {menu.menu}
                                        </option>
                                    ))}
                                </select>
                                <select className='focus:outline-none focus:border-blue-300 border rounded px-1 py-1'
                                    onChange={handleSubMenuListChange}
                                    value={subMenuList}
                                >
                                    {categoryList && categoryList.map((menu) => (
                                        <option key={menu.id} value={menu.additional_name}>
                                            {menu.category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white mt-8" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                                <thead>
                                    <tr>
                                        <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">IMAGE</th>
                                        <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">EDIT</th>
                                        <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">DELETE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products && products.map((category) => (
                                        <tr key={category.id}>
                                            <td className="px-4 py-2 font-normal border border-[#48ABF7]">
                                                <div
                                                    className='relative md:h-auto overflow-hidden flex justify-center bg-transparent border cursor-pointer'
                                                >
                                                    <img className={`w-24 sm:w-32 my-4 sm:mx-3 md:w-full transition-transform duration-500 ease-in-out hover:scale-125 ${isZoomed ? 'scale-125' : ''}`}
                                                        src={category.image_file} alt={category.image_name} style={{ width: '12%' }} />
                                                </div>
                                            </td>
                                            <td className="px-4 py-2 border border-[#48ABF7]">
                                                <button
                                                    className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center"
                                                    onClick={() => handleEdit(category.category, category.id)}
                                                >
                                                    <FaEdit className="mr-[1px]" />
                                                    Edit
                                                </button>
                                            </td>

                                            <td className="px-4 py-2 border border-[#48ABF7]">
                                                <button
                                                    className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center"
                                                    onClick={() => handleDelete(category.id)}
                                                >
                                                    <MdDeleteOutline className="mr-[1px]" />
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FieldPricing
