import React, { useState, useEffect, useRef } from "react";
import { FaShoppingCart, FaStar } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import {
  GetCategoryImagesAPI,
  getCategoryMenuData,
  addCartDetails,
  paperTypeSelectedOption,
} from "../serverApi/server";
import { memorialFuneralMenus } from "../components/Helper/Helpers";
import { FaEye } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import Skeleton from "./skeleton/Skeleton";
import { HiMiniPencilSquare } from "react-icons/hi2";
import perfect from "../assets/NewFormAssetOne/Perfect-(PUR).jpg";
import wiro from "../assets/NewFormAssetOne/Wire-O-Binding.jpg";
import staple from "../assets/NewFormAssetOne/Staple.jpg";
import Square from "../assets/NewFormAssetOne/Square.jpg";
import Satin from "../assets/NewFormAsset/paperType/Color - Paper Type/Satin.jpg";
import Gloss from "../assets/NewFormAsset/paperType/Color - Paper Type/Gloss.jpg";
import Uncoated from "../assets/NewFormAsset/paperType/Color - Paper Type/Uncoated.jpg";
import Recycled from "../assets/NewFormAsset/paperType/Color - Paper Type/Recycled-Uncoated.jpg";
import SatinGray from "../assets/NewFormAsset/paperType/Grayscale - Paper Type/Grayscale-Satin.jpg";
import GlossGray from "../assets/NewFormAsset/paperType/Grayscale - Paper Type/Grayscale-Gloss.jpg";
import UncoatedGray from "../assets/NewFormAsset/paperType/Grayscale - Paper Type/Grayscale-Uncoated.jpg";
import RecycledGray from "../assets/NewFormAsset/paperType/Grayscale - Paper Type/Grayscale-Recycled-Uncoated.jpg";
import Black from "../assets/NewFormAsset/bindingColor/Wire/Wire-Black.jpg";
import White from "../assets/NewFormAsset/bindingColor/Wire/Wire-White.jpg";
import Silver from "../assets/NewFormAsset/bindingColor/Wire/Wire-Silver.jpg";
import Red from "../assets/NewFormAsset/bindingColor/Wire/Wire-Red.jpg";
import Blue from "../assets/NewFormAsset/bindingColor/Wire/Wire-Blue.jpg";
import Gray from "../assets/NewFormAsset/bindingColor/Wire/Wire-Binding.jpg";
import BlackSpiral from "../assets/NewFormAsset/bindingColor/Spiral/Spiral-Black.jpg";
import WhiteSpiral from "../assets/NewFormAsset/bindingColor/Spiral/Spiral-White.jpg";
import NavySpiral from "../assets/NewFormAsset/bindingColor/Spiral/Spiral-Navy-Blue.jpg";
import ClearSpiral from "../assets/NewFormAsset/bindingColor/Spiral/Spiral-Clear.jpg";
import CoverGloss from "../assets/NewFormAsset/coverPaperType/Color/Cover-Gloss.jpg";
import CoverSatin from "../assets/NewFormAsset/coverPaperType/Color/Cover-Satin.jpg";
import CoverUncoated from "../assets/NewFormAsset/coverPaperType/Color/Cover-Uncoated.jpg";
import CoverGlossGray from "../assets/NewFormAsset/coverPaperType/Grayscale/Cover-GlossGray.jpg";
import CoverSatinGray from "../assets/NewFormAsset/coverPaperType/Grayscale/Cover-SatinGray.jpg";
import CoverUncoatedGray from "../assets/NewFormAsset/coverPaperType/Grayscale/Cover-UncoatedGray.jpg";
import None from "../assets/NewFormAsset/Choose Cover Finish/None.jpg";
import GlossLamination from "../assets/NewFormAsset/Choose Cover Finish/Gloss-Lamination.jpg";
import MatteLamination from "../assets/NewFormAsset/Choose Cover Finish/Matte-Lamination.jpg";
import MatteAntiScuf from "../assets/NewFormAsset/Choose Cover Finish/Matte-Anti-Scuff.jpg";
import SoftTouchLamination from "../assets/NewFormAsset/Choose Cover Finish/Soft-Touch-Lamination.jpg";
import UVMatte from "../assets/NewFormAsset/Choose Cover Finish/UV-Matte.jpg";
import UVGloss from "../assets/NewFormAsset/Choose Cover Finish/UV-Gloss.jpg";
import MatteLamPlusSpotUV from "../assets/NewFormAsset/Choose Cover Finish/Matte-Lam-plus-Spot-UV.jpg";
import GoldFoiling from "../assets/NewFormAsset/foiling/Gold.webp";
import SilverFoiling from "../assets/NewFormAsset/foiling/Silver.webp";
import Adhesive from "../assets/NewFormAsset/handcoverBooks/binding/Adhesive Casebound.webp";
import Smyth from "../assets/NewFormAsset/handcoverBooks/binding/Smyth Sewn.webp";
import GlossJacket from "../assets/NewFormAsset/handcoverBooks/duskJacket/gloss_lamination.webp";
import MatteJacket from "../assets/NewFormAsset/handcoverBooks/duskJacket/matte_lamination.webp";
import shrinkWrap from "../assets/NewFormAsset/shrink_wrap.webp";

const BookletsDesign = () => {
  const { id } = useParams();
  const [slides, setSlides] = useState([]);
  const [subTitle, setSubTitle] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [isFieldValidation, setisFieldValidation] = useState(false);
  const [errorResponse, setErrorResponse] = useState({});

  const REMOVEerror = (key) => {
    setErrorResponse(prev => {
      const newObj = { ...prev }
      delete newObj[key]
      return newObj
    })
  }
  const SETerror = (key, value) => {
    setErrorResponse(prev => ({
      ...prev,
      [key]: value
    }))
  }
  const navigate = useNavigate();
  const [additionalName, setAdditionalName] = useState();
  const [fieldValidation, setFieldValidation] = useState(false);
  const [fieldSize, setFieldSize] = useState("");
  const [quantitySizeOption, setQuantitySizeOption] = useState("");
  const [quantityId, setQuantityId] = useState("");
  const [quantityPriceAmount, setQuantityPriceAmount] = useState(false);
  const [photosDetails, setPhotosDetails] = useState("");
  const [sizePaperType, setSizePaperType] = useState({});
  const [categoryPermissionFields, setCategoryPermissionFields] = useState();
  const [selectedFormFieldsOptions, setSelectedFormFieldsOptions] = useState(
    {}
  );
  const [products, setProducts] = useState("");
  const [priceValidation, setPriceValidation] = useState(false);
  const [pagesRange, setPagesRange] = useState("");
  const [eachPrice, setEachPrice] = useState();

  const [currentEasternTime, setCurrentEasternTime] = useState(null);

  const GetOurProductsCategoryImages = async (additionalName) => {
    var category_menu = memorialFuneralMenus[additionalName];
    try {
      const GetCategoryImagesApiResponse = await GetCategoryImagesAPI(
        additionalName
      );
      if (
        GetCategoryImagesApiResponse.status === 200 &&
        GetCategoryImagesApiResponse.data &&
        GetCategoryImagesApiResponse.data.category_serializer.length !== 0
      ) {
        setProducts(
          GetCategoryImagesApiResponse.data.category_serializer.slice(0, 8)
        );
      }
    } catch (error) {
      console.error("Error fetching Category Slides:", error);
    }
  };

  const [isChecked, setIsChecked] = useState({
    addCover: false,
    bookmarkRibbon: false,
    HeadTailBand: false,
    duskJacket: false,
    bundling: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCategoryMenuData(id);
        if (data?.category_additional_name) {
          setAdditionalName(data?.category_additional_name);
          if (
            data?.category_additional_name === "paperback_books" ||
            data?.category_additional_name === "hardcover_books" ||
            data?.category_additional_name === "share_&_sell"
          ) {
            setIsChecked({
              ...isChecked,
              addCover: true,
            });
          }
          await GetOurProductsCategoryImages(data?.category_additional_name);
        }
        if (data?.category_menu_serilizer?.image_file) {
          setSlides([data.category_menu_serilizer.image_file]);
        }
        if (data?.category_name) {
          setSubTitle(data.category_name);
        }
        if (data?.form_fields_serializer) {
          var pagesList = data.form_fields_serializer.find(
            (field) => field.additional_name === "pages"
          );
          setPagesRange(pagesList);
          setFormFields(data.form_fields_serializer);
          if (data.form_fields_serializer.length === 0) {
            setisFieldValidation(true);
          }
          const photosDataDetails = Object.values(
            data.form_fields_serializer
          ).find((option) => option.additional_name === "photos");
          if (photosDataDetails) {
            setPhotosDetails(photosDataDetails);
          }
        }
        if (data?.category_permission_fields) {
          setCategoryPermissionFields(data.category_permission_fields);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    }, [id]);

    const parentRef = useRef(null);
    const [showSticky, setShowSticky] = useState(true);
    const handleScroll = () => {
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect();
        if (rect.bottom <= window.innerHeight) {
          setShowSticky(false);
        } else {
          setShowSticky(true);
        }
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
  
      handleScroll();
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [permissionFieldId, setPermissionFieldId] = useState("");

  const paperTypeSelectedOptionAPI = async (selectedOption) => {
    try {
      const paperTypeSelectedOptionAPIResponse = await paperTypeSelectedOption(
        selectedOption
      );
      const serializer =
        paperTypeSelectedOptionAPIResponse.data
          .paper_type_field_amount_serializer;
      const updatedFormFields = formFields.map((field) => {
        if (
          field.additional_name === "quantity" ||
          field.additional_name === "Quantity"
        ) {
          const updatedOptions = field.options.map((option) => {
            const match = serializer.find((item) => item.option === option.id);
            if (match) {
              if (match.option == quantityId) {
                const quantityOption = Object.values(selectedOptions).find(
                  (option) => option.id === match.option
                );
                setSelectedOptions((prevOptions) => {
                  const updatedOptions = { ...prevOptions };
                  const matchKey = Object.keys(updatedOptions).find(
                    (key) => updatedOptions[key].id === match.option
                  );
                  if (matchKey) {
                    setPermissionFieldId(match.id);
                    updatedOptions[matchKey] = {
                      ...updatedOptions[matchKey],
                      amount: match.amount,
                    };
                  }
                  return updatedOptions;
                });
              }
              return {
                ...option,
                amount: match.amount,
                permissionFieldId: match.id,
              };
            }
            setSelectedOptions((prevOptions) => {
              const updatedOptions = { ...prevOptions };
              Object.keys(updatedOptions).forEach((key) => {
                const currentOption = updatedOptions[key];
                if (
                  currentOption.id === option.id &&
                  currentOption.category_additional_name === additionalName
                ) {
                  currentOption.amount = "";
                }
              });
              return updatedOptions;
            });
            return {
              ...option,
              amount: "",
            };
          });
          return {
            ...field,
            options: updatedOptions,
          };
        }
        return field;
      });
      setFormFields(updatedFormFields);
    } catch (error) {
      console.error("Error fetching sub-menus:", error);
    }
  };

  const findMatchingOption = (value, options) => {
    const matchedOption = options.find((option) => {
      var optionName = option.name.replace(/\s/g, "");
      const parts = option.name.split("to");
      if (parts.length !== 2) {
        return false;
      }
      const [start, end] = parts.map((n) => {
        const trimmed = n.trim();
        const num = Number(trimmed);
        return isNaN(num) ? null : num;
      });

      return start !== null && end !== null && value >= start && value <= end;
    });

    return matchedOption || null;
  };

  const [colorPrinting, setColorPrinting] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [chooseBinding, setChooseBinding] = useState(null);
  const [chooseBindingColor, setChooseBindingColor] = useState(null);
  const [coverPaperType, setCoverPaperType] = useState(null);
  const [coverPaperWeight, setCoverPaperWeight] = useState(null);
  const [coverPrinting, setcoverPrinting] = useState(null);
  const [innerCoverPrinting, setInnerCoverPrinting] = useState(null);
  const [chooseFinishingID, setChooseFinishingID] = useState(null);
  const [foilingID, setFoilingID] = useState(null);
  const [jacketFinishID, setjacketFinishID] = useState(null);
  const [jacketColorID, setJacketColorID] = useState(null);

  const consolEE = () => {
    console.log('selectedOptions:',selectedOptions)
  }

  const [aloneId, setAloneId] = useState({})
  const SETAloneID = (key, value) => {
    setAloneId(prev => ({
      ...prev,
      [key]: value
    }))
  }
  const aloneCheckDisable = (Id) => {
    setSelectedOptions(prev => {
      const newOpt = { ...prev }
      delete newOpt[Id]

      return newOpt
    })
  }

  const [iDArray, setIDArray] = useState([])
  const SetIDFunc = (id) => {
    setIDArray(prev => [...prev, id])
  } 
  const addCoverDisable = () => {
    console.log('function called');

    setcoverPrinting(null)
    setInnerCoverPrinting(null)
    setCoverTypeHighlighter("")
    setCoverPaperWeight(null)
    setChooseFinishingID(null)
    setEndpaperHighL("")
    setfoilingHighL("")
    setJacketColorID(null)
    setjacketFinishID(null)
    setBundlingHighL(null)
    
    setSelectedOptions(prev => {
      const newOpt = { ...prev }

      iDArray.forEach(Id => {
        delete newOpt[Id]
      })

      return newOpt
    })
  }
  const [specialPTid, setSpecialPTid] = useState({})
  const reset = () => {
    setSelectedOptions({})
    setEachPrice()
    setTotalPrice(0.0)

    setcoverPrinting(null)
    setFormData(prev => 
      Object.keys(prev).reduce((acc, key) => {
        acc[key] = "";
        return acc;
      }, {})
    )
    setSpecialPageC({})
    setPageCount(2)
    setInnerCoverPrinting(null)
    setCoverTypeHighlighter(null)
    setCoverPaperWeight(null)
    setChooseFinishingID(null)
    setEndpaperHighL(null)
    setfoilingHighL(null)
    setJacketColorID(null)
    setjacketFinishID(null)
    setBundlingHighL(null)
    setColorPrinting(null)
    setOrientation(null)
    setChooseBinding(null)
    setPaperTypeHighL(null)
    setSpecialPTid({})
    setSpecialColorGrayImage(null)
    setBindingHighLighter(null)
    setRibbenHighL(null)
    setHeadTailHighL(null)
  }

  const handleChange = (e, field, option) => {
    if (!option) {
      return;
    }
    var fieldId = field.id;
    var { name, value } = e.target;

    var field = formFields.find((f) => f.id === fieldId);
    if (
      field.quantity_calculation === true &&
      field.additional_name === "quantity"
    ) {
      setPrevStateHolder(prev => ({
        ...prev,
        'quantity':  {
          name,
          value,
          field,
          fieldId
        },
      }))
      if (isPerfectBinding) {
        if (value < 25) {
          value = 25
          SETerror('perfect', 'Perfect binding requires a minimum quantity of 25')
        } else {
          REMOVEerror('perfect')
        }
      }
      if (value === "") {
        // setFormData((prev) => ({
        //   ...prev,
        //   [name]: "",
        // }));
        // setSelectedOptions((prevSelectedOptions) => ({
        //   ...prevSelectedOptions,
        //   [fieldId]: null,
        // }));
        // setSelectedIds((prevIds) => prevIds.filter((id) => id !== fieldId));
        // setTotalPrice(parseFloat(0.0).toFixed(2));
        // return;
        value = 1
      }

      value = Math.max(1, Math.min(10000, Number(value) || 0));
      var selectedOption = findMatchingOption(value, field.options);
    } else {
      var selectedOption = field?.options?.find(
        (oldOption) => oldOption.id === option.id
      );
    }

    if (field.additional_name === "print_turn_around") {
      const match = selectedOption.name.match(/^\d+/);
      var days = match ? parseInt(match[0], 10) : 0;

      const currentDateInEST = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });

      const dateObject = new Date(currentDateInEST);

      // dateObject.setDate(dateObject.getDate() + days);

      while (days > 0) {
        dateObject.setDate(dateObject.getDate() + 1);
        const dayOfWeek = dateObject.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          days--;
        }
      }

      const formattedDate = dateObject.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });

      setCurrentEasternTime(formattedDate);
    }

    if (field.additional_name === "color_printing") {
      setColorPrinting(selectedOption.id);
    } else if (field.additional_name === "orientation") {
      setOrientation(selectedOption.id);
    } else if (field.additional_name === "choose_binding") {
      setChooseBinding(selectedOption.id);
      setBindingForColor(selectedOption.name);
    } else if (field.additional_name === "binding_color") {
      setChooseBindingColor(selectedOption.id);
    } else if (field.additional_name === "cover_paper_type") {
      setCoverPaperType(selectedOption.id);
    } else if (field.additional_name === "cover_paper_weight") {
      setCoverPaperWeight(selectedOption.id);
    } else if (field.additional_name === "cover_printing") {
      setcoverPrinting(selectedOption.id);
    } else if (field.additional_name === "inner_cover_printing") {
      setInnerCoverPrinting(selectedOption.id);
    } else if (field.additional_name === "choose_cover_finish") {
      setChooseFinishingID(selectedOption.id);
      setEnableFoiling(selectedOption.name);
    } else if (field.additional_name === "add_cover_foiling") {
      setFoilingID(selectedOption.id);
    } else if (field.additional_name === "choose_dust_jacket_finish") {
      setjacketFinishID(selectedOption.id);
    } else if (field.additional_name === "dust_jacket_colors") {
      setJacketColorID(selectedOption.name);
    }

    if (selectedOption) {
      selectedOption.calculation_status = field.calculation_status || false;
      selectedOption.quantity_calculation = field.quantity_calculation || false;
      selectedOption.books_calculation = field.books_calculation || false;
      selectedOption.quantity_calculation_additional_name = field.additional_name || '';
    }
    if (field && field.field_name === "Pages") {
      setFieldSize(value);
    }
    if (field && field.field_name === "Quantity") {
      selectedOption.category_additional_name = additionalName;
      if (selectedOption && selectedOption.permissionFieldId) {
        setPermissionFieldId(selectedOption.permissionFieldId);
      }
      setQuantitySizeOption(value);
      setQuantityId(selectedOption.id);
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    if (categoryPermissionFields.includes(fieldId)) {
      setSelectedFormFieldsOptions((prev) => ({
        ...prev,
        [name]: selectedOption,
      }));
    }
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [fieldId]: selectedOption,
    }));
    setSelectedIds((prevIds) => {
      if (!prevIds.includes(fieldId)) {
        return [...prevIds, fieldId];
      }
      return prevIds;
    });
  };

  useEffect(() => {
    let categoryPermissionFieldsList = [];
    if (typeof categoryPermissionFields === "string") {
      try {
        categoryPermissionFieldsList = JSON.parse(categoryPermissionFields);
      } catch (e) {
        console.error("Error parsing categoryPermissionFields:", e);
      }
    } else if (Array.isArray(categoryPermissionFields)) {
      categoryPermissionFieldsList = categoryPermissionFields;
    }
    if (
      categoryPermissionFieldsList.length &&
      Object.keys(selectedFormFieldsOptions).length ===
        categoryPermissionFieldsList.length
    ) {
      paperTypeSelectedOptionAPI(selectedFormFieldsOptions);
    }
  }, [selectedFormFieldsOptions, categoryPermissionFields]);

  useEffect(() => {
    if (
      selectedIds.length === formFields.length ||
      ((additionalName === "booklets" ||
        additionalName === "paperback_books" ||
        additionalName === "hardcover_books" ||
        additionalName === "magazines" ||
        additionalName === "catalogs" ||
        additionalName === "share_&_sell") &&
        0 < Object.keys(selectedOptions).length)
    ) {
      const totalAmount = Object.values(selectedOptions).reduce(
        (total, option) => {
          var optionPrice = option.amount;
          if (option.calculation_status === true) {
            optionPrice = option.amount * fieldSize;
          }
          if (option.quantity_calculation === true) {
            optionPrice = option.amount * quantitySizeOption;
          }

          if (option.books_calculation === true) {
            optionPrice = option.amount * quantitySizeOption;
          }

          return total + (parseFloat(optionPrice) || 0);
        },
        0
      );
      const formattedTotal = parseFloat(totalAmount).toFixed(2);
      setTotalPrice(formattedTotal);
      if (parseInt(formattedTotal) !== 0) {
        var eachQuantityPrice = formattedTotal / quantitySizeOption;
        setEachPrice(parseFloat(eachQuantityPrice).toFixed(2));
      }
      setisFieldValidation(false);
      if (parseFloat(formattedTotal) === 0.0 && formFields.length != 0) {
        setisFieldValidation(true);
      }
    }
  }, [selectedIds, selectedOptions, fieldSize, quantitySizeOption]);

  const addTemporaryCartDetails = async (
    id,
    selectedOptions,
    permissionFieldId,
    quantitySizeOption,
    additionalName,
    totalPrice
  ) => {
    const addCartDetailsAPIResponse = await addCartDetails(
      id,
      selectedOptions,
      permissionFieldId,
      quantitySizeOption,
      additionalName,
      totalPrice
    );
    if (addCartDetailsAPIResponse.data.client_status_code === 200) {
      return addCartDetailsAPIResponse.data.new_cart_id;
    } else {
      SETerror('addCart', addCartDetailsAPIResponse.data.message || "Unable to add the cart.");
    }
  };

  const addTocart = async () => {
    if (5 > Object.keys(selectedOptions).length) {
      setFieldValidation(true);
      return;
    }
    if ( Object.keys(specialPTid).length > 0) {
      const unset = Object.entries(specialPTid)
      .filter(([key, value]) => value === 'unset')
      .map(([key]) => key);

      if(unset.length > 0) {
        SETerror('specialPT', `please choose pages for ${unset.join(', ')}`)
        specPaperTRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start"
        })
        return;
      } else {
        REMOVEerror('specialPT')
      }
    }

    setFieldValidation(false);
    if (parseFloat(totalPrice) < 24) {
      setPriceValidation(true);
      return;
    }
    setPriceValidation(false);
    const newCartId = await addTemporaryCartDetails(
      id,
      selectedOptions,
      permissionFieldId,
      quantitySizeOption,
      additionalName,
      totalPrice
    );
    navigate("/product-cart", { state: { newCartId: newCartId } });
  };

  const [showPriceTable, setShowPriceTable] = useState(false);
  const [showPhotosPriceTable, setshowPhotosPriceTable] = useState(false);

  const dropdownRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowPriceTable(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPriceTable]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setshowPhotosPriceTable(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPhotosPriceTable]);

  const productID = (productId) => {
    navigate(`/shopdetails/${productId}`);
  };

  const [selectedDesign, setSelectedDesign] = useState("");
  const handleCustomize = (selectedPrintType) => {
    setSelectedDesign(selectedPrintType);
  };

  const CustomizeDesign = () => {
    navigate("/template");
  };

  const [prevStateHolder, setPrevStateHolder] = useState({})
  
  const [isStableBinding, setIsStableBinding] = useState(false);
  const getNextMultipleOf4 = (num) => {
    return num % 4 === 0 ? num : num + (4 - (num % 4));
  };
  const [pageCount, setPageCount] = useState(2);
  const [specialPageC, setSpecialPageC] = useState({})
  const [isPerfectBinding, setIsPerfectBinding] = useState(false);
  const handlePagesStep = (name) => {
    if(name === 'Perfect') {
      setIsPerfectBinding(true)
      const quantityExist = prevStateHolder?.quantity
      if(quantityExist && quantityExist.value < 25) {
        handleChange(
          {
            target: {
              name: prevStateHolder.quantity.name,
              value: 25
            },
          },
          prevStateHolder.quantity.field,
          prevStateHolder.quantity.fieldId
        )
      }
    } else {
      setIsPerfectBinding(false)
      REMOVEerror('perfect')
    }
    if (name === 'Staple' || name === 'Square') {
      setIsStableBinding(true);
      const newPageCount = getNextMultipleOf4(pageCount)
      setPageCount(newPageCount);
      if (prevStateHolder.pages) {
        handleChange(
          {
            target: {
              name: prevStateHolder.pages.target.name,
              value: newPageCount
            },
          },
          prevStateHolder.pages.field,
          getRangeOption(newPageCount, prevStateHolder.pages.options)
        )
      }
      if (Object.keys(specialPageC).length !== 0) {
        Object.entries(specialPageC).map(([key, value]) => {
          const multipleOf4 = String(getNextMultipleOf4(Number(value)))
          const newOption = prevStateHolder[key].field.options.find(
            (option) => 
              option.name === multipleOf4
          )
          setSpecialPageC(prev => ({
            ...prev,
            [key]: multipleOf4
          }))
          handleChange(
            {
              target: {
                name: key,
                value: multipleOf4
              },
            },
            prevStateHolder[key].field, 
            newOption
          )
        })
      }
    } else {
      setIsStableBinding(false);
    }
  };
  
  const step = isStableBinding ? 4 : 2;
  const pagesRef = useRef(null)
  const getRangeOption = (newPageCount, options) => {
    setPageCount(newPageCount);
    return options.find((opt) => parseInt(opt.name, 10) === newPageCount) || null;
  };

  const toggleCheckbox = (name) => {
    if (name == 'addCover' && isChecked.addCover) {
      addCoverDisable()
    } else if (name == 'duskJacket' && aloneId.duskJacket !== '') {
      aloneCheckDisable(aloneId?.duskJacket)
      aloneCheckDisable(aloneId?.jacketFinish)
      setJacketColorID(null)
      setjacketFinishID(null)
    } else if (name == 'bundling' && aloneId.bundling !== '') {
      aloneCheckDisable(aloneId?.bundling)
      setBundlingHighL(null)
    } else if (name == 'bookmarkRibbon' && aloneId.bookmarkRibbon !== '') {
      aloneCheckDisable(aloneId?.bookmarkRibbon)
      setRibbenHighL(null)
    } else if (name == 'HeadTailBand' && aloneId.HeadTailBand !== '') {
      aloneCheckDisable(aloneId?.HeadTailBand)
      setHeadTailHighL(null)
    }
    setIsChecked({
      ...isChecked,
      [name]: !isChecked[name],
    });
  };

  const [isFoilingChecked, setIsFoilingChecked] = useState(false);
  const toggleFoilingCheckbox = () => {
    if (isFoilingChecked && aloneId.foiling !== '') {
      aloneCheckDisable(aloneId?.foiling)
      setfoilingHighL(null)
    }
    setIsFoilingChecked(!isFoilingChecked);
  };

  const [showHeader, setShowHeader] = useState(false);
  const prevScrollPos = useRef(0);
  const isAtBottom = useRef(false);

  const throttle = (callback, delay) => {
    let lastCall = 0;
    return function () {
      const now = new Date().getTime();
      if (now - lastCall < delay) return;
      lastCall = now;
      callback();
    };
  };

  useEffect(() => {
    const handleScroll = throttle(() => {
      const currentScrollPos = window.pageYOffset;
      const scrollHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      if (currentScrollPos < 100) {
        isAtBottom.current = true;
      } else {
        isAtBottom.current = false;
      }
      if (currentScrollPos < prevScrollPos.current && isAtBottom.current) {
        setShowHeader(false);
      } else if (!isAtBottom.current) {
        setShowHeader(true);
      }
      prevScrollPos.current = currentScrollPos;
    }, 100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentDateInEST = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
    const dateObject = new Date(currentDateInEST);

    const formattedDate = dateObject.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    setCurrentEasternTime(formattedDate);
  }, []);

  const paperTypeImages = {
    Color: {
      Satin,
      Gloss,
      Uncoated,
      Recycled,
    },
    Grayscale: {
      SatinGray,
      GlossGray,
      UncoatedGray,
      RecycledGray,
    },
  };

  const hasRun = useRef(false);

  const [colorGrayImage, setColorGrayImage] = useState("Color");
  const [colorPrint, setColorPrint] = useState(paperTypeImages.Color.Satin);

  const [selectedPaperType, setSelectedPaperType] = useState("Satin");
  const handlePaperTypeColor = (selectedOption) => {
    setColorGrayImage(selectedOption);
    const grayscaleKey = selectedPaperType + "Gray";
    if (selectedOption === "Grayscale") {
      setColorPrint(
        paperTypeImages.Grayscale[grayscaleKey] ||
          paperTypeImages.Grayscale.SatinGray
      );
    } else {
      setColorPrint(
        paperTypeImages.Color[selectedPaperType] || paperTypeImages.Color.Satin
      );
    }
  };

  const [paperTypeHighL, setPaperTypeHighL] = useState("");
  const handlePaperType = (option) => {
    setPaperTypeHighL(option);
    const selectedType = option.split(" ")[0];
    setSelectedPaperType(selectedType);
    if (colorGrayImage === "Color") {
      setColorPrint(
        paperTypeImages.Color[selectedType] || paperTypeImages.Color.Satin
      );
    } 
    else {
    const grayscaleKey = selectedType + "Gray";
    setColorPrint(
      paperTypeImages.Grayscale[grayscaleKey] ||
        paperTypeImages.GrayscaleSatinGray
      );
    }
  };

  const specialPaperTypeImages = {
    Color: {
      Gold: Satin,
      Silver: Gloss,
      Pearl: Uncoated,
    },
    Grayscale: {
      GoldGray: SatinGray,
      SilverGray: GlossGray,
      PearlGray: UncoatedGray,
    },
  };
  const [specialColorGrayImage, setSpecialColorGrayImage] = useState("");

  const handleSpecialPaperColor = (selectedOption) => {
    setSpecialColorGrayImage(selectedOption);
    // const grayscaleKey = selectedSpecialPaperType + "Gray";
    // if (selectedOption === "Grayscale") {
    //   setSpecialPaperTypeImage(
    //     specialPaperTypeImages.Grayscale[grayscaleKey] ||
    //     specialPaperTypeImages.Grayscale.GoldGray
    //   );
    // } else {
    //   setSpecialPaperTypeImage(
    //     specialPaperTypeImages.Color[selectedPaperType] || specialPaperTypeImages.Color.Gold
    //   );
    // }
  };

  const SETspecialPTidF = (key, value, type) => {
    if (type == 'btn' && specialPTid[key.field_name]) {
      setSpecialPTid(prev => {
        const newObj = { ...prev }
        delete newObj[key.field_name]
        return newObj
      })
      setSelectedOptions(prev => {
        const newObj = { ...prev }
        delete newObj[key.id]
        return newObj
      })
      setFormData(prev => ({
        ...prev,
        [key.field_name]: ""
      }));
      return;
    } else {
      setSpecialPTid(prev => ({
        ...prev,
        [key.field_name]: value
      }))
    }
  } 

  const specPaperTRef = useRef(null)
  const handleSpecialPaperType = (key, value) => {
    SETspecialPTidF(key, value, 'btn');

    // setSpecialPaperTypeHighL((prev) => prev.includes(option) ? prev.filter(item => item !== option) : [...prev, option]);
    // if (specialPaperTypeHighL.includes(option)) {
    //   setFormData((prev) => ({
    //     ...prev,
    //     option: ''
    //   }))
    // }
    // const selectedType = option.split(" ")[0];
    // setSelectedSpecialPaperType(selectedType);
    // if (specialColorGrayImage === "Color") {
    //   setSpecialPaperTypeImage(
    //     specialPaperTypeImages.Color[selectedType] || specialPaperTypeImages.Color.Gold
    //   );
    // } else {
    //   const grayscaleKey = selectedType + "Gray";
    //   setSpecialPaperTypeImage(
    //     specialPaperTypeImages.Grayscale[grayscaleKey] ||
    //     specialPaperTypeImages.Grayscale.GoldGray
    //   );
    // }
  };

  const coverPaperTypeImages = {
    Color: {
      CoverSatin,
      CoverGloss,
      CoverUncoated,
    },
    Grayscale: {
      CoverSatinGray,
      CoverGlossGray,
      CoverUncoatedGray,
    },
  };

  const [coverColorGrayToggle, setCoverColorGrayToggle] = useState("Color");
  const [settedCoverImage, setSettedCoverImage] = useState(
    coverPaperTypeImages.Color.CoverSatin
  );
  const [selectedCoverPaperType, setSelectedCoverPaperType] =
    useState("CoverSatin");
  const handleCoverColorGrayToggle = (ColorOrGray) => {
    setCoverColorGrayToggle(ColorOrGray);
    const grayScaleKey = selectedCoverPaperType + "Gray";
    if (ColorOrGray === "Grayscale") {
      setSettedCoverImage(
        coverPaperTypeImages.Grayscale[grayScaleKey] ||
          coverPaperTypeImages.Grayscale.CoverSatinGray
      );
    } else {
      setSettedCoverImage(
        coverPaperTypeImages.Color[selectedCoverPaperType] ||
          coverPaperTypeImages.Color.CoverSatin
      );
    }
  };

  const [coverTypeHighlighter, setCoverTypeHighlighter] = useState("");
  const handleCoverColorGrayChange = (name) => {
    setCoverTypeHighlighter(name);
    const selectedType = "Cover" + name;
    setSelectedCoverPaperType(selectedType);
    const grayScaleKey = selectedType + "Gray";
    if (coverColorGrayToggle === "Color") {
      setSettedCoverImage(
        coverPaperTypeImages.Color[selectedType] ||
          coverPaperTypeImages.Color.CoverSatin
      );
    } else {
      setSettedCoverImage(
        coverPaperTypeImages.Grayscale[grayScaleKey] ||
          coverPaperTypeImages.Grayscale.CoverSatinGray
      );
    }
  };

  const bindingColorImages = {
    WireO: {
      Black,
      White,
      Silver,
      Red,
      Blue,
      Gray,
    },
    Spiral: {
      BlackSpiral,
      WhiteSpiral,
      NavySpiral,
      ClearSpiral,
    },
  };

  const [bindingForColor, setBindingForColor] = useState(null);
  const [bindingHighLighter, setBindingHighLighter] = useState("");

  const [bindingColorImage, setBindingColorImage] = useState(
    bindingColorImages.WireO.Black
  );
  const handleBindingTypeToggle = (name) => {
    if (name === "Spiral") {
      setBindingColorImage(bindingColorImages.Spiral.BlackSpiral);
    }
    if (name === "Wire-O") {
      setBindingColorImage(bindingColorImages.WireO.Black);
    }
  };

  const handleBindingColorChange = (name) => {
    const optionName = name.split(" ")[0];
    setBindingHighLighter(name);
    if (bindingForColor === "Spiral") {
      const SpiralName = optionName + "Spiral";
      setBindingColorImage(
        bindingColorImages.Spiral[SpiralName] || BlackSpiral
      );
    } else {
      setBindingColorImage(bindingColorImages.WireO[optionName] || Black);
    }
  };

  const finishingColorImages = {
    None: None,
    "Gloss Lamination": GlossLamination,
    "Matte Lamination": MatteLamination,
    "Matte Anti-Scuff": MatteAntiScuf,
    "Soft-Touch Lamination": SoftTouchLamination,
    "UV Matte": UVMatte,
    "UV Gloss": UVGloss,
    "Matte Lam + Spot UV": MatteLamPlusSpotUV,
  };

  const bindingImages = {
    Staple: staple,
    Perfect: perfect,
    "Wire-O": Black,
    Spiral: BlackSpiral,
    Square: Square
  };

  const bindingHardbooks = {
    "Adhesive Casebound": Adhesive,
    "Smyth Sewn": Smyth,
  };

  const foilingImages = {
    GoldFoiling,
    SilverFoiling,
  };
  const [enableFoiling, setEnableFoiling] = useState("");
  const [foilingHighL, setfoilingHighL] = useState("");
  const [settedFoilingImage, setSettedFoilingImage] = useState(
    foilingImages.GoldFoiling
  );
  const handleFoilingColorChange = (name) => {
    setfoilingHighL(name);
    const selectedType = name + "Foiling";
    setSettedFoilingImage(foilingImages[selectedType]);
  };
  const [BundlingHighL, setBundlingHighL] = useState('')
  const handleBundling = (name) => {
    setBundlingHighL(name)
  }

  const priceDetailsRef = useRef(null);
  const focusPriceDetails = () => {
    priceDetailsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const importAll = (r, varName) => {
    r.keys().forEach((key) => {
      const fileName = key.replace("./", "").replace(/\.[^/.]+$/, "");
      varName[fileName] = r(key);
    });
  };

  const endpaperImages = {};
  importAll(
    require.context(
      "../assets/NewFormAsset/handcoverBooks/endPaper",
      false,
      /\.(png|jpe?g|svg)$/
    ),
    endpaperImages
  );
  
  const [endpaperColorImage, setEndpaperColorImage] = useState(
    endpaperImages.black
  );
  const [endpaperHighL, setEndpaperHighL] = useState("");
  const handleEndColorChange = (name) => {
    setEndpaperHighL(name);
    const optionName = name.toLowerCase().replace(/ /g, "_");
    setEndpaperColorImage(endpaperImages[optionName] || endpaperImages.black);
  };
  
  const ribbonImages = {};
  importAll(
    require.context(
      "../assets/NewFormAsset/handcoverBooks/bookmarkRibbon",
      false,
      /\.(png|jpe?g|svg)$/
    ),
    ribbonImages
  );
  
  const [ribbonImage, setribbonImage] = useState(ribbonImages.black);
  const [ribbenHighL, setRibbenHighL] = useState("");
  const handleRibbenChange = (name) => {
    setRibbenHighL(name);
    const optionName = name.toLowerCase().replace(/ /g, "_");
    setribbonImage(ribbonImages[optionName] || ribbonImages.black);
  };
  
  const headTailImages = {};
  importAll(
    require.context(
      "../assets/NewFormAsset/handcoverBooks/headTailBand",
      false,
      /\.(png|jpe?g|svg)$/
    ),
    headTailImages
  );

  const [headTailImage, setHeadTailImage] = useState(headTailImages.black);
  const [headTailHighL, setHeadTailHighL] = useState("");
  const handleHeadTailChange = (name) => {
    setHeadTailHighL(name);
    const optionName = name.toLowerCase().replace(/ /g, "_");
    setHeadTailImage(headTailImages[optionName] || headTailImages.black);
  };

  const jacketFinishImages = {
    "Gloss Lamination": GlossJacket,
    "Matte Lamination": MatteJacket,
  };

  useEffect(() => {
    if (hasRun.current || formFields.length === 0) return;
    hasRun.current = true;

    // 1
    const paperTypeCheck = formFields.length > 0 && formFields.some((field) => field.additional_name == "paper_type");
    if (paperTypeCheck) {
      Object.values(paperTypeImages).forEach((group) => {
        Object.values(group).forEach((src) => {
          const img = new Image();
          img.src = src;
        });
      });
    };

    // 2
    const coverPaperTypeCheck = formFields.length > 0 && formFields.some((field) => field.additional_name == "cover_paper_type");
    if (coverPaperTypeCheck) {
      Object.values(coverPaperTypeImages).forEach((group) => {
        Object.values(group).forEach((src) => {
          const img = new Image();
          img.src = src;
        });
      });
    };

    // 3
    const bindingColorCheck = formFields.length > 0 && formFields.some((field) => field.additional_name == "binding_color");
    if (bindingColorCheck) {
      Object.values(bindingColorImages).forEach((group) => {
        Object.values(group).forEach((src) => {
          const img = new Image();
          img.src = src;
        });
      });
    };

    // 4
    const coverFinishCheck = formFields.length > 0 && formFields.some((field) => field.additional_name == "choose_cover_finish");
    if (coverFinishCheck) {
      Object.values(finishingColorImages).forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };

    // 5
    const endpaperCheck = formFields.length > 0 && formFields.some((field) => field.additional_name == "choose_endpaper_color");
    if (endpaperCheck) {
      Object.values(endpaperImages).forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };

    // 6
    const ribbonCheck = formFields.length > 0 && formFields.some((field) => field.additional_name == "bookmark_ribbon");
    if (ribbonCheck) {
      Object.values(ribbonImages).forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };

    // 7
    const headTailCheck = formFields.length > 0 && formFields.some((field) => field.additional_name == "head_and_tail_band");
    if (headTailCheck) {
      Object.values(headTailImages).forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    };

  },[formFields]);

  return (
    <div>
      {/* <button onClick={consolEE} type="button" className="fixed top-52 z-10 p-4 bg-neutral-300 shadow-md rounded-full left-0">
        Console
      </button> */}
      <section className="flex flex-col items-center md:flex-row md:items-stretch md:justify-center w-full bg-[#EFEEF3] gap-y-5 sm:gap-y-6 md:gap-x-4 lg:gap-x-8 px-4 sm:px-6 md:px-10 lg:px-[6%] min-[1600px]:px-10 py-8 md:pt-6 md:pb-10 mb-8 sm:mb-8">
        <div ref={parentRef} className={`relative flex flex-col ${subTitle ? "justify-center" : ""} w-full sm:w-5/6 min-[710px]:w-3/4 md:w-[55%] min-[1600px]:max-w-[515px] h-full`}
        >
          <div className="flex flex-col bg-white px-4 lg:px-6 py-4 sm:pb-6 md:py-6 rounded-3xl md:rounded-3xl">
            <div className="flex md:hidden justify-center mb-1">
              {slides.length ? (
                <div className="w-full rounded-t-2xl overflow-hidden">
                  <img
                    src={slides[currentIndex]}
                    alt="product"
                    className="w-full"
                  />
                </div>
              ) : (
                <div className="w-full h-48 sm:h-64">
                  <Skeleton width="100%" height="100%" />
                </div>
              )}
            </div>
            <div className="flex w-full flex-col gap-3">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl sm:text-3xl font-semibold">
                  {subTitle ? subTitle : <Skeleton width="200px" height="30px" />}
                </h2>
                <button className="text-red-700 hover:text-red-600 duration-200 underline" onClick={reset}>Reset</button>
              </div>
              <div className="flex text-orange-400 text-[15px] gap-1">
                <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </div>
              <h2 className="sm:text-2xl md:text-3xl mt-2">
                Print Customization Interface
              </h2>
            </div>
            {formFields.length > 0 &&
              formFields.map(
                (field) =>
                  field.additional_name === "paperback_book_type" && (
                    <div className="mt-3 w-full">
                      <div className="flex justify-between md:gap-2 flex-wrap lg:gap-x-5 lg:gap-y-2 pb-3 mt-4">
                        <p className="font-semibold text-neutral-500 whitespace-nowrap">
                          {field.field_name}
                        </p>
                        <div
                          className={`${
                            field.options.length > 3
                              ? "grid grid-cols-3"
                              : "flex justify-around sm:justify-normal"
                          } overflow-hidden w-full gap-x-2 gap-y-3 mt-1`}
                        >
                          {field.options.map((colorOption) => (
                            <div
                              className={`${
                                field.options.length > 3
                                  ? ""
                                  : "max-[1500px]:max-w-[160px]"
                              } w-full flex flex-col gap-y-[3px]`}
                            >
                              <button
                                key={colorOption.id}
                                onClick={(e) => {
                                  handleChange(e, field, colorOption);
                                  // handlePaperbackTypeToggle(colorOption.name);
                                }}
                                className={`${
                                  chooseBinding === colorOption.id &&
                                  "border-[#d99389]"
                                } w-full rounded-md p-1 md:p-[2px] lg:p-1 border-2 flex flex-col items-start hover:border-[#d99389]/70 focus:outline-none duration-200`}
                              >
                                <div className="lg:rounded-md rounded-md md:rounded-t-sm overflow-hidden border">
                                  <img
                                    src={bindingImages.Spiral}
                                    // src={pbBooktypeImages[colorOption.name.split(" ")[0]]} Use this line after get Images and update above pbBooktypemages
                                    alt="Staple"
                                    className="object-cover"
                                  />
                                </div>
                              </button>
                              <p className="text-center px-2 md:text-start lg:text-center text-sm md:text-xs lg:text-sm font-semibold md:font-normal w-full text-neutral-600">
                                {colorOption.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
              )}
            {formFields.length > 0 &&
              formFields.map(
                (field) =>
                  field.additional_name === "book_type_for_print_on_demand" && (
                    <div className="mt-3 w-full">
                      <div className="flex justify-between md:gap-2 flex-wrap lg:gap-x-5 lg:gap-y-2 pb-3 mt-4">
                        <p className="font-semibold text-neutral-500 whitespace-nowrap">
                          {field.field_name}
                        </p>
                        <div
                          className={`${
                            field.options.length > 3
                              ? "grid grid-cols-3"
                              : "flex justify-around sm:justify-normal"
                          } overflow-hidden w-full gap-x-2 gap-y-3 mt-1`}
                        >
                          {field.options.map((colorOption) => (
                            <div
                              className={`${
                                field.options.length > 3
                                  ? ""
                                  : "max-[1500px]:max-w-[160px]"
                              } w-full flex flex-col gap-y-[3px]`}
                            >
                              <button
                                key={colorOption.id}
                                onClick={(e) => {
                                  handleChange(e, field, colorOption);
                                  // handlePaperbackTypeToggle(colorOption.name);
                                }}
                                className={`${
                                  chooseBinding === colorOption.id &&
                                  "border-[#d99389]"
                                } w-full rounded-md p-1 md:p-[2px] lg:p-1 border-2 flex flex-col items-start hover:border-[#d99389]/70 focus:outline-none duration-200`}
                              >
                                <div className="lg:rounded-md rounded-md md:rounded-t-sm overflow-hidden border">
                                  <img
                                    src={bindingImages.Spiral}
                                    // src={pbBooktypeImages[colorOption.name.split(" ")[0]]} Use this line after get Images and update above pbBooktypemages
                                    alt="Staple"
                                    className="object-cover"
                                  />
                                </div>
                              </button>
                              <p className="text-center px-2 md:text-start lg:text-center text-sm md:text-xs lg:text-sm font-semibold md:font-normal w-full text-neutral-600">
                                {colorOption.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
              )}
            <div className="flex justify-between gap-x-5 gap-y-1 flex-wrap mt-3 w-full">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name === "quantity" && (
                      <div
                        key={field.id || field.additional_name}
                        className="relative flex flex-col gap-2 flex-1 w-full"
                      >
                        <label
                          htmlFor="quantity"
                          className="font-semibold text-neutral-500"
                        >
                          Quantity
                        </label>

                        <input
                          type="number"
                          name={field.field_name}
                          value={formData[field.field_name] || ""}
                          onChange={(e) => handleChange(e, field, field.id)}
                          min={1}
                          max={10000}
                          step={1}
                          style={{ padding: "0.4rem" }}
                          className="block w-full p-3 pr-12 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 duration-200"
                        />

                        {/* Custom Arrows */}
                        <div
                          style={{ fontSize: "8px", top: "66%" }}
                          className="absolute right-2 top-1/2 transform -translate-y-1/2 flex flex-col space-y-1"
                        >
                          <button
                            type="button"
                            onClick={() =>
                              handleChange(
                                {
                                  target: {
                                    name: field.field_name,
                                    value: Math.min(
                                      Number(formData[field.field_name] || 0) +
                                        25,
                                      10000
                                    ),
                                  },
                                },
                                field,
                                field.id
                              )
                            }
                            className="text-gray-600 hover:text-orange-500"
                          >
                            ▲
                          </button>

                          <button
                            type="button"
                            onClick={() =>
                              handleChange(
                                {
                                  target: {
                                    name: field.field_name,
                                    value: Math.max(
                                      Number(formData[field.field_name] || 0) -
                                        25,
                                      1
                                    ),
                                  },
                                },
                                field,
                                field.id
                              )
                            }
                            className="text-gray-600 hover:text-orange-500"
                          >
                            ▼
                          </button>
                        </div>
                      </div>
                    )
                )}

              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name === "color_printing" && (
                      <div className="flex flex-col gap-2 flex-1 w-full">
                        <label
                          htmlFor="quantity"
                          className="font-semibold whitespace-nowrap text-neutral-500"
                        >
                          {field.field_name}
                        </label>
                        <div className="flex gap-2 w-full">
                          {field.options.map((colorOption) => (
                            <button
                              style={{
                                backgroundColor:
                                  colorPrinting !== colorOption.id
                                    ? "#f6f7f8"
                                    : undefined,
                              }}
                              key={colorOption.id}
                              onClick={(e) => {
                                handleChange(e, field, colorOption);
                                handlePaperTypeColor(colorOption.name);
                              }}
                              className={`${
                                colorPrinting === colorOption.id
                                  ? "bg-blue-100 border-[#d99389]"
                                  : "bg-neutral-100"
                              } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                            >
                              {colorOption.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )
                )}
            </div>
            { errorResponse?.perfect && <p className="text-sm text-[#d99389]">{errorResponse.perfect}</p>}
            <div className="flex justify-between gap-5 flex-wrap mt-4">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "orientation" && (
                      <div className="flex flex-col gap-2 flex-1">
                        <label
                          htmlFor="quantity"
                          className="font-semibold whitespace-nowrap text-neutral-500"
                        >
                          {field.field_name}
                        </label>
                        <div className="flex gap-2 w-full">
                          {field.options.map((colorOption) => (
                            <button
                              style={{
                                backgroundColor:
                                  orientation !== colorOption.id
                                    ? "#f6f7f8"
                                    : undefined,
                              }}
                              key={colorOption.id}
                              onClick={(e) =>
                                handleChange(e, field, colorOption)
                              }
                              className={`${
                                orientation === colorOption.id
                                  ? "bg-blue-100 border-[#d99389]"
                                  : "bg-neutral-100"
                              } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                            >
                              {colorOption.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )
                )}

              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "size" && (
                      <div className="flex flex-col gap-2 flex-1">
                        <label
                          htmlFor="quantity"
                          className="font-semibold text-neutral-500"
                        >
                          {field.field_name}
                        </label>
                        <select
                          name={field.field_name}
                          value={formData[field.field_name] || ""}
                          onChange={(e) => {
                            const selectedOption = field.options.find(
                              (option) =>
                                option.id.toString() === e.target.value
                            );
                            handleChange(e, field, selectedOption);
                          }}
                          className="w-full px-2 py-2 focus:outline-none bg-white rounded border border-neutral-300 duration-200 focus:shadow-[0_0_5px_2.5px_rgba(137,196,244,0.3)]"
                        >
                          <option value="" disabled>
                            Choose an option
                          </option>
                          {field.options.map((colorOption) => (
                            <option key={colorOption.id} value={colorOption.id}>
                              {colorOption.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                )}
            </div>
            <br />

            <div className="flex flex-wrap gap-3 mt-4">
              <div className="flex flex-col items-center gap-[10px] w-full">
                {formFields.length > 0 &&
                  formFields.map(
                    (field) =>
                      field.additional_name === "pages" &&
                      field.options.length > 0 && (
                        <>
                          <span key={pageCount} className="font-bold text-neutral-500">
                            {pageCount} Pages
                          </span>
                          <div className="flex items-center gap-4 w-full">
                            <button
                              type="button"
                              onClick={(e) => {
                                const baseMin = Math.min(
                                  ...field.options.map((opt) => parseInt(opt.name, 10))
                                );
                                const minVal = isStableBinding ? 4 : baseMin;
                                const newVal = Math.max(pageCount - step, minVal);
                                handleChange(
                                  {
                                    target: {
                                      name: field.field_name,
                                      value: newVal, 
                                    },
                                  },
                                  field,
                                  getRangeOption(
                                    newVal,
                                    field.options
                                  )
                                )
                                setPrevStateHolder(prev => ({
                                  ...prev,
                                  'pages':  {
                                    target: {
                                      name: field.field_name,
                                      value: newVal,
                                    },
                                    field: field,
                                    options: field.options
                                  },
                                }))
                              }}
                              className="py-[6px] px-[10px] bg-[#d99389] text-white border-none rounded transition-colors duration-200 hover:bg-[#ca7f74] active:bg-[#d99389]"
                            >
                              -
                            </button>
                            <input
                              type="range"
                              min={
                                isStableBinding 
                                ? 4 
                                : Math.min(
                                ...field.options.map((opt) =>
                                  parseInt(opt.name, 10)
                                )
                              )}
                              max={Math.max(
                                ...field.options.map((opt) =>
                                  parseInt(opt.name, 10)
                                )
                              )}
                              step={step}
                              className="formSliderThumb flex-1 w-full h-[6px] bg-[#ddd] rounded appearance-none outline-none"
                              value={pageCount}
                              ref={pagesRef}
                              onChange={(e) => {
                                const newVal = parseInt(e.target.value, 10);
                                setPageCount(newVal);
                                handleChange(
                                  {
                                    target: {
                                      name: field.field_name,
                                      value: newVal,
                                    },
                                  },
                                  field,
                                  getRangeOption(newVal, field.options)
                                );
                                setPrevStateHolder(prev => ({
                                  ...prev,
                                  'pages':  {
                                    target: {
                                      name: field.field_name,
                                      value: newVal,
                                    },
                                    field: field,
                                    options: field.options
                                  },
                                }))
                              }}
                            />
                            <button
                              type="button"
                              onClick={(e) => {
                                const baseMax = Math.max(
                                  ...field.options.map((opt) => parseInt(opt.name, 10))
                                );
                                const maxVal = isStableBinding
                                  ? baseMax - (baseMax % 4)
                                  : baseMax; 
                                const newVal = Math.min(pageCount + step, maxVal);
                                handleChange(
                                  {
                                    target: {
                                      name: field.field_name,
                                      value: newVal,
                                    },
                                  },
                                  field,
                                  getRangeOption(
                                    newVal,
                                    field.options
                                  )
                                )
                                setPrevStateHolder(prev => ({
                                  ...prev,
                                  'pages':  {
                                    target: {
                                      name: field.field_name,
                                      value: newVal,
                                    },
                                    field: field,
                                    options: field.options
                                  },
                                }))
                              }}
                              className="py-[6px] px-[10px] bg-[#d99389] text-white border-none rounded transition-colors duration-200 hover:bg-[#ca7f74] active:bg-[#d99389]"
                            >
                              +
                            </button>
                          </div>
                        </>
                      )
                  )}
              </div>
            </div>
            <br />
            <hr />

            <div className="flex items-center gap-5 mt-4">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "paper_type" && (
                      <>
                        <div className="flex flex-col gap-2 flex-1">
                          <label
                            htmlFor="quantity"
                            className=" font-semibold text-neutral-500"
                          >
                            {field.field_name}
                          </label>
                          {field.options.map((Option) => {
                            return (
                              <button
                                key={Option.id}
                                onClick={(e) => {
                                  handleChange(e, field, Option);
                                  handlePaperType(Option.name);
                                }}
                                className={`${
                                  paperTypeHighL === Option.name
                                    ? "bg-blue-100 border-[#d99389]"
                                    : "bg-neutral-100"
                                } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                              >
                                {Option.name}
                              </button>
                            );
                          })}
                        </div>
                        {/* max-w-36 */}
                        <div className="flex justify-center items-center p-1 rounded-xl overflow-hidden border-2 flex-1">
                          {colorGrayImage && (
                            <img
                              src={colorPrint}
                              key={colorPrint}
                              alt="paper-type"
                              className="rounded-lg"
                            />
                          )}
                        </div>
                      </>
                    )
                )}
            </div>
            <br />

            <div className="flex justify-between flex-wrap gap-5 mt-2">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "paper_weight" && (
                      <div className="flex flex-col gap-2 flex-1">
                        <label
                          htmlFor="quantity"
                          className="font-semibold text-neutral-500"
                        >
                          {field.field_name}
                        </label>
                        <select
                          name={field.field_name}
                          value={formData[field.field_name] || ""}
                          onChange={(e) => {
                            const selectedOption = field.options.find(
                              (option) =>
                                option.id.toString() === e.target.value
                            );
                            handleChange(e, field, selectedOption);
                          }}
                          className="w-full px-2 py-2 focus:outline-none bg-white rounded border border-neutral-300 duration-200 focus:shadow-[0_0_5px_2.5px_rgba(137,196,244,0.3)]"
                        >
                          <option value="" disabled>
                            Choose an option
                          </option>
                          {field.options.map((colorOption) => (
                            <option key={colorOption.id} value={colorOption.id}>
                              {colorOption.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                )}

              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "print_turn_around" && (
                      <div className="flex flex-col gap-2 flex-1">
                        <label
                          htmlFor="quantity"
                          className="font-semibold text-neutral-500"
                        >
                          {field.field_name}
                        </label>
                        <select
                          name={field.field_name}
                          value={formData[field.field_name] || ""}
                          onChange={(e) => {
                            const selectedOption = field.options.find(
                              (option) =>
                                option.id.toString() === e.target.value
                            );
                            handleChange(e, field, selectedOption);
                          }}
                          className="w-full px-2 py-2 focus:outline-none bg-white rounded border border-neutral-300 duration-200 focus:shadow-[0_0_5px_2.5px_rgba(137,196,244,0.3)]"
                        >
                          <option value="" disabled>
                            Choose an option
                          </option>
                          {field.options.map((colorOption) => (
                            <option key={colorOption.id} value={colorOption.id}>
                              {colorOption.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                )}
            </div>
            <br />
            <hr />

            {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "gold_embossed" || field.additional_name == "silver_embossed" || field.additional_name == "pearl_embossed"  && (
                      <div className="flex w-full items-center gap-8"> 
                        <label
                          htmlFor="special-paper-type"
                          className="w-1/2 font-semibold text-neutral-500 py-3"
                          ref={specPaperTRef}
                        >
                          Special Paper Type
                        </label>
                        <label
                          htmlFor="special-paper-type"
                          className="font-semibold text-neutral-500 w-1/2 py-3"
                        >
                          Number of pages
                        </label>
                      </div>
                  )
                )
            }
            
            <div className="flex flex-col gap-2">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    (field.additional_name == "gold_embossed" || field.additional_name == "silver_embossed" || field.additional_name == "pearl_embossed")  && (
                      <>
                        <div className="flex items-center gap-5 flex-1">
                          <button
                            onClick={(e) => {
                              handleSpecialPaperType(field, 'unset');
                            }}
                            className={`${
                              specialPTid[field.field_name]
                                ? "bg-blue-100 border-[#d99389]"
                                : "bg-neutral-100"
                            } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 hover:bg-neutral-200/80`}
                          >
                            {field.field_name}
                          </button>
                          <select
                            name={field.field_name}
                            disabled={!specialPTid[field.field_name]}
                            value={specialPageC[field.field_name] || ""}
                            onChange={(e) => {
                              const selectedOption = field.options.find(
                                (option) =>
                                  option.name === e.target.value
                              );
                              setSpecialPageC(prev => ({
                                ...prev,
                                [field.field_name]: selectedOption.name
                              }))
                              handleChange(e, field, selectedOption);
                              setPrevStateHolder(prev => ({
                                ...prev,
                                [field.field_name]:  {
                                  target: {
                                    name: field.field_name,
                                    value: selectedOption.name,
                                  },
                                  field,
                                  selectedOption,
                                },
                              }))
                              SETspecialPTidF(field, field.id)
                            }}
                            className={`${!specialPTid[field.field_name] ? 'cursor-not-allowed opacity-30' : 'opacity-100'} w-full px-2 py-2 focus:outline-none bg-white rounded border border-neutral-300 duration-200 focus:shadow-[0_0_5px_2.5px_rgba(137,196,244,0.3)]`}
                          >
                            <option value="" disabled>
                              Choose number of pages
                            </option>
                            {isStableBinding ? (
                              field.options
                              .filter((colorOption) => Number(colorOption.name) % 4 === 0)
                              .map((colorOption) => (
                                <option key={colorOption.id} value={colorOption.name}>
                                  {colorOption.name}
                                </option>
                              ))
                            )
                            : (
                              field.options.map((colorOption) => (
                                <option key={colorOption.id} value={colorOption.name}>
                                  {colorOption.name}
                                </option>
                              ))
                            )}                            
                          </select>
                        </div>
                      </>
                    )
                )}
            </div>
            
            {/* <div className="flex items-center gap-5 mt-2">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "silver_embossed"  && (
                      <>
                        <div className="flex items-center gap-5 flex-1">
                          <button
                            onClick={(e) => {
                              handleSpecialPaperType(field, 'unset');
                            }}
                            className={`${
                              specialPTid[field.field_name]
                                ? "bg-blue-100 border-[#d99389]"
                                : "bg-neutral-100"
                            } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 hover:bg-neutral-200/80`}
                          >
                            {field.field_name}
                          </button>
                          <select
                            name={field.field_name}
                            disabled={!specialPTid[field.field_name]}
                            value={formData[field.field_name] || ""}
                            onChange={(e) => {
                              const selectedOption = field.options.find(
                                (option) =>
                                  option.id.toString() === e.target.value
                              );
                              handleChange(e, field, selectedOption);
                              SETspecialPTidF(field, field.id)
                            }}
                            className={`${!specialPTid[field.field_name] ? 'cursor-not-allowed opacity-30' : 'opacity-100'} w-full px-2 py-2 focus:outline-none bg-white rounded border border-neutral-300 duration-200 focus:shadow-[0_0_5px_2.5px_rgba(137,196,244,0.3)]`}
                          >
                            <option value="" disabled>
                              Choose number of pages
                            </option>
                            {field.options.map((colorOption) => (
                              <option key={colorOption.id} value={colorOption.id}>
                                {colorOption.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )
                )}
            </div> */}

            {/* <div className="flex items-center gap-5 mt-2">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "pearl_embossed"  && (
                      <>
                        <div className="flex items-center gap-5 flex-1">
                          <button
                            onClick={(e) => {
                              handleSpecialPaperType(field, 'unset');
                            }}
                            className={`${
                              specialPTid[field.field_name]
                                ? "bg-blue-100 border-[#d99389]"
                                : "bg-neutral-100"
                            } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 hover:bg-neutral-200/80`}
                          >
                            {field.field_name}
                          </button>
                          <select
                            name={field.field_name}
                            disabled={!specialPTid[field.field_name]}
                            value={formData[field.field_name] || ""}
                            onChange={(e) => {
                              const selectedOption = field.options.find(
                                (option) =>
                                  option.id.toString() === e.target.value
                              );
                              handleChange(e, field, selectedOption);
                              SETspecialPTidF(field, field.id)
                            }}
                            className={`${!specialPTid[field.field_name] ? 'cursor-not-allowed opacity-30' : 'opacity-100'} w-full px-2 py-2 focus:outline-none bg-white rounded border border-neutral-300 duration-200 focus:shadow-[0_0_5px_2.5px_rgba(137,196,244,0.3)]`}
                          >
                            <option value="" disabled>
                              Choose number of pages
                            </option>
                            {field.options.map((colorOption) => (
                              <option key={colorOption.id} value={colorOption.id}>
                                {colorOption.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )
                )}
            </div> */}
            { errorResponse?.specialPT && <p className="text-sm text-red-800">{errorResponse.specialPT}</p>}

            <div className="w-full my-3">
            {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name === "printing_color" && (
                      <div className="flex flex-col gap-2 flex-1 w-full">
                        <label
                          htmlFor="quantity"
                          className="font-semibold whitespace-nowrap text-neutral-500"
                        >
                          {field.field_name}
                        </label>
                        <div className="flex gap-2 w-full">
                          {field.options.map((colorOption) => (
                            <button
                              style={{
                                backgroundColor:
                                  specialColorGrayImage !== colorOption.name
                                    ? "#f6f7f8"
                                    : undefined,
                              }}
                              key={colorOption.id}
                              onClick={(e) => {
                                handleChange(e, field, colorOption);
                                handleSpecialPaperColor(colorOption.name);
                              }}
                              className={`${
                                specialColorGrayImage === colorOption.name
                                  ? "bg-blue-100 border-[#d99389]"
                                  : "bg-neutral-100"
                              } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                            >
                              {colorOption.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )
                )}
            </div>
            <br />
            <hr />

            <div className="mt-3 w-full">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name === "choose_binding" && (
                      <div className="flex justify-between md:gap-2 flex-wrap lg:gap-x-5 lg:gap-y-2 pb-3 mt-4">
                        <p className="font-semibold text-neutral-500 whitespace-nowrap">
                          {field.field_name}
                        </p>
                        <div
                          className={`${
                            field.options.length > 3
                              ? "grid grid-cols-3"
                              : "flex justify-around sm:justify-normal"
                          } overflow-hidden w-full gap-x-2 gap-y-3 mt-1`}
                        >
                          {field.options.map((colorOption) =>
                            additionalName === "hardcover_books" ? (
                              <div
                                className={`${
                                  field.options.length > 3
                                    ? ""
                                    : "max-[1500px]:max-w-[160px]"
                                } w-full flex flex-col gap-y-[3px]`}
                              >
                                <button
                                  key={colorOption.id}
                                  onClick={(e) => {
                                    handleChange(e, field, colorOption);
                                    handleBindingTypeToggle(colorOption.name);
                                  }}
                                  className={`${
                                    chooseBinding === colorOption.id &&
                                    "border-[#d99389]"
                                  } w-full rounded-md p-1 md:p-[2px] lg:p-1 border-2 flex flex-col items-start hover:border-[#d99389]/70 focus:outline-none duration-200`}
                                >
                                  <div className="lg:rounded-md rounded-md md:rounded-t-sm overflow-hidden border">
                                    <img
                                      src={bindingHardbooks[colorOption.name]}
                                      key={bindingHardbooks[colorOption.name]}
                                      alt={colorOption.name}
                                      className="object-cover"
                                    />
                                  </div>
                                </button>
                                <p className="text-center px-2 md:text-start lg:text-center text-sm md:text-xs lg:text-sm font-semibold md:font-normal w-full text-neutral-600">
                                  {colorOption.name}
                                </p>
                              </div>
                            ) : (
                              <div
                                className={`${
                                  field.options.length > 3
                                    ? ""
                                    : "max-w-[160px]"
                                } w-full flex flex-col gap-y-[3px]`}
                              >
                                <button
                                  key={colorOption.id}
                                  onClick={(e) => {
                                    handleChange(e, field, colorOption);
                                    handleBindingTypeToggle(colorOption.name);
                                    handlePagesStep(colorOption.name.split(' ')[0]);
                                  }}
                                  className={`${
                                    chooseBinding === colorOption.id &&
                                    "border-[#d99389]"
                                  } w-full rounded-md p-1 md:p-[2px] lg:p-1 border-2 flex flex-col items-start hover:border-[#d99389]/70 focus:outline-none duration-200`}
                                >
                                  <div className="lg:rounded-md rounded-md md:rounded-t-sm overflow-hidden border">
                                    <img
                                      src={bindingImages[colorOption.name.split(' ')[0]]}
                                      key={bindingImages[colorOption.name.split(' ')[0]]}
                                      alt={colorOption.name}
                                      className="object-cover"
                                    />
                                  </div>
                                </button>
                                <p className="text-center px-2 md:text-start lg:text-center text-sm md:text-xs lg:text-sm font-semibold md:font-normal w-full text-neutral-600">
                                  {colorOption.name}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                        <hr />
                      </div>
                    )
                )}
            </div>

            {bindingForColor === "Wire-O" || bindingForColor === "Spiral"
              ? formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "binding_color" && (
                      <div className="flex flex-col md:gap-3 flex-wrap mt-4">
                        <p className="font-semibold text-neutral-500 whitespace-nowrap">
                          {field.field_name}
                        </p>
                        <div className="flex items-center justify-start lg:justify-end gap-1 pb-2 md:gap-2">
                          <div className="border-2 rounded-xl flex-1 p-2">
                            <div className="flex flex-col customScroll items-center max-h-[180px] h-full overflow-auto pr-2 gap-y-2">
                              {field.options
                                .filter((Option) => {
                                  if (bindingForColor === "Spiral") {
                                    return (
                                      Option.name !== "Red" &&
                                      Option.name !== "Blue" &&
                                      Option.name !== "Silver" &&
                                      Option.name !== "Gray"
                                    );
                                  }
                                  if (bindingForColor === "Wire-O") {
                                    return (
                                      Option.name !== "Navy Blue" &&
                                      Option.name !== "Clear"
                                    );
                                  }
                                  return true;
                                })
                                .map((Option) => {
                                  return (
                                    <button
                                      key={Option.id}
                                      onClick={(e) => {
                                        handleChange(e, field, Option);
                                        handleBindingColorChange(Option.name);
                                      }}
                                      className={`${
                                        bindingHighLighter === Option.name
                                          ? "bg-blue-100 border-[#d99389]"
                                          : "bg-neutral-100"
                                      } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                    >
                                      {Option.name}
                                    </button>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="flex justify-center items-center p-1 rounded-xl overflow-hidden border-2 flex-1">
                            <img
                              src={bindingColorImage}
                              key={bindingColorImage}
                              alt="binding-color"
                              className="rounded-lg"
                            />
                          </div>
                        </div>
                        <hr />
                      </div>
                    )
                )
              : null}

            <div className="w-full">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "bookmark_ribbon" && (
                      <div className="flex flex-col gap-4 flex-wrap mt-4">
                        <hr />
                        <label className="flex items-center font-semibold text-neutral-500 text-[17px] cursor-pointer relative">
                          <input
                            type="checkbox"
                            className="absolute opacity-0 w-0 h-0"
                            onChange={() => toggleCheckbox("bookmarkRibbon")}
                          />
                          <span
                            className={`inline-block h-6 w-6 rounded border-2 transition-all duration-300 mr-2 ${
                              isChecked.bookmarkRibbon
                                ? "bg-[#d99389] border-[#d99389]"
                                : "bg-white border-gray-300"
                            }`}
                          >
                            {isChecked.bookmarkRibbon && (
                              <span className="absolute top-1.5 left-2 w-1.5 h-3 rotate-45 border-t-0 border-l-0 border-white border-2"></span>
                            )}
                          </span>
                          Add {field.field_name}
                        </label>
                        {isChecked.bookmarkRibbon && (
                          <div className="flex items-center justify-start lg:justify-end gap-1 pb-2 md:gap-2">
                            <div className="border-2 rounded-xl flex-1 p-2">
                              <div className="flex flex-col customScroll items-center max-h-[180px] h-full overflow-auto pr-2 gap-y-2">
                                {field.options.map((Option) => {
                                  return (
                                    <button
                                      key={Option.id}
                                      onClick={(e) => {
                                        handleChange(e, field, Option);
                                        handleRibbenChange(Option.name);
                                        SETAloneID('bookmarkRibbon', field.id)
                                      }}
                                      className={`${
                                        ribbenHighL === Option.name
                                          ? "bg-blue-100 border-[#d99389]"
                                          : "bg-neutral-100"
                                      } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                    >
                                      {Option.name}
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="flex justify-center items-center p-1 rounded-xl overflow-hidden border-2 flex-1">
                              <img
                                src={ribbonImage}
                                key={ribbonImage}
                                alt="binding-color"
                                className="rounded-lg"
                              />
                            </div>
                          </div>
                        )}
                        <hr />
                      </div>
                    )
                )}
            </div>

            <div className="w-full">
              {formFields.length > 0 &&
                formFields.map(
                  (field) =>
                    field.additional_name == "head_and_tail_band" && (
                      <div className="flex flex-col md:gap-3 flex-wrap mt-4">
                        <label className="flex items-center font-semibold text-neutral-500 text-[17px] cursor-pointer relative">
                          <input
                            type="checkbox"
                            className="absolute opacity-0 w-0 h-0"
                            onChange={() => toggleCheckbox("HeadTailBand")}
                          />
                          <span
                            className={`inline-block h-6 w-6 rounded border-2 transition-all duration-300 mr-2 ${
                              isChecked.HeadTailBand
                                ? "bg-[#d99389] border-[#d99389]"
                                : "bg-white border-gray-300"
                            }`}
                          >
                            {isChecked.HeadTailBand && (
                              <span className="absolute top-1.5 left-2 w-1.5 h-3 rotate-45 border-t-0 border-l-0 border-white border-2"></span>
                            )}
                          </span>
                          Add {field.field_name}
                        </label>
                        {isChecked.HeadTailBand && (
                          <div className="flex items-center justify-start lg:justify-end gap-1 pb-2 md:gap-2">
                            <div className="border-2 rounded-xl flex-1 p-2">
                              <div className="flex flex-col customScroll items-center max-h-[180px] h-full overflow-auto pr-2 gap-y-2">
                                {field.options.map((Option) => {
                                  return (
                                    <button
                                      key={Option.id}
                                      onClick={(e) => {
                                        handleChange(e, field, Option);
                                        handleHeadTailChange(Option.name);
                                        SETAloneID('HeadTailBand', field.id)
                                      }}
                                      className={`${
                                        headTailHighL === Option.name
                                          ? "bg-blue-100 border-[#d99389]"
                                          : "bg-neutral-100"
                                      } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                    >
                                      {Option.name}
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="flex justify-center items-center p-1 rounded-xl overflow-hidden border-2 flex-1">
                              <img
                                src={headTailImage}
                                key={headTailImage}
                                alt="binding-color"
                                className="rounded-lg"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )
                )}
            </div>

            {additionalName !== "paperback_books" &&
              additionalName !== "hardcover_books" &&
              additionalName !== "share_&_sell" && (
                <>
                  <hr />
                  <label className="flex items-center font-semibold mt-4 text-neutral-500 text-[17px] cursor-pointer relative">
                    <input
                      type="checkbox"
                      className="absolute opacity-0 w-0 h-0"
                      onChange={() => toggleCheckbox("addCover")}
                    />
                    <span
                      className={`inline-block h-6 w-6 rounded border-2 transition-all duration-300 mr-2 ${
                        isChecked.addCover
                          ? "bg-[#d99389] border-[#d99389]"
                          : "bg-white border-gray-300"
                      }`}
                    >
                      {isChecked.addCover && (
                        <span className="absolute top-1.5 left-2 w-1.5 h-3 rotate-45 border-t-0 border-l-0 border-white border-2"></span>
                      )}
                    </span>
                    Add Cover (+4 Printed Pages)
                  </label>
                </>
              )}
          </div>

          {isChecked.addCover && (
            <div className="flex flex-col bg-white px-4 mt-4 lg:px-6 pb-4 sm:pb-6 md:py-6 rounded-3xl md:rounded-3xl">
              {(additionalName === "paperback_books" ||
                additionalName === "hardcover_books" ||
                additionalName === "share_&_sell") && (
                <span className="flex items-center font-semibold text-neutral-500 text-[17px]">
                  Cover (+4 Printed Pages)
                </span>
              )}
              <div
                className={`rounded-md ${
                  !isChecked.addCover &&
                  "p-3 border flex flex-wrap gap-3 mt-4 border-neutral-300"
                } mb-3mt-1 w-full`}
              >
                <div className="flex flex-col gap-2 font-semibold text-neutral-500 mt-2">
                  <div className="flex justify-between gap-x-5 gap-y-1 mt-4 w-full">
                    {formFields.length > 0 &&
                      formFields.map(
                        (field) =>
                          field.additional_name == "cover_printing" && (
                            <div className="flex-col w-full">
                              <span>{field.field_name}</span>
                              <div className="flex items-center justify-start gap-2 w-full mt-1 mb-2">
                                {field.options.map((Option) => {
                                  return (
                                    <button
                                      style={{
                                        backgroundColor:
                                          coverPrinting !== Option.id
                                            ? "#f6f7f8"
                                            : undefined,
                                      }}
                                      key={Option.id}
                                      onClick={(e) => {
                                        handleChange(e, field, Option);
                                        handleCoverColorGrayToggle(Option.name);
                                        SetIDFunc(field.id)
                                      }}
                                      className={`${
                                        coverPrinting === Option.id
                                          ? "bg-blue-100 border-[#d99389]"
                                          : "bg-neutral-100"
                                      } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                    >
                                      {Option.name}
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          )
                      )}
                    {formFields.length > 0 &&
                      formFields.map(
                        (field) =>
                          field.additional_name == "inner_cover_printing" && (
                            <div className="flex-col w-full">
                              <span>{field.field_name}</span>
                              <div className="flex items-center justify-start gap-2 w-full mt-1 mb-2">
                                {field.options.map((Option) => {
                                  return (
                                    <button
                                      style={{
                                        backgroundColor:
                                          innerCoverPrinting !== Option.id
                                            ? "#f6f7f8"
                                            : undefined,
                                      }}
                                      key={Option.id}
                                      onClick={(e) => {
                                        handleChange(e, field, Option)
                                        SetIDFunc(field.id)
                                      }}
                                      className={`${
                                        innerCoverPrinting === Option.id
                                          ? "bg-blue-100 border-[#d99389]"
                                          : "bg-neutral-100"
                                      } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                    >
                                      {Option.name}
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          )
                      )}
                  </div>
                  {formFields.length > 0 &&
                    formFields.map(
                      (field) =>
                        field.additional_name == "cover_paper_type" && (
                          <>
                            <span className="mt-1">{field.field_name}</span>
                            <div className="flex items-center justify-around gap-[10px] mt-1 mb-2">
                              <div className="flex flex-col gap-y-2 flex-1">
                                {field.options.map((Option) => {
                                  return (
                                    <button
                                      key={Option.id}
                                      onClick={(e) => {
                                        handleChange(e, field, Option);
                                        handleCoverColorGrayChange(Option.name);
                                        SetIDFunc(field.id)
                                      }}
                                      className={`${
                                        coverTypeHighlighter === Option.name
                                          ? "bg-blue-100 border-[#d99389]"
                                          : "bg-neutral-100"
                                      } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                    >
                                      {Option.name}
                                    </button>
                                  );
                                })}
                              </div>
                              <div className="flex flex-col gap-1 flex-1">
                                <div className="flex justify-center items-center p-1 rounded-xl overflow-hidden border-2 flex-1">
                                  <img
                                    src={settedCoverImage}
                                    key={settedCoverImage}
                                    alt="cover-paper-type"
                                    className="rounded-lg"
                                  />
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        )
                    )}
                  {formFields.length > 0 &&
                    formFields.map(
                      (field) =>
                        field.additional_name == "cover_paper_weight" && (
                          <>
                            <span className="mt-1">{field.field_name}</span>
                            <div className="grid grid-cols-2 min-[550px]:flex min-[550px]:items-center min-[550px]:justify-around md:grid md:grid-cols-2 min-[1450px]:flex min-[1450px]:items-center min-[1450px]:justify-around gap-2 w-full mt-1 mb-2">
                              {field.options.map((Option) => {
                                return (
                                  <button
                                    style={{
                                      backgroundColor:
                                        coverPaperWeight !== Option.id
                                          ? "#f6f7f8"
                                          : undefined,
                                    }}
                                    key={Option.id}
                                    onClick={(e) => {
                                      handleChange(e, field, Option)
                                      SetIDFunc(field.id)
                                    }}
                                    className={`${
                                      coverPaperWeight === Option.id
                                        ? "bg-blue-100 border-[#d99389]"
                                        : "bg-neutral-100"
                                    } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                  >
                                    {Option.name}
                                  </button>
                                );
                              })}
                            </div>
                            <hr />
                          </>
                        )
                    )}
                  <div className="w-full">
                    {formFields.length > 0 &&
                      formFields.map(
                        (field) =>
                          field.additional_name === "choose_cover_finish" && (
                            <div className="flex flex-col md:gap-2 flex-wrap lg:gap-x-5 lg:gap-y-2 pb-3 mt-1">
                              <p className="font-semibold text-neutral-500 whitespace-nowrap">
                                {field.field_name}
                              </p>
                              <div
                                className={`${
                                  field.options.length > 3
                                    ? "grid grid-cols-3"
                                    : "flex justify-around sm:justify-normal"
                                }  overflow-hidden w-full gap-x-2 gap-y-3 mt-1`}
                              >
                                {field.options.map((colorOption) => (
                                  <div
                                    className={`${
                                      field.options.length > 3
                                        ? ""
                                        : "max-w-[160px]"
                                    } w-full flex flex-col gap-y-[3px]`}
                                  >
                                    <button
                                      key={colorOption.id}
                                      onClick={(e) => {
                                        handleChange(e, field, colorOption);
                                        SetIDFunc(field.id)
                                        if(!(colorOption.name === 'Gloss Lamination' || colorOption.name === 'Matte Lamination')) { 
                                          toggleFoilingCheckbox()
                                        }
                                      }}
                                      className={`${
                                        chooseFinishingID === colorOption.id &&
                                        "border-[#d99389]"
                                      } w-full rounded-md p-1 md:p-[2px] lg:p-1 border-2 flex flex-col items-start hover:border-[#d99389]/70 focus:outline-none duration-200`}
                                    >
                                      <div className="lg:rounded-md rounded-md md:rounded-t-sm overflow-hidden border">
                                        <img
                                          src={finishingColorImages[colorOption.name]}
                                          key={finishingColorImages[colorOption.name]}
                                          alt="cover-finish"
                                          className="object-cover"
                                        />
                                      </div>
                                    </button>
                                    <p className="text-center px-2 md:text-start lg:text-center text-sm md:text-xs lg:text-sm font-semibold md:font-normal w-full text-neutral-600">
                                      {colorOption.name}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                      )}
                    <hr />
                  </div>
                  <div className="w-full">
                    {formFields.length > 0 &&
                      formFields.map(
                        (field) =>
                          field.additional_name == "choose_endpaper_color" && (
                            <div className="flex flex-col md:gap-3 flex-wrap mt-4">
                              <p className="font-semibold text-neutral-500 whitespace-nowrap">
                                {field.field_name}
                              </p>
                              <div className="flex items-center justify-start lg:justify-end gap-1 pb-2 mb-2 md:gap-2">
                                <div className="border-2 rounded-xl flex-1 p-2">
                                  <div className="flex flex-col customScroll items-center max-h-[180px] h-full overflow-auto pr-2 gap-y-2">
                                    {field.options.map((Option) => {
                                      return (
                                        <button
                                          key={Option.id}
                                          onClick={(e) => {
                                            handleChange(e, field, Option);
                                            handleEndColorChange(Option.name);
                                            SetIDFunc(field.id)
                                          }}
                                          className={`${
                                            endpaperHighL === Option.name
                                              ? "bg-blue-100 border-[#d99389]"
                                              : "bg-neutral-100"
                                          } font-semibold whitespace-nowrap text-start text-sm lg:text-base text-neutral-500 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200 w-full px-4 md:px-3 lg:px-4 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                        >
                                          {Option.name}
                                        </button>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="flex justify-center items-center p-1 rounded-xl overflow-hidden border-2 flex-1">
                                  <img
                                    src={endpaperColorImage}
                                    key={endpaperColorImage}
                                    alt="endpaper"
                                    className="rounded-lg"
                                  />
                                </div>
                              </div>
                              <hr />
                            </div>
                          )
                      )}
                  </div>
                  {additionalName !== "hardcover_books" &&
                    additionalName !== "share_&_sell" && (
                      <>
                        <label
                          className={`flex items-center font-semibold text-neutral-500 text-[17px] relative mt-2 md:mt-3 ${
                            !(
                              enableFoiling === "Gloss Lamination" ||
                              enableFoiling === "Matte Lamination"
                            )
                              ? "opacity-50 cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                        >
                          <input
                            type="checkbox"
                            className="absolute opacity-0 w-0 h-0"
                            onChange={toggleFoilingCheckbox}
                            disabled={
                              !(
                                enableFoiling === "Gloss Lamination" ||
                                enableFoiling === "Matte Lamination"
                              )
                            }
                          />
                          <span
                            className={`inline-block h-6 w-6 rounded border-2 transition-all duration-300 mr-2 ${
                              isFoilingChecked &&
                              (enableFoiling === "Gloss Lamination" ||
                                enableFoiling === "Matte Lamination")
                                ? "bg-[#d99389] border-[#d99389]"
                                : "bg-white border-gray-300"
                            } ${
                              !(
                                enableFoiling === "Gloss Lamination" ||
                                enableFoiling === "Matte Lamination"
                              )
                                ? "opacity-50"
                                : ""
                            }`}
                          >
                            {isFoilingChecked && (
                              <span className="absolute top-1.5 left-2 w-1.5 h-3 rotate-45 border-t-0 border-l-0 border-white border-2"></span>
                            )}
                          </span>
                          Add Cover Foiling
                        </label>
                        <p
                          className={`text-red-500 font-normal ${
                            !(
                              enableFoiling === "Gloss Lamination" ||
                              enableFoiling === "Matte Lamination"
                            )
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          Only Available With Matte or Gloss Lamination
                        </p>
                      </>
                    )}
                  {isFoilingChecked &&
                    (enableFoiling === "Gloss Lamination" ||
                      enableFoiling === "Matte Lamination") &&
                    formFields.length > 0 &&
                    formFields.map(
                      (field) =>
                        field.additional_name == "add_cover_foiling" && (
                          <div className="flex items-center border rounded p-2 md:rounded-md justify-around gap-[10px] mt-1 mb-2">
                            <div className="flex flex-col gap-y-2 flex-1">
                              {field.options.map((Option) => {
                                return (
                                  <button
                                    key={Option.id}
                                    onClick={(e) => {
                                      handleChange(e, field, Option);
                                      handleFoilingColorChange(Option.name);
                                      SETAloneID('foiling', field.id)
                                      SetIDFunc(field.id)
                                    }}
                                    className={`${
                                      foilingHighL === Option.name
                                        ? "bg-blue-100 border-[#d99389]"
                                        : "bg-neutral-100"
                                    } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                  >
                                    {Option.name}
                                  </button>
                                );
                              })}
                            </div>
                            <div className="flex justify-center items-center p-1 rounded-xl overflow-hidden border-2 flex-1">
                              <img
                                src={settedFoilingImage}
                                key={settedFoilingImage}
                                alt="paper-type"
                                className="rounded-lg"
                              />
                            </div>
                          </div>
                        )
                    )}
                  {additionalName === "hardcover_books" && (
                    <label className="flex items-center font-semibold mt-4 text-neutral-500 text-[17px] cursor-pointer relative">
                      <input
                        type="checkbox"
                        className="absolute opacity-0 w-0 h-0"
                        onChange={() => toggleCheckbox("duskJacket")}
                      />
                      <span
                        className={`inline-block h-6 w-6 rounded border-2 transition-all duration-300 mr-2 ${
                          isChecked.duskJacket
                            ? "bg-[#d99389] border-[#d99389]"
                            : "bg-white border-gray-300"
                        }`}
                      >
                        {isChecked.duskJacket && (
                          <span className="absolute top-1.5 left-2 w-1.5 h-3 rotate-45 border-t-0 border-l-0 border-white border-2"></span>
                        )}
                      </span>
                      Add Dust Jacket
                    </label>
                  )}

                  {isChecked.duskJacket && (
                    <div className="flex flex-col w-full gap-2 font-semibold text-neutral-500 mt-2">
                      {formFields.length > 0 &&
                        formFields.map(
                          (field) =>
                            field.additional_name == "dust_jacket_colors" && (
                              <div className="flex-col w-full">
                                <span>{field.field_name}</span>
                                <div className="flex items-center justify-start gap-2 w-full mt-1 mb-2">
                                  {field.options.map((Option) => {
                                    return (
                                      <button
                                        key={Option.id}
                                        onClick={(e) => {
                                          handleChange(e, field, Option);
                                          SETAloneID('duskJacket', field.id)
                                        }}
                                        className={`${
                                          jacketColorID === Option.name
                                            ? "bg-blue-100 border-[#d99389]"
                                            : "bg-neutral-100"
                                        } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                      >
                                        {Option.name}
                                      </button>
                                    );
                                  })}
                                </div>
                              </div>
                            )
                        )}
                      {formFields.length > 0 &&
                        formFields.map(
                          (field) =>
                            field.additional_name ==
                              "choose_dust_jacket_finish" && (
                              <div className="flex flex-col md:gap-2 flex-wrap lg:gap-x-5 lg:gap-y-2 pb-3 mt-1">
                                <p className="font-semibold text-neutral-500 whitespace-nowrap">
                                  {field.field_name}
                                </p>
                                <div
                                  className={`${
                                    field.options.length > 3
                                      ? "grid grid-cols-3"
                                      : "flex justify-around sm:justify-normal"
                                  }  overflow-hidden w-full gap-x-2 gap-y-3 mt-1`}
                                >
                                  {field.options.map((colorOption) => (
                                    <div
                                      className={`${
                                        field.options.length > 3
                                          ? ""
                                          : "max-w-[160px]"
                                      } w-full flex flex-col gap-y-[3px]`}
                                    >
                                      <button
                                        key={colorOption.id}
                                        onClick={(e) => {
                                          handleChange(e, field, colorOption);
                                          SETAloneID('jacketFinish', field.id)
                                        }}
                                        className={`${
                                          jacketFinishID === colorOption.id &&
                                          "border-[#d99389]"
                                        } w-full rounded-md p-1 md:p-[2px] lg:p-1 border-2 flex flex-col items-start hover:border-[#d99389]/70 focus:outline-none duration-200`}
                                      >
                                        <div className="lg:rounded-md rounded-md md:rounded-t-sm overflow-hidden border">
                                          <img
                                            src={jacketFinishImages[colorOption.name]}
                                            key={jacketFinishImages[colorOption.name]}
                                            alt="Staple"
                                            className="object-cover"
                                          />
                                        </div>
                                      </button>
                                      <p className="text-center px-2 md:text-start lg:text-center text-sm md:text-xs lg:text-sm font-semibold md:font-normal w-full text-neutral-600">
                                        {colorOption.name}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  )}

                  {additionalName !== "share_&_sell" && (
                    <label className="flex items-center font-semibold mt-4 text-neutral-500 text-[17px] cursor-pointer relative">
                      <input
                        type="checkbox"
                        className="absolute opacity-0 w-0 h-0"
                        onChange={() => toggleCheckbox("bundling")}
                      />
                      <span
                        className={`inline-block h-6 w-6 rounded border-2 transition-all duration-300 mr-2 ${
                          isChecked.bundling
                            ? "bg-[#d99389] border-[#d99389]"
                            : "bg-white border-gray-300"
                        }`}
                      >
                        {isChecked.bundling && (
                          <span className="absolute top-1.5 left-2 w-1.5 h-3 rotate-45 border-t-0 border-l-0 border-white border-2"></span>
                        )}
                      </span>
                      Add bundling
                    </label>
                  )}

                  {isChecked.bundling &&
                    formFields.length > 0 &&
                    formFields.map(
                      (field) =>
                        field.additional_name == "bundling" && (
                          <div className="flex items-center border rounded p-2 md:rounded-md justify-around gap-[10px] mt-1 mb-2">
                            <div className="flex flex-col gap-y-2 flex-1">
                              {field.options.map((Option) => {
                                return (
                                  <button
                                    key={Option.id}
                                    onClick={(e) => {
                                      handleChange(e, field, Option);
                                      handleBundling(Option.name);
                                      SETAloneID('bundling', field.id)
                                      SetIDFunc(field.id)
                                    }}
                                    className={`${
                                      BundlingHighL === Option.name
                                        ? "bg-blue-100 border-[#d99389]"
                                        : "bg-neutral-100"
                                    } font-medium text-neutral-600 rounded-md border lg:border-2 hover:border-[#d99389]/70 focus:outline-none duration-200  px-1 w-full lg:px-2 py-2 focus:bg-blue-100 hover:bg-neutral-200/80`}
                                  >
                                    {Option.name}
                                  </button>
                                );
                              })}
                            </div>
                            <div className="flex justify-center items-center p-1 rounded-xl overflow-hidden border-2 flex-1">
                              <img
                                src={shrinkWrap}
                                alt="paper-type"
                                className="rounded-lg"
                              />
                            </div>
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          )}
          {showSticky && (
            <div
              onClick={focusPriceDetails}
              className="sticky -mx-4 sm:-mx-6 cursor-pointer md:hidden bg-neutral-600 bottom-0 border-2 border-[#d99389] rounded-t-2xl"
            >
              <div className="flex items-center justify-center w-full py-4">
                <p className="text-lg text-white font-semibold">{totalPrice}</p>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col item-center w-full sm:w-5/6 min-[710px]:w-3/4 md:w-[45%] min-[1600px]:max-w-[515px]">
          <div className="bg-white  px-4 md:px-2 lg:px-4 pt-4 md:pt-2 lg:pt-4 rounded-t-3xl md:rounded-t-3xl">
            <div className="hidden md:flex justify-center">
              {slides.length ? (
                <div className="w-full max-h-[500px] rounded-t-2xl overflow-hidden">
                  <img
                    src={slides[currentIndex]}
                    alt="product"
                    className="h-full"
                  />
                </div>
              ) : (
                <div className="w-full h-48 sm:h-64 md:h-80 lg:h-96">
                  <Skeleton width="100%" height="100%" />
                </div>
              )}
            </div>
          </div>
          <div className="md:sticky top-0 z-10">
            <div className="bg-white px-4 md:px-2 lg:px-4 pb-4 md:pb-4 lg:pb-6 rounded-b-3xl md:rounded-b-3xl">
              <div ref={priceDetailsRef} className="pb-2 md:py-2">
                <div className="bg-[#4b5664] px-4 pb-4 pt-3 lg:p-4 rounded-xl md:rounded-md flex flex-col mt-2">
                  <span className="text-lg lg:text-[15px] text-white">
                    Print Turn Around Date:
                  </span>
                  <div className="flex gap-[10px] sm:gap-3 justify-between">
                    <div className="flex flex-col lg:gap-2 text-sm lg:text-[15px] text-[#ccc]">
                      <p className="mb-[10px] text-white lg:text-[21px]">
                        {currentEasternTime}
                      </p>
                      <p className="text-[#00b4aa]">High Quality Digital Printing</p>
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="text-white lg:text-[21px]">
                        $ {totalPrice}
                      </span>
                      {eachPrice && (
                        <p
                          style={{ marginTop: "-6px", color: "rgb(137 137 137)" }}
                          className="text-lg font-bold mb-4"
                        >
                          <span className="text-sm">( ${eachPrice} for each )</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-lg">Design Option</span>
                <div className="flex items-center gap-8 mt-[6px]">
                  <button
                    onClick={() => handleCustomize("upload-artwork")}
                    className={`${
                      selectedDesign == "upload-artwork" && "border-[#d99389]"
                    } rounded-md p-1 lg:p-2 border-2 hover:border-[#d99389]/70 focus:border-[#d99389] focus:outline-none duration-200`}
                  >
                    <div className="min-w-[60px] max-w-[160px] w-full rounded-md overflow-hidden border">
                      <img
                        src={perfect}
                        alt="PUR"
                        className="w-full object-cover"
                      />
                    </div>
                    <p className="mt-1 text-sm font-semibold text-neutral-500">
                      Upload Artwork
                    </p>
                  </button>
                  <button
                    onClick={() => handleCustomize("personalized-design")}
                    className={`${
                      selectedDesign === "personalized-design" &&
                      "border-[#d99389]"
                    } rounded-md p-1 lg:p-2 border-2 hover:border-[#d99389]/70 focus:border-[#d99389] focus:outline-none duration-200`}
                  >
                    <div className="min-w-[60px] max-w-[160px] w-full rounded-md overflow-hidden border">
                      <img
                        src={wiro}
                        alt="PUR"
                        className="w-full object-cover"
                      />
                    </div>
                    <p className="mt-1 text-sm font-semibold text-neutral-500">
                      Design Online
                    </p>
                  </button>
                </div>
              </div>
              <div className="w-full pt-4">
                {selectedDesign === "upload-artwork" && (
                  <button
                    onClick={addTocart}
                    className="inline-flex justify-center items-center gap-2 bg-orange-300 text-black w-full text-center px-5 py-3 duration-200 hover:bg-orange-400/80 rounded-md"
                  >
                    <FaShoppingCart /> Add To Cart
                  </button>
                )}
                {selectedDesign === "personalized-design" && (
                  <button
                    className="inline-flex justify-center items-center gap-2 bg-orange-300 text-black w-full text-center px-5 py-3 duration-200 hover:bg-orange-400/80 rounded-md"
                  >
                    <HiMiniPencilSquare /> Customize Your Design
                  </button>
                )}
              </div>
              <div className="flex flex-col sm:mb-1 md:mb-0 mt-4 bg-[#f9f9f9] p-3 rounded-md border border-[#ddd] text-[#3f3f3f]">
                <h2 className="text-black text-lg mb-1">
                  Have a Question? We're Here to Help!
                </h2>
                <p
                  className="text-[15px] sm:leading-5
              min-[1680px]:text-lg"
                >
                  If you can't find what you're looking for, our team is
                  available from{" "}
                  <span className="text-black">10:00 AM to 7:00 PM EST</span> to
                  assist you. Feel free to reach out via email at{" "}
                  <a
                    className="text-blue-600"
                    href="mailto:orders@stalbansprinting.com"
                  >
                    orders@stalbansprinting.com
                  </a>{" "}
                  or give us a call at 
                  <a className="text-blue-600" href="tel:718-528-5100">
                    718-528-5100
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="px-5 md:px-[8%] mb-8 sm:mb-10 md:mb-14">
        <div className="mb-5 sm:mb-7 md:mb-8 flex gap-3 items-center justify-center">
          <p className="w-6 sm:w-10 h-[1.5px] bg-[#3f3f3f]"></p>
          <h1 className="text-2xl md:text-[26px] lg:text-3xl xl:text-4xl text-center font-semibold text-[#3f3f3f]">
            You May Also Like
          </h1>
          <p className="w-6 sm:w-10 h-[1.5px] bg-[#3f3f3f]"></p>
        </div>
        <div>
          <Swiper
            spaceBetween={10}
            centeredSlides={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            slide
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 6,
              },
            }}
          >
            {products &&
              products.map((product) => (
                <SwiperSlide key={product.id}>
                  <div
                    onClick={() => productID(product.id)}
                    className="relative flex flex-col items-center justify-center border cursor-pointer"
                  >
                    <div className="w-full border relative overflow-hidden bg-transparent">
                      <img
                        className="w-full h-full transition-transform duration-200 ease-in-out hover:scale-125"
                        src={product.image_file}
                        alt={product.image_name}
                      />
                    </div>
                    <div className="mt-5 border-t w-full">
                      <div className="flex py-3 px-1 sm:px-2 justify-center items-center text-gray-700 gap-1 bg-gray-100 border">
                        <FaEye />
                        <button>View Detail</button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default BookletsDesign;
