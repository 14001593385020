import React from 'react';
import { decryptToken, getCookie } from '../components/Helper/Helpers'


const AdminPrivateRoute = ({ component: Component, ...rest }) => {
    const decryptedRefreshToken = decryptToken(getCookie('_prtsap'));
    const decryptedAccessToken = decryptToken(getCookie('_patsap'));
    const decryptedUserRole = getCookie('_prsap');
    return decryptedRefreshToken && decryptedAccessToken && decryptedUserRole === 'admin' ? <Component {...rest} /> : window.location.pathname = '/admin';
}

export default AdminPrivateRoute;
