import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const CONNECTION_REFUSED = "connection refused";

export const printingServices = [
    'Banners',
    'Bookmarks', 
    'Book Printing', 
    'Brochures', 
    'Business Cards', 
    'Buttons', 
    'Calendars', 
    'Car Magnets', 
    'Canvas Printing & Giclee', 
    'Customized Greeting Cards', 
    'E-Journal', 
    'Event Journal', 
    'Flyers', 
    'Funeral Buttons', 
    'Journals', 
    'Logo Designing' 
]

export const memorialFuneral = {
    'wqs': 'asAS'
}

export const memorialFuneralMenus = {
    'bookMarks': 'bookmarks',
    'memorialCollege': 'memorial_collages',
    'prayerCards': 'prayer_cards',
    'memorialPosters': 'memorial_posters',
    'funeralButton': 'funeral_buttons',
    'masks': 'mask',
    'memorialTShirt': 'memorial_t_shirts',
    'thankyouCards': 'thankyou_cards',
    'gateFold': 'gate_fold',
    'gateFoldBooklet': 'gate_fold_booklet',
    'graduatedStepSmall': 'graduated_step_small',
    'indexSmall': 'index_small',
    'obituaryLargePanel': 'obituary_large_panel',
    'obituaryLargeBooklets': 'obituary_large_booklets',
    'obituaryLargeSinglefold': 'obituary_large_singlefold',
    'obituarySmallPanel': 'obituary_small_panel',
    'obituarySmallBooklets': 'obituary_small_booklets',
    'obituarySmallSinglefold': 'obituary_small_singlefold'
}

// export const decryptToken = (encryptedData) => {
//     console.log('encryptedDataencryptedDataencryptedData', encryptedData)
//     if (!encryptedData) {
//         return;
//     }
//     const [ivHex, ciphertext, originalHmac] = encryptedData.split(':');
//     const iv = CryptoJS.enc.Hex.parse(ivHex);
//     const key = CryptoJS.PBKDF2(SECRET_KEY, iv, { keySize: 256 / 32, iterations: 1000 });
//     const newHmac = CryptoJS.HmacSHA256(ciphertext, key);
//     if (originalHmac !== newHmac.toString(CryptoJS.enc.Hex)) {
//       return;
//     }
//     const decrypted = CryptoJS.AES.decrypt(ciphertext, key, { iv });
//     return decrypted.toString(CryptoJS.enc.Utf8);
// };

export const encryptToken = (token) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(SECRET_KEY, iv, { keySize: 256 / 32, iterations: 1000 });
  const encrypted = CryptoJS.AES.encrypt(token, key, { iv });
  const hmac = CryptoJS.HmacSHA256(encrypted.toString(), key);
  return `${iv.toString(CryptoJS.enc.Hex)}:${encrypted.toString()}:${hmac.toString(CryptoJS.enc.Hex)}`;
};

export const decryptToken = (encryptedData) => {
    try {
        if (!encryptedData) return;
        const [ivHex, ciphertextB64, originalHmac] = encryptedData.split(':');
        const iv = CryptoJS.enc.Hex.parse(ivHex);
        const ciphertext = CryptoJS.enc.Base64.parse(ciphertextB64);
        const key = CryptoJS.PBKDF2(SECRET_KEY, iv, {
            keySize: 256 / 32, // 32 bytes = 256 bits
            iterations: 1000,
            hasher: CryptoJS.algo.SHA256,
        });
        const newHmac = CryptoJS.HmacSHA256(ciphertext, key).toString(CryptoJS.enc.Hex);
        if (originalHmac !== newHmac) {
            console.error("HMAC verification failed.");
            return null;
        }
        const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        return null;
    }
    
}

export const getCookie = (name) => {
    try {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [key, value] = cookie.split('=');
            if (key === name) return decodeURIComponent(value);
        }
        return null;
    } catch (error) {
        return null;
    }
};

export const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    console.log('expiresexpiresexpiresexpires', expires)
    document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/; Secure; SameSite=Strict`;
};
