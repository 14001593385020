import React, { useState, useEffect, useRef } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn, FaPinterest, FaRegStar, FaShoppingCart, FaStar } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import DescriReview from '../components/ShopDetails/DescriReview';
import ProductSlide from '../components/ShopDetails/ProductSlide';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { GetCategoryImagesAPI, getCategoryMenuData, addCartDetails, paperTypeSelectedOption ,getfieldName} from '../serverApi/server';
import { memorialFuneralMenus } from '../components/Helper/Helpers'
import { FaEye, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import Skeleton from './skeleton/Skeleton';
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


import { HiMiniPencilSquare } from 'react-icons/hi2';
import paperType from '../assets/NewFormAsset/1 (1).png';
import paperWeight from '../assets/NewFormAsset/1.png';
import perfect from '../assets/NewFormAsset/PUR.png';
import wiro from '../assets/NewFormAsset/WIRO.png';
import spiral from '../assets/NewFormAsset/COIL.png';
import blackColor from '../assets/NewFormAsset/BLACK.png'
import whiteColor from '../assets/NewFormAsset/WHITE.png'
import silverColor from '../assets/NewFormAsset/SILVER.png'
import redColor from '../assets/NewFormAsset/RED.png'
import { IoConstructOutline } from 'react-icons/io5';


const ShopDetails = () => {
  const { id } = useParams();
  const [slides, setSlides] = useState([]);
  const [subTitle, setSubTitle] = useState('');
  const [formFields, setFormFields] = useState([]);
  const [isFieldValidation, setisFieldValidation] = useState(false)
  const [errorResponse, setErrorResponse] = useState('')
  const navigate = useNavigate();
  const [additionalName, setAdditionalName] = useState();
  const [fieldValidation, setFieldValidation] = useState(false);
  const [fieldSize, setFieldSize] = useState('');
  const [quantityId, setQuantityId] = useState('');
  const [photosDetails, setPhotosDetails] = useState('');
  const [sizePaperType, setSizePaperType] = useState({});
  const [categoryPermissionFields, setCategoryPermissionFields] = useState();
  const [selectedFormFieldsOptions, setSelectedFormFieldsOptions] = useState({});
  const [products, setProducts] = useState('');
  const [priceValidation, setPriceValidation] = useState(false);
  const [eachPrice, setEachPrice] = useState();
  const [currentEasternTime, setCurrentEasternTime] = useState(null);
  const [numberOfPadsDetailsList, setNumberOfPadsDetailsList] = useState();
  const [carbonlessFormDiscount, setCarbonlessFormDiscount] = useState('');

  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleCalendarClick = () => {
    setShowDatePicker(!showDatePicker);
  };


  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const handleCalendarToggle = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };


  const GetOurProductsCategoryImages = async (additionalName) => {
    var category_menu = memorialFuneralMenus[additionalName];
    try {
      const GetCategoryImagesApiResponse = await GetCategoryImagesAPI(additionalName);
      if (GetCategoryImagesApiResponse.status === 200 && GetCategoryImagesApiResponse.data && GetCategoryImagesApiResponse.data.category_serializer.length !== 0) {
        setProducts(GetCategoryImagesApiResponse.data.category_serializer.slice(0, 8));
      }
    } catch (error) {
      console.error('Error fetching Category Slides:', error);
    }
  };

  const [openFieldTip, setOpenFieldTip] = useState(null);
  const fieldTipRef = useRef();
  const fieldTipButtonRef = useRef();

  const handleToggleFieldTip = (e, fieldName) => {
    e.preventDefault();
    console.log('Button trickered');
    setOpenFieldTip((prev) => (prev === fieldName ? null : fieldName));
  }

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        fieldTipRef.current &&
        !fieldTipRef.current.contains(e.target) &&
        fieldTipButtonRef.current &&
        !fieldTipButtonRef.current.contains(e.target)
      ) {
        setOpenFieldTip(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const fetchFormFields = async () => {
      try {
        const formFields = await getfieldName();
        setFormFields(formFields);
      } catch (error) {
        console.error('Error fetching form fields:', error);
      }
    };

    fetchFormFields();
  }, []);



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await getCategoryMenuData(id);
  //       if (data?.category_additional_name) {
  //         setAdditionalName(data?.category_additional_name);
  //         await GetOurProductsCategoryImages(data?.category_additional_name)
  //       }
  //       if (data?.category_menu_serilizer?.image_file) {
  //         setSlides([data.category_menu_serilizer.image_file]);
  //       }
  //       if (data?.category_name) {
  //         setSubTitle(data.category_name);
  //       }
  //       if (data?.form_fields_serializer) {
  //         const numberOfPadsField = data.form_fields_serializer.find(
  //           field => field.additional_name === "number_of_pads"
  //         );

  //         const numberOfPadsDetailsList = numberOfPadsField?.options || [];
  //         setNumberOfPadsDetailsList(numberOfPadsDetailsList)
  //         setFormFields(data.form_fields_serializer);
  //         if (data.form_fields_serializer.length === 0) {
  //           setisFieldValidation(true)
  //         }
  //         const photosDataDetails = Object.values(data.form_fields_serializer).find(option => option.additional_name === "photos");
  //         if (photosDataDetails) {
  //           setPhotosDetails(photosDataDetails)
  //         }
  //       }
  //       if (data?.category_permission_fields) {
  //         setCategoryPermissionFields(data.category_permission_fields);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData();
  // }, [id]);





  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCategoryMenuData(id);
        if (data?.category_additional_name) {
          setAdditionalName(data?.category_additional_name);
          await GetOurProductsCategoryImages(data?.category_additional_name);
        }
        if (data?.category_menu_serilizer?.image_file) {
          setSlides([data.category_menu_serilizer.image_file]);
        }
        if (data?.category_name) {
          setSubTitle(data.category_name);
        }
        if (data?.form_fields_serializer) {

          const numberOfPadsField = data.form_fields_serializer.find(
            field => field.additional_name === "number_of_pads"
          );
          const numberOfPadsDetailsList = numberOfPadsField?.options || [];
          setNumberOfPadsDetailsList(numberOfPadsDetailsList)

          const sortedFields = [...data.form_fields_serializer].sort((a, b) => {
            if (a.position_field_id == null) return 1;
            if (b.position_field_id == null) return -1;
            return a.position_field_id - b.position_field_id;
          });

          sortedFields.forEach(field => {
            if (field.options && field.options.length > 0) {
              field.options.sort((optionA, optionB) => {
                if (optionA.position_option_id == null) return 1;
                if (optionB.position_option_id == null) return -1;
                return optionA.position_option_id - optionB.position_option_id;
              });
            }
          });
          setFormFields(sortedFields);
          if (sortedFields.length === 0) {
            setisFieldValidation(true);
          }
          // Find the "photos" field and set it
          const photosDataDetails = Object.values(sortedFields).find(
            option => option.additional_name === "photos"
          );
          if (photosDataDetails) {
            setPhotosDetails(photosDataDetails);
          }
        }
        if (data?.category_permission_fields) {
          setCategoryPermissionFields(data.category_permission_fields);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id]);






  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const [formData, setFormData] = useState({});
  const [totalPrice, setTotalPrice] = useState(0.00);
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [permissionFieldId, setPermissionFieldId] = useState('')
  const paperTypeSelectedOptionAPI = async (selectedOption) => {
    try {
      const paperTypeSelectedOptionAPIResponse = await paperTypeSelectedOption(selectedOption);
      const serializer = paperTypeSelectedOptionAPIResponse.data.paper_type_field_amount_serializer;
      const updatedFormFields = formFields.map((field) => {
        if (field.additional_name === "quantity" || field.additional_name === "Quantity") {
          const updatedOptions = field.options.map((option) => {
            const match = serializer.find((item) => item.option === option.id);
            if (match) {
              if (match.option == quantityId) {
                const quantityOption = Object.values(selectedOptions).find(option => option.id === match.option);
                setSelectedOptions((prevOptions) => {
                  const updatedOptions = { ...prevOptions };
                  const matchKey = Object.keys(updatedOptions).find(
                    (key) => updatedOptions[key].id === match.option
                  );
                  if (matchKey) {
                    setPermissionFieldId(match.id)
                    updatedOptions[matchKey] = {
                      ...updatedOptions[matchKey],
                      amount: match.amount,
                    };
                  }
                  return updatedOptions;
                });
              }
              return {
                ...option,
                amount: match.amount,
                permissionFieldId: match.id,
              };
            }
            setSelectedOptions((prevOptions) => {
              const updatedOptions = { ...prevOptions };
              Object.keys(updatedOptions).forEach((key) => {
                const currentOption = updatedOptions[key];
                if (currentOption.id === option.id && currentOption.category_additional_name === additionalName) {
                  currentOption.amount = '';
                }
              });
              return updatedOptions;
            });
            return {
              ...option,
              amount: '',
            };
          });
          return {
            ...field,
            options: updatedOptions,
          };
        }
        return field;
      });
      setFormFields(updatedFormFields);
    } catch (error) {
      console.error('Error fetching sub-menus:', error);
    }
  };

  const findMatchingOption = (value, options) => {
    const matchedOption = options.find((option) => {
      var optionName = option.name.replace(/\s/g, '');
      const parts = option.name.split('to');
      if (parts.length !== 2) {
        return false;
      }
      const [start, end] = parts.map((n) => {
        const trimmed = n.trim();
        const num = Number(trimmed);
        return isNaN(num) ? null : num;
      });

      return start !== null && end !== null && value >= start && value <= end;
    });

    return matchedOption || null;
  }

  const [numbering, setNumbering] = useState(false)
  const [numberingInk, setNumberingInk] = useState(false);
  const [padding, setPadding] = useState(false);
  const [numberOfSet, setNumberOfSet] = useState(false);
  const [perNumberOfPads, setPerNumberOfPads] = useState([]);
  const [numberOfPadsOption, setNumberOfPadsOption] = useState(null)
  const [ticketPaperColor, setTicketPaperColor] = useState(false);
  const [envelopeSize , setEnvelopeSize] = useState(false);
  const [colorCopiesSellSheets , setColorCopiesSellSheets] = useState(false);
  const [numberOfPads, setNumberOfPads] = useState(0)
  const [brouchers, setBrouchers] = useState(false);

  const handleChange = (e, fieldId, fieldName) => {
    var { name, value, options, selectedIndex } = e.target;
    const selectedId = options?.[selectedIndex]?.getAttribute('data-id') || '';
    if (name === 'Size' || name === 'Paper Type') {
      setSizePaperType((prev) => ({
        ...prev,
        [name]: selectedId,
      }));
    }

    const field = formFields.find(f => f.id === fieldId);

    if (additionalName === 'carbonless_forms' && field.additional_name === 'sets_per_pad') {
      setNumberOfPadsOption(value)
      if (parseInt(value) === 25) {

        // var numberOfPadsDetails = formFields.find(field =>field.additional_name === "number_of_pads").options
        // console.log('\n numberOfPadsDetailsnumberOfPadsDetailsnumberOfPadsDetails', numberOfPadsDetails)
        var dataFirst = [10, 20, 40, 60, 80, 100, 120, 160, 200, 240, 280, 320, 360, 400]

        const matchedOptions = numberOfPadsDetailsList.filter(item =>
          dataFirst.includes(parseInt(item.name))
        );

        // Update the form fields
        setFormFields(prevFields =>
          prevFields.map(field =>
            field.additional_name === "number_of_pads"
              ? { ...field, options: matchedOptions }
              : field
          )
        );

        // console.log('Updated Options:', updatedOptions);

      } else if (parseInt(value) === 50) {
        // var numberOfPadsDetails = formFields.find(field =>field.additional_name === "number_of_pads").options
        // console.log('\n numberOfPadsDetailsnumberOfPadsDetailsnumberOfPadsDetails', numberOfPadsDetails)
        var dataSecond = [5, 10, 20, 30, 40, 50, 60, 80, 100, 120, 140, 160, 180, 200]

        const matchedOptions = numberOfPadsDetailsList.filter(item =>
          dataSecond.includes(parseInt(item.name))
        );
        // Update the form fields
        setFormFields(prevFields =>
          prevFields.map(field =>
            field.additional_name === "number_of_pads"
              ? { ...field, options: matchedOptions }
              : field
          )
        );

      } else if (parseInt(value) === 100) {
        // var numberOfPadsDetails = formFields.find(field =>field.additional_name === "number_of_pads").options
        // console.log('\n numberOfPadsDetailsnumberOfPadsDetailsnumberOfPadsDetails', numberOfPadsDetails)
        var dataThird = [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100]

        const matchedOptions = numberOfPadsDetailsList.filter(item =>
          dataThird.includes(parseInt(item.name))
        );

        // Update the form fields
        setFormFields(prevFields =>
          prevFields.map(field =>
            field.additional_name === "number_of_pads"
              ? { ...field, options: matchedOptions }
              : field
          )
        );

      }
    }

    if (field.quantity_calculation === true && field.additional_name === 'quantity') {
      if (value === '') {
        setFormData((prev) => ({
          ...prev,
          [name]: '',
        }));
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          [fieldId]: null,
        }));
        setSelectedIds((prevIds) => prevIds.filter((id) => id !== fieldId));
        setTotalPrice(parseFloat(0.00).toFixed(2))
        return;
      }
      value = Math.max(1, Math.min(10000, Number(value) || 0));
      var selectedOption = findMatchingOption(value, field.options);
    } else {
      var selectedOption = field?.options?.find(option => option.name === value);
      if (fieldName.additional_name === 'print_turn_around') {
        const match = selectedOption.name.match(/^\d+/);
        var days = match ? parseInt(match[0], 10) : 0;
        var currentOptionDay = match ? parseInt(match[0], 10) : 0;
        const currentDateInEST = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
        const [currentDate, currentTime] = currentDateInEST.split(", ");
        const [hours, minutes, meridian] = currentTime.match(/(\d+):(\d+):\d+ (\w+)/).slice(1);
        const currentHourIn24 = meridian === "PM" && hours !== "12" ? parseInt(hours, 10) + 12 : parseInt(hours, 10);
        const currentMinutes = parseInt(minutes, 10);
        const dateObjectValue = new Date(currentDateInEST);
        const dayOfWeekDate = dateObjectValue.getDay();
        if (dayOfWeekDate !== 0 && dayOfWeekDate !== 6) {
          if (currentHourIn24 > 11 && currentHourIn24 < 24) {
            days = days + 1
          } else if (currentHourIn24 === 11 && currentMinutes > 1) {
            days = days + 1;
          }
        }

        const dateObject = new Date(currentDateInEST);
        var orderDays = days

        if (days === 0) {
          const dayOfWeek = dateObject.getDay();
          if (dayOfWeek === 0) {
            var sundayHoliday = 1 + days
            dateObject.setDate(dateObject.getDate() + sundayHoliday);
          } else if (dayOfWeek === 6) {
            var sundayHoliday = 2 + days
            dateObject.setDate(dateObject.getDate() + sundayHoliday);
          } else {
            dateObject.setDate(dateObject.getDate() + days);
          }
        } else {
          while (days > 0) {
            dateObject.setDate(dateObject.getDate() + 1);
            const dayOfWeek = dateObject.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
              days--;
            }
          }
        }
        var weekEndOrderDate = new Date(currentDateInEST);
        var weekday = weekEndOrderDate.getDay();
        if ((orderDays !== 0) && (weekday === 0 || weekday === 6)) {
          dateObject.setDate(dateObject.getDate() + 1);
        }
        const formattedDate = dateObject.toLocaleString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
        setCurrentEasternTime(formattedDate)
        setSelectedDate(dateObject)
      }
    }

    if (selectedOption) {
      selectedOption.calculation_status = field.calculation_status || false;
      selectedOption.quantity_calculation = field.quantity_calculation || false;
    }

    if (fieldName && fieldName.field_name === 'Quantity') {
      selectedOption.category_additional_name = additionalName
      if (selectedOption && selectedOption.permissionFieldId) {
        setPermissionFieldId(selectedOption.permissionFieldId)
      }
      setFieldSize(value)
      setQuantityId(selectedOption.id)
    }
    
    if (additionalName === 'carbonless_forms' && fieldName && fieldName.additional_name === 'number_of_sets') {
      setFieldSize(value)
    } 

    if (additionalName === 'carbonless_forms' && fieldName && fieldName.additional_name === 'number_of_pads') {
      setNumberOfPads(value)
    }
  
    selectedOption.fieldName=fieldName.additional_name

    setFormData({
      ...formData,
      [name]: value,
    });
    if (categoryPermissionFields.includes(fieldId)) {
      setSelectedFormFieldsOptions(prev => ({
        ...prev,
        [name]: selectedOption,
      }));
    }
    setSelectedOptions(prevSelectedOptions => ({
      ...prevSelectedOptions,
      [fieldId]: selectedOption,
    }));
    setSelectedIds(prevIds => {
      if (!prevIds.includes(fieldId)) {
        return [...prevIds, fieldId];
      }
      return prevIds;
    });






    if (fieldName.additional_name === 'numbering' && selectedOption.name === 'Numbering') {
      setNumbering(true)
    } else if (fieldName.additional_name === 'numbering' && selectedOption.name === 'No Numbering') {
      setNumbering(false)


      const ids = formFields
        .filter(field =>
          field.additional_name === 'numbering_ink' ||
          field.additional_name === 'starting_number' ||
          field.additional_name === 'numbering_location'
        )
        .map(field => field.id);
      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setSelectedIds(prevIds => {
        const updatedIds = prevIds.filter(id => !ids.includes(id));
        return updatedIds;
      });
      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });


    }






    if (fieldName.additional_name === 'numbering_ink' && selectedOption.name !== "None") {
      setNumberingInk(true)
    } else if (fieldName.additional_name === 'numbering_ink' && selectedOption.name === "None") {
      setNumberingInk(false)
      const ids = formFields
        .filter(field =>
          field.additional_name === 'starting_number' ||
          field.additional_name === 'numbering_location'
        )
        .map(field => field.id);
      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setSelectedIds(prevIds => {
        const updatedIds = prevIds.filter(id => !ids.includes(id));
        return updatedIds;
      });
      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });
    }



    if (fieldName.additional_name === 'padding' && selectedOption.name !== "Not Padded (Loose Sets)") {
      setNumberOfSet(false)
      setPadding(true)


      const ids = formFields
        .filter(field =>
          field.additional_name === "number_of_sets"
        )
        .map(field => field.id);

      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setSelectedIds(prevIds => {
        const updatedIds = prevIds.filter(id => !ids.includes(id));
        return updatedIds;
      });

      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });

    } else if (fieldName.additional_name === 'padding' && selectedOption.name === "Not Padded (Loose Sets)") {
      setPadding(false)
      setNumberOfSet(true)
      setPerNumberOfPads([])

      const ids = formFields
        .filter(field =>
          field.additional_name === 'sets_per_pad' ||
          field.additional_name === 'number_of_pads' ||
          field.additional_name === 'number_of_sets'
        )
        .map(field => field.id);
      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setSelectedIds(prevIds => {
        const updatedIds = prevIds.filter(id => !ids.includes(id));
        return updatedIds;
      });

      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });

    }


    if (additionalName === 'carbonless_forms' && field.additional_name === 'sets_per_pad' || field.additional_name === 'number_of_pads') {
      setPerNumberOfPads(prevState => {
        if (!prevState.includes(field.id)) {
          return [...prevState, field.id];
        }
        return prevState;
      });

      // setPerNumberOfPads(prevState => {
      //   // Filter out the ID if it already exists
      //   const updatedState = prevState.filter(id => id !== field.id);

      //   // Add the ID to the updated state
      //   return [...updatedState, field.id];
      // });

    }
    if (additionalName === 'tickets' && fieldName.additional_name === 'color' && value === 'Full Color') {
      setTicketPaperColor(true)
      const ids = formFields
        .filter(field =>
          field.additional_name === "ticket_paper_color"
        )
        .map(field => field.id);
      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setSelectedIds(prevIds => {
        const updatedIds = prevIds.filter(id => !ids.includes(id));
        return updatedIds;
      });
      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });
    } else if (additionalName === 'tickets' && fieldName.additional_name === 'color' && value === 'Black Ink') {
      setTicketPaperColor(false)
    }

    
    if (additionalName === 'envelopes'  && fieldName.additional_name === 'size' && value === 'Tithe Envelopes 3.625  x 6.5') {
      setEnvelopeSize(true)
      const ids = formFields
        .filter(field =>
          field.additional_name === 'window' ||
          field.additional_name === 'pull_&_seal'
        )
        .map(field => field.id);
      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setSelectedIds(prevIds => {
        const updatedIds = prevIds.filter(id => !ids.includes(id));
        return updatedIds;
      });
      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });

    } else if (additionalName === 'envelopes'  && fieldName.additional_name === 'size' && value !== 'Tithe Envelopes 3.625  x 6.5') {
      setEnvelopeSize(false)
    }

    if (additionalName === 'flyer_5.5_x_8.5' && fieldName.additional_name === 'paper_type' && (value === '20lb Paper' || value === '28lb Hammermill White Paper')) {
      setColorCopiesSellSheets(true)
      const ids = formFields
        .filter(field =>
          field.additional_name === "edge_cutting"
        )
        .map(field => field.id);
      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setSelectedIds(prevIds => {
        const updatedIds = prevIds.filter(id => !ids.includes(id));
        return updatedIds;
      });
      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });
    } else if (additionalName === 'flyer_5.5_x_8.5' && fieldName.additional_name === 'paper_type' && (value !== '20lb Paper' || value !== '28lb Hammermill White Paper')) {
      setColorCopiesSellSheets(false)
    }

    if (additionalName === 'brouchers' && fieldName.additional_name ==='paper_type' && (value === '70lb Opaque Text Matt' || value === '100lb Gloss Text' || value === '65lb Matt Cover')) {
      setBrouchers(true)
      const ids = formFields
        .filter(field =>
          field.additional_name === "finish"
        )
        .map(field => field.id);
      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });
    } else if (additionalName === 'brouchers' && fieldName.additional_name ==='paper_type' && (value !== '70lb Opaque Text Matt' || value !== '100lb Gloss Text' || value !== '65lb Matt Cover')) {
      setBrouchers(false)
      const ids = formFields
        .filter(field =>
          field.additional_name === "finish"
        )
        .map(field => field.id);
      setSelectedOptions(prevSelectedOptions => {
        const updatedOptions = { ...prevSelectedOptions };
        ids.forEach(id => {
          if (updatedOptions[id]) {
            delete updatedOptions[id];
          }
        });
        return updatedOptions;
      });
      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        ids.forEach(id => {
          updatedFormData[formFields.find(field => field.id === id)?.field_name] = '';
        });
        return updatedFormData;
      });
    }

    if (additionalName === 'carbonless_forms' && field.additional_name === 'number_of_sets') {
      setCarbonlessFormDiscount(selectedOption.discount ? selectedOption.discount : 0)
    }



  };

  useEffect(() => {
    if (perNumberOfPads.length == 2) {
      const namesAsIntegers = perNumberOfPads
        .map(id => {
          const option = selectedOptions[id];
          return option ? parseInt(option.name, 10) : null;
        })
        .filter(value => !isNaN(value));

      if (namesAsIntegers.length === 2) {
        const product = namesAsIntegers[0] * namesAsIntegers[1];
        var numberOfSets = formFields.find(f => f.additional_name === 'number_of_sets')
        var numberOfSetsOption = numberOfSets.options.find(option => option.name == product);

        if (numberOfSetsOption) {
          setFieldSize(numberOfSetsOption.name)
          setFormData(prevState => {
            if (prevState[numberOfSets.field_name] !== numberOfSetsOption.name) {
              return {
                ...prevState,
                [numberOfSets.field_name]: numberOfSetsOption.name,
              };
            }
            return prevState;
          });

          setSelectedOptions(prevSelectedOptions => {
            if (prevSelectedOptions[numberOfSets.id]?.name !== numberOfSetsOption.name) {
              return {
                ...prevSelectedOptions,
                [numberOfSets.id]: numberOfSetsOption,
              };
            }
            return prevSelectedOptions;
          });

          setSelectedIds(prevIds => {
            if (!prevIds.includes(numberOfSets.id)) {
              return [...prevIds, numberOfSets.id];
            }
            return prevIds;
          });
          setNumberOfSet(true)
        }

        return;

      }
    }
  }, [perNumberOfPads, selectedOptions, formFields]);



  useEffect(() => {
    let categoryPermissionFieldsList = [];
    if (typeof categoryPermissionFields === 'string') {
      try {
        categoryPermissionFieldsList = JSON.parse(categoryPermissionFields);
      } catch (e) {
        console.error('Error parsing categoryPermissionFields:', e);
      }
    } else if (Array.isArray(categoryPermissionFields)) {
      categoryPermissionFieldsList = categoryPermissionFields;
    }
    if (categoryPermissionFieldsList.length && Object.keys(selectedFormFieldsOptions).length === categoryPermissionFieldsList.length) {
      paperTypeSelectedOptionAPI(selectedFormFieldsOptions);
    }
  }, [selectedFormFieldsOptions, categoryPermissionFields]);


  useEffect(() => {
    if ((selectedIds.length === formFields.length) || (additionalName === 'carbonless_forms' && 6 < Object.keys(selectedOptions).length ) || (additionalName === 'tickets' && Object.keys(selectedOptions).length >= 6) || (additionalName === 'envelopes' && Object.keys(selectedOptions).length >= 5) || (additionalName === 'flyer_5.5_x_8.5' && Object.keys(selectedOptions).length >= 5)) {
      setFieldValidation(false)
      const totalAmount = Object.values(selectedOptions).reduce((total, option) => {
        var optionPrice = option.amount
        if (option.calculation_status === true) {
          optionPrice = option.amount * fieldSize
        }
        if (option.quantity_calculation === true) {
          optionPrice = option.amount * fieldSize
        }
        if (additionalName === 'carbonless_forms' && option.fieldName === 'padding') {
          optionPrice = option.amount * numberOfPads
        }
        return total + (parseFloat(optionPrice) || 0);
      }, 0);
      var formattedTotal = parseFloat(totalAmount).toFixed(2);
      if (additionalName === 'carbonless_forms' && fieldSize !== '250' && carbonlessFormDiscount) {
        var discountPercentage = parseFloat(carbonlessFormDiscount) / 100;
        var discountAmount = (parseFloat(formattedTotal) * discountPercentage).toFixed(2);
        formattedTotal = (formattedTotal - discountAmount).toFixed(2);
      }
      setTotalPrice(formattedTotal)
      setEachPrice('')
      if (parseInt(formattedTotal) !== 0) {
        var eachQuantityPrice = formattedTotal / fieldSize
        setEachPrice(parseFloat(eachQuantityPrice).toFixed(2))
      }
      setisFieldValidation(false)
      if (parseFloat(formattedTotal) === 0.00 && formFields.length != 0) {
        setisFieldValidation(true)
      }
    }
  }, [selectedIds, selectedOptions, fieldSize, numberOfPads]);

  const addTemporaryCartDetails = async (id, selectedOptions, permissionFieldId, fieldSize, additionalName, totalPrice) => {
    const addCartDetailsAPIResponse = await addCartDetails(id, selectedOptions, permissionFieldId, fieldSize, additionalName, totalPrice);
    if (addCartDetailsAPIResponse.data.client_status_code === 200) {
      return addCartDetailsAPIResponse.data.new_cart_id
    } else {
      setErrorResponse(addCartDetailsAPIResponse.data.message || 'Unable to add the cart.');
    }
  }

  const addTocart = async () => {
    if (((additionalName !== 'carbonless_forms') && (additionalName !== 'tickets') && (additionalName !== 'envelopes') && (additionalName !== 'flyer_5.5_x_8.5') && (formFields.length !== Object.keys(selectedOptions).length)) || ((additionalName === 'carbonless_forms' && 6 > Object.keys(selectedOptions).length ))) {
      setFieldValidation(true)
      return;
    }
    setFieldValidation(false)
    if (parseFloat(totalPrice) < 24) {
      setPriceValidation(true)
      return;
    }
    setPriceValidation(false)
    const newCartId = await addTemporaryCartDetails(id, selectedOptions, permissionFieldId, fieldSize, additionalName, totalPrice)
    navigate('/product-cart', { state: { newCartId: newCartId } });

  };

  const [showPriceTable, setShowPriceTable] = useState(false);
  const [showPhotosPriceTable, setshowPhotosPriceTable] = useState(false);

  const dropdownRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowPriceTable(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPriceTable]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setshowPhotosPriceTable(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPhotosPriceTable]);


  const lensRef = useRef(null);
  const magnifiedImgRef = useRef(null);
  const productImgRef = useRef(null);
  const [lensStyles, setLensStyles] = useState({});
  const [magnifiedStyles, setMagnifiedStyles] = useState({});
  const [isActive, setIsActive] = useState(false);

  const moveLens = (e) => {
    const lens = lensRef.current;
    const productImg = productImgRef.current;
    const magnifiedImg = magnifiedImgRef.current;
    const productImgRect = productImg.getBoundingClientRect();
    const lensWidth = lens.offsetWidth;
    const lensHeight = lens.offsetHeight;
    let x = e.clientX - productImgRect.left - lensWidth / 2;
    let y = e.clientY - productImgRect.top - lensHeight / 2;
    const maxXPos = productImgRect.width - lensWidth;
    const maxYPos = productImgRect.height - lensHeight;
    if (x > maxXPos) x = maxXPos;
    if (x < 0) x = 0;
    if (y > maxYPos) y = maxYPos;
    if (y < 0) y = 0;

    setLensStyles({ top: `${y}px`, left: `${x}px` });

    const cx = magnifiedImg.offsetWidth / lensWidth;
    const cy = magnifiedImg.offsetHeight / lensHeight;

    setMagnifiedStyles({
      backgroundImage: `url('${slides[currentIndex]}')`,
      backgroundPosition: `-${x * cx}px -${y * cy}px`,
      backgroundSize: `${productImgRect.width * cx}px ${productImgRect.height * cy}px`,
    });
    setIsActive(true);
  };

  const leaveLens = () => {
    setIsActive(false);
  };

  const productID = (productId) => {
    navigate(`/shopdetails/${productId}`);
  }

  const [selectedDesign, setSelectedDesign] = useState('');

  const handleCustomize = (e) => {
    setSelectedDesign(e.target.value);
  };

  const CustomizeDesign = () => {
    navigate('/template')
  }

  const [loadingTime, setLoadingTime] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTime(false);
    }, 8000);

    return () => clearTimeout(timer);
  }, [])




  const [selectedBinding, setSelectedBinding] = useState(null);
  const handleSelectedBinding = (id) => {
    setSelectedBinding(id);
  }
  const [bindingColor, setBindingColor] = useState(blackColor);
  const [pageCount, setPageCount] = useState(32);
  const updatePageCount = (value) => {
    setPageCount(parseInt(value, 10));
  };
  const updateSlider = (step) => {
    setPageCount((prev) => {
      const newValue = Math.min(84, Math.max(4, prev + step));
      return newValue;
    });
  };
  const [isChecked, setIsChecked] = useState(false);
  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const [showHeader, setShowHeader] = useState(false);
  const prevScrollPos = useRef(0);
  const isAtBottom = useRef(false);

  const throttle = (callback, delay) => {
    let lastCall = 0;
    return function () {
      const now = new Date().getTime();
      if (now - lastCall < delay) return;
      lastCall = now;
      callback();
    };
  };

  useEffect(() => {
    const handleScroll = throttle(() => {
      const currentScrollPos = window.pageYOffset;
      const scrollHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      if (currentScrollPos < 100) {
        isAtBottom.current = true;
      } else {
        isAtBottom.current = false;
      }
      if (currentScrollPos < prevScrollPos.current && isAtBottom.current) {
        setShowHeader(false);
        setShowDatePicker(false);
      } else if (!isAtBottom.current) {
        setShowHeader(true);
      }
      prevScrollPos.current = currentScrollPos;
    }, 100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentDateInEST = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    const dateObject = new Date(currentDateInEST);

    const formattedDate = dateObject.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    setCurrentEasternTime(formattedDate);
    setSelectedDate(dateObject)
  }, []);

  
  const datePickerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker]);

  const handleDateChange = () => {
    setShowDatePicker(false);
  }


  return (
    <div>
      {showHeader && (
        <div
          className={`font-sans fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${showHeader ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-full"
            }`}
        >
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between border border-gray-300 p-3 gap-4 bg-white max-w-full">
            <div className="flex items-start md:items-center gap-4">
              <div style={{ width: '3rem' }} className="w-16 h-16 bg-cover bg-center rounded-md">
                <img src={slides[currentIndex]} alt="product" />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-lg font-bold">{subTitle ? (
                  subTitle
                ) : (
                  <Skeleton width='200px' height='40px' />
                )}</p>
                <p className="flex flex-wrap items-center gap-3 text-sm text-gray-600">
                  <span className="text-yellow-500 font-bold">⭐ 5</span>
                  <span>| 100 Reviews</span>
                  <span className="text-orange-500 underline cursor-pointer">| Product Description</span>
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-start md:items-center gap-4 w-full md:w-auto">
              <div className="flex items-center gap-4 text-lg">
                <span className="text-red-500">🔥 Hurry!</span>
                {/* <span className="flex items-center gap-1">
                  📅 <span>{currentEasternTime}</span>
                </span> */}

                <span className="flex items-center gap-1 relative">
                  <span className='cursor-pointer' onClick={handleCalendarClick}>📅</span>
                  <span>
                    {currentEasternTime}
                  </span>
                  {showDatePicker && (
                    <div ref={datePickerRef} style={{ marginTop: '350px' }} className="absolute z-10 bg-white shadow-lg rounded">
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        inline
                      />
                    </div>
                  )}
                </span>
              </div>
              <span className="text-xl font-bold text-gray-700">Total: ${totalPrice}</span>
              <div className="flex flex-col sm:flex-row gap-3 w-full md:w-auto">
                {/* <button className="w-full sm:w-auto px-4 py-2 bg-gray-200 border border-gray-400 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-300 transition">
                  Product Configuration
                </button> */}

                {!isFieldValidation && (
                  <button onClick={addTocart} className="inline-flex items-center gap-2 bg-orange-300 text-black px-5 py-2 duration-200 hover:bg-orange-400">
                    <FaShoppingCart /> Add To Cart
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* </div> */}


      <section className='flex flex-col justify-start items-center md:flex-row md:items-start md:justify-center gap-5 md:gap-0 py-5 sm:py-8 md:py-10 w-full'>
        <div className='w-full md:w-1/2 px-5 flex flex-col items-center group'>
          <div className='p-3 lg:p-5 border'>
            {slides.length ? (
              <div
                className='relative w-52 sm:w-64 md:w-72 lg:w-80 h-auto overflow-hidden '
                ref={productImgRef}
                onMouseMove={moveLens}
                onMouseLeave={leaveLens}
              >
                <img
                  src={slides[currentIndex]}
                  alt="product"
                  className='w-full h-auto duration-500 transition-transform'
                />
                <div
                  className={`absolute top-0 left-0 hidden md:block border rounded w-[150px] h-[100px] bg-[#FF980030] ${isActive ? "opacity-100" : "opacity-0"}`}
                  ref={lensRef}
                  style={lensStyles}
                >

                </div>
              </div>
            ) : (
              <div className='w-52 sm:w-64 md:w-72 lg:w-80 h-48 sm:h-64 md:h-80 lg:h-96'>
                <Skeleton width='100%' height='100%' />
              </div>
            )}
          </div>

          <div className='flex flex-col items-center w-full'>
            <h2 className='text-xl sm:text-2xl mt-4 mb-1 underline text-neutral-700 bebas-neue-regular'>Have a Question? We're Here to Help!</h2>
            <p className='text-neutral-500 text-center md:text-start text-sm sm:text-base max-[420px]:text-start max-[420px]:w-full w-4/5 sm:w-3/4 md:w-11/12 xl:w-3/4'>
              If you can't find what you're looking for, our team is available from 10:00 AM to 7:00 PM EST to assist you. Feel free to reach out via email at orders@stalbansprinting.com or give us a call at 718-528-5100.
            </p>
          </div>

          {slides.length > 1 && (
            <div
              onClick={prevSlide}
              className='absolute top-[50%] -translate-x-0 -translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'
            >
              <BsChevronCompactLeft size={30} />
            </div>
          )}
          {slides.length > 1 && (
            <div
              onClick={nextSlide}
              className='absolute top-[50%] -translate-x-0 -translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'
            >
              <BsChevronCompactRight size={30} />
            </div>
          )}
        </div>

        <div className='relative w-full md:w-1/2 lg:w-3/5 flex items-start justify-center md:justify-start md:mr-4 px-5 md:pb-16 sm:px-10 md:px-0'>
          <div className=' flex flex-col items-center justify-center sm:pl-[10%] md:pl-0 w-full'>
            <div className='flex w-full flex-col gap-3'>
              <h1 className='text-2xl sm:text-3xl font-semibold'>
                {subTitle ? (
                  subTitle
                ) : (
                  <Skeleton width='200px' height='40px' />
                )}
              </h1>
              <div className='flex text-orange-400 text-[15px] gap-1'><FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /></div>
              {isFieldValidation ? (
                <p className="text-lg font-bold text-gray-700 mb-4">
                  <span>Please check back later for the price, or call 718-528-5100 for more information.</span>
                </p>
              ) : ('')}
            </div>
            <div className="w-full pt-4">
              <form>
                {formFields.length ? formFields && formFields.map((field) => (
                  <>

                    {((((((field.additional_name === "starting_number" || field.additional_name === "numbering_location") && !numberingInk)) || (((field.additional_name === "sets_per_pad" || field.additional_name === "number_of_pads") && !padding)) || ((field.additional_name === "number_of_sets" && !numberOfSet)) || (((field.additional_name === "numbering_ink" || field.additional_name === "starting_number" || field.additional_name === "numbering_location" ) && !numbering)) ) && (additionalName === 'carbonless_forms')) || (additionalName === 'tickets' && ticketPaperColor && field.additional_name === "ticket_paper_color") || (additionalName === 'envelopes' && envelopeSize && (field.additional_name === "window" || field.additional_name === "pull_&_seal")) || (additionalName === 'flyer_5.5_x_8.5' && colorCopiesSellSheets && field.additional_name === "edge_cutting") ) ? ('') : (



                      <div className="flex items-center justify-start w-full mb-4" key={field.id}>
                        <div className="flex relative items-center w-40 sm:w-44 md:w-48">
                          <label
                            htmlFor={field.field_name}
                            className="block w-full text-left text-gray-600 font-medium flex items-center"
                          >
                            <span>{field.field_name}</span>
                            {field.description && (
                              <>

                                <button
                                  ref={fieldTipButtonRef}
                                  onClick={(e) => handleToggleFieldTip(e, field.field_name)}
                                  className="ml-1 text-white bg-blue-600 hover:bg-blue-700 duration-300 ease-in-out transform hover:scale-105 p-[6px] rounded-full shadow-md flex items-center justify-center"
                                  style={{ width: "15px", height: "15px" }}
                                  title="Click to see more information"
                                >
                                  ?

                                </button>
                                {openFieldTip === field.field_name && (
                                  <div
                                    ref={fieldTipRef}
                                    className="absolute shadow-lg top-8 left-4 z-20 p-4 border w-64 max-h-36 overflow-y-auto bg-white border-blue-500 text-gray-700 text-sm rounded-lg transition-all duration-300"
                                  >
                                    <p>{field.description}</p>
                                  </div>
                                )}
                              </>
                            )}
                            <span className="ml-1">:</span>
                          </label>
                        </div>
                        <div className='flex gap-3 items-center justify-start w-64'>

                          {(field.additional_name === 'quantity' && field.quantity_calculation === true) ? (

                            // <input
                            //   type="number"
                            //   name={field.field_name}
                            //   value={formData[field.field_name] || ''}
                            //   onChange={(e) => handleChange(e, field.id, field)}
                            //   min={1}
                            //   max={2000}
                            //   step={1}
                            //   className="block w-full p-2 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 duration-200"
                            // />

                            <div className="relative w-full max-w-md">
                              <input
                                type="number"
                                name={field.field_name}
                                value={formData[field.field_name] || ''}
                                onChange={(e) => handleChange(e, field.id, field)}
                                min={1}
                                max={10000}
                                step={1}
                                style={{ padding: '0.4rem' }}
                                className="block w-full p-3 pr-12 border border-gray-300 rounded bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 duration-200"
                              />

                              {/* Custom Arrows */}
                              <div
                                style={{ fontSize: '10px' }}
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 flex flex-col space-y-1"
                              >
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleChange(
                                      {
                                        target: {
                                          name: field.field_name,
                                          value: Math.min(
                                            (Number(formData[field.field_name] || 0) + 25),
                                            10000
                                          ),
                                        },
                                      },
                                      field.id,
                                      field
                                    )
                                  }
                                  className="text-gray-600 hover:text-orange-500"
                                >
                                  ▲
                                </button>

                                <button
                                  type="button"
                                  onClick={() =>
                                    handleChange(
                                      {
                                        target: {
                                          name: field.field_name,
                                          value: Math.max(
                                            (Number(formData[field.field_name] || 0) - 25),
                                            1
                                          ),
                                        },
                                      },
                                      field.id,
                                      field
                                    )
                                  }
                                  className="text-gray-600 hover:text-orange-500"
                                >
                                  ▼
                                </button>
                              </div>
                            </div>


                          ) : (
                            <>
                              <select
                                name={field.field_name}
                                value={formData[field.field_name] || ''}
                                onChange={(e) => handleChange(e, field.id, field)}
                                className="block w-full p-2 border border-gray-300 rounded bg-white shadow-sm hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-400 duration-200"
                                style={{
                                  maxHeight: '150px',
                                  overflowY: 'auto',
                                  appearance: field.additional_name === "number_of_sets" && perNumberOfPads.length === 2 ? 'none' : 'auto',
                                }}
                                disabled={field.additional_name === "number_of_sets" && perNumberOfPads.length === 2}
                              >
                                <option value="" disabled>
                                  Choose an option
                                </option>
                                {field.options &&
                                  (field.field_name === 'Quantity' || field.field_name === 'Photos'
                                    ? field.options
                                      .slice()
                                      .sort((a, b) => Number(a.name) - Number(b.name))
                                      .map((option) => (
                                        <option
                                          key={option.id}
                                          className="py-2 px-4 hover:bg-orange-100"
                                          value={option.name}
                                          data-id={option.id}
                                        >
                                          {option.name}
                                        </option>
                                      ))
                                    : field.options.filter((option) =>
                                      !(additionalName === 'brouchers' &&
                                        field.additional_name === 'finish' &&
                                        brouchers &&
                                        (option.name === 'High Gloss UV Coating'))
                                    )
                                    .map((option) => (
                                      <option
                                        key={option.id}
                                        className="py-2 px-4 hover:bg-orange-100"
                                        value={option.name}
                                        data-id={option.id}
                                      >
                                        {option.name}
                                      </option>



                                    )))}

                                    
                              </select>
                              {/* {field.additional_name === 'print_turn_around' && (
                            <><br />
                              <div className="mt-2">
                                <span className="text-sm text-gray-600">
                                  Same Day orders must be approved by NOON EST. Orders not approved by
                                  this time will be deemed to be approved the next business day 11111.
                                </span>
                              </div>
                              </>
                          )} */}
                            </>
                          )}
                          {(field.field_name === 'Quantity' && field.quantity_calculation !== true) && (
                            <div className='relative' ref={dropdownRef}>
                              <span
                                onClick={() => setShowPriceTable(!showPriceTable)}
                                className="text-blue-600 hover:text-blue-900 cursor-pointer duration-200 underline-offset-1 underline whitespace-nowrap"
                              >
                                Price Table
                              </span>
                              {showPriceTable && (
                                <div className="mt-2 px-4 py-2 text-sm md:text-base bg-white w-56 sm:w-64 lg:w-80 h-40 overflow-y-auto border border-gray-300 absolute top-5 right-0 rounded-md shadow-lg"
                                  style={{ zIndex: 11 }}>
                                  {field.field_name === 'Quantity' && field.options
                                    .slice()
                                    .sort((a, b) => Number(a.name) - Number(b.name))
                                    .map((option) => (
                                      <div key={option.id} className="flex justify-between gap-1">
                                        <span>{option.name} {subTitle}</span>
                                        <span className="text-red-500">${option.amount}</span>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          )}
                          {field.field_name === 'Photos' && (
                            <div className='relative' ref={dropdownRef}>
                              <span
                                onClick={() => setshowPhotosPriceTable(!showPhotosPriceTable)}
                                className="text-blue-600 hover:text-blue-900 cursor-pointer duration-200 underline-offset-1 underline whitespace-nowrap"
                              >
                                Price Table
                              </span>

                              {showPhotosPriceTable && (
                                <div className="mt-2 px-4 py-2 text-sm md:text-base bg-white w-56 sm:w-64 lg:w-80 h-40 overflow-y-auto border border-gray-300 absolute top-5 right-0 rounded-md shadow-lg z-10">
                                  {field.field_name === 'Photos' && field.options
                                    .slice()
                                    .sort((a, b) => Number(a.name) - Number(b.name))
                                    .map((option) => (
                                      <div key={option.id} className="flex justify-between gap-1">
                                        <span>{option.name} Photos</span>
                                        <span className="text-red-500">${option.amount}</span>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>)
                    }

                    {field.additional_name === 'print_turn_around' && (
                      <div className="flex items-center justify-start w-full mb-4">
                        <label
                          className="block w-40 sm:w-44 md:w-48 text-left text-gray-500 font-semibold"
                        >
                        </label>
                        <div className='flex gap-3 items-center justify-start w-64' style={{ marginTop: '-12px' }}>
                          <span style={{ fontSize: '14px' }} className="text-sm text-gray-600">
                            Same Day orders must be approved by 11:00 AM EST. Orders not approved by
                            this time will be deemed to be approved the next business day.
                          </span>
                        </div>
                      </div>
                    )}

                  </>
                )) : (
                  <div className='flex flex-col gap-2'>

                    <div className="flex items-center justify-start gap-8 w-full mb-4">
                      <div
                        className="w-20 sm:w-28 md:w-36 text-left flex items-center text-gray-500 font-semibold"
                      >
                        <Skeleton width='80%' height='25px' />
                      </div>
                      <div className='flex gap-3 items-center justify-start w-64'>
                        <Skeleton width='100%' height='35px' />
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-8 w-full mb-4">
                      <div
                        className="w-20 sm:w-28 md:w-36 text-left flex items-center text-gray-500 font-semibold"
                      >
                        <Skeleton width='60%' height='25px' />
                      </div>
                      <div className='flex gap-3 items-center justify-start w-64'>
                        <Skeleton width='100%' height='35px' />
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-8 w-full mb-4">
                      <div
                        className="w-20 sm:w-28 md:w-36 text-left flex items-center text-gray-500 font-semibold"
                      >
                        <Skeleton width='100%' height='25px' />
                      </div>
                      <div className='flex gap-3 items-center justify-start w-64'>
                        <Skeleton width='100%' height='35px' />
                      </div>
                    </div>

                  </div>
                )}
                {fieldValidation && (
                  <div className="flex items-center mb-4">
                    <p className="text-red-500 text-sm">Please choose the all fields.</p>
                  </div>
                )}
                {!isFieldValidation && (
                  <>
                    <p className="text-lg font-bold text-gray-700">
                      Total Price: $<span>{totalPrice}</span>
                    </p>
                    {eachPrice &&
                      <p style={{ marginTop: '-6px', color: 'rgb(137 137 137)' }} className="text-lg font-bold mb-4">
                        <span className="text-sm">
                          ( ${eachPrice} for each )
                        </span>
                      </p>
                    }
                  </>
                )}
                {priceValidation && (
                  <div className="flex items-center mb-4">
                    <p className="text-red-500 text-sm">The minimum order is $25 or more.</p>
                  </div>
                )}

              </form>
              {!isFieldValidation && (
                <button
                  onClick={addTocart}
                  className="inline-flex items-center gap-2 bg-orange-300 text-black px-5 py-2 duration-200 hover:bg-orange-400"
                >
                  <FaShoppingCart /> Add To Cart
                </button>
              )}
              <div className='flex items-center pt-3 sm:pt-4'>
                <p className='font-light'>Share on:</p>
                <div className='flex pl-2 items-center gap-x-4'>
                  <a href="#"><FaFacebookF /></a>
                  <a href="#"><FaXTwitter /></a>
                  <a href="#"><FaLinkedinIn /></a>
                  <a href="#"><FaPinterest /></a>
                </div>
              </div>
            </div>
          </div>
          <div className={`absolute w-full h-full hidden top-0 left-0 ${isActive ? "border-2 bg-white md:block" : "border-none bg-transparent"}`}>
            <div
              className={`absolute w-full h-full top-0 left-0 ${isActive ? "opacity-100 scale-100 " : "opacity-0 scale-50"}`}
              ref={magnifiedImgRef}
              style={magnifiedStyles}
            >
            </div>
          </div>
        </div>
      </section>
      {/* <DescriReview /> */}
      <div className='px-5 md:px-10 mt-7 sm:mt-8 lg:mt-10 mb-8 sm:mb-10 md:mb-14'>
        <div className='mb-5 sm:mb-7 md:mb-8 flex gap-3 items-center justify-center'>
          <p className='w-6 sm:w-10 h-[1.5px] bg-black'></p>
          <h1 className='text-2xl md:text-[26px] lg:text-3xl xl:text-4xl text-center font-semibold'>You May Also Like</h1>
          <p className='w-6 sm:w-10 h-[1.5px] bg-black'></p>
        </div>
        <div>
          <Swiper
            spaceBetween={10}
            centeredSlides={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            slide
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 6,
              },
            }}
          >
            {products && products.map((product) => (
              <SwiperSlide key={product.id}>
                <div onClick={() => productID(product.id)} className="relative flex flex-col items-center justify-center border cursor-pointer">
                  <div
                    className='w-auto h-40 md:h-52 border relative overflow-hidden bg-transparent'
                  >
                    <img
                      className='w-full h-full transition-transform duration-200 ease-in-out hover:scale-125'
                      src={product.image_file} alt={product.image_name}
                    />
                  </div>
                  <div className='mt-5 border-t-2 w-full'>
                    <div className="flex py-3 px-1 sm:px-2 justify-center items-center text-gray-700 gap-1 bg-gray-100 border">
                      <FaEye />
                      <button>
                        View Detail
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default ShopDetails;

