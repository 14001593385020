import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import product2 from '../assets/Product/product2.jpg'
import { FaInfoCircle } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { getCartDetails, deleteCartDetail, getInvoiceNumber, checkCouponValidation } from '../serverApi/server'
import { decryptToken, getCookie } from '../components/Helper/Helpers'

const Cart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [allCartDetails, setAllCartDetails] = useState();
  const [tax, setTax] = useState();
  const [subTotalAmount, setSubTotalAmount] = useState();
  const [discountSubTotalAmount, setDiscountSubTotalAmount] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [turnAround, setTurnAround] = useState();
  const { newCartId } = location.state || {};
  const [couponCode, setCouponCode] = useState("");
  const [couponId, setCouponId] = useState()

  const [couponCodeMessage, setCouponCodeMessage] = useState('')
  const [couponCodeMessageStatus, setCouponCodeMessageStatus] = useState(false)

  const [discountStatus, setDiscountStatus] = useState(false)
  const [discountPrice, setDiscountPrice] = useState()

  const [couponApplyStatus, setCouponApplyStatus] = useState(false)

  const formattedSubTotalAmount = parseFloat(subTotalAmount).toFixed(2);
  const formattedDiscountSubTotalAmount = parseFloat(discountSubTotalAmount).toFixed(2);
  const formattedTax = parseFloat(tax).toFixed(2);
  const formattedTotalPrice = parseFloat(totalPrice).toFixed(2);

  useEffect(() => {
    const cartIdListFromStorage = localStorage.getItem('cartIdList');
    let parsedCartIdList = [];
    try {
      parsedCartIdList = cartIdListFromStorage ? JSON.parse(cartIdListFromStorage) : [];
      if (!Array.isArray(parsedCartIdList)) {
        parsedCartIdList = [];
      }
    } catch (e) {
      console.error('Error parsing cartIdList:', e);
    }
    if (newCartId && !parsedCartIdList.includes(newCartId)) {
      parsedCartIdList.push(newCartId);
      localStorage.setItem('cartIdList', JSON.stringify(parsedCartIdList));
    }
    fetchCartDetails(parsedCartIdList);
  }, [newCartId]);


  const fetchCartDetails = async (cartIdList) => {
    if (cartIdList.length >= 1) {
      try {
          const getCartDetailsAPIResponse = await getCartDetails(cartIdList);
          if (getCartDetailsAPIResponse.data.client_status_code === 200) {
            setAllCartDetails(getCartDetailsAPIResponse.data.all_cart_details)
            setTax(getCartDetailsAPIResponse.data.tax_amount)
            setSubTotalAmount(getCartDetailsAPIResponse.data.sub_total_amount)
            setTotalPrice(getCartDetailsAPIResponse.data.total_amount)
            setTurnAround(getCartDetailsAPIResponse.data.turn_around)
          } else {
            console.log('donot have the cart details')
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    } else {
      setAllCartDetails()
      setTax()
      setSubTotalAmount()
      setTotalPrice()
      setTurnAround()
    }
  }

  const deleteCart = async(cart_id) => {
    const deleteCartDetailAPIResponse = await deleteCartDetail(cart_id)
    if (deleteCartDetailAPIResponse.data.client_status_code === 200) {
      const cartIdListFromStorage = localStorage.getItem('cartIdList');
      const parsedCartIdList = JSON.parse(cartIdListFromStorage);
      const updatedCartIdList = parsedCartIdList.filter(id => id !== cart_id);
      localStorage.setItem('cartIdList', JSON.stringify(updatedCartIdList));
      fetchCartDetails(updatedCartIdList);
    } else {
      console.log('donot have the cart details')
    }
  }

  const getInvoiceNumberDetails = async() => {
    const getInvoiceNumberAPIResponse = await getInvoiceNumber()
    if (getInvoiceNumberAPIResponse.data.client_status_code === 200) {
      return getInvoiceNumberAPIResponse.data.invoice_number
    } else {
      return null
    }
  }

  const proceedCheckout = async () => {
    const invoiceNumber = await getInvoiceNumberDetails()
    const userId = getCookie('_puisap')
    const accessToken = decryptToken(getCookie('_patsap'));
    const refreshToken = decryptToken(getCookie('_prtsap')); 
    navigate('/login', { state: {totalPrice: totalPrice, invoiceNumber: invoiceNumber, loginType: 'purchase', couponId: couponId, turnAround: turnAround}}) 
  }
  
  return (
    <div className='pb-16'>
      <div className='w-full h-32 sm:h-52 md:h-72 bg-socialbg flex flex-col items-center justify-center'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl font-semibold sm:mb-2'>SHOPPING CART</h1>
        <p className='text-neutral-600 text-sm sm:text-base'><Link to='/cart' className='text-orange-500'>Home</Link> - Shopping Cart</p>
      </div>
      <div className='relative px-4 sm:px-8 md:px-8 lg:px-20 pt-10 w-full grid md:grid-cols-3 gap-4 sm:gap-7 lg:gap-10'>

        <div className='relative sm:col-span-1'>
          <div className='sticky top-0 max-[460px]:px-5 px-10 sm:px-0'>
            <div className='border'>
              <h2 className='px-5 py-2 text-2xl font-medium bg-socialbg'>Cart Summary</h2>

                <div className='p-4 text-[17px] border-b'>
                  <div className='mb-3 flex justify-between'>
                    <p>Subtotal</p>
                    <p>$ {formattedSubTotalAmount}</p>
                  </div>
                  <div className='flex justify-between'>
                    <p>Tax</p>
                    <p>$ {formattedTax}</p>
                  </div>
                </div>
                <div className='p-4 font-bold text-xl mb-1 flex justify-between'>
                  <p>Total</p>
                  <p>$ {formattedTotalPrice}</p>
                </div>
                <div className='px-4 pb-6'>
                  <button onClick={proceedCheckout} className='text-center w-full px-2 py-3 bg-orange-400 hover:text-white hover:bg-orange-600 duration-200 sm:rounded-md'><span className='sm:hidden md:inline'>Proceed To</span> Checkout</button>
                </div>
            </div>
          </div>
        </div>

        <div className='overflow-y-auto sm:col-span-2 flex flex-col w-full gap-5 h-[calc(100vh-100px)]'>
          {allCartDetails && allCartDetails.map((item, index) =>
            <div className='p-2 sm:p-4 flex justify-between gap-2 sm:gap-6 border rounded-lg'>
              <div className='flex justify-between gap-2 sm:gap-4'>
                <div className='w-24'>
                  <img src={item.category_menu_option_image} alt="Product" />
                </div>
                <div>
                  <h3 className='sm:text-xl font-medium'>{item.category_menu}</h3>
                  {item.cart_field_options_serializer && item.cart_field_options_serializer.map((cart_field_option, index) =>
                    <p className='text-sm sm:text-base text-neutral-600'>{cart_field_option.field_name}: <span className='font-semibold text-neutral-700'>{cart_field_option.option_name}</span></p>
                  )}
                  {item.turn_around_date && item.turn_around_date !== "None" && (
                    <p className="text-sm sm:text-base text-neutral-600">
                      Delivery date: <span className="font-semibold text-neutral-700">{item.turn_around_date} (Between 5:00 pm and 7:00 pm)</span>
                    </p>
                  )}
                  <p className='text-sm sm:text-base text-neutral-600'>Your total price: <span className='font-semibold text-neutral-700'>{item.total_amount}</span></p>
                  {/* <Link className='text-sm sm:text-base text-orange-500 hover:underline underline-offset-4 duration-200'>Files/Order Info</Link> */}
                </div>
              </div>
              
              <div className='flex flex-col items-end text-sm sm:text-[17px] font-medium'>
                {/* <Link className='hover:text-orange-700 duration-200'>Edit</Link> */}
                <button className='hover:text-orange-700 duration-200' onClick={() => deleteCart(item.cart_id)}>Remove</button>
                <button className='text-lg my-1 border-transparent border-l border-r border-b hover:border-gray-500 hover:border-l hover:border-r hover:border-b hover:text-orange-700 p-[1px] duration-200 rounded-full'><FaInfoCircle /></button>
                {/* <button className='text-xl border-transparent border-l border-r border-b hover:border-gray-500 hover:border-l hover:border-r hover:border-b hover:text-orange-700 duration-200 rounded'><MdDelete /></button> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Cart
