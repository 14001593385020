import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyCaptcha } from "../serverApi/server";

const Captcha = ({ onVerify }) => {
  const handleCaptcha = async (token) => {
    try {
      const response = await verifyCaptcha(token);
      onVerify(response.data.success);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <ReCAPTCHA
        sitekey="6LfCtbkqAAAAAIXb6_AGARorv9IlEJnd2YhmPL9_"
        onChange={handleCaptcha}
      />
    </div>
  );
};

export default Captcha;
