import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { getCategorySubMenusAPI, uploadImageZipFile, getCategoryImagesFolders, deleteCategoryImageFolder } from "../../serverApi/server";
import { useLocation } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";


const CategoryImageUpload = () => {
  const location = useLocation();
  const formheaderMenus = location.state || [];
  const toggleForm = () => setFormOpen(!formOpen);
  const [formOpen, setFormOpen] = useState(false);
  const [categoryZipFile, setCategoryZipFile] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  var formData = new FormData();
  const [serverResponse, setServerResponse] = useState(null);
  const [categoryImageFolders, setCategoryImageFolders] = useState()


  const getCategoryImagesFoldersAPI = async () => {
    const getCategoryImagesFoldersAPIResponse = await getCategoryImagesFolders()
    console.log('getCategoryImagesFoldersAPIResponse', getCategoryImagesFoldersAPIResponse.data.categories_serializer)
    setCategoryImageFolders(getCategoryImagesFoldersAPIResponse.data.categories_serializer)
  }

  useEffect(() => {
    getCategoryImagesFoldersAPI()
  }, []);



  const handleFileChange = (event) => {
    setCategoryZipFile(event.target.files[0]);
  };
  
  const [loading, setLoading] = useState(false);
  const saveCategory = async (e) => {
    e.preventDefault();
    setLoading(true);
    formData.append('category_id', categoryID);
    formData.append('category_upload_file', categoryZipFile);
    try {
        const response = await uploadImageZipFile(formData);
        setLoading(false);
        if (response.status === 200) {
            getCategoryImagesFoldersAPI()
            setCategoryID(null);
            setCategoryZipFile(null);
            toggleForm();
        } else {
          setServerResponse(response.data.message || 'Upload zip failed')
        }
    } catch (error) {
        console.error(error);
    }
  };

  const [selectedFormHeader, setSelectedFormHeader] = useState(
    formheaderMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
  );
  const handleFormHeaderChange = (e) => {
    setSelectedFormHeader(e.target.value);
  };

  const [formCategoryList, setFormCategoryList] = useState([]);
  const [formSubMenuList, setFormSubMenuList] = useState('')
  const handleFormSubMenuListChange = async (e) => {
    setFormSubMenuList(e.target.value);
    await setCategoryID(e.target.value)
  }
  useEffect(() => {
    const fetchCategorySubMenus = async () => {
      try {
        const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedFormHeader);
        setFormCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer)
        setFormSubMenuList(getCategorySubMenusAPIResponse.data.categories_serializer.find((menu) => menu.additional_name === 'bookmarks')?.id || '')
      } catch (error) {
        console.error('Error fetching FORM-sub-menus:', error);
      }
    };
    if (selectedFormHeader) {
      fetchCategorySubMenus();
    }
  }, [selectedFormHeader]);

  const handleDelete = async (deleteCategoryId) => {
    console.log('deleteCategoryId', deleteCategoryId)
    await deleteCategoryImageFolder(deleteCategoryId)
    getCategoryImagesFoldersAPI()
  }

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full custom-scroll">
        <SideBar />
      </div>
      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <div className="bg-[#F5F7FD] px-7 py-6">
          <div className="flex items-center justify-between">
            <div className="text-blue-950 flex items-center gap-6">
              <h1 className="pr-6 text-2xl font-medium border-r">
                Admin User
              </h1>
              <div className="flex text-sm items-center gap-3">
                <button>
                  <SlHome />
                </button>
                <SlArrowRight />
                <button>Category Image Upload</button>
              </div>
            </div>

            <button
              className="bg-blue-500 hover:bg-blue-400 duration-200 px-4 py-2 rounded text-white mr-4 md:mr-8"
              onClick={toggleForm}
            >
              Upload Category Images
            </button>
          </div>
          {formOpen && (
            <div
              className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
            >
              <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-4 border-b">
                  <h5 className="text-lg font-medium">
                    Upload Images (.zip file only accepted)
                  </h5>
                  <button
                    type="button"
                    className="text-gray-600 text-3xl hover:text-gray-900"
                    onClick={toggleForm}
                  >
                    &times;
                  </button>
                </div>
                <div className="p-4">
                  <form onSubmit={saveCategory}>
                    <div className="mb-4">
                      <label
                        htmlFor="categoryType"
                        className="block text-sm text-gray-700"
                      >
                        Header Name
                      </label>
                      <select
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        onChange={handleFormHeaderChange}
                        value={selectedFormHeader}
                      >
                        {formheaderMenus.map((menu) => (
                          <option key={menu.id} value={menu.id}>
                            {menu.menu}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="subCategory"
                        className="block text-sm text-gray-700"
                      >
                        Category 
                      </label>
                      <select
                        id="subCategory"
                        name="subCategory"
                        required
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                        onChange={handleFormSubMenuListChange}
                        value={formSubMenuList}
                      >
                        <option value="" disabled>
                          Choose an option
                        </option>
                        {formCategoryList && formCategoryList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.category}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="category_zip_file"
                        className="block text-sm text-gray-700"
                      >
                        Category Zip File:
                      </label>
                      <input
                        type="file"
                        name="category_zip_file"
                        required
                        onChange={handleFileChange}
                        accept=".zip,application/zip"
                        placeholder="Enter Field Name"
                        className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                      />
                    </div>
                    <div className="flex justify-end items-center p-4 border-t">
                      <button
                        type="button"
                        className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                        onClick={toggleForm}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
                {serverResponse && (<p className="text-sm text-red-500">{serverResponse}</p>)}
              </div>
            </div>
          )}

          <div className="bg-white mt-10 px-3 py-4 rounded-lg">
          <div className="overflow-x-auto table-auto">
              <table className="min-w-full bg-white mt-8" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">CATEGORY NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">CATEGORY FOLDER</th>
                    {/* <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">ACTION</th> */}
                  </tr>
                </thead>
                
                
                <tbody>
                  {categoryImageFolders && categoryImageFolders.map((category) => (
                    <tr key={category.id}>
                      <td className="px-4 py-2 font-normal border border-[#48ABF7]">{category.category}</td>
                      <td className="px-4 py-2 font-normal border border-[#48ABF7]">{category.folder_name}</td>
                      {/* <td className="px-4 py-2 border border-[#48ABF7]">
                        <button 
                          className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center" 
                          onClick={() => handleDelete(category.id)}
                        >
                          <MdDeleteOutline className="mr-[1px]"/>
                          Delete
                        </button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            {loading && (
              <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-90 flex flex-col justify-center items-center z-50">
                <div className="w-12 h-12 border-4 border-gray-300 border-t-4 border-t-orange-500 rounded-full animate-spin"></div>
                <p className="mt-4 text-sm sm:text-base md:text-lg text-gray-600">
                  Loading, please wait...
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryImageUpload;
