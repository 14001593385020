import React from "react";
import ErrorImage from '../assets/error_image.png'


const ErrorResponse = () => {
    return (
        <div className="flex justify-center items-center min-h-screen font-sans">
            <div className="container text-center bg-white p-6 rounded-lg">
                <img
                    src={ErrorImage}
                    alt="404 Illustration"
                    className="w-80 h-auto mx-auto"
                />
                <h1 className="text-3xl font-bold text-gray-700 mt-4">
                    404: Oops, Printing Path Lost
                </h1>
                <p className="text-gray-600 text-base leading-relaxed mt-4 w-full max-w-3xl mx-auto">
                    Lost your way in the digital maze? No problem! You've found a 404 page, but we’re here to make it a creative detour. Discover our premium die-cut prints, elegant business cards, and one-of-a-kind marketing materials. Let’s guide you back or invite you to explore our printing wonderland below.
                </p>
                <div className="buttons flex justify-center gap-4 mt-6">
                    <a
                        href="/"
                        className="px-5 py-2 text-gray-700 bg-white border-2 border-yellow-500 rounded-md hover:bg-yellow-500 hover:text-white transition-colors"
                    >
                        Return to Home
                    </a>
                    <a
                        href="/"
                        className="px-5 py-2 text-gray-700 bg-white border-2 border-yellow-500 rounded-md hover:bg-yellow-500 hover:text-white transition-colors"
                    >
                        Start Shopping
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ErrorResponse;
