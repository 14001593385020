import React, { useState, useEffect } from 'react'
import CryptoJS from 'crypto-js';
import { useSearchParams } from 'react-router-dom';
import newStalbansLogo from '../../src/assets/newStalbansLogo.png'
import { unSubscribeClientMail } from '../../src/serverApi/server'


const Unsubscribe = () => {
    const [clientId, setClientId] = useState('');
    const [clientMail, setClientMail] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);
    const [unsubscribeSuccess, setUnSubscribeSuccess] = useState(false);
    const [unSubscribeError, setUnSubscribeError] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [searchParams] = useSearchParams();
    const decryptionKey = 'pVO-DLZE3reccLcODl_gz9tfn869KqDB';
    function unpad(data) {
        const padding = data.charCodeAt(data.length - 1);
        return data.slice(0, -padding);
    }

    function decodeUrlSafeBase64(data) {
        const base64Data = data.replace(/-/g, '+').replace(/_/g, '/') + '='.repeat((4 - data.length % 4) % 4);
        return base64Data;
    }

    useEffect(() => {
        const encryptedData = new URLSearchParams(window.location.search).get('data');
        if (encryptedData) {
            try {
                const key = CryptoJS.enc.Utf8.parse(decryptionKey);
                const base64Data = decodeUrlSafeBase64(encryptedData);
                const encryptedWords = CryptoJS.enc.Base64.parse(base64Data);
                const decryptedBytes = CryptoJS.AES.decrypt(
                    { ciphertext: encryptedWords },
                    key,
                    {
                        mode: CryptoJS.mode.ECB,
                        padding: CryptoJS.pad.NoPadding,
                    }
                );
                let decryptedString = CryptoJS.enc.Utf8.stringify(decryptedBytes);
                decryptedString = unpad(decryptedString);
                const decryptedData = JSON.parse(decryptedString);
                setClientId(decryptedData.client_id);
                setClientMail(decryptedData.client_mail);
            } catch (error) {
                console.error('Decryption failed:', error);
                setUnSubscribeError(true);
            }
        }
    }, []);
    const submitUnsubscribe = async () => {
        const unSubscribeClientMailResponce = await unSubscribeClientMail(clientId)
        if (unSubscribeClientMailResponce.data && unSubscribeClientMailResponce.data.client_status_code === 200) {
            setResponseMessage(unSubscribeClientMailResponce.data.message)
            setUnSubscribeSuccess(true);
            setUnSubscribeError(false);
        } else if (unSubscribeClientMailResponce.data && unSubscribeClientMailResponce.data.client_status_code === 400) {
            setResponseMessage(unSubscribeClientMailResponce.data.message)
            setUnSubscribeSuccess(false);
            setUnSubscribeError(true);
        }
        setShowPopUp(false);
    }
    return (
        <div class="bg-gray-100 flex items-center justify-center p-4 sm:p-8 md:p-12">
            <div class="bg-white rounded-lg w-full max-w-xl sm:max-w-2xl md:max-w-4xl p-6 sm:p-8 md:p-12 shadow-lg">
                <div class="mb-6 flex justify-center">
                    <img src={newStalbansLogo} alt="4over Logo" class="w-24 sm:w-32 md:w-36 h-auto" />
                </div>
                <h1 class="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 mb-4 text-center">
                    Communication Preferences
                </h1>
                <p class="text-base sm:text-lg text-gray-600 font-semibold mb-4 text-center">
                    {clientMail}
                </p>
                <p class="text-sm text-gray-500 mb-6 text-center">
                    If this is not your email address, please ignore this page since the email associated with this page was most likely forwarded to you.
                </p>
                <div class="flex justify-center">
                    <button onClick={submitUnsubscribe}
                        class="bg-red-500 text-white font-bold py-2 px-4 rounded-lg shadow hover:bg-red-600 transition text-sm sm:text-base md:text-lg">
                        UNSUBSCRIBE ME FROM ALL MAILING LISTS
                    </button>
                </div>
                <div class="flex justify-center m-2">
                    <span className={`text-sm ${unsubscribeSuccess ? 'text-green-500' : unSubscribeError ? 'text-red-500' : ''}`}>
                        {responseMessage}
                    </span>
                </div>

            </div>
        </div>
    )
}

export default Unsubscribe;