import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Skeleton from "../../pages/skeleton/Skeleton";

const SocialMedia = () => {
  const [loadingTime, setLoadingTime] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTime(false);
    }, 1700);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex items-center justify-between py-2 px-6 sm:px-[2vw] md:px-[3vw] lg:px-[5vw] bg-socialbg">
      <div className={`hidden md:flex items-center ${loadingTime ? 'gap-4' : ''}`}>
        {loadingTime ? (
          <Skeleton width="35px" height="20px" />
        ) : (
          <Link to="/faq" className="font-light text-gray-800">
            FAQs
          </Link>
        )}
        {loadingTime ? (
          <Skeleton width="35px" height="20px" />
        ) : (
          <>
          <span className="font-light px-2">|</span>
          <Link className="font-light text-gray-800">Help</Link>
          </>
        )}

        {loadingTime ? (
          <Skeleton width="35px" height="20px" />
        ) : (
          <>
          <span className="font-light px-2">|</span>
          <Link className="font-light text-gray-800">Support</Link>
          </>
        )}
      </div>
      <div className="flex items-center">
        {loadingTime ? (
          <Skeleton width="140px" height="20px" />
        ) : (
          <p>718-528-5100</p>
        )}
      </div>

      <div className={`flex items-center justify-center ${loadingTime ? 'gap-4' : ''}`}>
        {loadingTime ? (
          <Skeleton width="20px" height="20px" />
        ) : (
          <a
          className="text-dark px-2"
          href="https://www.facebook.com/stalbansdigitalprinting"
          target="blank"
          >
            <FaFacebookF />
          </a>
        )}
        {loadingTime ? (
          <Skeleton width="20px" height="20px" />
        ) : (
          <a
          className="text-dark px-2"
          href="https://x.com/stalbans123"
          target="blank"
          >
            <FaXTwitter />
          </a>
        )}
        {loadingTime ? (
          <Skeleton width="20px" height="20px" />
        ) : (
          <a
          className="text-dark px-2"
          href="https://www.linkedin.com/company/st-albans-digital-printing/"
          target="blank"
          >
            <FaLinkedinIn />
          </a>
        )}
        {loadingTime ? (
          <Skeleton width="20px" height="20px" />
        ) : (
          <a
          className="text-dark px-2"
          href="https://www.instagram.com/stalbansprinting/profilecard/?igsh=ZXJwbm5yNTVsZW53"
          target="blank"
          >
            <FaInstagram />
          </a>
        )}
      </div>
    </div>
  );
};

export default SocialMedia;
