import React, { useEffect, useState } from 'react'
import product1 from '../../assets/Product/product1.jpg'
import product2 from '../../assets/Product/product2.jpg'
import { FaCartArrowDown, FaEye } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import ourProduct1 from '../../assets/OurProducts/ourProduct1.png'
import ourProduct2 from '../../assets/OurProducts/ourProduct2.png'
import ourProduct3 from '../../assets/OurProducts/ourProduct3.png'
import ourProduct4 from '../../assets/OurProducts/ourProduct4.png'
import ourProduct5 from '../../assets/OurProducts/ourProduct5.png'
import Skeleton from '../../pages/skeleton/Skeleton';

const Template = () => {

  const products = [
      { id: 1, img: product1, title: "Colorful Stylish Shirt" },
      { id: 2, img: product2, title: "Colorful Stylish Shirt" },
      { id: 3, img: product1, title: "Colorful Stylish Shirt" },
      { id: 4, img: product2, title: "Colorful Stylish Shirt" },
      { id: 5, img: product1, title: "Colorful Stylish Shirt" },
      { id: 6, img: product2, title: "Colorful Stylish Shirt" },
      { id: 1, img: product1, title: "Colorful Stylish Shirt" },
      { id: 2, img: product2, title: "Colorful Stylish Shirt" }
    ];
  const [isZoomed, setIsZoomed] = useState(false);
  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };    

  const ourProducts = [ 
    {
      id: 1,
      image: ourProduct1,
      url: 'https://xotemtech.com'
    }, 
    {
      id: 2,
      image: ourProduct2,
      url: 'https://citypulse.craftcominc.com'
    }, 
    {
      id: 3,
      image: ourProduct3,
      url: 'https://craftcominc.com'
    }, 
    {
      id: 4,
      image: ourProduct4,
      url: 'https://church.3pstudio.us'
    }, 
    {
      id: 5,
      image: ourProduct5,
      url: 'https://3pstudio.us'
    }, 
  ]

  const [loadingTime, setLoadingTime] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTime(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [])

  return (
    <div className='mt-8 sm:mt-10 lg:mt-14 sm:px-5 md:px-9 lg:px-10'>

      {/* <div className='w-full flex flex-col items-center justify-center'>
        <div className='flex flex-col w-11/12 px-5 items-center justify-center shadow-[5px_0px_20px_0px_rgb(0,0,0,0.1);] rounded-xl py-5 gap-5'>
          {loadingTime ? (
              <>
              <div className='flex items-center justify-center'>
                <Skeleton width='300px' height='36px' marginBottom='6px'/>
              </div>
              <div className='flex w-full justify-center'>
                <Skeleton width='400px' height='18px' marginBottom='4px'/>
              </div>
              <div className='flex gap-1 sm:gap-4'>
                <Skeleton width='140px' height='34px'/>
                <Skeleton width='140px' height='34px'/>
              </div>
              </>
            ) : (
              <>
              <div className='flex gap-3 items-center justify-center'>
                <p className='w-10 h-[2px] bg-black'></p>
                <h1 className='text-3xl md:text-4xl font-semibold whitespace-nowrap'>Templates</h1>
                <p className='w-10 h-[2px] bg-black'></p>
              </div>
              <p className='text-neutral-400 text-center text-[17px]'>Choose From Our Ready-made Templates Lists</p>
              <div className='flex gap-1 sm:gap-4'>
                <button className='px-2 sm:px-4 py-1 sm:py-2 bg-orange-400 hover:text-white duration-200 border-2 whitespace-nowrap rounded'>Design Templates</button>
                <button className='px-2 sm:px-4 py-1 sm:py-2 border-2 rounded whitespace-nowrap  hover:bg-orange-400 hover:text-white duration-200'>Blank Templates</button>
              </div>
              </>
            )}
        </div>
      </div>

      <div className='mt-4 md:mt-6 lg:mt-8 grid grid-cols-2 sm:grid-cols-4 gap-3 sm:gap-4 md:gap-10'>
        {loadingTime ? (
          <>
          <div>
            <div>
              <div
                className='w-full h-60 border'
              >
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className="border-l border-r flex w-full justify-center pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                <Skeleton width='130px' height='18px'/>
              </div>
              <div className="flex py-[6px] justify-around md:justify-between items-center gap-1 border">
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div>
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div> 
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className='w-full h-60 border'
              >
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className="border-l border-r flex w-full justify-center pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                <Skeleton width='130px' height='18px'/>
              </div>
              <div className="flex py-[6px] justify-around md:justify-between items-center gap-1 border">
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div>
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div> 
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className='w-full h-60 border'
              >
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className="border-l border-r flex w-full justify-center pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                <Skeleton width='130px' height='18px'/>
              </div>
              <div className="flex py-[6px] justify-around md:justify-between items-center gap-1 border">
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div>
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div> 
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className='w-full h-60 border'
              >
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className="border-l border-r flex w-full justify-center pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                <Skeleton width='130px' height='18px'/>
              </div>
              <div className="flex py-[6px] justify-around md:justify-between items-center gap-1 border">
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div>
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div> 
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className='w-full h-60 border'
              >
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className="border-l border-r flex w-full justify-center pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                <Skeleton width='130px' height='18px'/>
              </div>
              <div className="flex py-[6px] justify-around md:justify-between items-center gap-1 border">
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div>
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div> 
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className='w-full h-60 border'
              >
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className="border-l border-r flex w-full justify-center pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                <Skeleton width='130px' height='18px'/>
              </div>
              <div className="flex py-[6px] justify-around md:justify-between items-center gap-1 border">
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div>
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div> 
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className='w-full h-60 border'
              >
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className="border-l border-r flex w-full justify-center pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                <Skeleton width='130px' height='18px'/>
              </div>
              <div className="flex py-[6px] justify-around md:justify-between items-center gap-1 border">
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div>
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div> 
              </div>
            </div>
          </div>
          <div>
            <div>
              <div
                className='w-full h-60 border'
              >
                <Skeleton width='100%' height='100%'/>
              </div>
              <div className="border-l border-r flex w-full justify-center pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                <Skeleton width='130px' height='18px'/>
              </div>
              <div className="flex py-[6px] justify-around md:justify-between items-center gap-1 border">
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div>
                <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                  <Skeleton width='100px' height='22px'/>
                </div> 
              </div>
            </div>
          </div>
          </>
        ) : (
           products.map((product) => (
            <div key={product.id} >
              <div>
                <div
                  className='relative md:h-auto overflow-hidden flex justify-center bg-transparent border cursor-pointer'
                  onClick={() => handleImageClick}
                >
                  <img
                    className={`w-24 sm:w-32 my-4 sm:mx-3 md:w-full transition-transform duration-500 ease-in-out hover:scale-125 ${isZoomed ? 'scale-125' : ''}`}
                    src={product.img} alt={product.title} />
                </div>
                <div className="card-body border-l border-r text-center p-0 pt-1 pb-1 px-2 sm:pt-4 sm:pb-3">
                  <h6 className="text-truncate sm:mb-3">{product.title}</h6>
                  <div className="flex justify-center">
                    <h6>{product.price}</h6>
                  </div>
                </div>
                <div className="flex py-[6px] sm:py-2 px-2 sm:px-3 text-sm justify-around md:justify-between items-center text-gray-700 gap-1 bg-gray-100 border">
                  <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                    <FaEye className='text-orange-700'/>
                    <button className='text-xs'>
                      <span className='hidden sm:inline'>View</span> Detail
                    </button>
                  </div>
                  <div className='flex items-center justify-center gap-[2px] sm:gap-1'>
                    <FaCartArrowDown className='text-orange-700'/>
                    <button className='text-xs'>
                      Add <span className='hidden sm:inline'>To Cart</span>
                    </button>
                  </div>
                  
                </div>
              </div>
            </div>
          ))
        )}
      </div> 
      <div className='mt-4 md:mt-6 lg:mt-8 w-full flex flex-col items-center justify-center'>
        {loadingTime ? (
          <Skeleton width='180px' height='40px'/>
        ) : (
        <button className='px-6 py-2 bg-orange-300 hover:bg-orange-400 duration-200 border-2 border-spacing-2 rounded-md'>View All Products</button>
        )}
      </div> */}

      <div className='mt-8 sm:mt-10 lg:mt-14 flex items-center justify-center'>
        {loadingTime ? (
          <div className='w-[400px] sm:[500px] h-11'>
            <Skeleton width='100%' height='100%' marginBottom='8px'/>
          </div>
        ) : (
          <>
          <p className='w-10 h-[2px] bg-black'></p>
          <h1 className='text-2xl md:text-[26px] lg:text-3xl xl:text-4xl rounded md:rounded-md font-semibold whitespace-nowrap px-6 py-1 bg-[#EDF1FF]'>Our Allied Companies</h1>
          <p className='w-10 h-[2px] bg-black'></p>
          </>
        )}  
      </div>
      
      <div className='mt-4 md:mt-6 lg:mt-8 w-full flex justify-center'>
        <div className="ourProduct-slide w-[95%] flex justify-center">
          {loadingTime ? (
            <>
              <div className='grid grid-cols-4 w-full gap-[10px]'>
                <div className='flex justify-center w-full h-28 border p-3'>
                  <Skeleton width='180px' height='100%'/>
                </div>
                <div className='flex justify-center w-full h-28 border p-3'>
                  <Skeleton width='180px' height='100%'/>
                </div>
                <div className='flex justify-center w-full h-28 border p-3'>
                  <Skeleton width='180px' height='100%'/>
                </div>
                <div className='flex justify-center w-full h-28 border p-3'>
                  <Skeleton width='180px' height='100%'/>
                </div>
              </div>
            </>
          ) : (
          <Swiper
            spaceBetween={10}
            centeredSlides={false}
            slidesPerView={4}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            slide
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidePerView: 4,
              },
            }}
          >
            {ourProducts.map((ourProduct) => (
              <SwiperSlide key={ourProduct.i} >
              <a href={ourProduct.url} target="_blank" rel="noopener noreferrer" className='relative h-20 md:h-28 flex items-center justify-center border p-3 cursor-pointer'>
                <img src={ourProduct.image} alt="ourProduct list" className='h-full'/>
              </a>
              </SwiperSlide>
            ))} 
          </Swiper>
          )}
        </div>
      </div>

    </div>
  )
}

export default Template
