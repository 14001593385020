import React, { useState } from 'react'
import contact from '../assets/Carousel/St Albans Banner 01.png'
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'
import { saveContactUsDetails } from '../serverApi/server'
import Captcha from './Captcha'

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [mailExits, setMailExits] = useState(false);
  const [mailExitsMessage, setMailExitsMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [messageSentSuccess, setMessageSentSuccess] = useState('');

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const handleCaptchaVerify = (success) => {
    setIsCaptchaVerified(success);
  };

  const validate = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = 'Name is required';
    if (!email.trim() || !/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      newErrors.email = 'Valid email is required';
    }
    if (!phone.trim() || !/^\d{9,20}$/.test(phone)) {
      newErrors.phone = 'Valid phone number is required';
    }
    if (!subject.trim()) newErrors.subject = 'Subject is required';
    if (!message.trim()) newErrors.message = 'Message cannot be empty';
    if (!isCaptchaVerified) newErrors.captcha = 'Please verify Captcha';
    return newErrors;
  };

  const sendMessage = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setMailExits(false);
    setIsLoading(true);
    const saveContactUsDetailsAPIResponse = await saveContactUsDetails(name, email, phone, subject, message)
    if (saveContactUsDetailsAPIResponse.data.id === 1) {
      setMailExitsMessage(saveContactUsDetailsAPIResponse.data.message)
      setMailExits(true);
      setErrors({});
    } else if (saveContactUsDetailsAPIResponse.data.id === 2) {
      setMailExitsMessage(saveContactUsDetailsAPIResponse.data.message)
      setMailExits(true);
    } else if (saveContactUsDetailsAPIResponse.data.id === 3) {
      setName('');
      setEmail('');
      setPhone('');
      setSubject('');
      setMessage('');
      setErrors({});
      setMessageSentSuccess(saveContactUsDetailsAPIResponse.data.message)
      setMessageSent(true);
      setTimeout(() => {
        setMessageSent(false);
      }, 15000)
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className='px-5 sm:px-10 flex flex-col items-center justify-center'>
        <div className='relative w-full h-full mb-5 sm:mb-10'>
          <img src={contact} alt="contact banner" className='w-full h-full' />
          <div className='absolute top-0 w-full h-full bg-black/40 flex items-center justify-center'>
            <h1 className='text-2xl sm:text-4xl font-medium text-white drop-shadow-md'>Contact us</h1>
          </div>
        </div>
        <div className='flex gap-1 sm:gap-3 items-center justify-center'>
          <p className='w-20 sm:w-12 h-[2px] bg-black'></p>
          <h1 className='text-3xl text-center sm:text-4xl font-semibold'>Contact For Any Queries</h1>
          <p className='w-20 sm:w-12 h-[2px] bg-black'></p>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-[55%_40%] justify-center gap-10 w-full pt-8 pb-4 sm:pb-12'>
          <div className='px-4 py-4 sm:px-8 sm:py-8 bg-neutral-50 shadow-[0_3px_20px_-4px_rgba(0,0,0,0.1)] rounded-xl'>
            <div className='flex flex-col items-start justify-start gap-4'>
              <div className='w-full'>
                <input
                  type='text'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className='border border-neutral-300 rounded focus:shadow-[0_0_10px_-5px_rgba(0,0,0,0.5)] transition-shadow duration-200 focus:outline-none px-3 py-2 w-full'
                />
                {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}
              </div>
              <div className='w-full'>
                <input
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='border border-neutral-300 rounded focus:shadow-[0_0_10px_-5px_rgba(0,0,0,0.5)] transition-shadow duration-200 focus:outline-none px-3 py-2 w-full'
                />
                {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
              </div>
              <div className='w-full'>
                <input
                  type='text'
                  placeholder='Phone'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className='border border-neutral-300 rounded focus:shadow-[0_0_10px_-5px_rgba(0,0,0,0.5)] transition-shadow duration-200 focus:outline-none px-3 py-2 w-full'
                />
                {errors.phone && <p className='text-red-500 text-sm'>{errors.phone}</p>}
              </div>
              <div className='w-full'>
                <input
                  type='text'
                  placeholder='Subject'
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className='border border-neutral-300 rounded focus:shadow-[0_0_10px_-5px_rgba(0,0,0,0.5)] transition-shadow duration-200 focus:outline-none px-3 py-2 w-full'
                />
                {errors.subject && <p className='text-red-500 text-sm'>{errors.subject}</p>}
              </div>
              <div className='w-full'>
                <textarea
                  placeholder='Message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className='h-44 border border-neutral-300 rounded focus:shadow-[0_0_10px_-5px_rgba(0,0,0,0.5)] transition-shadow duration-200 focus:outline-none px-3 py-2 w-full resize-none'
                />
                {errors.message && <p className='text-red-500 text-sm'>{errors.message}</p>}
                {mailExits && <p className='text-red-500 text-sm'>{mailExitsMessage}</p>}
              </div>
              <div className="w-full">
                <Captcha onVerify={handleCaptchaVerify} />
                {errors.captcha && <p className='text-red-500 text-sm'>{errors.captcha}</p>}
              </div>
              <button
                className={`my-auto px-4 py-2 bg-orange-400 text-neutral-600 hover:bg-orange-600 hover:text-white duration-200 rounded flex items-center justify-center transform transition-all ${isLoading ? 'cursor-wait scale-95 animate-pulse' : 'cursor-pointer'}`}
                onClick={sendMessage}
                disabled={isLoading}
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin text-white mr-2"></i>
                ) : (
                  'Send Message'
                )}
                {isLoading ? 'Submitting...' : ''}
              </button>
              {messageSent && <p className='text-green-500 text-sm font-bold'>{messageSentSuccess}</p>}
            </div>
          </div>
          <div>
            <div>
              <h2 className='text-xl font-semibold'>Get In Touch</h2>
              <p className='py-3 text-neutral-500 text-[17px]'>Reach out to us for any inquiries or assistance. We’re here to help you with any questions. Our team is dedicated to providing you with quality service and support. Connect with us and let us make your experience seamless.</p>
            </div>
            <div className='pb-4'>
              <h2 className='pb-3 text-xl font-semibold'>Store 1</h2>
              <p className='pb-2 text-neutral-500 text-[17px]'><FaMapMarkerAlt className='text-orange-600 inline relative bottom-[2px] mr-2' /> 199-12 Linden Blvd,<br />
                St. Albans, Queens,<br />
                NY 11412</p>
              <p className='pb-2 text-neutral-500 text-[17px]'><IoIosMail className='text-orange-600 inline relative bottom-[2px] mr-2' /> orders@stalbansprinting.com</p>
              <p className='text-neutral-500 text-[17px]'><FaPhoneAlt className='text-orange-600 inline relative bottom-[2px] mr-2' /> 718-528-5100</p>
            </div>
            {/* <div className='pb-4'>
              <h2 className='pb-3 text-xl font-semibold'>Store 2</h2>
              <p className='pb-2 text-neutral-500 text-[17px]'><FaMapMarkerAlt className='text-orange-600 inline relative bottom-[2px] mr-2'/> North Carolina division,<br/>
                307 Greentown Ave,<br/>
                Warrenton, NC 27589</p> 
              <p className='pb-2 text-neutral-500 text-[17px]'><IoIosMail className='text-orange-600 inline relative bottom-[2px] mr-2'/> orders@stalbansprinting.com</p> 
              <p className='text-neutral-500 text-[17px]'><FaPhoneAlt className='text-orange-600 inline relative bottom-[2px] mr-2'/> 919-500-9648</p> 
            </div> */}
          </div>
        </div>
      </div>
      <div className='mt-5 mb-8 sm:mb-16 w-full'>
        <iframe
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&q=st.Albansprinting,+199-12+Linden+Blvd,+St.+Albans,+Queens,+NY+11412"
          className='border-none w-full h-96' title='shop location' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Contact
