// import React, { useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { loginUserAPI } from '../serverApi/server';

// const Login = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { totalPrice, invoiceNumber, loginType } = location.state || {};
//   const [loginData, setLoginData] = useState({
//     email: '',
//     password: ''
//   });

//   const [loginSuccessful, setLoginSuccessful] = useState(false);
//   const [incorrectPassword, setIncorrectPassword] = useState('');

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setLoginData((prevData) => ({
//       ...prevData,
//       [name]: value.replace(/\s+/g, '')
//     }));
//     if (name === 'password') {
//       setIncorrectPassword('');
//     }
//   };

//   const handleSubmit = async (e) => {
//     var login_type = 'user'
//     e.preventDefault();
//     try {
//       const response = await loginUserAPI(
//         loginData.email,
//         loginData.password,
//         login_type
//       );

//       if (response.data.client_status_code === 200) {
//         setLoginSuccessful(true);
//         if (loginType === 'purchase') {
//           navigate('/payment-page', { state: { userId: response.data.user_id, totalPrice: totalPrice, invoiceNumber: invoiceNumber } });
//         } else {
//           navigate('/orders', { state: response.data.user_id });
//         }
//         // navigate('/payment-page', { state: {userId: userId, totalPrice: totalPrice, invoiceNumber: invoiceNumber} })
//       } else {
//         setIncorrectPassword(response.data.message || 'Incorrect email (or) password');
//       }
//     } catch (error) {
//       setIncorrectPassword('An error occurred. Please try again.');
//     }
//   };

//   const registerUser = () => {
//     navigate('/register', { state: { totalPrice: totalPrice, invoiceNumber: invoiceNumber } });
//   }

//   return (
//     <div className="px-5 sm:px-10 md:px-20 pb-16 flex justify-center">
//       <div className="shadow-xl w-11/12 sm:w-3/4 lg:w-2/5">
//         <form onSubmit={handleSubmit} className="w-full pt-6 md:pt-16 pb-8 px-5">
//           <h1 className="text-center text-orange-400 mb-5 font-bold text-2xl tracking-wide">Login</h1>

//           <label htmlFor="email" className="block text-neutral-700 font-medium mb-4 text-lg">Email:</label>
//           <input
//             type="email"
//             name="email"
//             value={loginData.email}
//             onChange={handleChange}
//             required
//             className="focus:outline-none text-neutral-600 px-2 py-1 sm:py-2 rounded w-full border-neutral-300 border mb-2"
//           />

//           <label htmlFor="password" className="block text-neutral-700 font-medium mb-4 text-lg">Password:</label>
//           <input
//             type="password"
//             name="password"
//             value={loginData.password}
//             onChange={handleChange}
//             required
//             className={`focus:outline-none relative text-neutral-600 px-2 py-1 sm:py-2 rounded w-full border-neutral-300 border ${incorrectPassword ? 'mb-0' : 'mb-2'}`}
//           />
//           {incorrectPassword && (
//             <p className="text-red-500 text-sm">{incorrectPassword}</p>
//           )}

//           <p className="text-orange-600 mb-1">Forget Password?</p>

//           <button
//             type="submit"
//             className="block rounded focus:border-opacity-100 border-neutral-400 border-[3px] border-opacity-0 w-full px-2 py-1 bg-orange-400 hover:text-white hover:bg-orange-600 duration-200 mb-2"
//           >
//             Login
//           </button>
//         </form>
//         <p className="text-center" style={{ marginBottom: '23px' }}>
//           <span>
//             Don’t have an account? <button className="text-green-600" onClick={registerUser}>Register now</button>
//           </span>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Login;



import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginUserAPI } from '../serverApi/server';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing icons for showing and hiding password
import { encryptToken, setCookie } from '../components/Helper/Helpers'

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { totalPrice, invoiceNumber, loginType, couponId, turnAround } = location.state || {};
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });

  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value.replace(/\s+/g, '')
    }));
    if (name === 'password') {
      setIncorrectPassword('');
    }
  };

  const handleSubmit = async (e) => {
    var login_type = 'user';
    e.preventDefault();
    try {
      const response = await loginUserAPI(
        loginData.email,
        loginData.password,
        login_type
      );

      if (response.data.client_status_code === 200) {
        const { access_token, refresh_token, user_id, user_role } = response.data;
        const encryptedAccessToken = access_token
        const encryptedRefreshToken = refresh_token
        setCookie('_patsap', encryptedAccessToken, 100);
        setCookie('_prtsap', encryptedRefreshToken, 364);
        setCookie('_puisap', user_id, 364);
        setCookie('_prsap', user_role, 364);
        setLoginSuccessful(true);
        if (loginType === 'purchase') {
          navigate('/payment-page', { state: { userId: response.data.user_id, totalPrice: totalPrice, invoiceNumber: invoiceNumber, couponId: couponId, turnAround: turnAround } });
        } else {
          navigate('/orders', { state: response.data.user_id });
        }
      } else {
        setIncorrectPassword(response.data.message || 'Incorrect email (or) password');
      }
    } catch (error) {
      setIncorrectPassword('An error occurred. Please try again.');
    }
  };

  const registerUser = () => {
    navigate('/register', { state: { totalPrice: totalPrice, invoiceNumber: invoiceNumber, couponId: couponId, turnAround: turnAround } });
  }

  return (
    <div className="px-5 sm:px-10 md:px-20 pb-16 flex justify-center">
      <div className="shadow-xl w-11/12 sm:w-3/4 lg:w-2/5">
        <form onSubmit={handleSubmit} className="w-full pt-6 md:pt-16 pb-8 px-5">
          <h1 className="text-center text-orange-400 mb-5 font-bold text-2xl tracking-wide">Login</h1>

          <label htmlFor="email" className="block text-neutral-700 font-medium mb-4 text-lg">Email:</label>
          <input
            type="email"
            name="email"
            value={loginData.email}
            onChange={handleChange}
            required
            className="focus:outline-none text-neutral-600 px-2 py-1 sm:py-2 rounded w-full border-neutral-300 border mb-2"
          />

          <label htmlFor="password" className="block text-neutral-700 font-medium mb-4 text-lg">Password:</label>
          <div className="relative mb-2">
            <input
              type={showPassword ? 'text' : 'password'} // Toggle password visibility
              name="password"
              value={loginData.password}
              onChange={handleChange}
              required
              className={`focus:outline-none text-neutral-600 px-2 py-1 sm:py-2 rounded w-full border-neutral-300 border ${incorrectPassword ? 'mb-0' : 'mb-2'}`}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)} // Toggle the state
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
            >
              {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />} {/* Show eye icon */}
            </button>
          </div>
          {incorrectPassword && (
            <p className="text-red-500 text-sm">{incorrectPassword}</p>
          )}

          <p className="text-orange-600 mb-1">Forget Password?</p>

          <button
            type="submit"
            className="block rounded focus:border-opacity-100 border-neutral-400 border-[3px] border-opacity-0 w-full px-2 py-1 bg-orange-400 hover:text-white hover:bg-orange-600 duration-200 mb-2"
          >
            Login
          </button>
        </form>
        <p className="text-center" style={{ marginBottom: '23px' }}>
          <span>
            Don’t have an account? <button className="text-green-600" onClick={registerUser}>Register now</button>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
