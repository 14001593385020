import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import { useLocation } from "react-router-dom";
import { getCategorySubMenusAPI, fetchCategoriesAPI, uploadKeywordsAPI, deleteCategoryAPI } from "../../serverApi/server";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FaSearch } from 'react-icons/fa';

const CategoryKeywords = () => {
    const location = useLocation();
    const formheaderMenus = location.state || [];
    const toggleForm = () => setFormOpen(!formOpen);
    const [formOpen, setFormOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [formSubMenuList, setFormSubMenuList] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const [selectedFormHeader, setSelectedFormHeader] = useState(
        formheaderMenus.find((menu) => menu.additional_name === 'memorial_funeral')?.id || ''
    );
    const [formCategoryList, setFormCategoryList] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [updatingId, setUpdatingId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [editOpen, setEditOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editedKeywords, setEditedKeywords] = useState("");
    const [currentPageData, setCurrentPageData] = useState([]);
    const filteredData = currentPageData.filter((category) =>
        category.category.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleFormHeaderChange = (e) => {
        setSelectedFormHeader(e.target.value);
    };
    const toggleEdit = () => {
        setEditOpen(!editOpen);
    };
    const openEditModal = (category) => {
        setEditingCategory(category);
        setEditedKeywords(category.keywords || "");
        toggleEdit();
    };

    const handleUpdate = async () => {
        if (updatingId === editingCategory.id) {
            return;
        }

        setUpdatingId(editingCategory.id);
        try {
            const response = await uploadKeywordsAPI({
                category_id: editingCategory.id,
                keywords: editedKeywords,
            });

            if (response && response.includes("Keywords uploaded successfully")) {
                await fetchCategories();
                setCategories(prevCategories =>
                    prevCategories.map(category =>
                        category.id === editingCategory.id
                            ? { ...category, keywords: editedKeywords }
                            : category
                    )
                );
                setSuccessMessage("Keywords updated successfully!");
                toggleEdit();
            } else {
                console.error("Unexpected API response format:", response);
            }
        } catch (error) {
            console.error("Error updating keywords:", error);
        } finally {
            setUpdatingId(null);
        }
    };

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(''), 3000);
            return () => clearTimeout(timer);
        }
    }, [successMessage]);


    const handleDelete = async (categoryId) => {
        try {
            const successMessage = await deleteCategoryAPI(categoryId);
            const updatedCategories = categories.map(headerMenu => ({
                ...headerMenu,
                categories: headerMenu.categories.filter(category => category.id !== categoryId),
            }));
            setCategories(updatedCategories);
            setSuccessMessage(successMessage);
        } catch (error) {
            console.error("Error deleting category:", error);
        }
    };

    const handleFormSubMenuListChange = async (e) => {
        setFormSubMenuList(e.target.value);
    }

    useEffect(() => {
        const fetchCategorySubMenus = async () => {
            try {
                const getCategorySubMenusAPIResponse = await getCategorySubMenusAPI(selectedFormHeader);
                setFormCategoryList(getCategorySubMenusAPIResponse.data.categories_serializer);
                setFormSubMenuList(getCategorySubMenusAPIResponse.data.categories_serializer.find((menu) => menu.additional_name === 'bookmarks')?.id || '');
            } catch (error) {
                console.error('Error fetching FORM-sub-menus:', error);
            }
        };
        if (selectedFormHeader) {
            fetchCategorySubMenus();
        }
    }, [selectedFormHeader]);

    const fetchCategories = async () => {
        try {
            const categories = await fetchCategoriesAPI();
            setCategories(categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            category_id: formSubMenuList,
            keywords: e.target.keywords_text_area.value,
        };

        try {
            const message = await uploadKeywordsAPI(payload);

            setSuccessMessage('Keywords uploaded successfully!');
            fetchCategories();
            setFormOpen(false);
        } catch (error) {
            console.error('Error uploading keywords:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const totalItems = categories.reduce((acc, headerMenu) => acc + headerMenu.categories.length, 0);
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    useEffect(() => {
        const fetchCurrentPageData = () => {
            const data = getCurrentPageData();
            setCurrentPageData(data);
        };

        fetchCurrentPageData();
    }, [currentPage, categories]);


    const getCurrentPageData = () => {
        const flatCategories = categories.flatMap(headerMenu => headerMenu.categories);
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return flatCategories.slice(startIndex, endIndex);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="flex w-full h-screen">
            <div className="fixed h-full custom-scroll">
                <SideBar />
            </div>
            <div className="ml-64 flex-1 overflow-y-auto">
                <Header />
                <div className="bg-[#F5F7FD] px-7 py-6">
                    <div className="flex items-center justify-between">
                        <div className="text-blue-950 flex items-center gap-6">
                            <h1 className="pr-6 text-2xl font-medium border-r">Admin User</h1>
                            <div className="flex text-sm items-center gap-3">
                                <button>
                                    <SlHome />
                                </button>
                                <SlArrowRight />
                                <button>Category Keywords Upload</button>
                            </div>
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-400 duration-200 px-4 py-2 rounded text-white"
                            onClick={toggleForm}
                        >
                            Upload Category Keywords
                        </button>
                    </div>
                    {formOpen && (
                        <div className="fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto">
                                <div className="flex justify-between items-center p-4 border-b">
                                    <h5 className="text-lg font-medium">Upload Keywords</h5>
                                    <button
                                        type="button"
                                        className="text-gray-600 text-3xl hover:text-gray-900"
                                        onClick={toggleForm}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="p-4">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="categoryType"
                                                className="block text-sm text-gray-700"
                                            >
                                                Header Name
                                            </label>
                                            <select
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                                onChange={handleFormHeaderChange}
                                                value={selectedFormHeader}
                                            >
                                                {formheaderMenus.map((menu) => (
                                                    <option key={menu.id} value={menu.id}>
                                                        {menu.menu}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="subCategory"
                                                className="block text-sm text-gray-700"
                                            >
                                                Category
                                            </label>
                                            <select
                                                id="subCategory"
                                                name="subCategory"
                                                required
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                                onChange={handleFormSubMenuListChange}
                                                value={formSubMenuList}
                                            >
                                                <option value="" disabled>
                                                    Choose an option
                                                </option>
                                                {formCategoryList &&
                                                    formCategoryList.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.category}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                htmlFor="keywords_text_area"
                                                className="block text-sm text-gray-700"
                                            >
                                                Keywords include here:
                                            </label>
                                            <textarea
                                                name="keywords_text_area"
                                                required
                                                placeholder="Enter keywords here"
                                                rows={4}
                                                className="mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm"
                                            ></textarea>
                                        </div>
                                        <div className="flex justify-end items-center p-4 border-t">
                                            <button
                                                type="button"
                                                className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                                                onClick={toggleForm}
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="submit"
                                                className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                    {successMessage && (
                        <div className="mt-4 bg-green-100 text-green-800 p-4 flex items-center justify-between rounded">
                            <div className="flex items-center gap-2">
                                <AiOutlineCheckCircle className="text-2xl" />
                                <span>{successMessage}</span>
                            </div>
                        </div>
                    )}

                    <div className="bg-white mt-10 px-3 py-4 rounded-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Category Table</h2>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search by category"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="px-4 py-2 pl-10 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                />
                                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                            </div>
                        </div>

                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white mt-8">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">
                                            CATEGORY NAME
                                        </th>
                                        <th className="px-4 py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">
                                            CATEGORY KEYWORDS
                                        </th>
                                        <th className="px-4 py-2 border border-[#48ABF7] text-white font-normal bg-blue-400">
                                            ACTION
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0
                                        ? filteredData.map((category) => (
                                            <tr key={category.id}>
                                                <td className="px-4 py-2 border border-[#48ABF7]">{category.category}</td>
                                                <td className="px-4 py-2 border border-[#48ABF7]">
                                                    {category.keywords || "No keywords yet"}
                                                </td>
                                                <td className="px-4 py-2 border border-[#48ABF7] flex items-center justify-center space-x-2">
                                                    <button
                                                        className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center"
                                                        onClick={() => openEditModal(category)}
                                                    >
                                                        <FaEdit className="mr-1" /> Update
                                                    </button>
                                                    <button
                                                        className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center"
                                                        onClick={() => handleDelete(category.id)}
                                                    >
                                                        <MdDeleteOutline className="mr-1" /> Delete
                                                    </button>
                                                </td>

                                            </tr>
                                        ))
                                        : currentPageData.map((category) => (
                                            <tr key={category.id}>
                                                <td className="px-4 py-2 border border-[#48ABF7]">{category.category}</td>
                                                <td className="px-4 py-2 border border-[#48ABF7]">
                                                    {category.keywords || "No keywords yet"}
                                                </td>
                                                <td className="px-4 py-2 border border-[#48ABF7] flex items-center justify-center space-x-2">
                                                    <button
                                                        className="bg-blue-500 text-white py-1 px-3 rounded-full flex items-center"
                                                        onClick={() => openEditModal(category)}
                                                    >
                                                        <FaEdit className="mr-1" /> Update
                                                    </button>
                                                    <button
                                                        className="bg-red-500 text-white py-1 px-3 rounded-full flex items-center"
                                                        onClick={() => handleDelete(category.id)}
                                                    >
                                                        <MdDeleteOutline className="mr-1" /> Delete
                                                    </button>
                                                </td>

                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            {editOpen && (
                                <div
                                    className="overflow-y-auto fixed inset-0 h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
                                    aria-hidden="true"
                                >
                                    <div className="bg-white rounded-lg w-11/12 max-w-md max-h-[90vh] overflow-y-auto animate-slide-down">
                                        <div className="flex justify-between items-center p-4 border-b">
                                            <h5 className="text-lg font-medium">Edit Keywords</h5>
                                            <button
                                                type="button"
                                                className="text-gray-600 text-3xl hover:text-gray-900"
                                                onClick={toggleEdit}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                        <div className="p-4">
                                            <form>
                                                <div className="mb-4">
                                                    <label
                                                        htmlFor="keywords"
                                                        className="block text-sm text-gray-700"
                                                    >
                                                        Update Keywords
                                                    </label>
                                                    <textarea
                                                        id="keywords"
                                                        value={editedKeywords}
                                                        onChange={(e) => setEditedKeywords(e.target.value)}
                                                        placeholder="Enter keywords here"
                                                        className="h-[150px] mt-2 block w-full rounded-md border-2 px-3 py-2 focus:outline-none shadow-sm resize-none"
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div className="flex justify-end items-center p-4 border-t">
                                            <button
                                                type="button"
                                                className="bg-red-500 hover:bg-red-400 duration-200 px-3 py-2 mr-3 text-white rounded"
                                                onClick={toggleEdit}
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="button"
                                                className="bg-blue-500 hover:bg-blue-400 duration-200 px-3 py-2 text-white rounded"
                                                onClick={handleUpdate}
                                                disabled={updatingId === editingCategory.id}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                    <div className="flex justify-between items-center mt-4">
                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                    {loading && (
                        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-90 flex justify-center items-center z-50">
                            <div className="w-12 h-12 border-4 border-gray-300 border-t-4 border-t-orange-500 rounded-full animate-spin"></div>
                            <p className="mt-4 text-gray-600">Loading, please wait...</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

};
export default CategoryKeywords;
