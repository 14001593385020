// import React, { useEffect, useRef, useState } from 'react'
// import { Link, useNavigate } from 'react-router-dom'
// import { CiSearch } from 'react-icons/ci';
// import { IoMdArrowDropdown } from 'react-icons/io';
// import { FaCartArrowDown, FaEye } from 'react-icons/fa'
// import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
// import { GetCategoryImagesAPI } from '../serverApi/server'
// import { CONNECTION_REFUSED } from '../components/Helper/Helpers'

// const AnniversaryImages = ({ title, additionalName }) => {
//   const navigate = useNavigate();
//   const [products, setProducts] = useState('');
//   const [showPopup, setShowPopup] = useState(false);
//   const [popupMessage, setPopupMessage] = useState('');
//   const [showPopupRefused, setShowPopupRefused] = useState(false);
//   const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
//   const [description, setDescription] = useState(null);

//   const pageSize = 500;
//   const [totalPageAPI, setTotalPageAPI] = useState(null);
//   const [currentPageAPI, setCurrentPageAPI] = useState(null);
//   const [previousPageAPI, setPreviousPageAPI] = useState(null);
//   const [nextPageAPI, setNextPageAPI] = useState(null);
  
//   const GetOurProductsCategoryImages = async (additionalName, page, pageSize) => {

//     try {
//       const GetCategoryImagesApiResponse = await GetCategoryImagesAPI(additionalName, page, pageSize);
//       if (GetCategoryImagesApiResponse.status === 200 && GetCategoryImagesApiResponse.data && GetCategoryImagesApiResponse.data.category_serializer.length !== 0) {
//         setProducts(GetCategoryImagesApiResponse.data.category_serializer);
//         setDescription(GetCategoryImagesApiResponse.data);
//         setCurrentPageAPI(GetCategoryImagesApiResponse.data.current_page);
//         setTotalPageAPI(GetCategoryImagesApiResponse.data.total_pages);
//         setPreviousPageAPI(GetCategoryImagesApiResponse.data.previous_page)
//         setNextPageAPI(GetCategoryImagesApiResponse.data.next_page)
//       } else {
//         setPopupMessage(GetCategoryImagesApiResponse.response.data.message);
//         setShowPopup(true);
//         setTimeout(() => setShowPopup(false), 2000);
//       }
//     } catch (error) {
//       setPopupRefusedMessage(CONNECTION_REFUSED);
//       setShowPopupRefused(true);
//       setTimeout(() => setShowPopupRefused(false), 2000);
//     }
//   };
//   const firstPage = 1;
//   useEffect(() => {
//     GetOurProductsCategoryImages(additionalName, firstPage, pageSize)
//   }, [additionalName]);
  
//   const [currentPage, setCurrentPage] = useState(currentPageAPI);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//     GetOurProductsCategoryImages(additionalName, page, pageSize);
//   };

//   const [dropdownOpen, setDropdownOpen] = useState(false);

//   const dropdownRef = useRef(null);

//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownOpen(false);
//       }
//     }

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [dropdownOpen]);

//   const [isZoomed, setIsZoomed] = useState(false);

//   const handleImageClick = () => {
//     setIsZoomed(!isZoomed);
//   };

//   const formPageDetails = (product) => {
//     if (additionalName === 'booklets' || additionalName === 'paperback_books' || additionalName === 'hardcover_books' || additionalName === 'magazines' || additionalName === 'catalogs' || additionalName === 'share_&_sell') {
//       navigate(`/booklets-design/${product.id}`)
//     } else {
//       navigate(`/shopdetails/${product.id}`)
//     }
//   }

//   return (
//     <div>

//         <div className='px-4 md:px-14 py-5 md:py-6  gap-8'>

//             <div class="mb-10 sm:mb-10 mt-4 sm:mt-6">
//                 <div class="flex flex-col items-center">
//                     <h1 class="font-semibold uppercase mb-3 text-2xl md:text-3xl">{description && description.category_name}</h1>
//                     <div class="inline-flex w-full md:11/12 lg:w-3/4"
//                         dangerouslySetInnerHTML={{
//                         __html: description && description.category_description,
//                         }}
//                     >
//                     </div>
//                 </div>
//             </div>

//             <div className='mt-5 lg:mt-0 w-full'>
//                 <div className='mt-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 sm:gap-4 md:gap-6'>
//                     {products && products.map((product) => (
//                     <div onClick={() => formPageDetails(product)} key={product.id} >
//                         <div className=''>
//                         <div
//                             className='relative w-full h-full overflow-hidden flex justify-center bg-transparent border-t border-r border-l cursor-pointer'
//                             onClick={() => handleImageClick}
//                         >
//                             <img
//                             className={`w-full h-full transition-transform duration-500 ease-in-out hover:scale-125 ${isZoomed ? 'scale-125' : ''}`}
//                             src={product.image_file} alt={product.image_name} />
//                         </div>
//                         <div className="flex py-[6px] sm:py-2 px-2 sm:px-3 text-sm justify-around md:justify-between items-center text-gray-700 gap-1 bg-gray-100 border">
//                         </div>
//                         </div>
//                     </div>
//                     ))}
//                 </div>
//             </div>
//         </div>

//         <div class="lightbox" id="lightbox">
//             <span class="close" onclick="closeLightbox()">&times;</span>
//             <span class="arrow left" onclick="changeImage(-1)">&#10094;</span>
//             <img id="lightbox-img" src="" alt="Expanded Image" />
//             <span class="arrow right" onclick="changeImage(1)">&#10095;</span>
//         </div>

//     </div>
//   )
// }

// export default AnniversaryImages;




import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetCategoryImagesAPI } from '../serverApi/server';
import { CONNECTION_REFUSED } from '../components/Helper/Helpers';

// import './Lightbox.css'; // Ensure to create Lightbox.css for styling

const AnniversaryImages = ({ title, additionalName }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [description, setDescription] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await GetCategoryImagesAPI(additionalName, 1, 500);
        if (response.status === 200 && response.data?.category_serializer.length !== 0) {
          setProducts(response.data.category_serializer);
          setDescription(response.data);
        }
      } catch (error) {
        console.error("Error fetching images", error);
      }
    };
    fetchImages();
  }, [additionalName]);

  const openLightbox = (index) => {
    console.log('\n indexindexindexindexindex', index)
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => setLightboxOpen(false);

  const changeImage = (direction) => {
    setCurrentImageIndex((prevIndex) => (prevIndex + direction + products.length) % products.length);
  };

  return (
    <div className='px-4 md:px-14 py-5 md:py-6 gap-8'>
        <div class="mb-10 sm:mb-10 mt-4 sm:mt-6">
            <div class="flex flex-col items-center">
                <h1 class="font-semibold uppercase mb-3 text-2xl md:text-3xl">{description && description.category_name}</h1>
                <div class="inline-flex w-full md:11/12 lg:w-3/4"
                    dangerouslySetInnerHTML={{
                    __html: description && description.category_description,
                    }}
                >
                </div>
            </div>
        </div>

        <div className='mt-5 lg:mt-0 w-full'>
            <div className='mt-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 sm:gap-4 md:gap-6'>
                {products && products.map((product, index) => (
                <div onClick={() => openLightbox(index)} key={product.id} >
                    <div className=''>
                    <div
                        className='relative w-full h-full overflow-hidden flex justify-center bg-transparent border-t border-r border-l cursor-pointer'
                        
                    >
                        <img
                        className={`w-full h-full transition-transform duration-500 ease-in-out hover:scale-125 ${isZoomed ? 'scale-125' : ''}`}
                        src={product.image_file} alt={product.image_name} />
                    </div>
                    {/* <div className="flex py-[6px] sm:py-2 px-2 sm:px-3 text-sm justify-around md:justify-between items-center text-gray-700 gap-1 bg-gray-100 border"></div> */}
                    </div>
                </div>
                ))}
            </div>
        </div>

      {lightboxOpen && (
        <div className='lightbox' onClick={closeLightbox}>
          <span className='close'>&times;</span>
          <span className='arrow left' onClick={(e) => { e.stopPropagation(); changeImage(-1); }}>&#10094;</span>
          <img src={products[currentImageIndex].image_file} style={{ width: '550px' }} alt='Expanded View' className='lightbox-img' />
          <span className='arrow right' onClick={(e) => { e.stopPropagation(); changeImage(1); }}>&#10095;</span>
        </div>
      )}

    </div>
  );
};

export default AnniversaryImages;
