import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FaEye, FaEyeSlash, FaInfoCircle, FaTruck } from 'react-icons/fa';
import { MdError } from 'react-icons/md';
import { IoClose, IoLockClosed } from "react-icons/io5";
import { BsTruck } from "react-icons/bs";
import visa from "../assets/visa.png";
import bank from "../assets/bank-transfer.png";
import discover from "../assets/discover.png";
import masterCard from '../assets/masterCard.png';
import paymentFail from '../assets/paymentFail.jpg'
import { SiTicktick } from "react-icons/si";
import { decryptToken, getCookie, setCookie } from '../components/Helper/Helpers'
import { AiOutlineDelete } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCartDetails,
  deleteCartDetail,
  getInvoiceNumber,
  loginUserAPI,
  registerUserAPI,
  checkEmailExists,
  resetPassword,
  getConvergeSessionToken,
  transactionResponse,
  generateRefreshToken,
  checkCouponValidation,
  getStateList
} from "../serverApi/server";

const CartPlusLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [shippingCost, setShippingCost] = useState(0);
  const [isCalculatingShipping, setIsCalculatingShipping] = useState(false);
  var [userId, setUserId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  var parsedCartIdList = localStorage.getItem('cartIdList') ? JSON.parse(localStorage.getItem('cartIdList')) : [];
  const FullPageLoader = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-12 h-12 border-4 border-orange-500 border-t-4 border-t-white rounded-full animate-spin"></div>
    </div>
  );

  const [stateList, setStateList] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("FEDEX_GROUND");
  const [estimatedShippingRates, setEstimatedShippingRates] = useState({
    totalNetFedexCharge: null,
    deliveryDate: "",
    deliveryDay: "",
    transitTime: "",
  });

  const [deliveryInformation, setDeliveryInformation] = useState({
    totalNetFedexCharge: null,
    deliveryDate: "",
    deliveryDay: "",
    transitTime: "",
  });

  const serviceTypeMapping = {
    FEDEX_GROUND: "FedEx Ground",
    FEDEX_EXPRESS_SAVER: "FedEx Express",
    FEDEX_2_DAY: "FedEx 2-Day",
    PRIORITY_OVERNIGHT: "FedEx Priority Overnight",
    STANDARD_OVERNIGHT: "FedEx Standard Overnight",
  };

  // const handleCalculateShippingRates = async () => {
  //   setIsCalculatingShipping(true);
  //   const result = await calculateShippingRates(zipCode, selectedServiceType);
  //   if (result) {
  //     setEstimatedShippingRates(result);
  //   }
  //   setIsCalculatingShipping(false);
  // };

  const fetchStateList = async () => {
    try {
      const response = await getStateList();
      if (response.data.client_status_code === 200) {
        setStateList(response.data.states_serializer_list);
      }
    } catch (error) {
      console.log('error fetching state', error);
    }
  }
  useEffect(() => {
    fetchStateList();
  }, []);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [registerData, setRegisterData] = useState({
    regName: "",
    regEmail: "",
    regPassword: "",
    regConfirmPassword: "",
  });

  const [forgotData, setForgotData] = useState({
    forgotEmail: "",
    forgotNewPassword: "",
    forgotConfirmPassword: ""
  });

  const [errorsResponse, setErrorsResponse] = useState({});
  const [dropDown, setDropDown] = useState('');
  const handleDropDown = (name) => {
    setDropDown((prev) => (name === prev) ? null : name);
  };

  const [showPassword, setShowPassword] = useState({
    password: false,
    regPassword: false,
    regConfirmPassword: false,
    forgotNewPassword: false,
    forgotConfirmPassword: false,
  });

  const handleShowPassword = (name) => {
    setShowPassword((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const refs = {
    email: useRef(),
    password: useRef(),
    regName: useRef(),
    regEmail: useRef(),
    regPassword: useRef(),
    regConfirmPassword: useRef(),
    forgotEmail: useRef(),
    forgotNewPassword: useRef(),
    forgotConfirmPassword: useRef()
  };

  const [isLoading, setIsLoading] = useState({
    login: false,
    register: false,
    forgotPassword: false,
    billing: false,
    shipping: false,
    payment: false,
  });

  const [showPopUp, setshowPopUp] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const handleLoginRegist = (state) => {
    setIsLogin(state);
  };

  const openPopUp = (condi) => {
    setshowPopUp(condi);
    setErrorsResponse({});
    setPasswordForm(false);
  };

  const handleLogin = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value.replace(/\s+/g, '')
    }));
  };

  const loginValidate = () => {
    const newErrors = {};
    if (!loginData.email || !/\S+@\S+\.\S+/.test(loginData.email)) {
      newErrors.email = "Valid email is required";
    }
    if (!loginData.password || loginData.password.length < 6) {
      newErrors.password = "Password must be 6 digit";
    }
    setErrorsResponse(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const [dropDownAllowed, setDropDownAllowed] = useState({
    login: false,
    billing: false,
    shipping: false,
    delivery: false,
    payment: false,
  });

  const [tickMark, setTickMark] = useState({
    login: false,
    billing: false,
    shipping: false,
    delivery: false,
    payment: false,
  });

  const decryptedRefreshToken = decryptToken(getCookie('_rtsap'));
  const decryptedAccessToken = decryptToken(getCookie('_atsap'));
  const loginUserId = getCookie('_uisap')
  const [totalPrice, setTotalPrice] = useState();
  
  useEffect(() => {
    
    handleDropDown('Login');
    setDropDownAllowed((prev) => ({
      ...prev,
      login: true,
    }));
      
    // if (decryptedRefreshToken && decryptedAccessToken && loginUserId) {
    //   setUserId(loginUserId)
    //   handleDropDown('Billing Information');
    //   setDropDownAllowed((prev) => ({
    //     ...prev,
    //     login: true,
    //   }));
    //   setDropDownAllowed((prev) => ({
    //     ...prev,
    //     billing: true,
    //   }));
    //   setTickMark((prev) => ({
    //     ...prev,
    //     login: true,
    //   }));
    // } else {
    //   handleDropDown('Login');
    //   setDropDownAllowed((prev) => ({
    //     ...prev,
    //     login: true,
    //   }));
    // }


  }, []);

  const loginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading((prev) => ({
      ...prev,
      login: true,
    }));
    if (loginValidate()) { }
    else {
      setIsLoading((prev) => ({
        ...prev,
        login: false,
      }));
      return;
    };
    var login_type = 'user';
    if (loginData.email !== '' && loginData.password !== '') {
      try {
        const response = await loginUserAPI(
          loginData.email,
          loginData.password,
          login_type
        );
        if (response.data.client_status_code === 200) {
          const { access_token, refresh_token, user_id, user_role } = response.data;
          setCookie('_patsap', access_token, 100);
          setCookie('_prtsap', refresh_token, 364);
          setCookie('_puisap', user_id, 364);
          setCookie('_prsap', user_role, 364);
          setUserId(response.data.user_id)
          handleDropDown('Billing Information');
          setTickMark((prev) => ({
            ...prev,
            login: true,
          }));
          setDropDownAllowed((prev) => ({
            ...prev,
            billing: true,
          }));

          // if (loginType === 'purchase') {
          //   navigate('/payment-page', { state: { userId: response.data.user_id, totalPrice: totalPrice, invoiceNumber: invoiceNumber, couponId: couponId } });
          // } else {
          //   navigate('/orders', { state: response.data.user_id });
          // }

        } else {
          setErrorsResponse({
            ...errorsResponse,
            commonResponse: response.data.message || 'Invalid email or password',
          });
          refs.password.current?.focus();
        }
      } catch (error) {
        setErrorsResponse({
          ...errorsResponse,
          commonResponse: 'An error occurred. Please try again.',
        });
        refs.email.current?.focus();
      }
    }
    setIsLoading((prev) => ({
      ...prev,
      login: false,
    }));
  };

  useEffect(() => {
    refs.regName.current?.focus();
  }, [showPopUp.register]);

  const handleRegister = (e) => {
    const { name, value } = e.target;
    setRegisterData((prevData) => ({
      ...prevData,
      [name]: [
        "name",
      ].includes(name)
        ? value.replace(/\s{2,}/g, " ")
        : name == 'regName'? value : value.replace(/\s+/g, ""),
    }));
  };

  const registerValidate = () => {
    const newErrors = {};
    if (!registerData.regName || registerData.regName === '') {
      newErrors.regName = "Enter your name";
    }
    if (!registerData.regEmail || !/\S+@\S+\.\S+/.test(registerData.regEmail)) {
      newErrors.regEmail = "Valid email is required";
    }
    if (!registerData.regPassword || registerData.regPassword < 6) {
      newErrors.regPassword = "Password must be 6 digit";
    }
    if (!registerData.regConfirmPassword || registerData.regConfirmPassword < 6) {
      newErrors.regConfirmPassword = "Password must be 6 digit";
    }
    if ((registerData.regPassword > 6 && registerData.regConfirmPassword > 6) && registerData.regConfirmPassword !== registerData.regPassword) {
      newErrors.regConfirmPassword = "Password do not match";
    }
    setErrorsResponse(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const registerSubmit = async (e) => {
    e.preventDefault();
    setIsLoading((prev) => ({
      ...prev,
      register: true,
    }));
    if (registerValidate()) { }
    else {
      setIsLoading((prev) => ({
        ...prev,
        register: false,
      }));
      return;
    };
    try {
      const response = await registerUserAPI(registerData);
      if (response.client_status_code === 200) {
        handleLoginRegist(true);
        setErrorsResponse({
          ...errorsResponse,
          commonResponse: response.message,
        });
        const { access_token, refresh_token, user_id, user_role } = response;
        setCookie('_patsap', access_token, 100);
        setCookie('_prtsap', refresh_token, 364);
        setCookie('_puisap', user_id, 364);
        setCookie('_prsap', user_role, 364);
        setUserId(response.user_id)
        handleDropDown('Billing Information');
        setTickMark((prev) => ({
          ...prev,
          login: true,
        }));
        setDropDownAllowed((prev) => ({
          ...prev,
          billing: true,
        }));
        // navigate("/login", {
        //   state: {
        //     totalPrice: totalPrice,
        //     invoiceNumber: invoiceNumber,
        //     loginType: "purchase",
        //     couponId: couponId,
        //   },
        // });
      } else if (response.client_status_code === 400) {
        setErrorsResponse({
          ...errorsResponse,
          regEmail: response.message
        });
        refs.regEmail.current?.focus()
        refs.regEmail.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      } else {
        setErrorsResponse({
          ...errorsResponse,
          commonResponse: response.message || "An unknown error occurred",
        });
      }
    } catch (error) {
      if (error.response?.status === 404 && error.response?.data?.message) {
        setErrorsResponse({
          ...errorsResponse,
          commonResponse: error.response.data.message,
        });
        refs.regEmail.focus();
      } else {
        setErrorsResponse({
          ...errorsResponse,
          commonResponse: "Failed to register user.",
        });
      }
    }
    setIsLoading((prev) => ({
      ...prev,
      register: false,
    }));
  };


  const [isDeliveryAddress, setIsDeliveryAddress] = useState(true);
  const handleDeliveryAddress = (condi) => {
    setIsDeliveryAddress(condi);
    handleDropDown('Payment Information');
    setTickMark((prev) => ({
      ...prev,
      shipping: true,
    }));
    setDropDownAllowed((prev) => ({
      ...prev,
      payment: true,
    }));
  }

  const [allCartDetails, setAllCartDetails] = useState(null);
  const [tax, setTax] = useState();
  const [subTotalAmount, setSubTotalAmount] = useState();
  const [discountSubTotalAmount, setDiscountSubTotalAmount] = useState();
  
  const [turnAround, setTurnAround] = useState();
  var { newCartId } = location.state || {};
  const [searchParams] = useSearchParams();
  newCartId = searchParams.get("newCartId") ? parseInt(searchParams.get("newCartId")) : newCartId
  const [couponCode, setCouponCode] = useState("");
  const [couponId, setCouponId] = useState()
  const [couponCodeMessage, setCouponCodeMessage] = useState('')
  const [couponCodeMessageStatus, setCouponCodeMessageStatus] = useState(false)
  const [discountStatus, setDiscountStatus] = useState(false)
  const [discountPrice, setDiscountPrice] = useState()
  const [couponApplyStatus, setCouponApplyStatus] = useState(false)
  const formattedSubTotalAmount = parseFloat(subTotalAmount).toFixed(2);
  const formattedDiscountSubTotalAmount = parseFloat(discountSubTotalAmount).toFixed(2);
  const formattedTax = parseFloat(tax).toFixed(2);
  const formattedTotalPrice = parseFloat(totalPrice).toFixed(2);


  const [passwordForm, setPasswordForm] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleForgot = (e) => {
    const { name, value } = e.target;
    if (name === 'forgotEmail') {
      const emailValue = value.replace(/\s+/g, '').toLowerCase();
      setForgotData((prev) => ({
        ...prev,
        forgotEmail: emailValue,
      }));
      return;
    }
    setForgotData((prev) => ({
      ...prev,
      [name]: value.replace(/\s+/g, ''),
    }));
  };

  const emailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading((prev) => ({
      ...prev,
      forgotPassword: true,
    }));
    if (forgotData.forgotEmail === '') {
      setErrorsResponse({
        forgotEmail: 'Please Enter your email'
      });
      refs.forgotEmail.current?.focus();
      setIsLoading((prev) => ({
        ...prev,
        forgotPassword: false,
      }));
      return;
    }
    try {
      const data = await checkEmailExists(forgotData.forgotEmail);

      if (data.message === 'Email exists') {
        setPasswordForm(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        refs.forgotEmail.current?.focus();
        setErrorsResponse({
          forgotEmail: 'The entered email is not registered. Please create a new account.',
        });
      } else {
        setErrorsResponse({
          forgotEmail: 'An error occurred. Please try again.',
        });
      }
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        forgotPassword: false,
      }));
    }
  };

  useEffect(() => {
    refs.forgotNewPassword.current?.focus();
  }, [passwordForm]);

  const passwordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading((prev) => ({
      ...prev,
      forgotPassword: true,
    }));
    if (forgotData.forgotNewPassword === '') {
      setErrorsResponse({
        forgotNewPassword: 'Enter your new password'
      });
      refs.forgotNewPassword.current?.focus();
      setIsLoading((prev) => ({
        ...prev,
        forgotPassword: false,
      }));;
      return;
    }
    if (forgotData.forgotConfirmPassword === '') {
      setErrorsResponse({
        forgotConfirmPassword: 'Enter your new password'
      });
      refs.forgotConfirmPassword.current?.focus();
      setIsLoading((prev) => ({
        ...prev,
        forgotPassword: false,
      }));;
      return;
    }
    if (forgotData.forgotNewPassword !== forgotData.forgotConfirmPassword) {
      setErrorsResponse({
        forgotConfirmPassword: 'Password do not match'
      });
      refs.forgotConfirmPassword.current?.focus();
      setIsLoading((prev) => ({
        ...prev,
        forgotPassword: false,
      }));;
      return;
    }
    if (forgotData.forgotNewPassword === forgotData.forgotConfirmPassword) {
      try {

        const data = await resetPassword(forgotData.forgotEmail, forgotData.forgotNewPassword);

        if (data.client_status_code === 200) {
          // setOpenSnackbar(true);
          setPasswordForm(false)
          setshowPopUp(false)
          setForgotData({
            forgotEmail: "",
            forgotNewPassword: "",
            forgotConfirmPassword: ""
          })
        } else {
          setErrorsResponse({
            ...errorsResponse,
            commonResponse: 'An error occurred. Please try again.'
          });
        }
      } catch (error) {
        setErrorsResponse({
          ...errorsResponse,
          commonResponse: 'An error occurred. Please try again.'
        });
        console.error('Error:', error);
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          forgotPassword: false,
        }));
      }
    } else {
      setErrorsResponse({
        ...errorsResponse,
        commonResponse: 'Passwords do not match'
      });
      refs.forgotConfirmPassword.current?.focus();
    }
    setIsLoading((prev) => ({
      ...prev,
      forgotPassword: false,
    }));
  };

  const [billingData, setBillingData] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    firstName: "",
    lastName: "",
    address1: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    phoneNumber: "",
  });

  const [shippingData, setShippingData] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    firstName: "",
    lastName: "",
    address1: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    phoneNumber: "",
    fedux: "",
  });

  const handleBilling = (e) => {
    const { name, value } = e.target;
    setBillingData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = `${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getFullYear()).slice(-2)}`;
      setBillingData((prev) => ({
        ...prev,
        expiryDate: formattedDate,
      }));
    } else {
      setBillingData((prev) => ({
        ...prev,
        expiryDate: "",
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   setBillingData((prev) => ({
  //     ...prev,
  //     expiryDate: date,
  //   }));
  // };

  const handleShipping = (e) => {
    const { name, value } = e.target;
    setShippingData((prev) => ({
      ...prev,
      [name]: name === "state" ? value.trim() : value,
    }));
  };

  const [errorsBill, setErrorsBill] = useState({});
  const billingValidate = () => {
    setIsLoading((prev) => ({
      ...prev,
      billing: true,
    }));
    const newErrors = {};
    if (!billingData.firstName) {
      newErrors.firstName = "First name is required.";
    }
    if (!billingData.lastName) {
      newErrors.lastName = "Last name is required.";
    }
    if (!billingData.address1) {
      newErrors.address1 = "Address1 is required.";
    }
    if (!billingData.city) {
      newErrors.city = "City is required.";
    }
    if (!billingData.state) {
      newErrors.state = "State/Province is required.";
    }
    if (!billingData.zipCode) {
      newErrors.zipCode = "Postal code is required.";
    }
    if (!billingData.email || !/\S+@\S+\.\S+/.test(billingData.email)) {
      newErrors.email = "Valid email is required.";
    }
    if (!billingData.phoneNumber || billingData.phoneNumber.length < 10) {
      newErrors.phoneNumber = "Phone number must be at least 10 digits.";
    }
    setIsLoading((prev) => ({
      ...prev,
      billing: false,
    }));
    setErrorsBill(newErrors);
    if (Object.keys(newErrors).length === 0) {
      handleDropDown('Shipping Information');
      setTickMark((prev) => ({
        ...prev,
        billing: true,
      }));
      setDropDownAllowed((prev) => ({
        ...prev,
        shipping: true,
      }));
    }
  }

  const shippingValidate = async () => {

    // setIsPickUp(false);
    // setIsPageLoading(true);

    const newErrors = {};
    if (!shippingData.firstName) {
      newErrors.SfirstName = "First name is required.";
    }
    if (!shippingData.lastName) {
      newErrors.SlastName = "Last name is required.";
    }
    if (!shippingData.address1) {
      newErrors.Saddress1 = "Address1 is required.";
    }
    if (!shippingData.city) {
      newErrors.Scity = "City is required.";
    }
    if (!shippingData.state) {
      newErrors.Sstate = "State/Province is required.";
    }
    if (!shippingData.zipCode) {
      newErrors.SzipCode = "Postal code is required.";
    }
    if (!shippingData.email || !/\S+@\S+\.\S+/.test(shippingData.email)) {
      newErrors.Semail = "Valid email is required.";
    }
    if (!shippingData.phoneNumber || shippingData.phoneNumber.length < 10) {
      newErrors.SphoneNumber = "Phone number must be at least 10 digits.";
    }

    setErrorsBill(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        handleDropDown('Payment Information');
        setTickMark((prev) => ({
          ...prev,
          shipping: true,
        }));
        setDropDownAllowed((prev) => ({
          ...prev,
          payment: true,
        }));
      } catch (error) {
        console.error('Error calculating shipping cost:', error);
      } finally {
        setIsPageLoading(false);
      }
    } else {
      setIsPageLoading(false);
    }


  };

  // const DeliverySubmit = () => {
  //   handleDropDown('Payment Information');
  //   setTickMark((prev) => ({
  //     ...prev,
  //     delivery: true,
  //   }));
  //   setDropDownAllowed((prev) => ({
  //     ...prev,
  //     payment: true,
  //   }));
  // };

  const paymentValidate = () => {
    const newErrors = {};
    if (!billingData.cardNumber || billingData.cardNumber.length !== 16) {
      newErrors.cardNumber = "Card number must be 16 digits.";
    }
    if (!billingData.expiryDate || billingData.expiryDate.length == 0) {
      newErrors.expiryDate = "Expiration date must be in MM/YY format.";
    }
    if (!billingData.cvv || billingData.cvv.length !== 3) {
      newErrors.cvv = "CVV must be 3 digits.";
    }
    setErrorsBill(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getInvoiceNumberDetails = async () => {
    const getInvoiceNumberAPIResponse = await getInvoiceNumber()
    if (getInvoiceNumberAPIResponse.data.client_status_code === 200) {
      return getInvoiceNumberAPIResponse.data.invoice_number
    } else {
      return null
    }
  }

  const getConvergeSessionTokenFunction = async (totalPrice) => {
    try {
      const getConvergeSessionTokenResponse = await getConvergeSessionToken(totalPrice);
      if (getConvergeSessionTokenResponse.status === 401) {
        const generateRefreshTokenAPIResponse = await generateRefreshToken()
        // const encryptedAccessToken = encryptToken(generateRefreshTokenAPIResponse.data.access_token);
        const encryptedAccessToken = generateRefreshTokenAPIResponse.data.access_token
        setCookie('_patsap', encryptedAccessToken, 100);
        await getConvergeSessionTokenFunction(1)
      } else if (getConvergeSessionTokenResponse.data.client_status_code === 200) {
        return getConvergeSessionTokenResponse.data.session_token
      } else {
        return null
      }
    } catch (error) {
      console.error('Error fetching session token:', error);
    }
  };

  const transactionResponseFunction = async (payment_response, user_data, couponId, shippingAddress, turnAround) => {
    try {
      const transactionAPIResponse = await transactionResponse(payment_response, user_data, couponId, shippingAddress, turnAround);
      if (transactionAPIResponse.data.client_status_code === 200) {
        return transactionAPIResponse.data
      } else {
        return null
      }
    } catch (error) {
      console.error('Error fetching session token:', error);
    }
  };

  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const user_data = {
    'user_id': userId,
    'last_name': billingData.lastName,
    'first_name': billingData.firstName,
    'email': billingData.email,
    'phone': billingData.phoneNumber,
    'state': billingData.state,
    'city': billingData.city,
    'address': billingData.address1,
    'zip': billingData.zipCode,
    'cart_id_list': parsedCartIdList
  }

  const shippingAddress = {
    'shipping_first_name': shippingData.lastName,
    'shipping_last_name': shippingData.firstName,
    'shipping_mail': shippingData.email,
    'shipping_phone_number': shippingData.phoneNumber,
    'shipping_state_province': shippingData.state,
    'shipping_city': shippingData.city,
    'shipping_address_one': shippingData.address1,
    'shipping_postal_code': shippingData.zipCode,
    'service_type': shippingData.fedux,
  }

  // const callback = {
  //   onError: async (error) => {
  //     const transactionDetailsResponse = await transactionResponseFunction(error, user_data, couponId, shippingAddress, turnAround);
  //     setIsLoading((prev) => ({
  //       ...prev,
  //       payment: false,
  //     }));
  //     localStorage.removeItem('cartIdList');
  //     setIsSubmitting(false);
  //     navigate('/pdf-upload-form', {
  //       state: {
  //         userId: transactionDetailsResponse.user_id,
  //         orderId: transactionDetailsResponse.order_id,
  //         paymentId: transactionDetailsResponse.payment_id,
  //         headerMenu: transactionDetailsResponse.header_menu
  //       }
  //     })
  //     setIsPaymentFailed(true)
  //   },
  //   onDeclined: async (response) => {
  //     const transactionDetailsResponse = await transactionResponseFunction(response, user_data, couponId, shippingAddress, turnAround);
  //     setIsLoading((prev) => ({
  //       ...prev,
  //       payment: false,
  //     }));
  //     localStorage.removeItem('cartIdList');
  //     setIsSubmitting(false);
  //     navigate('/pdf-upload-form', {
  //       state: {
  //         userId: transactionDetailsResponse.user_id,
  //         orderId: transactionDetailsResponse.order_id,
  //         paymentId: transactionDetailsResponse.payment_id,
  //         headerMenu: transactionDetailsResponse.header_menu
  //       }
  //     })
  //     // setIsPaymentFailed(true)
  //   },
  //   onApproval: async (response) => {
  //     const transactionDetailsResponse = await transactionResponseFunction(response, user_data, couponId, shippingAddress, turnAround);
  //     setIsLoading((prev) => ({
  //       ...prev,
  //       payment: false,
  //     }));
  //     localStorage.removeItem('cartIdList');
  //     setIsSubmitting(false);
  //     navigate('/pdf-upload-form', {
  //       state: {
  //         userId: transactionDetailsResponse.user_id,
  //         orderId: transactionDetailsResponse.order_id,
  //         paymentId: transactionDetailsResponse.payment_id,
  //         headerMenu: transactionDetailsResponse.header_menu
  //       }
  //     })
  //   },
  // };

  const callback = {
    onError: async  (error) => {
      setLoading(false);
      setIsLoading((prev) => ({
        ...prev,
        payment: false,
      }));
      setIsPaymentFailed(true)
      setIsSubmitting(false);
    },
    onDeclined: (response) => {
      setLoading(false);
      setIsLoading((prev) => ({
        ...prev,
        payment: false,
      }));
      setIsPaymentFailed(true)
      setIsSubmitting(false);
    },
    onApproval: async (response) => {
      const transactionDetailsResponse = await transactionResponseFunction(response, user_data, couponId, shippingAddress, turnAround);
      setIsLoading((prev) => ({
        ...prev,
        payment: false,
      }));
      setLoading(false);
      localStorage.removeItem('cartIdList');
      setIsSubmitting(false);
      navigate('/pdf-upload-form', { state: {
        userId: transactionDetailsResponse.user_id,
        orderId: transactionDetailsResponse.order_id,
        paymentId: transactionDetailsResponse.payment_id,
        headerMenu: transactionDetailsResponse.header_menu
      }})
    },
  };

  const paymentSubmit = async (e) => {
    e.preventDefault();
    const invoiceNumber = await getInvoiceNumberDetails()
    setIsSubmitting(true);
    setIsPaymentFailed(false);
    if (!paymentValidate()) {
      setIsSubmitting(false);
      return;
    }
    setIsLoading((prev) => ({
      ...prev,
      payment: true,
    }));
    if (!userId && !totalPrice && !invoiceNumber) {
      setIsLoading((prev) => ({
        ...prev,
        payment: false,
      }));
      setIsSubmitting(false);
      return;
    }
    const convergeSessionToken = await getConvergeSessionTokenFunction(totalPrice);    
    const paymentData = {
      ssl_txn_auth_token: convergeSessionToken,
      ssl_card_number: billingData.cardNumber,
      ssl_exp_date: billingData.expiryDate.replace("/", ""),
      ssl_get_token: 'y',
      ssl_add_token: 'y',
      ssl_invoice_number: invoiceNumber,
      ssl_first_name: billingData.firstName,
      ssl_last_name: billingData.lastName,
      ssl_cvv2cvc2: billingData.cvv,
      ssl_avs_address: billingData.address1,
      ssl_city: billingData.city,
      ssl_state: billingData.state,
      ssl_avs_zip: billingData.zipCode,
    };

    if (userId && totalPrice && invoiceNumber && convergeSessionToken) {
      window.ConvergeEmbeddedPayment.pay(paymentData, callback);
    } else {
      setIsLoading((prev) => ({
        ...prev,
        payment: false,
      }));
      setIsSubmitting(false);
      setIsPaymentFailed(true)
    }
  };

  const [shippingForm, setShippingForm] = useState(false);
  const openShippingAddress = () => {
    setShippingForm((prevState) => !prevState);
    if (!shippingForm) {
      setShippingData({
        cardNumber: billingData.cardNumber,
        expiryDate: billingData.expiryDate,
        cvv: billingData.cvv,
        firstName: billingData.firstName,
        lastName: billingData.lastName,
        address1: billingData.address1,
        city: billingData.city,
        state: billingData.state,
        zipCode: billingData.zipCode,
        email: billingData.email,
        phoneNumber: billingData.phoneNumber,
      });
    } else {
      setShippingData({
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        firstName: "",
        lastName: "",
        address1: "",
        city: "",
        state: "",
        zipCode: "",
        email: "",
        phoneNumber: "",
      });
    }
  }



  useEffect(() => {
    const cartIdListFromStorage = localStorage.getItem('cartIdList');
    let parsedCartIdList = [];
    try {
      parsedCartIdList = cartIdListFromStorage ? JSON.parse(cartIdListFromStorage) : [];
      if (!Array.isArray(parsedCartIdList)) {
        parsedCartIdList = [];
      }
    } catch (e) {
      console.error('Error parsing cartIdList:', e);
    }
    if (newCartId && !parsedCartIdList.includes(newCartId)) {
      parsedCartIdList.push(newCartId);
      localStorage.setItem('cartIdList', JSON.stringify(parsedCartIdList));
    }
    fetchCartDetails(parsedCartIdList);
  }, [newCartId]);

  const fetchCartDetails = async (cartIdList) => {
    if (cartIdList.length >= 1) {
      try {
        const getCartDetailsAPIResponse = await getCartDetails(cartIdList);
        if (getCartDetailsAPIResponse.data.client_status_code === 200) {
          setAllCartDetails(getCartDetailsAPIResponse.data.all_cart_details);

          setTax(getCartDetailsAPIResponse.data.tax_amount)
          setSubTotalAmount(getCartDetailsAPIResponse.data.sub_total_amount)
          
          setTotalPrice((parseFloat(getCartDetailsAPIResponse.data.sub_total_amount) + parseFloat(getCartDetailsAPIResponse.data.tax_amount) + (isPickUp ? 0 : parseFloat(shippingCost))).toFixed(2))
          
          setTurnAround(getCartDetailsAPIResponse.data.turn_around)
        } else {
          console.log('donot have the cart details')
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setAllCartDetails()
      setTax()
      setSubTotalAmount()
      setTotalPrice()
      setTurnAround()
    }
  }

  const deleteCart = async (cart_id) => {
    const deleteCartDetailAPIResponse = await deleteCartDetail(cart_id)
    if (deleteCartDetailAPIResponse.data.client_status_code === 200) {
      const cartIdListFromStorage = localStorage.getItem('cartIdList');
      const parsedCartIdList = JSON.parse(cartIdListFromStorage);
      const updatedCartIdList = parsedCartIdList.filter(id => id !== cart_id);
      localStorage.setItem('cartIdList', JSON.stringify(updatedCartIdList));
      fetchCartDetails(updatedCartIdList);
    } else {
      console.log('donot have the cart details')
    }
  }

  // const getInvoiceNumberDetails = async () => {
  //   const getInvoiceNumberAPIResponse = await getInvoiceNumber()
  //   if (getInvoiceNumberAPIResponse.data.client_status_code === 200) {
  //     return getInvoiceNumberAPIResponse.data.invoice_number
  //   } else {
  //     return null
  //   }
  // }

  const proceedCheckout = async () => {
    const invoiceNumber = await getInvoiceNumberDetails()
    const userId = getCookie('_uisap')
    const accessToken = decryptToken(getCookie('_atsap'));
    const refreshToken = decryptToken(getCookie('_rtsap'));
    if (userId && accessToken && refreshToken) {
      navigate('/payment-page', { state: { userId: userId, totalPrice: totalPrice, invoiceNumber: invoiceNumber, couponId: couponId, turnAround: turnAround } });
    } else {
      navigate('/login', { state: { totalPrice: totalPrice, invoiceNumber: invoiceNumber, loginType: 'purchase', couponId: couponId, turnAround: turnAround } })
    }
  }


  const handleInputChange = (event) => {
    setCouponCode(event.target.value);
  };


  // const checkCoupon = async () => {
  //   console.log('\n couponCode------------>', couponCode)
  //   var checkCouponValidationResponse = await checkCouponValidation(couponCode)
  //   console.log('\n checkCouponValidationResponse', checkCouponValidationResponse)
  //   if (checkCouponValidationResponse.data && checkCouponValidationResponse.data.client_status_code === 400 && checkCouponValidationResponse.data.id === 1) {
  //     setCouponCodeMessage(checkCouponValidationResponse.data.message)
  //     setCouponCodeMessageStatus(true);
  //     setTimeout(() => {
  //       setCouponCodeMessageStatus(false)
  //     }, 2000)
  //   } else if (checkCouponValidationResponse.data && checkCouponValidationResponse.data.client_status_code === 200 && checkCouponValidationResponse.data.id === 2) {
      
  //     console.log('\n subTotalAmount ', subTotalAmount)
  //     var duplicateTotalPrice = (parseFloat(subTotalAmount) + parseFloat(tax) + (isPickUp ? 0 : parseFloat(shippingCost))).toFixed(2)
  //     var discountPrice = (duplicateTotalPrice * 5) / 100
  //     console.log('\n discountPrice ', discountPrice)
  //     var subTotalPrice = subTotalAmount - discountPrice
  //     console.log('\n subTotalPrice ', subTotalPrice)
  //     var taxPrice = (subTotalPrice * (8.875 / 100))
  //     console.log('\n taxPrice ', taxPrice)
  //     var final_price = subTotalPrice + taxPrice
  //     console.log('\n final_price  shippingCost ', final_price)

  //     var mainPrice = duplicateTotalPrice - discountPrice

  //     setDiscountSubTotalAmount(parseFloat(subTotalPrice).toFixed(2))
  //     // setTax(parseFloat(taxPrice).toFixed(2))
  //     setDiscountPrice(parseFloat(discountPrice).toFixed(2))

  //     setTotalPrice((parseFloat(mainPrice)).toFixed(2))

  //     setDiscountStatus(true)
  //     setCouponApplyStatus(true)
  //     setCouponId(checkCouponValidationResponse.data.coupon_code)
  //   }
  // }

  // useEffect(()=>{
  //   if (couponCode) {
  //     checkCoupon()
  //   }
  // }, [allCartDetails])


  const [isPickUp, setIsPickUp] = useState(false);
  const handlePickUp = () => {
    setIsPickUp(true);
    setShippingCost(0); // Set delivery cost to zero
    handleDropDown('Delivery Information');
    setTickMark((prev) => ({
      ...prev,
      shipping: true,
    }));
    setDropDownAllowed((prev) => ({
      ...prev,
      delivery: true,
    }));
  };

  const dayMapping = {
    "Mon": "Monday",
    "Tue": "Tuesday",
    "Wed": "Wednesday",
    "Thu": "Thursday",
    "Fri": "Friday",
    "Sat": "Saturday",
    "Sun": "Sunday"
  };
  
  const fullDayName = dayMapping[estimatedShippingRates.deliveryDay] || deliveryInformation.deliveryDay;

  return (
    <div className="relative flex w-full min-h-screen overflow-y-auto justify-center min-[920px]:justify-normal bg-[#EFEEF3] p-4 sm:p-6 md:p-5 lg:px-[4%] lg:py-8">
      {isPageLoading && <FullPageLoader />}
      <div className="w-full sm:w-11/12 md:w-4/5 min-[920px]:w-full bg-white rounded-2xl md:rounded-3xl flex flex-col gap-5 min-[920px]:gap-3 min-[920px]:flex-row min-[920px]:items-start lg:gap-6 p-4 sm:p-6 md:p-5">

        <div className="w-full min-[920px]:w-1/2 duration-300 flex flex-col gap-4">
          <div className="p-2 border rounded-2xl">
            <div className="w-full flex items-center justify-between">
              <button type="button" disabled={!tickMark.login} onClick={() => handleDropDown('Login')} className="w-auto flex items-center gap-[6px] font-medium group">
                <span className="bg-orange-500 text-white px-[13px] py-[2px] rounded-full">1</span>
                <span className="text-orange-500 group-hover:text-orange-600">Login</span>
              </button>
              <div className="w-full flex items-center justify-between"></div>
              {tickMark.login && <span className="text-orange-500"><SiTicktick /></span>}
            </div>
            {dropDownAllowed.login && dropDown === 'Login' && (
              <>
                {isLogin ? (
                  <form onSubmit={loginSubmit} className="w-full flex justify-center">
                    <div className="w-full bg-[#EFEEF3]/50 rounded-xl flex flex-col md:justify-center md:items-center px-2 py-4 lg:px-4 lg:py-3 mt-2">
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="email"
                          className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 md:text-[17px] text-[15px] sm:text-base transform transition-all duration-100'
                        >
                          Email *
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          ref={refs.email}
                          placeholder="Enter email"
                          value={loginData.email}
                          onChange={handleLogin}
                          className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] border ${errorsResponse.email ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                        />
                        {errorsResponse.email && (
                          <label
                            htmlFor="email"
                            className={`absolute right-4 text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px] md:translate-y-[14px]`}
                          >
                            <MdError />
                          </label>
                        )}
                        {errorsResponse.email && (
                          <p className="text-red-500 text-sm duration-500">{errorsResponse.email}</p>
                        )}
                      </div>

                      <div className='relative w-full'>
                        <label htmlFor="password" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                        >
                          Password *
                        </label>
                        <input
                          type={showPassword.password ? 'text' : 'password'}
                          name="password"
                          id="password"
                          ref={refs.password}
                          placeholder="Enter password"
                          minLength={6}
                          maxLength={16}
                          value={loginData.password} 
                          onChange={handleLogin}
                          className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] border ${errorsResponse.password ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500 ${errorsResponse.commonResponse ? 'mb-0' : 'mb-1'}`}
                        />
                        {errorsResponse.password && (
                          <label
                            htmlFor="email"
                            className={`absolute ${loginData.password !== '' ? 'right-12' : 'right-4'} text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px] md:translate-y-[14px]`}
                          >
                            <MdError />
                          </label>
                        )}
                        {loginData.password !== '' && (
                          <button
                            type="button"
                            onClick={() => handleShowPassword('password')}
                            className="absolute right-5 transform text-neutral-500 hover:text-neutral-600 translate-y-3 min-[500px]:translate-y-4 duration-300"
                          >
                            {showPassword.password ? <FaEye size={16} /> : <FaEyeSlash size={16} />}
                          </button>
                        )}
                        {errorsResponse.password !== '' && (
                          <p className="text-red-500 text-sm duration-500">{errorsResponse.password}</p>
                        )}
                      </div>

                      <div className='flex flex-col items-start sm:flex-row sm:items-center w-full sm:justify-between px-2'>
                        <button type="button" onClick={() => openPopUp('forgotPassword')} className="text-orange-500 px-[6px] py-[1px] rounded-lg hover:bg-orange-100/70 text-sm md:text-[14.5px] font-medium hover:text-orange-600 focus:outline-none duration-200">Forget Password?</button>

                        {errorsResponse.commonResponse && (
                          <p className="text-green-500 text-sm duration-500">{errorsResponse.commonResponse}</p>
                        )}
                      </div>

                      <div className='flex w-full items-center justify-between mt-4'>
                        <p className='leading-3 sm:leading-normal'>
                          <span className='text-neutral-600 text-sm md:text-[15px]'>
                            Don’t have an account? <button type="button" className="font-medium text-orange-500 px-[6px] py-[1px] rounded-lg hover:bg-orange-100/70 text-[15px] hover:text-orange-600 duration-200" onClick={() => handleLoginRegist(false)}>Register now</button>
                          </span>
                        </p>
                        <button
                          type="submit"
                          disabled={isLoading.login}
                          className={`block relative rounded-full border-none text-[15px] whitespace-nowrap font-medium bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 md:px-6 md:py-[10px] duration-200 transition-colors ${isLoading.login ? 'text-opacity-0' : 'text-opacity-100'}`}
                        >
                          {isLoading.login && <div className="absolute inset-0 w-full flex justify-center items-center"><div className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 border-2 border-orange-600 border-t-2 border-t-white rounded-full animate-spin"></div></div>}
                          <span>Login <span className="inline md:hidden min-[960px]:inline">& Continue</span></span>
                        </button>
                      </div>
                    </div>
                  </form>
                )
                  : (
                    <form onSubmit={registerSubmit} className="w-full flex justify-center">
                      <div className="w-full bg-[#EFEEF3]/50 rounded-xl flex flex-col md:justify-center md:items-center px-2 py-4 lg:px-4 lg:py-3 mt-2">
                        <div className="w-full ">
                          <h1 className="text-orange-500 text-2xl">
                            Create a Account
                          </h1>
                        </div>
                        <div className="relative w-full mt-3">
                          <label
                            htmlFor="regName"
                            className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                          >
                            Full Name *
                          </label>
                          <input
                            type="text"
                            name="regName"
                            id="regName"
                            placeholder="Enter name"
                            minLength={3}
                            ref={refs.regName}
                            value={registerData.regName}
                            onChange={handleRegister}
                            className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] transform transition-all duration-500 border ${errorsResponse.regName
                              ? "border-[#B3261E] outline-[#B3261E]"
                              : "border-neutral-500 outline-[#FDCFA8]"
                              } rounded outline-2 outline-opacity-0 focus:outline-opacity-100`}
                          />
                          {errorsResponse.regName && <label
                            htmlFor="regName"
                            className='absolute right-4 text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px] md:translate-y-4'
                          >
                            <MdError />
                          </label>}
                          {errorsResponse.regName && (
                            <p className="text-red-500 text-sm px-2">{errorsResponse.regName}</p>
                          )}
                        </div>

                        <div className={`relative w-full ${errorsResponse.regName ? 'mt-[6px]' : 'mt-3'}`}>
                          <label
                            htmlFor="regEmail"
                            className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                          >
                            Email *
                          </label>
                          <input
                            type="email"
                            name="regEmail"
                            id="regEmail"
                            placeholder="Enter email"
                            ref={refs.regEmail}
                            value={registerData.regEmail}
                            onChange={handleRegister}
                            className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] transform transition-all duration-500 border rounded outline-2 outline-opacity-0 focus:outline-opacity-100
                          ${errorsResponse.regEmail
                                ? "border-[#B3261E] outline-[#B3261E]"
                                : "border-neutral-500 outline-[#FDCFA8]"
                              }
                        `}
                          />
                          {errorsResponse.regEmail && <label
                            htmlFor="regEmail"
                            className='absolute right-4 text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px] md:translate-y-4'
                          >
                            <MdError />
                          </label>}
                          {errorsResponse.regEmail && (
                            <p className="text-red-500 text-sm px-2">{errorsResponse.regEmail}</p>
                          )}
                        </div>

                        <div className={`relative w-full ${errorsResponse.regEmail ? 'mt-[6px]' : 'mt-3'}`}>
                          <label
                            htmlFor="regPassword"
                            className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                          >
                            Password *
                          </label>
                          <input
                            type={showPassword.regPassword ? "text" : "password"}
                            name="regPassword"
                            id="regPassword"
                            placeholder="New password"
                            ref={refs.regPassword}
                            minLength={6}
                            maxLength={16}
                            value={registerData.regPassword}
                            onChange={handleRegister}
                            className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] transform transition-all duration-500 border rounded outline-2 outline-opacity-0 focus:outline-opacity-100 
                          ${errorsResponse.regPassword
                                ? "border-[#B3261E] outline-[#B3261E]"
                                : "border-neutral-500 outline-[#FDCFA8]"
                              }
                        `}
                          />
                          {errorsResponse.regPassword && <label
                            htmlFor="regPassword"
                            className={`absolute ${registerData.regPassword !== "" ? 'right-12' : 'right-4'} text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px]`}
                          >
                            <MdError />
                          </label>}
                          {registerData.regPassword !== "" && (
                            <button
                              type="button"
                              onClick={() => handleShowPassword('regPassword')}
                              className="absolute right-4 transform text-neutral-500 hover:text-neutral-600 translate-y-3 min-[500px]:translate-y-4 duration-300"
                            >
                              {showPassword.regPassword ? (
                                <FaEye size={16} />
                              ) : (
                                <FaEyeSlash size={16} />
                              )}
                            </button>
                          )}
                          {errorsResponse.regPassword && (
                            <p className="text-red-500 text-sm px-2">{errorsResponse.regPassword}</p>
                          )}
                        </div>

                        <div className={`relative w-full ${errorsResponse.regPassword ? 'mt-[6px]' : 'mt-3'}`}>
                          <label
                            htmlFor="regConfirmPassword"
                            className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                          >
                            Confirm Password*
                          </label>
                          <input
                            type={showPassword.regConfirmPassword ? "text" : "password"}
                            name="regConfirmPassword"
                            id="regConfirmPassword"
                            placeholder="Confirm password"
                            minLength={6}
                            maxLength={16}
                            ref={refs.regConfirmPassword}
                            onChange={handleRegister}
                            className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] transform transition-all duration-500 border rounded outline-2 outline-opacity-0 focus:outline-opacity-100
                          ${errorsResponse.regConfirmPassword
                                ? "border-[#B3261E] outline-[#B3261E]"
                                : "border-neutral-500 outline-[#FDCFA8]"
                              }
                        `}
                          />
                          {errorsResponse.regConfirmPassword && <label
                            htmlFor="regConfirmPassword"
                            className={`absolute ${registerData.regConfirmPassword !== "" ? 'right-12' : 'right-4'} text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px]`}
                          >
                            <MdError />
                          </label>}
                          {registerData.regConfirmPassword !== "" && (
                            <button
                              type="button"
                              onClick={() => handleShowPassword('regConfirmPassword')}
                              className="absolute right-4 transform text-neutral-500 hover:text-neutral-600 translate-y-3 min-[500px]:translate-y-4 duration-300"
                            >
                              {showPassword.regConfirmPassword ? (
                                <FaEye size={16} />
                              ) : (
                                <FaEyeSlash size={16} />
                              )}
                            </button>
                          )}
                          {errorsResponse.regConfirmPassword && (
                            <p className="text-red-500 text-sm px-2">{errorsResponse.regConfirmPassword}</p>
                          )}
                        </div>
                        {errorsResponse.commonResponse && (
                          <div className="px-2 mt-1">
                            <p className="text-red-500 text-sm">{errorsResponse.commonResponse}</p>
                          </div>
                        )}

                        <div className='flex w-full items-center justify-between mt-4'>
                          <p className='leading-3 sm:leading-normal'>
                            <span className='text-neutral-600 text-sm md:text-[15px]'>
                              Already have an account? <button type="button" className="font-medium text-orange-500 px-[6px] py-[1px] rounded-lg hover:bg-orange-100/70 text-[15px] hover:text-orange-600 duration-200" onClick={() => handleLoginRegist(true)}>Login now</button>
                            </span>
                          </p>
                          <button
                            type='submit'
                            disabled={isLoading.register}
                            className={`hidden min-[621px]:block relative rounded-full border-none text-[13px] sm:text-sm md:text-[15px] font-medium bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 duration-200 ${isLoading.register ? 'text-opacity-0' : 'text-opacity-100'}`}
                          >
                            {isLoading.register && <div className="absolute inset-0 w-full flex justify-center items-center"><div className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 border-2 border-orange-600 border-t-2 border-t-white rounded-full animate-spin"></div></div>}
                            Create account
                          </button>
                          <button
                            type='submit'
                            disabled={isLoading.register}
                            className={`max-[620px]:block hidden relative rounded-full border-none text-[13px] sm:text-sm md:text-[15px] font-medium bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 duration-200 ${isLoading.register ? 'text-opacity-0' : 'text-opacity-100'}`}
                          >
                            {isLoading.register && <div className="absolute inset-0 w-full flex justify-center items-center"><div className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 border-2 border-orange-600 border-t-2 border-t-white rounded-full animate-spin"></div></div>}
                            Create
                          </button>
                        </div>
                      </div>
                    </form>
                  )}

                {showPopUp && (
                  <div className="fixed inset-0 z-10 w-full h-screen flex items-center justify-center bg-black/40">
                    {!passwordForm && (
                      <form onSubmit={emailSubmit} className="max-[460px]:w-11/12 w-4/5 sm:w-3/5 md:w-1/2 xl:w-2/5 bg-white flex flex-col items-center p-4 sm:p-6 rounded-lg">
                        <div className="w-full flex justify-between items-center">
                          <h1 className="text-orange-500 text-2xl">
                            Find your email
                          </h1>
                          <button
                            onClick={() => openPopUp(false)}
                            className="text-neutral-600 hover:text-neutral-700 duration-200 text-2xl"
                          >
                            <IoClose />
                          </button>
                        </div>
                        <div className="relative w-full mt-10">
                          <label
                            htmlFor="forgotEmail"
                            className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                          >
                            Email *
                          </label>
                          <input
                            type="email"
                            name="forgotEmail"
                            id="forgotEmail"
                            placeholder="Enter your email"
                            ref={refs.forgotEmail}
                            value={forgotData.forgotEmail}
                            onChange={handleForgot}
                            className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[14px] transform transition-all duration-500 border ${errorsResponse.forgotEmail
                              ? 'border-[#B3261E] outline-[#B3261E]'
                              : 'border-neutral-500 outline-[#FDCFA8]'
                              } rounded outline-2 outline-opacity-0 focus:outline-opacity-100`}
                          />
                          {errorsResponse.forgotEmail && (
                            <label
                              htmlFor="forgotEmail"
                              className="absolute right-4 text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px]"
                            >
                              <MdError />
                            </label>
                          )}
                          {errorsResponse.forgotEmail && (
                            <p className="text-red-500 text-sm px-2 md:text-[15px]">{errorsResponse.forgotEmail}</p>
                          )}
                          <p className="text-neutral-600 text-[13px] mt-1 ml-1 sm:text-sm md:text-[15px]">
                            If you have forgotten your email, please create a new account.
                          </p>
                        </div>

                        <div className='sm:px-2'>
                          {errorsResponse.commonResponse && (
                            <p className="text-red-500 text-sm md:text-[15px]">{errorsResponse.commonResponse}</p>
                          )}
                        </div>

                        <div className='flex w-full items-center justify-end mt-8 gap-3 sm:gap-4 md:gap-5'>
                          <button
                            type='submit'
                            disabled={isLoading.forgotPassword}
                            className={`relative rounded-full border-none text-[13px] sm:text-sm md:text-[15px] font-medium bg-orange-500 hover:bg-orange-600 text-white px-6 py-3 duration-200 ${isLoading.forgotPassword ? 'text-opacity-0' : 'text-opacity-100'}`}
                          >
                            {isLoading.forgotPassword && <div className="absolute inset-0 w-full flex justify-center items-center"><div className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 border-2 border-orange-600 border-t-2 border-t-white rounded-full animate-spin"></div></div>}
                            Next
                          </button>
                        </div>
                      </form>
                    )}

                    {passwordForm && (
                      <form onSubmit={passwordSubmit} className="max-[460px]:w-11/12 w-4/5 sm:w-3/5 md:w-1/2 xl:w-2/5 bg-white flex flex-col items-center p-4 sm:p-6 rounded-lg">
                        <div className="w-full flex justify-between items-center">
                          <h1 className="text-orange-500 text-2xl">
                            Create a new, strong password.
                          </h1>
                          <button
                            onClick={() => openPopUp(false)}
                            className="text-neutral-600 hover:text-neutral-700 duration-200 text-2xl"
                          >
                            <IoClose />
                          </button>
                        </div>
                        <div className="flex flex-col md:justify-center">
                          <div className="relative w-full mt-6 sm:mt-8">
                            <label
                              htmlFor="forgotNewPassword"
                              className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                            >
                              New password *
                            </label>
                            <input
                              type={showPassword.forgotNewPassword ? "text" : "password"}
                              name="forgotNewPassword"
                              id='forgotNewPassword'
                              placeholder="Enter your new password"
                              minLength={6}
                              maxLength={16}
                              ref={refs.forgotNewPassword}
                              value={forgotData.forgotNewPassword}
                              onChange={handleForgot}
                              className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] border ${errorsResponse.forgotNewPassword ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                            />
                            {errorsResponse.forgotNewPassword && <label
                              htmlFor="forgotNewPassword"
                              className={`absolute ${forgotData.forgotNewPassword !== "" ? 'right-12' : 'right-4'} text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px]`}
                            >
                              <MdError />
                            </label>}
                            {forgotData.forgotNewPassword !== "" && (
                              <button
                                onClick={() => handleShowPassword('forgotNewPassword')}
                                className="absolute right-4 transform text-neutral-500 hover:text-neutral-600 translate-y-3 min-[500px]:translate-y-4 duration-300"
                              >
                                {showPassword.forgotNewPassword ? (
                                  <FaEye size={16} />
                                ) : (
                                  <FaEyeSlash size={16} />
                                )}
                              </button>
                            )}
                            {errorsResponse.forgotNewPassword && (
                              <p className="text-red-500 text-sm duration-500">{errorsResponse.forgotNewPassword}</p>
                            )}
                          </div>

                          <div className={`relative w-full ${errorsResponse.forgotNewPassword ? 'mt-1' : 'mt-3'}`}>
                            <label
                              htmlFor="forgotConfirmPassword"
                              className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                            >
                              Confirm password *
                            </label>
                            <input
                              type={showPassword.forgotConfirmPassword ? "text" : "password"}
                              name="forgotConfirmPassword"
                              id='forgotConfirmPassword'
                              placeholder="Confirm your new password"
                              ref={refs.forgotConfirmPassword}
                              value={forgotData.forgotConfirmPassword}
                              minLength={6}
                              maxLength={16}
                              onChange={handleForgot}
                              className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] border ${errorsResponse.forgotNewPassword ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                            />
                            {errorsResponse.forgotConfirmPassword && <label
                              htmlFor="email"
                              className={`absolute ${forgotData.forgotConfirmPassword !== "" ? 'right-12' : 'right-4'} text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px]`}
                            >
                              <MdError />
                            </label>}
                            {forgotData.forgotConfirmPassword !== "" && (
                              <button
                                type="button"
                                onClick={() => handleShowPassword('forgotConfirmPassword')}
                                className="absolute right-4 transform text-neutral-500 hover:text-neutral-600 translate-y-3 min-[500px]:translate-y-[14px] duration-300"
                              >
                                {showPassword.forgotConfirmPassword ? (
                                  <FaEye size={16} />
                                ) : (
                                  <FaEyeSlash size={16} />
                                )}
                              </button>
                            )}
                            {errorsResponse.forgotConfirmPassword && (
                              <p className="text-red-500 text-sm duration-500">{errorsResponse.forgotConfirmPassword}</p>
                            )}
                          </div>

                          <div className='px-2 flex w-full justify-end'>
                            {errorsResponse.commonResponse && (
                              <p className="text-red-500 text-sm">{errorsResponse.commonResponse}</p>
                            )}
                          </div>

                          <div className='flex justify-end w-full mt-5'>
                            <button
                              type='submit'
                              disabled={isLoading.forgotPassword}
                              className={`relative rounded-full border-none text-[13px] sm:text-sm md:text-[15px] font-medium bg-orange-500 hover:bg-orange-600 text-white px-5 py-[10px] duration-200 ${isLoading.forgotPassword ? 'text-opacity-0' : 'text-opacity-100'}`}
                            >
                              {isLoading.forgotPassword && <div className="absolute inset-0 w-full flex justify-center items-center"><div className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 border-2 border-orange-600 border-t-2 border-t-white rounded-full animate-spin"></div></div>}
                              Create
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="p-2 border rounded-2xl">
            <div className="w-full flex items-center justify-between">
              <button type="button" disabled={!dropDownAllowed.billing} onClick={() => handleDropDown('Billing Information')} className="w-auto flex items-center gap-[6px] font-medium group">
                <span className="bg-orange-500 text-white px-3 py-[2px] rounded-full">2</span>
                <span className="text-orange-500 group-hover:text-orange-600">Billing Information</span>
              </button>
              {tickMark.billing && <span className="text-orange-500"><SiTicktick /></span>}
            </div>
            {dropDownAllowed.billing && dropDown === 'Billing Information' && (
              <form className="w-full flex justify-center">
                <div className="w-full bg-[#EFEEF3]/50 rounded-xl flex flex-col gap-2 md:justify-center md:items-center px-2 py-4 lg:px-4 lg:py-3 mt-2">
                  <div className="w-full flex gap-2 sm:gap-3 md:gap-2 lg:gap-5 xl:gap-7">
                    <div className="w-full flex flex-col gap-[2px]">
                      <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                        First Name *
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={billingData.firstName}
                        onChange={handleBilling}
                        className={`w-full p-2 border ${errorsBill.firstName ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                      />
                      {errorsBill.firstName && <span className="text-red-500 text-sm">{errorsBill.firstName}</span>}
                    </div>
                    <div className="w-full flex flex-col gap-[2px]">
                      <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                        Last Name*
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={billingData.lastName}
                        onChange={handleBilling}
                        className={`w-full p-2 border ${errorsBill.lastName ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                      />
                      {errorsBill.lastName && <span className="text-red-500 text-sm">{errorsBill.lastName}</span>}
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-[2px]">
                    <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                      Address1 *
                    </label>
                    <input
                      type="text"
                      name="address1"
                      placeholder="Street Address"
                      value={billingData.address1}
                      onChange={handleBilling}
                      className={`w-full p-2 border ${errorsBill.address1 ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                    />
                    {errorsBill.address1 && <span className="text-red-500 text-sm">{errorsBill.address1}</span>}
                  </div>
                  <div className="w-full flex gap-2 sm:gap-3 md:gap-2 lg:gap-5 xl:gap-7">
                    <div className="w-full flex flex-col gap-[2px]">
                      <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                        City *
                      </label>
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={billingData.city}
                        onChange={handleBilling}
                        className={`w-full p-2 border ${errorsBill.city ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                      />
                      {errorsBill.city && <span className="text-red-500 text-sm">{errorsBill.city}</span>}
                    </div>
                    <div className="w-full flex flex-col gap-[2px]">
                      <label htmlFor="state" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                        State/Province *
                      </label>
                      {stateList.length > 0 ? (
                        <select name="state" value={billingData.state} onChange={handleBilling} className={`w-full p-2 border ${errorsBill.state ? 'border-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded transform transition-opacity duration-500`}>
                          <option value="">Select a state</option>
                          {stateList.map((state, index) => (
                            <option key={index} value={state.province_code}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      )
                        : (
                          <input
                            type="text"
                            name="state"
                            id="state"
                            placeholder="State/Province"
                            value={billingData.state}
                            onChange={handleBilling}
                            className={`w-full p-2 border ${errorsBill.state ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                          />
                        )}
                      {errorsBill.state && <span className="text-red-500 text-sm">{errorsBill.state}</span>}
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-[2px]">
                    <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                      Zip Code *
                    </label>
                    <input
                      type="number"
                      name="zipCode"
                      placeholder="Zip Code"
                      value={billingData.zipCode}
                      onChange={handleBilling}
                      className={`w-full p-2 border ${errorsBill.zipCode ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                    />
                    {errorsBill.zipCode && <span className="text-red-500 text-sm">{errorsBill.zipCode}</span>}
                  </div>
                  <div className="w-full flex flex-col gap-[2px]">
                    <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                      Email *
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={billingData.email}
                      onChange={handleBilling}
                      className={`w-full p-2 border ${errorsBill.email ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                    />
                    {errorsBill.email && <span className="text-red-500 text-sm">{errorsBill.email}</span>}
                  </div>
                  <div className="w-full flex flex-col gap-[2px]">
                    <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                      Phone Number *
                    </label>
                    <input
                      type="number"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={billingData.phoneNumber}
                      onChange={handleBilling}
                      className={`w-full p-2 border ${errorsBill.phoneNumber ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                    />
                    {errorsBill.phoneNumber && <span className="text-red-500 text-sm">{errorsBill.phoneNumber}</span>}
                  </div>
                  <div className='flex w-full items-center justify-end mt-2'>
                    <button
                      type="button"
                      disabled={isLoading.billing}
                      onClick={billingValidate}
                      className={`block relative rounded-full border-none text-[15px] whitespace-nowrap font-medium bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 md:px-6 md:py-[10px] duration-200 transition-colors ${isLoading.billing ? 'text-opacity-0' : 'text-opacity-100'}`}
                    >
                      {isLoading.billing && <div className="absolute inset-0 w-full flex justify-center items-center"><div className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 border-2 border-orange-600 border-t-2 border-t-white rounded-full animate-spin"></div></div>}
                      <span>Continue</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
          <div className="p-2 border rounded-2xl">
            <div className="w-full flex items-center justify-between">
              <button type="button" disabled={!dropDownAllowed.shipping} onClick={() => handleDropDown('Shipping Information')} className="w-auto flex items-center gap-[6px] font-medium group">
                <span className="bg-orange-500 text-white px-3 py-[2px] rounded-full">3</span>
                <span className="text-orange-500 group-hover:text-orange-600">Shipping Information</span>
              </button>
              {tickMark.shipping && <span className="text-orange-500"><SiTicktick /></span>}
            </div>
            {dropDownAllowed.shipping && dropDown === 'Shipping Information' && (
              <div className="w-full">
                <div className="flex border text-neutral-700 rounded-2xl mt-2 w-full overflow-hidden">
                  <button onClick={() => handleDeliveryAddress(false)} className={`w-full text-center hover:bg-neutral-50 duration-200 ${!isDeliveryAddress && 'bg-blue-50'} border-r py-3 px-1 flex justify-center gap-2 items-center`}><IoLockClosed className="text-orange-600" />Pickup</button>
                  <button onClick={() => handleDeliveryAddress(true)} className={`w-full text-center hover:bg-neutral-50 duration-200 ${isDeliveryAddress && 'bg-blue-50'} py-3 px-1 flex justify-center gap-2 items-center`}><FaTruck className="text-orange-600" />Delivery</button>
                </div>
                {isDeliveryAddress ? (
                  <form className="w-full flex justify-center">
                    <div className="w-full bg-[#EFEEF3]/50 rounded-xl flex flex-col gap-2 md:justify-center md:items-center px-2 py-4 lg:px-4 lg:py-3 mt-2">
                      <div className="w-full flex justify-end">
                        <label className="flex items-center text-neutral-700 text-[14.5px] min-[1700px]:text-base cursor-pointer relative">
                          <input
                            type="checkbox"
                            className="absolute opacity-0 w-0 h-0"
                            onChange={openShippingAddress}
                          />
                          <span
                            className={`inline-block h-6 w-6 rounded border-2 transition-all duration-300 mr-1 ${shippingForm
                              ? "bg-orange-500 border-orange-500/70"
                              : "bg-white border-gray-300"
                              }`}
                          >
                            {shippingForm && (
                              <span className="absolute top-1 left-2 w-1.5 h-3 rotate-45 border-t-0 border-l-0 border-white border-2"></span>
                            )}
                          </span>
                          Same as Billing Addresss
                        </label>
                      </div>
                      <div className="w-full flex gap-2 sm:gap-3 md:gap-2 lg:gap-5 xl:gap-7">
                        <div className="w-full flex flex-col gap-[2px]">
                          <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                            First Name *
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={shippingData.firstName}
                            onChange={handleShipping}
                            className={`w-full p-2 border ${errorsBill.SfirstName ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                          />
                          {errorsBill.SfirstName && <span className="text-red-500 text-sm">{errorsBill.SfirstName}</span>}
                        </div>
                        <div className="w-full flex flex-col gap-[2px]">
                          <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                            Last Name*
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={shippingData.lastName}
                            onChange={handleShipping}
                            className={`w-full p-2 border ${errorsBill.SlastName ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                          />
                          {errorsBill.SlastName && <span className="text-red-500 text-sm">{errorsBill.SlastName}</span>}
                        </div>
                      </div>
                      <div className="w-full flex flex-col gap-[2px]">
                        <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                          Address1 *
                        </label>
                        <input
                          type="text"
                          name="address1"
                          placeholder="Street Address"
                          value={shippingData.address1}
                          onChange={handleShipping}
                          className={`w-full p-2 border ${errorsBill.Saddress1 ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                        />
                        {errorsBill.Saddress1 && <span className="text-red-500 text-sm">{errorsBill.Saddress1}</span>}
                      </div>
                      <div className="w-full flex gap-2 sm:gap-3 md:gap-2 lg:gap-5 xl:gap-7">
                        <div className="w-full flex flex-col gap-[2px]">
                          <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                            City *
                          </label>
                          <input
                            type="text"
                            name="city"
                            placeholder="City"
                            value={shippingData.city}
                            onChange={handleShipping}
                            className={`w-full p-2 border ${errorsBill.Scity ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                          />
                          {errorsBill.Scity && <span className="text-red-500 text-sm">{errorsBill.Scity}</span>}
                        </div>
                        <div className="w-full flex flex-col gap-[2px]">
                          <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                            State/Province *
                          </label>
                          {stateList.length > 0 ? (
                            <select name="state" value={shippingData.state} onChange={handleShipping} className={`w-full p-2 border ${errorsBill.Sstate ? 'border-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded transform transition-opacity duration-500`}>
                              <option value="">Select a state</option>
                              {stateList.map((state, index) => (
                                <option key={index} value={state.province_code}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          )
                            : (
                              <input
                                type="text"
                                name="state"
                                id="state"
                                placeholder="State/Province"
                                value={shippingData.state}
                                onChange={handleShipping}
                                className={`w-full p-2 border ${errorsBill.Sstate ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                              />
                            )}
                          {errorsBill.Sstate && <span className="text-red-500 text-sm">{errorsBill.Sstate}</span>}
                        </div>
                      </div>
                      <div className="w-full flex flex-col gap-[2px]">
                        <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                          Zip Code *
                        </label>
                        <input
                          type="number"
                          name="zipCode"
                          placeholder="Zip Code"
                          value={shippingData.zipCode}
                          onChange={handleShipping}
                          className={`w-full p-2 border ${errorsBill.SzipCode ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                        />
                        {errorsBill.SzipCode && <span className="text-red-500 text-sm">{errorsBill.SzipCode}</span>}
                      </div>
                      <div className="w-full flex flex-col gap-[2px]">
                        <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                          Email *
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={shippingData.email}
                          onChange={handleShipping}
                          className={`w-full p-2 border ${errorsBill.Semail ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                        />
                        {errorsBill.Semail && <span className="text-red-500 text-sm">{errorsBill.Semail}</span>}
                      </div>
                      <div className="w-full flex flex-col gap-[2px]">
                        <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                          Phone Number *
                        </label>
                        <input
                          type="number"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          value={shippingData.phoneNumber}
                          onChange={handleShipping}
                          className={`w-full p-2 border ${errorsBill.SphoneNumber ? 'border-[#B3261E] outline-[#B3261E]' : 'border-neutral-500 outline-[#FDCFA8]'} rounded outline-2 outline-opacity-0 focus:outline-opacity-100 transform transition-opacity duration-500`}
                        />
                        {errorsBill.SphoneNumber && <span className="text-red-500 text-sm">{errorsBill.SphoneNumber}</span>}
                      </div>
                      
                      
                      {/* <div className="w-full flex flex-col gap-[2px]">
                        <label htmlFor="feduxType" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                          FedEx service type: *
                        </label>
                        <div className="flex items-center gap-1 text-neutral-700 mt-2">
                          <input
                            type="radio"
                            name="fedux"
                            id="FEDEX_GROUND"
                            checked={shippingData.fedux === 'FEDEX_GROUND'}
                            value={'FEDEX_GROUND'}
                            onChange={handleShipping}
                          />
                          <label htmlFor="FEDEX_GROUND" className="cursor-pointer mr-2">FedEx Ground</label>
                          <input
                            type="radio"
                            name="fedux"
                            id="FEDEX_EXPRESS_SAVER"
                            checked={shippingData.fedux === 'FEDEX_EXPRESS_SAVER'}
                            value={'FEDEX_EXPRESS_SAVER'}
                            onChange={handleShipping}
                          />
                          <label htmlFor="FEDEX_EXPRESS_SAVER" className="cursor-pointer">FedEx Express</label>
                        </div>
                        {errorsBill.fedux && <span className="text-red-500 text-sm">{errorsBill.fedux}</span>}
                      </div> */}


                      <div className='flex w-full items-center justify-end mt-2'>
                        <button
                          type="button"
                          disabled={isLoading.shipping}
                          onClick={shippingValidate}
                          className={`block relative rounded-full border-none text-[15px] whitespace-nowrap font-medium bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 md:px-6 md:py-[10px] duration-200 transition-colors ${isLoading.shipping ? 'text-opacity-0' : 'text-opacity-100'}`}
                        >
                          {isLoading.shipping && <div className="absolute inset-0 w-full flex justify-center items-center"><div className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 border-2 border-orange-600 border-t-2 border-t-white rounded-full animate-spin"></div></div>}
                          <span>Continue</span>
                        </button>
                      </div>
                    </div>
                  </form>
                )
                  : (
                    <div className="w-full bg-[#EFEEF3]/50 text-neutral-700 rounded-xl flex flex-col px-2 py-4 lg:px-4 lg:py-3 mt-2">
                      <h3 className="font-semibold">Pickup Details</h3>
                      <div className="flex flex-col items-center justify-center mt-4">
                        <BsTruck className="text-6xl text-orange-500" /> {/* Scooter icon */}
                        <p className="text-lg font-semibold mt-2">Store Pickup</p>
                        <p className="text-neutral-600 text-center mt-1">Your order will be ready for pickup at our store.</p>
                      </div>

                      <div className='flex w-full items-center justify-end mt-4'>
                        <button
                          type="button"
                          onClick={handlePickUp}
                          className='block relative rounded-full border-none text-[15px] whitespace-nowrap font-medium bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 md:px-6 md:py-[10px] duration-200 transition-colors text-opacity-100'
                        >
                          <span>Continue</span>
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>

          {/* <div className="p-2 border rounded-2xl">
            <div className="w-full flex items-center justify-between">
              <button type="button" disabled={!dropDownAllowed.delivery} onClick={() => handleDropDown('Delivery Information')} className="w-auto flex items-center gap-[6px] font-medium group">
                <span className="bg-orange-500 text-white px-3 py-[2px] rounded-full">4</span>
                <span className="text-orange-500 group-hover:text-orange-600">Delivery Information</span>
              </button>
              {tickMark.delivery && <span className="text-orange-500"><SiTicktick /></span>}
            </div>
            {dropDownAllowed.delivery && dropDown === 'Delivery Information' && (
              <form onSubmit={paymentSubmit} className="w-full flex flex-col items-center">
                <div className='flex w-full items-start justify-start mt-3 pb-4 border-b border-b-neutral-400'>
                  <h3 className="text-neutral-700 text-lg font-semibold">Shipping Cost: <span className="text-neutral-600 [17px] font-normal">${shippingCost}</span></h3>
                </div>
                <div className="relative w-full mt-3">
                  <label
                    htmlFor="projectName"
                    className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'
                  >
                    Project Name *
                  </label>
                  <input
                    type="text"
                    name="projectName"
                    id="projectName"
                    placeholder="Enter project name"
                    minLength={3}
                    className={`w-full p-2 min-[500px]:p-3 md:px-4 md:py-[10px] transform transition-all duration-500 border ${errorsResponse.projectName
                      ? "border-[#B3261E] outline-[#B3261E]"
                      : "border-neutral-500 outline-[#FDCFA8]"
                      } rounded outline-2 outline-opacity-0 focus:outline-opacity-100`}
                  />
                  {errorsResponse.projectName && <label
                    htmlFor="regName"
                    className='absolute right-4 text-xl text-red-500 transform transition-all duration-100 translate-y-3 min-[500px]:translate-y-[14px] md:translate-y-4'
                  >
                    <MdError />
                  </label>}
                  {errorsResponse.projectName && (
                    <p className="text-red-500 text-sm px-2">{errorsResponse.projectName}</p>
                  )}
                </div>
                <h1 className="w-full bg-[#EFEEF3]/70 text-neutral-700 text-lg font-semibold p-2 mt-3 rounded-xl">Shipment</h1>
                <div className="w-full rounded-xl flex flex-col gap-2 mt-2 lg:mt-3">
                  <div className="flex gap-2 lg:gap-3">
                    {!isPickUp ? (
                      <div className="w-full bg-[#EFEEF3]/50 text-neutral-700 rounded-xl p-2 sm:p-3 md:p-2 lg:px-2 lg:py-3">
                        <h3 className="font-semibold">SHIP FROM</h3>
                        <div className="flex flex-col font-light">
                          <p className="text-[17px] mt-2">{billingData.firstName} {billingData.lastName}</p>
                          <p>{billingData.address1}</p>
                          <p>{billingData.city}, {billingData.state} {billingData.zipCode}</p>
                        </div>
                        <div className="flex flex-col gap-2 mt-3">
                          <h3 className="text-neutral-700 font-semibold">
                            Service type:{" "}
                            <span className="text-neutral-600 font-light">
                              {serviceTypeMapping[shippingData.fedux] || shippingData.fedux}
                            </span>
                          </h3>
                          <h3 className="text-neutral-700 font-semibold">
                            Delivery date:
                            <span className="text-neutral-600 font-light">
                              {new Date(deliveryInformation.deliveryDate).toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                              })}
                            </span>
                          </h3>
                          <h3 className="text-neutral-700 font-semibold">
                            Delivery day: <span className="text-neutral-600 font-light">{dayMapping[deliveryInformation.deliveryDay] || deliveryInformation.deliveryDay}</span>
                          </h3>
                          {deliveryInformation.transitTime && (
                            <h3 className="text-neutral-700 font-semibold">
                              Transit time: <span className="text-neutral-600 font-light">{deliveryInformation.transitTime}</span>
                            </h3>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="w-full bg-[#EFEEF3]/50 text-neutral-700 rounded-xl p-2 sm:p-3 md:p-2 lg:px-2 lg:py-3">
                        <h3 className="font-semibold">PICKUP DETAILS</h3>
                        <div className="flex flex-col font-light">
                          {allCartDetails && allCartDetails.map((item) => (
                            <>
                              {item.turn_around_date && item.turn_around_date !== "None" && (
                                <p className="text-neutral-600 font-light">
                                  Delivery date: <span className="text-neutral-700 font-normal">{item.turn_around_date} (Between 5:00 pm and 7:00 pm)</span>
                                </p>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    )}
                    {!isPickUp && (
                      <div className="w-full bg-[#EFEEF3]/50 text-neutral-700 rounded-xl p-2 sm:p-3 md:p-2 lg:px-2 lg:py-3">
                        <h3 className="font-semibold">SHIP TO</h3>
                        <div className="flex flex-col font-light">
                          <p className="text-[17px] mt-2">{shippingData.firstName} {shippingData.lastName}</p>
                          <p>{shippingData.address1}</p>
                          <p>{shippingData.city}, {shippingData.state} {shippingData.zipCode}</p>
                        </div>

                      </div>
                    )}
                  </div>
                  <div className='flex w-full items-center justify-end mt-4'>
                    <button
                      type="button"
                      onClick={DeliverySubmit}
                      className='block relative rounded-full border-none text-[15px] whitespace-nowrap font-medium bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 md:px-6 md:py-[10px] duration-200 transition-colors text-opacity-100'
                    >
                      <span>Continue</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div> */}


          <div className="p-2 border rounded-2xl">
            <div className="w-full flex items-center justify-between">
              <button type="button" disabled={!dropDownAllowed.payment} onClick={() => handleDropDown('Payment Information')} className="w-auto flex items-center gap-[6px] font-medium group">
                <span className="bg-orange-500 text-white px-3 py-[2px] rounded-full">4</span>
                <span className="text-orange-500 group-hover:text-orange-600">Payment Information</span>
              </button>
              {tickMark.payment && <span className="text-orange-500"><SiTicktick /></span>}
            </div>
            {dropDownAllowed.payment && dropDown === 'Payment Information' && (
              <form onSubmit={paymentSubmit} className="w-full flex justify-center">
                <div className="w-full bg-[#EFEEF3]/50 rounded-xl flex flex-col gap-2 md:justify-center md:items-center px-2 py-4 lg:px-4 lg:py-4 mt-2">
                  {/* Payment fields */}
                  <div className="flex flex-wrap w-full gap-[10px]">
                    <img src={visa} alt="visa" className="w-10" />
                    <img src={bank} alt="bank-transfer" className="w-10" />
                    <img src={discover} alt="discover" className="w-10" />
                    <img src={masterCard} alt="masterCard" className="w-10" />
                  </div>
                  {/* Card Details */}
                  <div className="flex flex-col gap-[2px] w-full">
                    <label htmlFor="cardNumber" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                      Card Number*
                    </label>
                    <input
                      type="text"
                      name="cardNumber"
                      placeholder="Enter your card number"
                      value={billingData.cardNumber}
                      onChange={handleBilling}
                      className={`w-full p-2 transform transition-all duration-500 border ${errorsBill.cardNumber
                        ? "border-[#B3261E] outline-[#B3261E]"
                        : "border-neutral-500 outline-[#FDCFA8]"
                        } rounded outline-2 outline-opacity-0 focus:outline-opacity-100`}
                    />
                    {errorsBill.cardNumber && <span className="text-red-500 text-sm">{errorsBill.cardNumber}</span>}
                  </div>
                  {/* Other fields (Expiry, CVV) */}
                  <div className="flex flex-col gap-[2px] w-full">
                    <label
                      htmlFor="expiryDate"
                      className="font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base"
                    >
                      Expiration Date (MM/YY)*
                    </label>
                    <DatePicker
                      selected={
                        billingData.expiryDate
                          ? new Date(
                            `20${billingData.expiryDate.split("/")[1]}`,
                            parseInt(billingData.expiryDate.split("/")[0]) - 1,
                            1
                          )
                          : null
                      }
                      onChange={handleDateChange}
                      dateFormat="MM/yy"
                      showMonthYearPicker
                      className={`w-full cursor-pointer p-2 transform transition-all duration-500 border ${errorsBill.expiryDate ? "border-[#B3261E] outline-[#B3261E]" : "border-neutral-500 outline-[#FDCFA8]"
                        } rounded outline-2 outline-opacity-0 focus:outline-opacity-100`}
                      placeholderText="MM/YY"
                    />
                    {errorsBill.expiryDate && (
                      <span className="text-red-500 text-sm">{errorsBill.expiryDate}</span>
                    )}
                  </div>
                  <div className="flex flex-col gap-[2px] w-full">
                    <label htmlFor="cvv" className='font-light cursor-text left-2 min-[500px]:left-3 md:left-[14px] text-neutral-700 transform transition-all duration-100 md:text-[17px] text-[15px] sm:text-base'>
                      CVV*
                    </label>
                    <input
                      type="text"
                      name="cvv"
                      placeholder="Enter your CVV"
                      value={billingData.cvv}
                      onChange={handleBilling}
                      className={`w-full p-2 transform transition-all duration-500 border ${errorsBill.cvv
                        ? "border-[#B3261E] outline-[#B3261E]"
                        : "border-neutral-500 outline-[#FDCFA8]"
                        } rounded outline-2 outline-opacity-0 focus:outline-opacity-100`}
                    />
                    {errorsBill.cvv && <span className="text-red-500 text-sm">{errorsBill.cvv}</span>}
                  </div>
                  <div className='flex w-full items-center justify-end mt-4'>
                    <button
                      type="submit"
                      disabled={isLoading.payment}
                      className={`block relative rounded-full border-none text-[15px] whitespace-nowrap font-medium bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 md:px-6 md:py-[10px] duration-200 transition-colors ${isLoading.payment ? 'text-opacity-0' : 'text-opacity-100'}`}
                    >
                      {isLoading.payment && <div className="absolute inset-0 w-full flex justify-center items-center"><div className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 border-2 border-orange-600 border-t-2 border-t-white rounded-full animate-spin"></div></div>}
                      <span><span className='sm:hidden md:inline'>Proceed To</span> Checkout</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>

        {/* h-[calc(100vh-100px)] */}
        <div className='w-full min-[920px]:w-1/2 min-[920px]:hover:w-3/5 duration-300 bg-[#F7F7F7] flex flex-col rounded-lg sm:rounded-xl md:rounded-2xl overflow-y-auto py-1 md:pt-2 md:pb-4 lg:pb-6 pr-2'>
          <div className='customScroll overflow-y-auto h-[300px] min-[920px]:h-auto min-[920px]:max-h-[500px]'>
            <div className='flex flex-col w-full px-4 py-4 md:px-3 md:pt-2 md:pb-0 lg:px-5 lg:pt-2 gap-5 overflow-y-auto'>
              <h2 className="text-neutral-700 text-xl md:text-2xl font-medium md:font-semibold">Cart Items</h2>
              {allCartDetails && allCartDetails.length > 0 ? allCartDetails.map((item, index) =>
                <div className={`relative flex items-start ${index == allCartDetails.length - 1 ? '' : 'border-b border-b-neutral-300'} pb-5 gap-2 sm:gap-4`}>

                  <div className='min-w-16 max-w-20 sm:max-w-28 md:min-w-24 md:max-w-36 min-[920px]:max-w-44 mt-4 border border-neutral-200 rounded-md overflow-hidden'>
                    <img src={item.category_menu_option_image} alt="Product" />
                  </div>

                  <div className="flex gap-2 items-start pr-2 py-2">
                    <div className="text-[15px] sm:text-base flex flex-col">
                      <h3 className='sm:text-xl text-neutral-700 font-medium'>{item.category_menu}</h3>
                      {(() => {
                        if (!item.cart_field_options_serializer) return null;

                        const specialPaperOptions = item.cart_field_options_serializer.filter(
                          option =>
                            ['Gold Embossed', 'Silver Embossed', 'Pearl Embossed'].includes(option.field_name)
                        );
                        
                        const otherOptions = item.cart_field_options_serializer.filter(
                          option =>
                            !['Gold Embossed', 'Silver Embossed', 'Pearl Embossed'].includes(option.field_name)
                        );

                        return (
                          <>
                            {specialPaperOptions.length > 0 && (
                              <>
                                <p className="text-neutral-700 font-normal">Special Paper Type</p>
                                <ul className="list-disc pl-5">
                                  {specialPaperOptions.map((option, index) => (
                                    <li key={index} className="text-neutral-600 font-light">
                                      {option.field_name}: <span className="text-neutral-700 font-normal">{option.option_name}</span>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}

                            {otherOptions.map((option, index) => (
                              <p key={index} className="text-neutral-600 font-light">
                                {option.field_name}: <span className="text-neutral-700 font-normal">{option.option_name}</span>
                              </p>
                            ))}
                          </>
                        );
                      })()}
                      {item.turn_around_date && item.turn_around_date !== "None" && (
                        <p className="text-neutral-600 font-light">
                          Delivery date: <span className="text-neutral-700 font-normal">{item.turn_around_date} (Between 5:00 pm and 7:00 pm)</span>
                        </p>
                      )}
                      <p className='text-base text-neutral-600 font-light'>Your total price: <span className='text-neutral-700 font-normal'>{item.total_amount}</span></p>
                    </div>
                    {/* <button className='text-sm sm:text-[17px] pr-2 pt-2 font-medium text-neutral-600 hover:text-neutral-800 duration-200'><FaInfoCircle /></button> */}
                  </div>

                  <button className='absolute top-2 right-2 text-sm sm:text-[17px] font-medium text-neutral-600 hover:text-neutral-800 duration-200' onClick={() => deleteCart(item.cart_id)}><AiOutlineDelete /></button>

                </div>
              )
                : (
                  <div className="w-full h-16 flex items-center justify-center text-neutral-500">
                    <p>--Your cart is empty--</p>
                  </div>
                )}
            </div>
          </div>

          <div className="border-t mt-2 border-neutral-300">
            <div className='px-4 py-4 md:px-3 md:py-2 lg:px-5 lg:pt-5 lg:pb-0'>
              
              {/* <div className="text-[17px]">
                <h3 className="lg:text-lg sm:w-full sm:text-right font-semibold text-neutral-700">Enter Discount code</h3>
                <div className="w-full flex flex-col items-end justify-center sm:flex-row sm:items-center sm:justify-end sm:gap-2">
                  <input 
                    className="inline-block p-1 border border-gray-300 rounded-l-md rounded-t-md sm:rounded-md bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-200" 
                    placeholder="Enter coupon code" 
                    value={couponCode}
                    onChange={handleInputChange}
                    readOnly={couponApplyStatus}
                  />
                  {!couponApplyStatus &&
                    <button 
                      onClick={checkCoupon}
                      className="px-3 pb-1 sm:py-1 text-white bg-orange-500 hover:bg-orange-600 rounded-b-md sm:rounded-md shadow-md transition duration-200"
                    >
                      Apply
                    </button>
                  }
                </div>
                {couponCodeMessageStatus && 
                  <span className="text-red-500 text-sm">{couponCodeMessage}</span>
                }
              </div> */}

              <div className='text-[17px] py-2 text-neutral-700 border-b'>
                <div className='flex justify-between'>
                  <p>Subtotal</p>
                  <p>$ {formattedSubTotalAmount}</p>
                </div>
                <div className='flex justify-between'>
                  <p>Tax</p>
                  <p>$ {formattedTax}</p>
                </div>
                {/* <div className='flex justify-between'>
                  <p>Delivery Cost</p>
                  <p>$ {shippingCost}</p>
                </div> */}
              </div>

              <div className='px-4 py-2 text-neutral-800 font-semibold border-b text-lg md:text-xl flex justify-between'>
                <p>Grand Total</p>
                <p>$ {totalPrice}</p>
              </div>
              {/* WORKING */}
              

              {/* <div className="flex w-full mt-3">
                <div className="bg-white rounded-lg flex flex-col text-neutral-700 items-start justify-center p-2 sm:p-3 md:p-2 lg:px-2 lg:py-3 w-full">
                  <h3 className="lg:text-lg text-neutral-700 font-semibold mb-2">Estimated Shipping Rates</h3>

                  <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between w-full gap-4">

                    <div className="flex flex-col w-full sm:w-auto">
                      <div className="flex flex-col sm:flex-row sm:items-center sm:gap-2">
                        <input
                          className="inline-block p-1 border border-gray-300 rounded-md bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-200 w-full sm:w-auto"
                          placeholder="Enter zip code"
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                        <button
                          onClick={handleCalculateShippingRates}
                          disabled={isCalculatingShipping || !zipCode || !selectedServiceType}
                          className="px-3 py-1 text-white bg-orange-500 hover:bg-orange-600 rounded-md shadow-md transition duration-200 w-full sm:w-auto mt-2 sm:mt-0"
                        >
                          {isCalculatingShipping ? (
                            <div className="flex items-center justify-center">
                              <div className="w-4 h-4 border-2 border-white border-t-2 border-t-transparent rounded-full animate-spin"></div>
                            </div>
                          ) : (
                            "Calculate"
                          )}
                        </button>
                      </div>

                      <div className="flex flex-col gap-2 mt-2">
                        {[
                          { value: "FEDEX_2_DAY", label: "2-Day Transit" },
                          { value: "PRIORITY_OVERNIGHT", label: "1-Day Transit by 10:30 AM" },
                          { value: "STANDARD_OVERNIGHT", label: "1-Day Transit by 3:00 PM" },
                        ].map((option) => (
                          <label key={option.value} className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="serviceType"
                              value={option.value}
                              checked={selectedServiceType === option.value}
                              onChange={(e) => setSelectedServiceType(e.target.value)}
                            />
                            {option.label}
                          </label>
                        ))}
                      </div>
                    </div>

                    {estimatedShippingRates.totalNetFedexCharge && (
                      <div className="flex flex-col font-light w-full sm:w-auto mt-4 sm:mt-0 sm:ml-4 sm:border-l sm:pl-4">
                        <p className="text-[17px] text-neutral-700">
                          <span className="font-semibold">Estimated Shipping:</span> ${estimatedShippingRates.totalNetFedexCharge}
                        </p>
                        <p className="text-neutral-700">
                          <span className="font-semibold">Delivery Date:</span>{" "}
                          {new Date(estimatedShippingRates.deliveryDate).toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          })}
                        </p>
                        <p className="text-neutral-700">
                          <span className="font-semibold">Delivery Day:</span> {dayMapping[estimatedShippingRates.deliveryDay] || estimatedShippingRates.deliveryDay}
                        </p>
                        {estimatedShippingRates.transitTime && (
                          <p className="text-neutral-700">
                            <span className="font-semibold">Transit Time:</span> {estimatedShippingRates.transitTime}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div> */}


              {/* <div className='px-4 py-3'>
                <button style={{ marginTop: '10px' }} onClick={proceedCheckout} className='text-center w-full px-2 py-3 text-white bg-orange-500 hover:bg-orange-600 duration-200 sm:rounded-md'><span className='sm:hidden md:inline'>Proceed To</span> Checkout</button>
              </div> */}

            </div>
          </div>
        </div>
        <div>
            {isPaymentFailed && 
              <div className="fixed top-0 left-0 w-full h-full bg-neutral-400 flex flex-col justify-center px-5 sm:px-8 items-center z-[150]">
                <div className="bg-white flex flex-col gap-3 sm:gap-5 p-8 sm:p-10 md:p-12 items-center rounded-lg animate-slide-down">
                  <h1 className="text-center text-neutral-700 text-xl sm:text-2xl min-[1600px]:text-4xl font-medium">
                    Your payment is declined
                  </h1>
                  <p className="text-base sm:text-lg min-[1600px]:text-2xl text-center text-neutral-500 font-light">
                  The transaction was declined. Please use a different card or contact issuer.
                  </p>
                  <div className="flex gap-3 sm:gap-4 md:gap-6 justify-end">
                    <button
                    className="text-[15px] md:text-base min-[1600px]:text-xl border border-neutral-400 font-medium block sm:inline text-neutral-700 bg-neutral-200 hover:bg-neutral-300 px-4 py-2 rounded md:px-4 md:py-[10px] duration-200"
                    onClick={() => setIsPaymentFailed(false)}
                    >
                      Return to Shop
                    </button>
                    <button
                    onClick={paymentSubmit}
                    className="block border-none rounded text-[15px] md:text-base min-[1600px]:text-xl font-medium text-white bg-yellow-500 hover:bg-yellow-600 px-4 py-2 md:px-4 md:py-[10px] duration-200">
                      Try again
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>

      </div>
    </div>
  );
};

export default CartPlusLogin;

