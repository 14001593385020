import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { SlHome } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import product from '../../assets/Product/product8.jpg'
import { MdFileDownload } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { getCurrentOrderDetailsAPI, downloadUploadFormImagesAPI, orderStatusList, updateCurrentOrderStatus, deleteOrderPaymentAPI, exportOrderDetails } from '../../serverApi/server';


const CurrentOrders = () => {
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const [estDate, setEstDate] = useState(null);

  const dispatchPopup = () => {
    setPopup(true);
  }

  const [orderStatusListData, setOrderStatusListData] = useState()
  const orderStatusListDetails = async () => {
    try {
      const response = await orderStatusList();
      setOrderStatusListData(response.data.order_status_list_serializer);
    } catch (error) {
      console.error('Error fetching Admin OrdersDetails:',error);
    }
  }

  useEffect(() => {
    orderStatusListDetails()
  }, [])

  useEffect(() => {
    if (popup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [popup]);

  const [ordersDetails, setOrdersDetails] = useState([]);

  const fetchOrdersDetails = async (filter_date, filterMonthYear) => {
    const user_type= "admin";
    const user_id = null;
    try {
      const response = await getCurrentOrderDetailsAPI(user_type, user_id, filter_date, filterMonthYear);
      setOrdersDetails(response.data.all_orders_details);
    } catch (error) {
      console.error('Error fetching Admin OrdersDetails:',error);
    }
  }

  useEffect(() => {
    const dateInEST = null
    const filterMonthYear = null
    fetchOrdersDetails(dateInEST, filterMonthYear);
  }, [])

  const downloadImages = async (order_id, folderName) => {    
    try {
      const response = await downloadUploadFormImagesAPI(order_id);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/zip' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = `${folderName}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error('Error: Unable to download zip file.');
      }
    } catch (error) {
      console.error('Error downloading images:', error);
    }
  };

  const changeOrderStatus = async (orderId, newStatus) => {
    const updateCurrentOrderStatusResponse = await updateCurrentOrderStatus(orderId, newStatus)
    if (updateCurrentOrderStatusResponse.data.client_status_code === 200) {
      setOrdersDetails((prevOrder) =>
        prevOrder.map((order) =>
          order.order_id === orderId
            ? { ...order, order_submission: newStatus }
            : order
        )
      );
    }
  };

  const handleDateChange = async (event) => {
    await setEstDate(event.target.value);
    await setCurrentMonthYear('');
    await fetchOrdersDetails(event.target.value, null);
  };
  const handleDeleteOrder = async (orderId) => {
    const delete_type = 'current_order'
    const deleteOrderAPIResponse = await deleteOrderPaymentAPI(orderId, delete_type)
    if (deleteOrderAPIResponse.data && deleteOrderAPIResponse.data.client_status_code == 200) {
      const updatedOrders = ordersDetails.filter(order => order.order_id !== orderId);
      setOrdersDetails(updatedOrders);
    }
  }

  const [currentMonthYear, setCurrentMonthYear] = useState(null);

  const handleMonthYear = async (event) => {
    await setCurrentMonthYear(event.target.value);
    await setEstDate('')
    await fetchOrdersDetails(null, event.target.value);
  }

  const exportDetails = async () => {
    const exportOrderDetailsResponse = await exportOrderDetails(estDate, currentMonthYear)

    if (exportOrderDetailsResponse) {
      const blob = new Blob([exportOrderDetailsResponse.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Order_Details_${new Date().toISOString().split('T')[0]}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <div className="flex w-full h-screen">
      <div className="fixed h-full custom-scroll">
        <SideBar />
      </div>

      <div className="ml-64 flex-1 overflow-y-auto">
        <Header />
        <section className="bg-[#F5F7FD] px-7 py-6">
          <div className="text-blue-950 flex items-center gap-6 justify-between">
            <h1 className="pr-6 text-2xl font-medium border-r">
              Current Orders
            </h1>
            <div >

              {/* <div className="flex text-sm items-center gap-3">
                <button>
                  <SlHome />
                </button>
                <SlArrowRight />
                <button>
                  Orders
                </button>
                <SlArrowRight />
                <button>
                  Current Orders
                </button>
              </div> */}

              <div>
                <button
                    className="bg-blue-500 hover:bg-blue-400 duration-200 px-4 py-2 rounded text-white mr-4 md:mr-8"
                    onClick={exportDetails}
                  >
                    Export
                </button>
              </div>

            </div>
          </div>

          <section className='bg-white mt-10 py-4 rounded-lg shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]'>
            <div className='px-5 md:px-7 pt-4 flex items-center justify-between gap-2'>
              <div className='flex items-center gap-1 font-light'>
                <p className='text-neutral-700'>show</p>
                <select name="entries" className='border text-sm rounded-md focus:outline-none'>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <p className='text-neutral-700'>entries</p>
              </div>

              <div style={{ display: 'flex', height: '28px' }}>
                
                <input type='month' id="dateInput" value={currentMonthYear} onChange={handleMonthYear} style={{ marginRight: '20px', backgroundColor: '#cde6fd', padding: '19px', borderRadius: '10px' }} />
                
                <input type="date" id="dateInput" value={estDate} onChange={handleDateChange} style={{ marginRight: '20px', backgroundColor: '#cde6fd', padding: '19px', borderRadius: '10px' }} />
                
                <form className='flex items-center gap-1 font-light'>
                  <label htmlFor="entries-search" className='text-neutral-700'>Search:</label>
                  <input type="text" className='border-2 w-auto sm:w-40 text-sm px-2 py-[2px] rounded focus:outline-none'/>
                </form>
              </div>
            </div>

            <div className="overflow-x-auto sm:px-5 md:px-7">
              <table className="min-w-full bg-white mt-4" style={{ borderCollapse: 'separate', borderSpacing: '2px' }}>
                <thead>
                  <tr>

                    {/* <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">FULL <br />NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PHONE NUMBER</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">EMAIL</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ADDRESS</th> 
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PRODUCT IMAGE & FIELDS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />DATE</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />ID</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">AMOUNT</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PAYMENT <br />STATUS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />STATUS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">UPLOAD FILE<br />FOLDER</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">DOWNLOAD <br />PHOTOS</th> */}

                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">DELIVERY <br />DATE</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />DATE</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />ID</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ORDER <br />STATUS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PRODUCT IMAGE & FIELDS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">AMOUNT</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">UPLOAD FILE<br />FOLDER</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">DOWNLOAD<br />PHOTOS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PHONE <br />NUMBER</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">FULL <br />NAME</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">EMAIL</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">PAYMENT <br />STATUS</th>
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">BILLING ADDRESS</th> 
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">SHIPPING ADDRESS</th> 
                    <th className="px-2 sm:px-4 text-start py-2 border border-[#48ABF7] text-white text-xs font-medium tracking-wider bg-blue-400">ACTION</th>
                  </tr>
                </thead>
                <tbody className='text-neutral-800 text-sm'>
                  {ordersDetails && ordersDetails.length > 0 ? (
                    ordersDetails.map((order) => {
                    
                    console.log('\n order.delivery_dateorder.delivery_date', order.delivery_date)
                    var delivery_date_list = order.delivery_date ? order.delivery_date.split(" ") : '';
                    var delivery_date = ''
                    if (delivery_date_list) {
                      console.log('\n delivery_datedelivery_datedelivery_datedelivery_date', delivery_date_list)
                      delivery_date = delivery_date_list[0] + ' ' + delivery_date_list[1] + ' ' + delivery_date_list[2] + ' ' + delivery_date_list[3]
                    }
                    // // console.log('\n delivery_datedelivery_datedelivery_date:', delivery_date)
                    // console.log('\n order.order_dateorder.order_dateorder.order_date:', order.order_date)

                    // const formatDateEST = (dateString) => {
                    //   if (!dateString) return '';
                    //   const date = new Date(dateString);
                    //   const estOffset = -5 * 60;
                    //   const adjustedDate = new Date(date.getTime() + estOffset * 60 * 1000);
                
                    //   const options = { day: 'numeric', month: 'long', year: 'numeric' };
                    //   const formattedDate = adjustedDate.toLocaleDateString('en-US', options);
                    //   console.log('\n formattedDateformattedDateformattedDate', formattedDate)
                    //   return formattedDate
                    // };
                
                    // const delivery_date = formatDateEST(order.delivery_date?.split(" ")[0]);
                    // const order_date = formatDate(order.order_date);
                

                    return (
                      <tr>
                        <td className="px-4 py-2 border border-[#48ABF7]">{delivery_date}</td>
                        <td className="px-4 py-2 border border-[#48ABF7]">{order.order_date}</td>
                        <td className="px-4 py-2 border border-[#48ABF7]">{order.unique_order_id}</td>
                        <td className="px-4 py-2 border border-[#48ABF7]">
                          <select value={order.order_submission}
                            onChange={(e) => changeOrderStatus(order.order_id, e.target.value)}
                            className="px-3 py-2 border border-[#48ABF7] rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
                          >
                            {orderStatusListData.map((orderStatus) => (
                              <option key={orderStatus.id} value={orderStatus.additional_name}>
                                {orderStatus.status_name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td className="px-4 py-2 border border-[#48ABF7]">
                          { order.all_cart_option_details && order.all_cart_option_details.map((option) => (
                            <>
                            <h4><b>{option.category}</b></h4><br/>
                            <table className='border border-[#48ABF7]'>
                              <tr>
                                <td className='border border-[#48ABF7] p-2'>
                                  <div className='w-28 h-28'>
                                    <img src={option.category_menu_option_image} alt="ordered-product" className='w-full h-full'/>
                                  </div>
                                </td>
                                <td>
                                    <table className='border border-[#48ABF7] w-full' >
                                  { option.cart_option_serializer && option.cart_option_serializer.map((field) => (
                                      <tr>
                                        <td className='border border-[#48ABF7] px-1 py-1'>{field.field_name}</td>
                                        <td className='border border-[#48ABF7] px-1 py-1'>{field.option_name}</td>
                                      </tr>
                                  )) }
                                  </table> 
                                </td>
                              </tr>
                            </table>
                            </>
                          )) }
                        </td>
                        <td className="px-4 py-2 border border-[#48ABF7]">{order.amount}</td>
                        <td className="px-4 py-2 border border-[#48ABF7]">{order.upload_folder_name ? order.upload_folder_name : "Not Upload Files"}</td>
                        <td className="px-4 py-2 border border-[#48ABF7]">
                          <button onClick={() => downloadImages(order.order_id, order.upload_folder_name)} className='p-1 flex justify-center w-full rounded-full hover:bg-neutral-100 duration-200'>
                            Download <MdFileDownload className='text-xl'/>
                          </button>
                        </td>
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">{order.phone}</td>
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">{order.first_name} {order.last_name}</td>
                        
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">{order.email}</td>
                        <td className="px-4 py-2 border border-[#48ABF7]"><button className='px-3 py-2 rounded bg-green-600 hover:bg-green-700 duration-200 text-white inline'>{order.payment_status ? "Paid": 'Unpaid'}</button></td>
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">
                          {order.address},
                          {order.city},
                          {order.state},
                          {order.zip}
                        </td>
                        <td className="px-4 py-6 font-normal border border-[#48ABF7]">
                          {order.shipping_address_one},
                          {order.shipping_address_two},
                          {order.shipping_city},
                          {order.shipping_state_province},
                          {order.shipping_postal_code}
                        </td>                        
                        <td className="px-4 py-2 border border-[#48ABF7]">
                          <button
                            className="bg-red-500 text-white py-2 px-3 rounded-full flex items-center"
                            onClick={() => handleDeleteOrder(order.order_id)}
                          >
                            <MdDeleteOutline className="mr-[1px]" />
                            Delete
                          </button>
                        </td>

                        </tr>
                      );
                      })
                      ) : (
                      <tr>
                        <td colSpan="12" className="px-4 py-6 text-center text-neutral-600">
                          No orders available.
                        </td>
                      </tr>
                      )}
                </tbody>
              </table>
            </div>

            <div className='px-5 md:px-7 pt-6 pb-3 text-neutral-700 text-sm font-light flex items-center justify-between gap-2'>
              {/* <p>Showing 1 to 10 of 10 entries</p> */}
              <p>Showing entries</p>
              <div className='flex items-center gap-2'>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Previous</button>
                <p className='px-3 py-1 rounded-full bg-blue-500 text-white font-normal'>1</p>
                <button className='px-3 py-1 rounded-full bg-neutral-200'>Next</button>
              </div>
            </div>

          </section>

        </section>
        <div>
        {popup && 
        <div 
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex flex-col justify-center items-center z-[150]"
        >
          <div 
            className="w-3/4 sm:w-3/5 md:w-1/2 lg:w-2/5 xl:w-1/3 bg-white rounded-lg animate-slide-down"
          >
            <div className="border-b">
              <div className="flex border-b-4 border-b-green-600 justify-center gap-1 sm:gap-2 mx-4 my-8 lg:my-12 py-6 sm:py-8 md:py-12 rounded-md shadow-[0px_0px_6px_2px_rgba(0,0,0,0.1)]">
                <p className="text-base sm:text-lg text-neutral-600 font-medium">Are you sure you want to dispatch this item?</p>
              </div>
            </div>
            <div className="flex gap-2 justify-end px-4 py-4">
              <button 
                className="bg-neutral-500 hover:bg-neutral-600 duration-200 px-3 py-[6px] rounded text-white"
                onClick={() => setPopup(false)}
              >
                Cancel
              </button>
              <button 
                className="bg-green-500 hover:bg-green-600 duration-200 px-3 py-[6px] rounded text-white"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        }
      </div>
      </div>
    </div>
  )
}

export default CurrentOrders